import React, { Component, useContext } from "react";
import _ from 'lodash';
import { XObject, x } from "../XObject";
import { db } from "../db";
import { SystemContext } from "../etc/SystemContext";
import { createEntity } from "../etc/createEntity";
import { $GroupedSelectAttribute } from "../glue/structs/$GroupedSelectAttribute";
import { evaluate, execute } from "../glue/main";
import { Runtime } from "../glue/Runtime";
import { PriorityIcon } from "../etc/PriorityIcon";
import { getScopeTree, objectName, typesInScopes } from "./objectFuncs";
import { ObjectType } from "../types/QueryParentType";
import { createEntityFromQuery, executeQuery } from "../etc/queryFuncs";
import { Tag } from "./Tag";
import { SelectEditor } from "./SelectEditor";
import { TextEditor } from "./TextEditor";
import { RenderData } from "../MyNotionDocument/Editor";
import { types } from "../MyNotionDocument/types";
import { FileUpload, uploadedFileUrl } from "./FileUpload";
import { component, styled } from "../component2";
import { CellType } from "./notionDatabase/CellType";
import { getPathInGraph } from "../etc/queryGraph";
import { entityDisplayName } from "./entityDisplayName";
import { resumeMode } from "../resumeMode";
import { linkColor } from "./linkColor";

export class TextCellType extends CellType {
  id = 'text';
  renderValue(value) {
    if (_.isDate(value)) {
      return 'date object';
    }
    return value;
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
        close={() => {
          close();
        }}
      />
    );
  }
}

export class URLCellType extends CellType {
  id = 'url';
  renderValue(value) {
    return value && <a style={{ color: linkColor  }} href={value} target="_blank" onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>Open</a>;
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
        close={() => {
          close();
        }}
      />
    );
  }
}


export class TitleCellType extends CellType {
  id = 'title';
  showOpenButton(): boolean {
    return true;
  }
  renderValue(value, { row, args }) {
    if (_.isDate(value)) {
      return 'date object';
    }

    return (
      <>
        <RenderData
            ctx={{
            types: types,
          }}
          data={value}
        />
        {(() => {
          const path = getPathInGraph(null, row.id(), args?.baseId);
          return path.length > 0 && (
            <span className="path">
              {path.map(id => {
                return (
                  <span className="comp" key={id} onClick={() => {
                  }}>{entityDisplayName(id)}</span>
                )
              })}
            </span>
          );
        })()}
      </>
    )
  }

  renderEditor({ frame, value, setValue, close }) {
    return <TextEditor
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      setValue={value => {
        setValue(value);
      }}
      value={value()}

      close={() => {
        close();
      }} />;
  }
}

export class SelectCellType extends CellType {
  id = 'select';

  renderValue(value) {
    const column = this.metaData;
    if (column.showAll) {
      return column.options.map(option => {
        return <span key={option._id} style={{
          opacity: value == option._id ? 1 : 0.5,
        }}>
          <Tag text={option.title} />
        </span>;
      });
    }
    return value && [value]?.map?.((option) => (
      <span key={option}>
        <Tag text={column.options.find(o => o._id == option)?.title} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    const col = this.metaData;
    const ref = React.createRef<any>();
    return (
      <SelectEditor
        ref={ref}
        options={col.options}
        createOption={col.addOption}
        editOption={col.editOption}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
          ref.current.forceUpdate();
        }}
      />
    );
  }
}



export class MultiSelectCellType extends CellType {
  id = 'multiSelect';


  defaultValue = [];

  wrapper = styled.div`
    margin: -2px 0 0 -2px;
    display: flex;
    flex-wrap: wrap;
    > span {
      margin: 2px;
    }
  `;

  renderValue(value) {
    const Wrapper = this.wrapper;
    const column = this.metaData;
    return <Wrapper>{value?.map?.((option) => (
      <span key={option}>
        <Tag text={column.options.find(o => o._id == option)?.title} />
      </span>
    ))}</Wrapper>;
  }

  renderEditor({ frame, value, setValue, close }) {
    const col = this.metaData;
    return (
      <SelectEditor
        multi
        options={col.options}
        createOption={col.addOption}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}
      />
    );
  }

}

export class PriorityCellType extends CellType {
  id = 'priority';


  defaultValue = [];

  renderValue(value) {
    return !_.isNil(value) && <PriorityIcon priority={value} />;
    const context = useContext(SystemContext);
    // const column = this.metaData;
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={db.entities.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    // const col = this.metaData;
    return <SelectEditor
      options={[
        {
          _id: '0',
          title: 'No Priority',
        },
        {
          _id: '1',
          title: 'Urgent',
        },
        {
          _id: '2',
          title: 'High',
        },
        {
          _id: '3',
          title: 'Medium',
        },
        {
          _id: '4',
          title: 'Low',
        },
      ]}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value === null ? null : parseInt(value));
      }} />;
  }

}

export class EntityCellType extends CellType {
  id = 'entity';

  defaultValue = [];

  renderValue(value) {
    const context = useContext(SystemContext);
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={db.entities.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return <SelectEditor
      createOption={value => {
        const entity = XObject.obj({
          name: value,
        });
        if (this.metaData.query) {
          createEntityFromQuery(this.metaData.query, entity);
        }
        createEntity(entity, this.metaData.baseEntity); // createEntityNull
        return entity._id;
      }}
      optionDisplay={id => db.entities.findById(id)?.name}
      options={query => {
        let entities;
        if (this.metaData.query) {
          entities = executeQuery(this.metaData.query).map(id => db.entities.findById(id));
        }
        else {
          entities = db.entities.filter(e => {
            if (!_.isString(e.name))
              return false;
            return e.name.toLowerCase().includes(query.toLowerCase());
          })
        }
    
        return entities.map(e => ({
          _id: e._id,
          title: e.name,
        }))
      }}

      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}

export class $GroupedSelectAttribute_CellType extends CellType {
  id = $GroupedSelectAttribute.$;

  icon = 'text';


  defaultValue = [];

  _options() {
    const valuePoint = execute(this.metaData.valuePoint, new Runtime({}));
    const evaluated = evaluate(valuePoint);

    const options = [];
    for (const group of evaluated.groups) {
      for (const entry of group.entries) {
        options.push({
          _id: entry._id,
          color: group.color,
          title: `[${group.name}] ${entry.name}`,
        });
      }
    }

    return options;
  }

  renderValue(value) {
    return value && [value]?.map?.((option) => (
      <span key={option}
        data-value-point={this.metaData.valuePoint}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
        }}
      >
        <Tag text={this._options().find(o => o._id == value)?.title} color={this._options().find(o => o._id == value)?.color} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {

    return <SelectEditor
      // createOption={value => {
      //   const entity = XObject.obj({
      //     name: value,
      //   })
      //   createEntity(entity);
      //   return entity._id;
      // }}
      // optionDisplay={id => db.entities.findById(id)?.name}
      options={this._options()}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}

export class $GroupedSelectAttribute_Multi_CellType extends CellType {
  id = $GroupedSelectAttribute.$ + '_multi';

  icon = 'text';


  defaultValue = [];

  _options() {
    const valuePoint = execute(this.metaData.valuePoint, new Runtime({}));
    const evaluated = evaluate(valuePoint);

    const options = [];
    for (const group of evaluated.groups) {
      for (const entry of group.entries) {
        options.push({
          _id: entry._id,
          color: group.color,
          title: `[${group.name}] ${entry.name}`,
        });
      }
    }

    return options;
  }

  renderValue(value) {
    return value?.map?.((option) => (
      <span key={option}
        data-value-point={this.metaData.valuePoint}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
        }}
      >
        <Tag text={this._options().find(o => o._id == option)?.title} color={this._options().find(o => o._id == option)?.color} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {

    return <SelectEditor
      // createOption={value => {
      //   const entity = XObject.obj({
      //     name: value,
      //   })
      //   createEntity(entity);
      //   return entity._id;
      // }}
      // optionDisplay={id => db.entities.findById(id)?.name}
      options={this._options()}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }}
      multi />;
  }

}

export class EntityTypesCellType extends CellType {
  id = 'entityTypes';
  defaultValue = [];

  renderValue(value) {
    const context = useContext(SystemContext);
    return value?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={db.entityTypes.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close, state }) {
    let scopes = [];
    for (const s of this.metaData.scopes) {
      const tree = getScopeTree(s);
      console.log(s, tree);
      for (const entry of tree) {
        if (!scopes.find(ss => _.isEqual(x(entry), x(ss)))) {
          scopes.push(entry);
        }
      }
    }

    scopes = scopes.filter(s => s.type != ObjectType.type);

    console.log('scopes', scopes, this.metaData.scopes);



    return (
      <SelectEditor
        optionDisplay={id => {
          const type = db.entityTypes.findById(id);
          // if (type?.scope) {
          // return `${type.name} (${objectName(type.scope)})`;
          // } else {
          return type?.name;
          // }
        }}
        multi
        options={query => typesInScopes(this.metaData.scopes).map(id => db.entityTypes.findById(id)).filter(e => {
          if (!_.isString(e.name))
            return false;
          return e.name.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 10).map(e => {

          return ({
            _id: e._id,
            title: e.scope ? `${e.name} (${objectName(e.scope)})` : e.name,
          });
        })}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}

        createOption={value => {
          const newType = XObject.obj({
            name: value,
            scope: scopes.find(s => s.id == state.scope)
          });
          db.entityTypes.push(newType);
          return newType._id;
        }}

        renderCreate={filter => {
          return <>Create&nbsp;<Tag text={filter} />

            <select
              value={state.scope || ''}
              onChange={e => {
                state.scope = e.target.value || null;
              }}
            >
              <option />

              {scopes.map(t => {
                return <option key={t.id} value={t.id}>{objectName(t)}</option>;
              })}
            </select>
          </>;
        }} />
    );
  }
}

export class EntitiesCellType extends CellType {
  id = 'entities';


  defaultValue = [];

  renderValue(value) {
    const context = useContext(SystemContext);
    // const column = this.metaData;
    return value?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={db.entities.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <SelectEditor
        createOption={value => {
          const entity = XObject.obj({
            name: value,
          });
          if (this.metaData.query) {
            createEntityFromQuery(this.metaData.query, entity);
          }
          createEntity(entity, this.metaData.baseEntity); // createEntityNull
          return entity._id;
        }}
        optionDisplay={id => db.entities.findById(id)?.name}
        multi
        options={query => {
          let entities;
          if (this.metaData.query) {
            entities = executeQuery(this.metaData.query).map(id => db.entities.findById(id));
          }
          else {
            entities = db.entities.filter(e => {
              if (!_.isString(e.name))
                return false;
              return e.name.toLowerCase().includes(query.toLowerCase());
            })
          }
      
          return entities.map(e => ({
            _id: e._id,
            title: e.name,
          }))
        }}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }} />
    );
  }

}

@component
class Media extends Component<{ value, setValue }> {
  static styles = styled.div`
    height: 100%;
    > div {
      height: 100%;
    }
    img {
      height: 30px;
      display: block;
    }
  `;
  render() {
    return (
      <FileUpload
        disabled={resumeMode.enabled}
        onUpload={async (file) => {
          this.props.setValue(file);
        }}
      >
        <img src={uploadedFileUrl(this.props.value)} />
      </FileUpload>

    )
  }
}

export class MediaCellType extends CellType {
  renderEditor({ frame, value, setValue, close, state }: { frame: any; value: any; setValue: any; close: any; state: any; }) {
    
  }

  renderValue(value, { setValue }) {
    return (
      <Media value={value} setValue={setValue} />
    )
  }
}