import React, { Component } from "react";
import { component } from "../component";
import { db } from "../db";
import { getPathInGraph, resolvePath } from "../etc/queryGraph";
import { styled } from "../component2";



@component
export class EntityPath extends Component<{ entity, root?, graph?, separator? }> {
  static styles = styled.div``;
  render(Container?) {
    // const path = resolvePath(this.props.graph || '63e969838f513756f28239f7', this.props.root || '63f010a25c10a34f0a51c364', this.props.entity);
    const path = getPathInGraph(null, this.props.entity, this.props.root);

    return <Container className="path">{path.map(id => db.entities.findById(id)?.name || '...').join(' / ')}</Container>;

  }
}
