import _ from 'lodash';
import React, { Component } from 'react';
import { db } from '../db';
import { component, styled } from '../component2';
import { X, XInit, XObject } from '../XObject';
import { EntityViewType } from './EntityRow';
import { GlueStack, PaneContext, RootValue, createRootValuePoint, glueTextStyles } from '../glue/main';
import { InspectState } from './InspectState';
import { componentSystem } from '../componentSystem';
import { Svg } from './Svg';
import { ComponentIDEEditor, EditorState } from '../ide';

@component
export class UIInspectPane extends Component<{ args: {
  entity
  viewType
  mode
  component
}, onClickClose? }> {
  state = XInit(class {
    active

    state = X({
      stack: [],
    })
  });

  static styles = styled.div`
    height: 100%;
    ${glueTextStyles}
    background: #1e1e1e;
    > .close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999999999;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #323232;
      cursor: pointer;
      border-bottom-left-radius: 4px;
      svg {
        width: 12px;
        height: 12px;
        fill: gray;
      }
    }
    > .panes {
      display: flex;
      overflow: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      > .pane {
        min-width: 600px;
        height: 100%;
        overflow: auto;        
      }
    }

    .pane:first-child {
      border-right: 1px solid #ccc;
      padding: 8px;
      box-sizing: border-box;
    }
    .pane.stack {
      display: flex;
      overflow: visible;
      width: 100%;
      > * {
        overflow: auto;
      }
    }

    ${ComponentIDEEditor} {
      height: 100%;
    }
  `;

  editorState = new EditorState(componentSystem);
  render() {
    let c;
    if (this.props.args.mode == InspectState.ui) {
      const entity = db.entities.findById(this.props.args.entity);
      const type = db.entityTypes.findById(entity.type);
  
      c = (
        <>
        <div className="panes">
            <div className="pane">
            <div>
            {type.name}
  
  
          </div>
  
          <div>
            {_.invert(EntityViewType)[this.props.args.viewType]}
          </div>
          <ul>
            {type?.views?.filter?.(v => v.viewType == this.props.args.viewType)?.map(view => {
              return (
                <li key={view._id}>
                  <PaneContext.Provider value={{
                    active: this.state.active,
                    selectValuePoint: id => {
                      this.state.active = id;
                      this.state.state.stack = X([
                        XObject.obj({
                          type: 'valuePoint',
                          id,
                        })
                      ])
                    },
                  }}>
                    <RootValue id={view.valuePoint} />
                    <button
                      onClick={() => {
                        type.views.splice(type.views.indexOf(view), 1);
                      }}
                    >-</button>
                  </PaneContext.Provider>
                </li>
              );
            })}
          </ul>
          <button
            onClick={() => {
              const newValuePoint = createRootValuePoint();
  
              XObject.push(type, 'views', XObject.obj({
                viewType: this.props.args.viewType,
                valuePoint: newValuePoint._id,
              }));
            }}
          >Glue</button>
            </div>
            {this.state.state.stack.length > 0 && <div className="pane stack">
              <GlueStack state={this.state.state} />
            </div>}
          </div>
        </>
      );
    }
    else if (this.props.args.mode == InspectState.code) {
      c = (
        <>
          <ComponentIDEEditor
            componentSystem={componentSystem}
            editorState={this.editorState}
            id={this.props.args.component}
            onClickComponent={() => {

            }}
          />
        </>
      )
    }

    return (
      <>
        {c}
        <span
          className="close"
          onClick={() => {
            this.props.onClickClose();
          }}
        >
          <Svg name="icons8-close" />
        </span>
      </>
    );
  }
}
