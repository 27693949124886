import React, { Component } from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";
import { openWindow } from '../../osHelpers';
import { WindowType } from '../../etc/WindowType';
import { componentSystem } from '../../componentSystem';
import { registerStackSystem } from '../stackSystems';
import { db } from '../../db';
import { CodeComponenType, ComponentEditor, ComponentIDEEditor, EditorState } from '../../ide';

const Set = '5c8a3e0c-7fe5-59d9-8729-3f2405b43e07'

registerChangeAction(Set, {
  name: 'Set String',
});


@component
class Editor extends Component<{ args }> {
  editorState = new EditorState(componentSystem);
  static styles = styled.div`
    height: 100%;
    ${ComponentIDEEditor} {
      height: 100%;
    }
  `
  render() {
    const { args } = this.props;
    return (
      <ComponentIDEEditor
        componentSystem={componentSystem}
        editorState={this.editorState}
        id={args.component}
        onClickComponent={() => {

        }}
        paneWidth={'600px'}
        hideFirstPane={true}
      />
    )
  }
}

registerStackSystem('af6ab1b6-f591-58ab-9a68-cb0a3ab19e64', {
  width: 'auto',
  height: '100%',
  padding: '0px',
  render: args => {
    return (
      <Editor args={args} />
    )
  }
});

@component
class CodeComponentValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`
    color: #ca8f76;
  `;
  timerId
  render() {
    if (this.props.state.content) {
      const comp = db.codeComponents.findById(this.props.state.content);
      if (comp) {
        return comp.name;
      }
    }
    else {
      return (
        <>
          <select
            onChange={e => {
              const comp = componentSystem.createComponent(e.target.value, 'component');
              this.props.state.content = comp._id;
            }}
          >
            <option></option>
            <option value={CodeComponenType.function}>Function</option>
            <option value={CodeComponenType.component}>Component</option>
          </select>
        </>
      )
    }
    return (
      <>
        CID: <_Editor inline id={this.props.elId + this.props.tick} defaultContent={this.props.state.content} onChange={(_, v) => {
          clearTimeout(this.timerId);
          this.timerId = setTimeout(() => {
          if (v !== this.props.state.content && this.props.state.type?.[0] == ValueType.CodeComponent) {
            trackChange(Set, {}, [
              [ UndoActions.modifiedValuePoint, this.props.state._id ],
            ]);
  
            this.props.state.content = v;
          }
        }, 1000);
        }} />

        <button
          onClick={() => {
            openWindow({
              type: WindowType.CodeComponent,
              component: this.props.state.content,
            })

          }}
        >Edit</button>
      </>
    );
  }
}

@component
class CodeComponentSideBarComp extends Component<{ state, useStackSystem }> {
  editorState = new EditorState(componentSystem);
  render() {
    return (
      <>
        <ComponentEditor
          componentSystem={componentSystem}
          component={componentSystem.componentEditorObject(this.props.state.content)}
          editorState={this.editorState}
          onClickComponent={() => {
            console.log('test');
          }}
          onClickedCodeRef={codeElement => {
            this.props.useStackSystem('af6ab1b6-f591-58ab-9a68-cb0a3ab19e64', {
              component: this.props.state.content,
              activeComponent: codeElement._id,
            })
          }}
          onFocus={() => {}}
          path={[]}
          viewState={{} as any}
        />
      </>
    )
  }
}

export const registerCodeComponentType = () => {
  registerType(ValueType.CodeComponent, {
    evaluate: (value, map) => {
      return value.content;
    },
    render: (value, map, state, renderType) => {
      return <span key={value._id} data-value-point={value._id}>{value.content}</span>;
    },
    editorComp: CodeComponentValuePoint,
    sideBarComp: CodeComponentSideBarComp,
  });
};