import { db } from '../db';
import { XObject } from '../XObject';
import { inArchivedSpace } from './inArchivedSpace';




export function getReferences(entityId: string): { sourceEntity; attribute; }[] {
  // return [];
  const references = [];

  for (const entity of db.entities) {
    if (inArchivedSpace(entity, entityId))
      continue;
    if (entity.attributes) {
      for (const attrId in entity.attributes) {
        if (XObject.isArray(entity.attributes[attrId]) && entity.attributes[attrId].includes(entityId)) {
          references.push({
            sourceEntity: entity._id,
            attribute: attrId,
          });
        }
        else if (entity.attributes[attrId] == entityId) {
          references.push({
            sourceEntity: entity._id,
            attribute: attrId,
          });
        }
      }
    }
  }

  return references;
}
