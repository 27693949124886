
export const collectEntitiesGood = blocks => {
  const entities: {
    entity;
    block;
  }[] = [];
  const _collectEntities = blocks => {
    for (const block of blocks) {
      if (block.id) {
        entities.push({ block: block._id, entity: block.id });
      }
      if (block.data?.filter) {
        const r = block.data.filter(e => e?.[1] == 'entity');
        for (const e of r) {
          entities.push({ block: block._id, entity: e[0] });
        }
        // if (r) {
        //   entities.push({ block: block._id, entity: r[0] });
        // }
      }
      if (block.children) {
        _collectEntities(block.children);
      }
    }
  };
  _collectEntities(blocks);

  return entities;
};


export const collectBlocks = (blocks, blockId?, filter?) => {
  const allBlocks = [];
  const _collectBlocks = (blocks, started?) => {
    for (const block of blocks) {
      if (filter && !filter(block)) continue;
      if (!blockId || started || block._id == blockId) allBlocks.push(block);
      if (block.children) {
        _collectBlocks(block.children, started || block._id == blockId);
      }
    }
  };
  _collectBlocks(blocks);

  return allBlocks;
};

export const findBlock = (blocks, blockId) => {
  const _findBlock = blocks => {
    for (const block of blocks) {
      if (block._id == blockId) return block;
      if (block.children) {
        const r = _findBlock(block.children);
        if (r) return r;
      }
    }
  };
  return _findBlock(blocks);
}

