import { XInit } from '../XObject';
import { getStorageState } from './getStorageState';

export const appState = getStorageState('state1', 0, class {
  currentPage;
  panes = [];
  rightSidebar = false;
  leftSidebar = true;

  inspecting: any = null;

  focus = null;

  glue

  glueInspectStack

  lastViewState

  appInspectOverlay
  appInspect

  graphView

  lastActivePane

  currentMode

  showGraphView = false
});


export const memoryAppState = XInit(class {
  hoverValuePoint
  hoveringValuePoints = {}

  user = '6442de826a926a84038cc0d3'

  image = null
  showSidebar = false
})