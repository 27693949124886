import React, { Component } from 'react';
import { component, styled } from '../component2';
import { Svg } from './Svg';
import { isMobile } from '../isMobile';
import { memoryAppState } from '../etc/appState';


@component
export class ObjectPageFrame extends Component<{ icon; path; right?; children?; }> {
  static styles() {
    const { t } = this;
    return styled.div`


      &.embedded {

        > ${t.content} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        }
        &:not(.embedded) {
          > ${t.top} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 16px;
        }

        > ${t.content} {
          position: absolute;
          top: 30px;
          left: 0;
          right: 0;
          bottom: 0;
        }

      }


    `;
  }
  static t = {
    top: styled.div`
      font-size: 8px;   
      border-bottom: 1px solid #f0f0f0; 
      display: flex;
      align-items: center;
      overflow: hidden;

      > .toggleMenu {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        fill: #3131319c;
      }


      .space {
        margin-left: 4px;
      }

      .path {
        svg {
          width: 10px;
          height: 10px;
          vertical-align: middle;
          margin-right: 2px;
          fill: #3131319c;

        }
        white-space: nowrap;
        flex: 1 1 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }
        > * {
          cursor: pointer;
        }
        > *:not(:first-child) {
          &:before {
            content: ' / ';
          }
        }

        > svg + span {
          &:before {
            display: none;
          }
        }

      }

      .more {
        margin-left: 8px;
        svg {
          width: 18px;
          height: 18px;
          fill: #bababa;
        }
      }


      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .sourceCheckbox {
        margin-left: auto;

      }
    `,
    content: styled.div`
      overflow: auto;
      padding: 16px;
    `,
  };

  render() {
    const { t } = ObjectPageFrame;
    return (
      <>
        <t.top>
          {isMobile() && (
            <Svg
              onClick={() => {
                memoryAppState.showSidebar = !memoryAppState.showSidebar;
              }}
              name="menu"
              className="toggleMenu"
            />
          )}
          <span className="path">
            <Svg name={this.props.icon} /> {this.props.path}
          </span>
          <div className="right">
            {this.props.right}
          </div>
        </t.top>
        <t.content>
          {this.props.children}
        </t.content>
      </>
    );
  }
}
