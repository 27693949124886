import { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { XInit, XObject, x } from '../XObject';
import { DraftSelect } from '../etc/draftHelpers';
import { entityTypeName } from '../etc/entityTypeName';
import { styled } from '../component';
import { showContextMenu } from '../helpers';
import { ObjectRef } from '../types/QueryParentType';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { Svg } from './Svg';
import { typeIcon, objectName, getScopeTree, typesInScope } from './objectFuncs';

@component
export class ObjectDisplay extends Component<{obj: ObjectRef, showPath?: boolean, onContextMenu? }> {
  static styles = styled.span`
    display: inline-flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
      flex: 0 0 auto;
    }
    .path {
      display: inline-flex;
      align-items: center;
      color: gray;
      margin-left: 8px;
      font-size: 12px;
      svg {
        fill: gray;
        width: 11px;
        height: 11px;
      }
      > span {
        &:not(:last-child) {
          &:after {
            content: ' / ';
          }
        }
      }
    }
  `;
  render(Container?) {
    const icon = typeIcon(this.props.obj.type);
    let c = <>{icon && <Svg name={icon} />} {objectName(this.props.obj, false)}</>;

    
    return (
      <Container
      onContextMenu={this.props.onContextMenu}
      draggable
      onDragStart={(e) => {
        e.dataTransfer.setData('text/plain', JSON.stringify(this.props.obj));
      }}

      >
        {c}

        {this.props.showPath && (() => {
          const tree = getScopeTree(this.props.obj);
          const path = tree.reverse().slice(1, -1);
          if (path.length) {
            return (
              <span className="path">
                {path.map(obj => {
                  return (
                    <span key={obj.id}><ObjectDisplay obj={obj} /></span>
                  )
                })}
              </span>
            )
  
          }
        })()}
      </Container>
    )
  }
}



@component
export class TypeEditor extends Component<{ value; onChange; inline?; showClear?, right?, scopeObj?: ObjectRef }> {
  static styles = styled.span`
    /* min-width: 34px !important; */
  `;
  
  state = XInit(class {
    scope
  });

  render(Container?) {
    const types = typesInScope(this.props.scopeObj).map(id => db.entityTypes.findById(id)).map(t => ({
      display: t.scope ? `${entityTypeName(t._id)} (${objectName(t.scope)})` : t.name,
      value: t._id,
    }));

    const tree = getScopeTree(this.props.scopeObj);

    // sort by name
    types.sort((a, b) => {
      if (a.display < b.display) return -1;
      if (a.display > b.display) return 1;
      return 0;
    });
    return (
      <Container
        data-asdfasd
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Clear type',
              onClick: () => {
                this.props.onChange(null);
              }
            }
          ]);
        }}
      >
        {this.props.value ? <><span
          onClick={() => {
            openWindow({
              type: WindowType.TypeWindow,
              id: this.props.value,
            })
          }}
        >{entityTypeName(this.props.value)}</span> {this.props.showClear && <button
          onClick={() => {
            this.props.onChange(null);
          }}
        >X</button>}</> : <DraftSelect
          placeholder="Type"
          right={this.props.right}
          inline={this.props.inline}
          id="type"
          options={types}
          stateOnFocus={false}
          keepMenuOpen
          onCreate={value => {
            let selectedScope;
            if (this.state.scope) {
              selectedScope = tree.find(ref => ref.id == this.state.scope);
            }
            const t = XObject.obj({
              name: value,
              scope: selectedScope,
            });
            db.entityTypes.push(t);
            return t._id;

          }}

          onSelect={value => {
            this.props.onChange(value);
          }}

          renderCreate={filter => {
            return (
              <>Create type "{filter}" <select
                value={this.state.scope || ''}

                onChange={e => {
                  this.state.scope = e.target.value || null;
                }}
              >
                {tree.map(ref => {
                  // const obj = getObject(ref);
                  return <option key={ref.id} value={ref.id}>{objectName(ref)}</option>
                })
                }
                </select></>
            );
          }}
        />}
      </Container>
    );
  }
}
