import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { XObject } from '../XObject';
import { AllGraph, GraphViewStyle } from './AllGraph';
import { appState } from '../etc/appState';
import { SystemContext } from '../etc/SystemContext';


@component
export class GraphView__ extends Component {
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    .top {
      border-bottom: 1px solid #e8e8e8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 37px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: #fbfbfb;
      padding: 0 6px;
      white-space: nowrap;
      box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    }
    .bottom {
      position: absolute;
      top: 37px;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `;
  static contextType = SystemContext;
  context: any;
  render() {
    const graphView = XObject.get(appState, 'graphView', {});
    return (
      <>
        <div className="top">
          <select
            value={graphView.view || GraphViewStyle.navStack}
            onChange={e => {
              graphView.view = e.target.value;
            }}
          >
            <option value={GraphViewStyle.navStack}>Nav Stack</option>
            <option value={GraphViewStyle.currentSpace}>Current Space</option>
            <option value={GraphViewStyle.connections}>Connections</option>
          </select>

          <div>
            <input type="checkbox" checked={graphView.edges} onChange={e => {
              graphView.edges = e.target.checked;
            }} /> Edges
          </div>
          <div>
            <input type="checkbox"
              checked={graphView.properties}
              onChange={e => {
                graphView.properties = e.target.checked;
              }} /> Properties
          </div>
          <div>
            <input type="checkbox"
              checked={graphView.pageLinks}
              onChange={e => {
                graphView.pageLinks = e.target.checked;
              }} /> Page links
          </div>

          <div>
            <input type="checkbox"
              checked={graphView.limitToSpace}
              onChange={e => {
                graphView.limitToSpace = e.target.checked;
              }} /> Current Space
          </div>

        </div>
        <div className="bottom">
          <AllGraph
            active={appState.inspecting?.id}
            _onClick={(id, paneIndex, pointer) => {
              appState.inspecting = {
                type: 'entity',
                id,
              };
              this.context.navigate?.({
                type: 'entity',
                id,
              }, paneIndex, pointer);
            }}
            config={{
              edges: graphView.edges,
              properties: graphView.properties,
              pageLinks: graphView.pageLinks,
              view: graphView.view,
              limitToSpace: graphView.limitToSpace,
            }} />
        </div>
      </>
    );
  }
}
