import React, { Component } from "react";
import { component, styled } from "../component";
import ContentEditable from 'react-contenteditable';
import { htmlToText } from 'html-to-text';
import { RichTextEditor } from "./richTextEditor/RichTextEditor";


@component
export class TextEditor extends Component<{ value; setValue; close; frame; debounce? }> {
  static styles = styled.div`
    border-radius: 4px;
    background: white;
    position: relative;
    max-width: calc(100vw - 24px);
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;

    /* width: 100px; */
    /* height: 100px; */
    padding: 6px 9px;

    /* width: 350px; */
    min-height: 35px;
    max-height: 572px;
    box-sizing: border-box;

    > div {
      &:focus {
        outline: none;
      }
    }
  `;

  componentDidMount() {
  }

  render(Container?) {
    let timerId;
    return (
      <Container
        style={{
          minWidth: this.props.frame.width,
          minHeight: this.props.frame.height,
        }}
      >

      <RichTextEditor
        setValue={value => {
          if (this.props.debounce) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
              this.props.setValue(value);
            }, this.props.debounce);
          } else {
            this.props.setValue(value);
          }

        }}
        value={()=> this.props.value}
        />

        {/* <ContentEditable
          ref={e => {
            if (e) {
              console.log(e);
              e.el.current.focus();
              document.execCommand('selectAll', false, null);
              document.getSelection().collapseToEnd();

            }
          }}
          html={this.props.value}
          onChange={e => {
            if (this.props.debounce) {
              clearTimeout(timerId);
              timerId = setTimeout(() => {
                this.props.setValue(htmlToText(e.target.value));
              }, this.props.debounce);
            } else {
              this.props.setValue(htmlToText(e.target.value));
            }
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              console.log("enter");
              this.props.close();
            }
          }} /> */}
      </Container>
    );
  }
}
