import { db } from '../db';
import { deleteEdgeByIndex } from './getEdgesForEntity';

export function deleteEntity(id: any) {
  while (true) {
    const index = db.edges.findIndex(e => e.entities.includes(id));
    if (index === -1) {
      break;
    }
    deleteEdgeByIndex(index);
  }

  const index = db.entities.findIndex(e => e._id === id);
  if (index !== -1) {
    db.entities.splice(index, 1);
  }
}
