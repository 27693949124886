import { WindowType } from "../../etc/WindowType";
import { appState } from "../../etc/appState";
import { openWindow } from "../../osHelpers";
import { renderInspect } from "../../components/EntityInspect";
import { ValueType } from "../ValueType";
import { RenderType, evaluate, getRootValuePoint, mapStructure, render } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { executeCreate } from "./executeCreate";


const $NotionEntityDetails = typeRegistry.registerType({
  $:'2dd82320-9985-5bdc-b004-3529329c9554',
  Entity: 'f9e5ee91-158e-59db-8f5f-43c9f68e5b05',
  States: '3606f358-3990-5471-a782-e95d5c94b42b',
  Attributes: 'ea5809b2-101f-536c-8c1e-30c7cbec2079',
  Sections: {
    $: '298ade01-5b8e-59f9-8b37-61be33a70272',
    $$: '42e27c23-2afb-596f-942b-9af21eaf691f',
    Title: '043a9d49-ced4-5179-a6c6-2f6f419eb25f',
    Content: '779fea61-d27f-5677-81db-b15afd9cd169',
    Adder: '48a20725-e81c-5893-b7bb-81406d57e189',
  },
  RowEntity: '5b46da81-30f5-544a-b5a0-98422126432f',
}, ids => ({
  _id: ids.$,
  name: 'Notion Entity Details',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    },
    {
      id: ids.States,
      name: 'States',
      type: [ValueType.Array, []],
      property: 'states',
    },
    {
      id: ids.Attributes,
      name: 'Attributes',
      type: [ValueType.Array, []],
      property: 'attributes',
    },
    {
      id: ids.Sections.$,
      name: 'Sections',
      type: [ValueType.Array, [ValueType.Structure, ids.Sections.$$, [
        {
          id: ids.Sections.Title,
          name: 'Title',
          type: [],
          property: 'title',
        },
        {
          id: ids.Sections.Content,
          name: 'Content',
          type: [],
          property: 'content',
        },
        {
          scope: [
            {
              id: ids.RowEntity,
              name: '%Row Entity',
            }
          ],
          id: ids.Sections.Adder,
          name: 'Adder',
          type: [],
          property: 'adder',
        },
      ]]],
      property: 'sections',
    },
  ]
}));

structRenderers[$NotionEntityDetails.$] = (value, map, state, renderType) => {
  const mapped = mapStructure(value);
  const entity = evaluate(mapped.entity, map);
  const states = mapped.states && evaluate(mapped.states, map);
  const attributes = mapped.attributes && evaluate(mapped.attributes, map);
  return (
    <>
      {renderInspect(entity, state, null, null, {
        valuePoint: value._id,
        menu: [
          {
            text: 'Edit',
            onClick: () => {
              const root = getRootValuePoint(mapped.states._id);
              appState.glue = {
                valuePoint: value._id,
              }
              // openWindow({
              //   type: WindowType.GlueDev,
              //   valuePoint: mapped.states._id,
              //   // focusValuePoint: mapped.states._id,
              //   currentNamespace: root.namespace,
              // })
            }
          },
        ],
        states,
        attributes,
        statesContextMenu: [
          {
            text: 'Edit',
            onClick: () => {
              // const root = getRootValuePoint(mapped.states._id);
              appState.glue = {
                valuePoint: mapped.states._id,
              }
              // openWindow({
              //   type: WindowType.GlueDev,
              //   valuePoint: mapped.states._id,
              //   currentNamespace: root.namespace,
              // })
            }
          },

        ],
        sections: mapped.sections?.content?.map?.(c => {
          const cMapped = mapStructure(c);
          return {
            _id: c._id,
            contextMenu: [
            ],
            title: render(cMapped.title, map),
            content: render(cMapped.content, map/* {
              [$EntityTemplate.Entity]: state.entity,
            } */, RenderType.full, state),

            adder: cMapped.adder && (() => {
              console.log('adsf');
              executeCreate(cMapped.adder, {
                ...map,
                [$NotionEntityDetails.RowEntity]: entity,
              });
            }),
          };
        }),
      })}



       



    </>
  );
}