import copyToClipboard from 'copy-to-clipboard';
import { component } from '../component2';
import { X } from '../XObject';
import { renderInspect } from './EntityInspect';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { RenderType, evaluate, execute, mapStructure, render } from '../glue/main';
import { Runtime } from '../glue/Runtime';
import { Comp } from '../glue/Comp';
import { db } from '../db';
import { $EntityTemplate } from '../glue/structs/$EntityTemplate';
import { styled } from '../component';
import { getPathInGraph } from '../etc/queryGraph';
import { SystemContext } from '../etc/SystemContext';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { Svg } from './Svg';
import { showContextMenu } from '../helpers';
import { entityDisplayName, entityDisplayView } from './entityDisplayName';
import { deleteEntity } from '../etc/deleteEntity';
import { ObjectPageFrame } from './ObjectPageFrame';

export function attributesForType(id) {
  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
  const executed = execute(templateSupplierId);
  const mapped = mapStructure(executed);
  for (const match of mapped.matches.content) {
    const matchMapped = mapStructure(match);
    if (id == matchMapped.match?.content) {
      return evaluate(matchMapped.attributes);
    }
  }

  return [];
}


export function entitySchema(id, ctx={}) {
  const entity = db.entities.findById(id);
  if (!entity) return;
  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
  if (!templateSupplierId) return null;
  const executed = execute(templateSupplierId, new Runtime(ctx));
  const mapped = mapStructure(executed);
  let template;
  let _match;
  if (mapped?.matches?.content) for (const match of mapped.matches.content) {
    const matchMapped = mapStructure(match);
    if (entity.type == matchMapped.match?.content) {
      _match = match;
      template = matchMapped.template;

      return {
        match,
        name: matchMapped.name?.content,
        template: matchMapped.template,
        elements: matchMapped.elements,
        rowElements: matchMapped.rowElements,
        attributes: matchMapped.attributes,
        states: matchMapped.states,
        titleAttribute: matchMapped.titleAttribute,
        showCreator: matchMapped.showCreator?.content,
        showTimestamp: matchMapped.showTimestamp?.content,
        sets: matchMapped.sets,
        actions: matchMapped.actions,
        queries: matchMapped.queries,
        views: matchMapped.views,
      }
    }
  }
}

@component
export class EntityView extends Comp<{ id; state; embedded?: boolean }> {
  state = X({});

  static contextType = SystemContext;
  context: any;

  render() {
    const entity = db.entities.findById(this.props.id);
    const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
    const executed = templateSupplierId && execute(templateSupplierId, new Runtime(this.context));
    const mapped = executed && mapStructure(executed);
    let template;
    let _match;
    if (mapped?.matches?.content) for (const match of mapped.matches.content) {
      const matchMapped = mapStructure(match);
      if (entity.type == matchMapped.match?.content) {
        _match = match;
        template = matchMapped.template;
      }
    }

    const path = mapped?.pathGraph?.content;

    let c;
    if (template && !this.props.state.source) c = (
      <div>
        {render(template, {
          [$EntityTemplate.Entity]: this.props.id,
        }, RenderType.full, this.props.state)}
      </div>
    )
    else {
      c = renderInspect(this.props.id, this.props.state, null, this.context?.navigate, {
        menu: [
          {
            text: 'Create match',
            onClick: () => {

            },
          }
        ]
      });
    }

    return (
      <ObjectPageFrame
        icon="tesseract"
        path={getPathInGraph(path, this.props.id).concat(this.props.id).map(id => {
          return (
            <span key={id} onClick={() => {
              this.context.navigate({
                type: 'entity',
                id,
              })
            }}>{entityDisplayView(id)}</span>
          )
        })}

        right={(
          <>
                        <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Copy ID',
                      onClick: () => {
                        copyToClipboard(this.props.id);
                      }
                    },
                    {
                      text: 'Edit',
                      onClick: () => {
                        openWindow({
                          type: WindowType.EntityConfig,
                          id: this.props.id,
                        })
                      }
                    },
                    {
                      text: 'Pin',
                      onClick: () => {
                        defaultWorkspace().pinnedEntities.push(this.props.id);
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        deleteEntity(this.props.id);
                      },
                    }
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
          </>
        )}
      >
        {c}
      </ObjectPageFrame>
    )

  }
}
