import type {LanguageId} from './register';
import type {ScopeName, TextMateGrammar, ScopeNameInfo} from './providers';

// Recall we are using MonacoWebpackPlugin. According to the
// monaco-editor-webpack-plugin docs, we must use:
//
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
//
// instead of
//
// import * as monaco from 'monaco-editor';
//
// because we are shipping only a subset of the languages.
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {createOnigScanner, createOnigString, loadWASM} from 'vscode-oniguruma';
import {SimpleLanguageInfoProvider} from './providers';
import {registerLanguages} from './register';
import {rehydrateRegexps} from './configuration';
import VsCodeDarkTheme from './vs-dark-plus-theme';

// import 'monaco-editor/esm/vs/language/typescript/monaco.contribution.js';
// import 'monaco-editor/esm/vs/language/css/monaco.contribution.js';



interface DemoScopeNameInfo extends ScopeNameInfo {
  path: string;
}

let inited = false;

export async function initTextMate() {
  if (inited) return;
  inited = true;
  // In this demo, the following values are hardcoded to support Python using
  // the VS Code Dark+ theme. Currently, end users are responsible for
  // extracting the data from the relevant VS Code extensions themselves to
  // leverage other TextMate grammars or themes. Scripts may be provided to
  // facilitate this in the future.
  //
  // Note that adding a new TextMate grammar entails the following:
  // - adding an entry in the languages array
  // - adding an entry in the grammars map
  // - making the TextMate file available in the grammars/ folder
  // - making the monaco.languages.LanguageConfiguration available in the
  //   configurations/ folder.
  //
  // You likely also want to add an entry in getSampleCodeForLanguage() and
  // change the call to main() above to pass your LanguageId.
  const languages: monaco.languages.ILanguageExtensionPoint[] = [
    // {
    //   id: 'python',
    //   // extensions: [
    //   //   '.py',
    //   //   '.rpy',
    //   //   '.pyw',
    //   //   '.cpy',
    //   //   '.gyp',
    //   //   '.gypi',
    //   //   '.pyi',
    //   //   '.ipy',
    //   //   '.bzl',
    //   //   '.cconf',
    //   //   '.cinc',
    //   //   '.mcconf',
    //   //   '.sky',
    //   //   '.td',
    //   //   '.tw',
    //   // ],
    //   aliases: ['Python', 'py'],
    //   // filenames: ['Snakefile', 'BUILD', 'BUCK', 'TARGETS'],
    //   // firstLine: '^#!\\s*/?.*\\bpython[0-9.-]*\\b',
    // },

    {
      id: 'typescript',
      aliases: [
        "TypeScript",
        "ts",
        "typescript"
      ],
      extensions: [
        ".ts"
      ],
    },

    {
      id: 'typescriptreact',
      aliases: [
        "TypeScript React",
        "tsx"
      ],
      extensions: [
        ".tsx"
      ],
    },

    {
      id: 'source.css.styled',
      aliases: [ 'CSS (Styled Components)' ],
    },

    {
      "id": "scss",
      "aliases": ["SCSS", "scss"],
      "extensions": [".scss"],
    },

    {
      id: 'css',
      aliases: [ 'css', 'CSS' ],
      extensions: ['.css']
    }


  ];
  const grammars: {[scopeName: string]: DemoScopeNameInfo} = {
    'source.python': {
      language: 'python',
      path: 'MagicPython.tmLanguage.json',
    },

    'source.ts': {
      language: 'typescript',
      path: 'TypeScript.tmLanguage.json',

      "tokenTypes": {
        "entity.name.type.instance.jsdoc": "other",
        "entity.name.function.tagged-template": "other",
        "meta.import string.quoted": "other",
        "variable.other.jsdoc": "other"
      },


      "embeddedLanguages": {
        "source.css.scss": "css",
        "meta.embedded.line.ts": "typescript"
      },

    },

    'source.tsx': {
      "language": "typescriptreact",
      "path": "TypeScriptReact.tmLanguage.json",
      "embeddedLanguages": {
        "meta.tag.tsx": "jsx-tags",
        "meta.tag.without-attributes.tsx": "jsx-tags",
        "meta.tag.attributes.tsx": "typescriptreact",
        "meta.embedded.expression.tsx": "typescriptreact",
        "source.css.scss": "css",
        "meta.embedded.line.ts": "typescript"
      },
      "tokenTypes": {
        "entity.name.type.instance.jsdoc": "other",
        "entity.name.function.tagged-template": "other",
        "meta.import string.quoted": "other",
        "variable.other.jsdoc": "other"
      }
    },

    'source.css.scss': {
      "language": "scss",
      "path": "scss.tmLanguage.json"
    },

    'source.css': {
      "language": "css",
      "path": "css.tmLanguage.json",

      "tokenTypes": {
        "meta.function.url string.quoted": "other"
      }

    },



    'source.css.styled': {
      "language": "source.css.styled",
      "path": "css.styled.json"
    },

    // styled: {
    //   "injectTo": [
    //     "source.js",
    //     "source.ts",
    //     "source.jsx",
    //     "source.js.jsx",
    //     "source.tsx",
    //     "source.vue"
    //   ],
    //   "path": "styled-components.json",
    //   "embeddedLanguages": {
    //     "source.css.scss": "css",
    //     "meta.embedded.line.ts": "typescript"
    //   }
    // }




  };

  const fetchGrammar = async (scopeName: ScopeName): Promise<TextMateGrammar> => {
    const {path} = grammars[scopeName];
    const uri = `/grammars/${path}`;
    const response = await fetch(uri);
    const grammar = await response.text();
    const type = path.endsWith('.json') ? 'json' : 'plist';
    return {type, grammar};
  };

  const fetchConfiguration = async (
    language: LanguageId,
  ): Promise<monaco.languages.LanguageConfiguration> => {
    const uri = `/configurations/${language}.json`;
    const response = await fetch(uri);
    const rawConfiguration = await response.text();
    return rehydrateRegexps(rawConfiguration);
  };

  const data: ArrayBuffer | Response = await loadVSCodeOnigurumWASM();
  loadWASM(data);
  const onigLib = Promise.resolve({
    createOnigScanner,
    createOnigString,
  });

  const provider = new SimpleLanguageInfoProvider({
    grammars,
    fetchGrammar,
    configurations: languages.map((language) => language.id),
    fetchConfiguration,
    theme: VsCodeDarkTheme,
    onigLib,
    monaco,
  });
  registerLanguages(
    languages,
    (language: LanguageId) => provider.fetchLanguageInfo(language),
    monaco,
  );

  // const value = getSampleCodeForLanguage(language);
  // const id = 'container';
  // const element = document.getElementById(id);
  // if (element == null) {
  //   throw Error(`could not find element #${id}`);
  // }

  // const r = await fetch('https://cdn.jsdelivr.net/npm/@types/react@16.9.41/index.d.ts');

  
  // monaco.languages.typescript.typescriptDefaults.addExtraLib(
  //   await r.text(),
  //   // 'declare const React: any',
  //   `file:///node_modules/@react/types/index.d.ts`
  //   );
  


  // monaco.languages.typescript.typescriptDefaults.addExtraLib('declare const React: any');
  // monaco.languages.typescript.typescriptDefaults.setCompilerOptions(Object.assign({}, monaco.languages.typescript.typescriptDefaults.getCompilerOptions(), {
  //   jsx: monaco.languages.typescript.JsxEmit.React,
  //   allowNonTsExtensions: true,
  //   allowSyntheticDefaultImports: true,
  //   esModuleInterop: false,
  //   // typeRoots: ["node_modules/@types"],
  //   // module: monaco.languages.typescript.ModuleKind.System,
  //   // moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
  //   noEmit: true,
  // }));

//   const model = monaco.editor.createModel(`const t = (
//   <div>
//     {
//       (() => {
//         return '';
//       })()
//     }
//   </div>
// );

// css\`
//   .test {
//     display: block;
//   }
// \`;
// const asdf = 'adsf';
// const asdf = '';`, 'typescriptreact', monaco.Uri.parse(`file:///hello.tsx`));

//   monaco.editor.create(element, {
//     value: `
// @import '';

// .test {
//     display: block;
// }

// #test {

// }

// div {
    
// }
//     `,
//     model,
//     // language: 'typescriptreact',
//     // language: 'source.css.styled',
//     // language: 'scss', 
//     // language: 'python',
//     theme: 'vs-dark',
//     minimap: {
//       enabled: true,
//     },
//   });
  provider.injectCSS();
}

// Taken from https://github.com/microsoft/vscode/blob/829230a5a83768a3494ebbc61144e7cde9105c73/src/vs/workbench/services/textMate/browser/textMateService.ts#L33-L40
async function loadVSCodeOnigurumWASM(): Promise<Response | ArrayBuffer> {
  const response = await fetch('/onig.wasm');
  const contentType = response.headers.get('content-type');
  if (contentType === 'application/wasm') {
    return response;
  }

  // Using the response directly only works if the server sets the MIME type 'application/wasm'.
  // Otherwise, a TypeError is thrown when using the streaming compiler.
  // We therefore use the non-streaming compiler :(.
  return await response.arrayBuffer();
}

function getSampleCodeForLanguage(language: LanguageId): string {
  if (language === 'python') {
    return `\
import foo

async def bar(): string:
  f = await foo()
  f_string = f"Hooray {f}! format strings are not supported in current Monarch grammar"
  return foo_string
`;
  }

  else if (language == 'typescript' || language == 'typescriptreact') {
    return 'const test = ""; const test = ""';
  }

  throw Error(`unsupported language: ${language}`);
}
