import React, { Component } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { component } from '../component2';
import { X, XObject } from '../XObject';
import { Cell } from './Cell';
import { cellForCol, NotionTable } from './NotionTable';
import { Type } from './Type';
import ContentEditable from 'react-contenteditable';
import { ObjectPageFrame } from './ObjectPageFrame';
import { triggerInspectObject } from '../osHelpers';
import { SystemContext } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import { resolvePath } from './resolvePath';
import { uploadedFileUrl } from './FileUpload';
import { MyNotionDocument } from '../MyNotionDocument/MyNotionDocument';
import { memoryAppState } from '../etc/appState';
import { isMobile } from '../isMobile';

@component
export class TableRowView extends Component<{ window; }> {
  static styles = styled.div`
    .title {
      margin: 0 30px 0;

      margin-bottom: 10px;
      min-height: 31px;
      font-weight: 700;
      line-height: 1.2;
      font-size: 40px;

      font-size: 26px;
      font-weight: 600;
      cursor: text;

      > div {
        /* > * { */
          &:empty::before {
            content: 'Untitled';
            color: rgba(55, 53, 47, 0.15); 
          }
          &:empty:focus::before {
            content: "";
          }
          
        /* } */

      }

      > div:focus {
        outline: none;
      }
    }

    .galleryProperty {
      display: block;
      height: 500px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    
    .cols {
      padding: 8px 30px;
      > div {
        display: flex;

        height: 29px;

        .name {
          width: 160px;
          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          color: #969696;
          flex: 0 0 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            background: #f3f3f3;
          }

        }

        .value {
          color: #4a4a4a;
          flex: 1 1 auto;
          > * {
            width: 100%;
          }

          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          &:hover {
            background: #f3f3f3;
          }
        }

      }
    }

    &.mobile {
      .title {
        margin-left: 0;
        margin-right: 0;
      }
      .cols {
        padding-left: 0;
        padding-right: 0;

        > div {
          .name {
            width: 100px;
          }
        }
      }
    }
  `;
  static contextType = SystemContext;
  context: any;
  render(Container?) {
    const [doc, row] = resolvePath(this.props.window.path);
    const cols = doc.tableData.columns;

    const titleCol = cols.find(col => col.type == Type.name);
    const galleryCol = cols.find(col => col._id == doc.tableData.galleryProperty);

    if (row.blocks && row.blocks.length == 0) {
      row.blocks.push(XObject.obj());
    }
    return (
      <Container
        className={cx({
          mobile: isMobile(),
        })}
      >
        <ObjectPageFrame
          icon={"icons8-page"}
          path={
            <>
              {row.values?.[titleCol._id] || ''}
            </>
          }
          right={null}
        >
          <>
            {galleryCol && <span
            onClick={() => {
              memoryAppState.image = uploadedFileUrl(row.values[galleryCol._id]);
            }}
              className="galleryProperty"
              style={{
                backgroundImage: `url(${uploadedFileUrl(row.values[galleryCol._id])})`
              }}
            />}
            {titleCol && (
              <div className="title">
                <ContentEditable
                  placeholder="Untitled"
                  html={row.values?.[titleCol._id] || ''}
                  onChange={e => {
                    if (!row.values) {
                      row.values = X({
                        [titleCol._id]: e.target.value
                      });
                    }
                    else {
                      row.values[titleCol._id] = e.target.value;
                    }
                  }}
                />
              </div>
            )}
            <div className="cols">
              {cols.filter(col => col.type != Type.name).map(col => {
                const cell = cellForCol(col, row);
                return (
                  <div key={col._id}>
                    <span className="name">{col.title}</span>
                    <span className="value">
                      {cell && (
                        <Cell
                          cell={cell}
                          get={() => {
                            return row.values?.[col._id];
                          }}
                          set={value => {
                            if (!row.values) {
                              row.values = X({
                                [col._id]: value
                              });
                            }
                            else {
                              row.values[col._id] = value;
                            }

                          }}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>

            {row.tableData && (
              <>
                <NotionTable
                  state={this.props.window}
                  editView={id => {
                    triggerInspectObject({
                      type: '95d1b469-6eff-5bcc-88f9-a53e4377f1bf',
                      args: {
                        path: this.props.window.path,
                        view: id,
                      },
                    })
                  }}
                  table={row.tableData}
                  onClickRow={id => {
                    this.context?.navigate?.({
                      type: PaneType.tableRow,
                      path: [...this.props.window.path, id],
                    });
                  }}
                  active={(() => {
                    const next = this.context?.next?.();
                    if (next?.type == PaneType.tableRow) {
                      return next.path[next.path.length - 1];
                    }
                  })()}
                  onClickAI={() => {
                    // this.context?.navigate?.({
                    //   type: PaneType.chatGPT2,
                    //   table: document._id,
                    // });
                  }}
                />
              </>
            )}
            {/* {!row.tableData && <NotionDocumentBad
              inline
              blocks={XObject.get(row, 'blocks', [
                XObject.obj()
              ])}
              setBlocks={blocks => {
                row.blocks = blocks;
              }}
              blockTypes={[]}
              docId={null}
              entity={null}
            />} */}
            {!row.tableData && (
              <MyNotionDocument
                baseEntity={null}
                blocks={XObject.get(row, 'blocks', [
                  XObject.obj()
                ])}
                setBlocks={blocks => {
                  row.blocks = blocks;
                }}
                docId={null}
                extendEntity={null}
                setTitle={null}
                title={null}
              />

            )}


          </>
        </ObjectPageFrame>
      </Container>
    );
  }
}
