import React, { Component } from "react";
import _ from 'lodash';
import cx from "classnames";
import { component, styled } from "../component";
import { XInit, x } from "../XObject";
import { Tag } from "./Tag";
import { NotionButton } from "./AddButton";



@component
export class SelectEditor extends Component<{
  multi?;
  value;
  setValue;
  close;
  createOption?;
  editOption?;
  optionDisplay?;
  renderCreate?;
  frame: {
    width;
    height;
  };
  options: ((query) => {
    title;
    _id;
    color?;
  }[]) | {
    title;
    color?;
    _id;
  }[];
}> {

  static styles = styled.div`
    border-radius: 4px;
    background: white;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
    overflow: hidden;
    width: 300px;

    /* margin: 100px; */

    .header2 {
      max-height: 240px;
      box-shadow: rgba(55, 53, 47, 0.16) 0px -1px inset;
      z-index: 1;
      overflow: hidden auto;
      margin-right: 0px;
      margin-bottom: 0px;
      min-height: 30px;
      display: flex;
      flex-wrap: wrap;

      background: rgba(242, 241, 238, 0.6);
      cursor: text;
      overflow: auto;
      padding: 8px 9px 1px;

      > * {
        margin: 0px 6px 6px 0px;

      }

      input {
        flex: 1 1 0;
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
      }
    }

    .instruction {
      color: #969696;
      fill: rgba(55, 53, 47, 0.45);
      font-size: 12px;
      font-weight: 500;
      line-height: 120%;
      user-select: none;

      padding-left: 14px;
      padding-right: 14px;
      margin-top: 6px;
      margin-bottom: 8px;
      display: block;
    }

    .optionsCont {
      padding: 6px 0;

      .options {
        .option {
          ${NotionButton} {
            svg {
              width: 13px;
              height: 13px;
            }
            margin-left: auto;
            height: 20px;
          }
          margin-left: 4px;
          margin-right: 4px;
          min-height: 28px;
          display: flex;
          align-items: center;
          padding: 0 8px;



          &.focused, &:hover {
            border-radius: 3px;
            background: #f3f3f3;
          }
        }
      }
    }
  `;

  value() {
    let value;
    if (_.isFunction(this.props.value)) {
      value = this.props.value();
    }
    else value = this.props.value;
    if (this.props.multi)
      return value || [];
    else
      return value;
  }

  state = XInit(class {
    query = '';
    focused = 0;
  });

  filteredOptions() {
    return this.options().filter((option) => option.title?.toLowerCase?.().includes?.(this.state.query.toLowerCase()));
  }

  numOfOptions() {
    return this.filteredOptions().length + (this.hasCreateOption() ? 1 : 0);
  }

  focusedOnCreate() {
    return this.state.focused == this.numOfOptions() - 1;
  }

  options() {
    if (_.isFunction(this.props.options))
      return this.props.options(this.state.query);
    else
      return this.props.options || [];
  }

  hasCreateOption() {
    return this.props.createOption && this.state.query.length > 0 && !this.options().find((option) => option.title?.toLowerCase?.() == this.state.query.toLowerCase());
  }

  removeValue(value?) {
    if (this.props.multi) {
      if (value) {
        this.props.setValue(this.value().filter((v) => v != value));        
      }
      else {
        this.props.setValue(this.value().slice(0, -1));
      }
    }
    else {
      this.props.setValue(null);
    }

    this.forceUpdate();

  }

  addValue(value) {
    if (this.props.multi) {
      this.props.setValue([...x(this.value()), value]);
    }
    else {
      this.props.setValue(value);
      this.props.close();
    }

    this.forceUpdate();
  }

  render() {
    const values = (this.props.multi ? this.value() : (this.value() ? [this.value()] : []));
    const showPlaceholder = !values.length;
    return (
      <>
        <div className="header2">
          {values.map((value) => (
            <Tag text={this.props.optionDisplay ? this.props.optionDisplay(value) : this.options().find((option) => option._id === value)?.title} onClickX={() => {
              this.removeValue(value);
            }} />
          ))}

          <input autoFocus type="text" placeholder={showPlaceholder && "Search for an option..."} value={this.state.query} onKeyDown={e => {
            if (e.key == 'Escape') {
              this.props.close();
            }
            if (e.key == 'ArrowDown') {
              // use wrapping
              this.state.focused = (this.state.focused + 1) % this.numOfOptions();
            }
            else if (e.key == 'ArrowUp') {
              // use wrapping
              this.state.focused = (this.state.focused - 1 + this.numOfOptions()) % this.numOfOptions();
            }
            if (e.key == 'Enter') {
              if (this.hasCreateOption() && this.focusedOnCreate()) {
                const value = (e.target as any).value;
                (e.target as any).value = '';
                this.state.query = '';

                const id = this.props.createOption(value);
                this.addValue(id);
              }
              else {
                this.addValue(this.filteredOptions()[this.state.focused]._id);
                this.state.query = '';

              }


            }
            else if (e.key == 'Backspace' && (e.target as any).value == '') {
              this.removeValue();
            }
          }}
            onChange={(e) => {
              this.state.query = (e.target as any).value;
              this.state.focused = 0;

            }} />
        </div>
        <div className="optionsCont">
          <span className="instruction">Select an option or create one</span>
          <div className="options">
            {this.filteredOptions().map((option, i) => (
              <div className={cx('option', { focused: i == this.state.focused })} key={option._id}
                onClick={() => {
                  this.addValue(option._id);
                }}
              >
                <Tag text={option.title} color={option.color} />

                {this.props.editOption && <NotionButton img="dots"
                  onClick={e => {
                    e.stopPropagation();
                    this.props.editOption?.(e, option);
                  }}
                />}
              </div>
            ))}

            {this.hasCreateOption() && (
              <div className={cx('option', { focused: this.focusedOnCreate() })}
                onClick={() => {
                  // this.props.value.push(option._id);
                }}
              >
                {this.props.renderCreate ? this.props.renderCreate(this.state.query) : (<>
                  Create&nbsp;<Tag text={this.state.query} />
                </>
                )}
              </div>
            )}

          </div>
        </div>
      </>
    );
  }
}
