import { db } from "../../db";
import { NotionDocumentBad as NotionDocumentBad } from "../../components/NotionDocumentBad";
import { X, x, XTouch } from "../../XObject";
import { evaluate, execute, getValuePoint, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { ValueType } from "../ValueType";
import juration from "../../juration";
import { resolveEntityBinding } from "./resolveBinding";
import { defaultWorkspace } from "../../etc/defaultWorkspace";
import { Runtime } from "../Runtime";

const $SumAggregate = typeRegistry.registerType({
  $: '7998aaa9-fad8-5b20-b2e1-7d280a1ba529',
  Attribute: '3bd7402a-4d21-5939-94bf-6a23624eb034',
}, ids => ({
  _id: ids.$,
  name: 'Sum Aggregate',
  definition: [
    {
      id: ids.Attribute,
      name: 'Attribute',
      type: [],
      property: 'attribute',
    }
  ]
}));

function calcSumAggregate(agg, block) {
  const mapped = mapStructure(agg);
  const attribute = mapped.attribute.content;

  const entities = [];
  const collectEntities = blocks => {
    for (const block of blocks) {
      if (block.id) {
        entities.push(block.id);
      }
      if (block.children) {
        collectEntities(block.children);
      }
    }
  }
  collectEntities([block]);

  let sum = 0;
  for (const id of entities) {
    const entity = db.entities.findById(id);
    if (entity) {
      const value = entity.attributes?.[attribute];
      if (value) {
        sum += value;
      }
    }
  }

  const attrType = db.attributeTypes.findById(attribute);

  if (attrType?.type == 'duration') {
    return juration.stringify(sum);
  }

  return sum;
}

export const $DocumentCheckbox = typeRegistry.registerType({
  $: 'ff893cfd-7383-5e57-bbf0-4990e9ff4197',
  Binding: '7f86c372-e0e2-595e-a757-e12cf9ec980f',
  Disabled: 'c2e9ff09-d10f-55c6-a687-8822ab46aef0',
}, ids => ({
  _id: ids.$,
  name: '[Document] Checkbox',
  definition: [
    {
      id: ids.Binding,
      name: 'Binding',
      type: [],
      property: 'binding',
    },
    {
      id: ids.Disabled,
      name: 'Disabled',
      type: [],
      property: 'disabled',
    }
  ]
}));

const $Content = typeRegistry.registerType({
  $: '71eb3965-f44a-59ae-aec5-ab172bd54d9c',
}, ids => ({
  _id: ids.$,
  name: 'Block Content',
  definition: []
}));

const $Badge = typeRegistry.registerType({
  $: '0f973a29-13c6-5839-9c5e-18b96248b272',
  Content: '583a5a81-13d7-54cc-b275-bb10ad8bd8dd',
}, ids => ({
  _id: ids.$,
  name: 'Badge',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    }
  ]
}));

const $Attribute = typeRegistry.registerType({
  $: 'dd073724-0dbe-5ad8-aac6-007154d7f39d',
  Attribute: 'd3f2cc3d-4a07-5674-ad46-d42c50506932',
  Name: '46dbcb86-b502-515b-bfd3-87cd0b89e053',
}, ids => ({
  _id: ids.$,
  name: '[Document] Attribute',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [ValueType.String],
      property: 'name',
    },
    {
      id: ids.Attribute,
      name: 'Attribute',
      type: [],
      property: 'attribute',
    }
  ]
}));

export const $Document = typeRegistry.registerType({
  $: 'd7a8740c-d670-5739-bdd9-c8347ce00014',
  Entity: 'dd45186d-1774-5106-a59f-b5cbd0724ba4',
  BlockTypes: {
    $: '7c3af4b3-3040-5ca1-8628-8d5bebdd4e59',
    Name: 'ded3399d-3efc-5485-b485-426376032b45',
    Elements: 'aae49399-a9cb-5918-a442-e85b217d3a46',
    EntityType: '8ff77585-9624-546b-9ef5-46a0f86420ff',
    Parent: '15f6c15b-5c4f-559b-84aa-47ec1957571d',
    Stateful: '8e9ec8a8-6110-5664-8486-eab657ff5c97',
  }
}, ids => ({
  _id: ids.$,
  name: 'Document',
  definition: [
    {
      scope: [
        {
          id: ids.Entity,
          name: '%Entity',
          type: [],
        }
      ],

      id: ids.BlockTypes.$,
      name: 'Block Types',
      property: 'blockTypes',
      type: [ ValueType.Array, [ValueType.Structure, '07072655-f6b8-5334-bd53-0940cbe98145', [
        {
          id: ids.BlockTypes.Name,
          name: 'Name',
          type: [ValueType.String],
          property: 'name',
        },
        {
          id: ids.BlockTypes.Elements,
          name: 'Elements',
          type: [ValueType.Array, []],
          property: 'elements',
        },
        {
          id: ids.BlockTypes.EntityType,
          name: 'Entity Type',
          type: [],
          property: 'entityType',
        },
        {
          id: ids.BlockTypes.Parent,
          name: 'Parent',
          type: [],
          property: 'parent',
        },
        {
          id: ids.BlockTypes.Stateful,
          name: 'Stateful',
          type: [],
          property: 'stateful',
        },
      ]]],
    }
  ]
}));

export function setEntityState(entity, state, value) {
  if (value) {
    if (!entity.states) entity.states = X({
      [state]: value
    });
    else {
      entity.states[state] = value;
    }
  }
  else {
    delete entity.states[state];
  }
}

export function resolveBlockTypes(value, map) {
  const mapped = mapStructure(value);
  return mapped.blockTypes.content.map(blockType => {
    const m = mapStructure(blockType);

    return {
      _id: blockType._id,
      name: evaluate(m.name, map),
      entityType: m.entityType?.content,
      parent: m.parent && evaluate(m.parent, map),
      stateful: !!m.stateful?.content,
      elements: (m?.elements?.content?.map?.(element => {
        const m = mapStructure(element);
        if (m.disabled?.content) return null;
        let type;

        let set, get, bindingType;
        if (element.type?.[1] == $DocumentCheckbox.$) {
          type = 'checkbox';

          get = (entityId) => {
            return resolveEntityBinding(entityId, m.binding, map).get();
          }

          set = (entityId, value) => {
            return resolveEntityBinding(entityId, m.binding, map).set(value);
          }

          // const stateValue = m.binding.content;
          // const state = db.stateTypes.find(st => st.values.find(v => v._id == stateValue));

          // get = (entityId) => {
          //   if (!entityId) return false;
          //   const entity = db.entities.findById(entityId);
          //   return entity?.states?.[state._id] == stateValue;
          // }
          // set = (entityId, value) => {
          //   const entity = db.entities.findById(entityId);
          //   setEntityState(entity, state._id, value ? stateValue : undefined);
          // }
        }
        else if (element.type?.[1] == $Attribute.$ && m.attribute) {
          type = 'attribute';

          const attr = db.attributeTypes.findById(m.attribute.content);

          bindingType = attr.type;

          get = (entityId) => {
            const entity = db.entities.findById(entityId);
            const attr = db.attributeTypes.findById(m.attribute.content);
            if (!entity || !attr) return undefined;
            const v = entity.attributes?.[m.attribute.content];

            if (attr.type == 'number') return parseFloat(v);
            if (attr.type == 'duration') return v && juration.stringify(v);
            return v;
          }

          set = (entityId, value) => {
            const entity = db.entities.findById(entityId);
            const attr = db.attributeTypes.findById(m.attribute.content);
            if (attr.type == 'number') value = parseFloat(value);
            if (attr.type == 'duration') value = juration.parse(value);

            if (value) {
              if (!entity.attributes) entity.attributes = X({
                [m.attribute.content]: value
              });
              else {
                entity.attributes[m.attribute.content] = value;
              }
            }
            else {
              delete entity.attributes[m.attribute.content];
            }
          }
        }
        else if (element.type?.[1] == $Badge.$) {
          type = 'badge';
          get = (block) => {
            if (m.content.type?.[1] == $SumAggregate.$) {
              return calcSumAggregate(m.content, block);
            }
            else {
              return 'poop';
            }
          }

          set = () => {

          }
        }

        return {
          _id: element._id,
          type,
          title: m.name && evaluate(m.name),
          binding: {
            type: bindingType,
            get: (entity) => get(entity),
            set: (entity, value) => {
              set(entity, value);
            }
          }
        };
      }) || []).filter(Boolean),
      // type,
      // binding: {
      //   get: () => false,
      //   set: value => {
      //     console.log('set', value);
      //   }
      // }
    }
  });
}

structRenderers[$Document.$] = (value, map, state) => {
  const mapped = mapStructure(value);

  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
  const executed = execute(templateSupplierId, new Runtime({}));
  const m = mapStructure(executed);


  return (
    <>
      <NotionDocumentBad
        docId={null}
        entity={null}
        blockTypes={m.blockTypes.content.map(blockType => {
          const m = mapStructure(blockType);

          return {
            _id: blockType._id,
            name: evaluate(m.name),
            elements: m?.elements?.content?.map?.(element => {
              const m = mapStructure(element);
              let type;

              let set, get;
              if (element.type?.[1] == $DocumentCheckbox.$) {
                type = 'checkbox';

                const stateValue = m.binding.content;
                const state = db.stateTypes.find(st => st.values.find(v => v._id == stateValue));

                get = (entityId) => {
                  const entity = db.entities.findById(entityId);
                  return entity.states[state._id] == stateValue;
                }
                set = (entityId, value) => {
                  console.log(x(state), stateValue);


                  const entity = db.entities.findById(entityId);
                  if (value)
                    entity.states[state._id] = stateValue;
                  else
                    delete entity.states[state._id];
                    
                  // entity.states[state._id] = stateValue;
                  
                }
              }
      
              return {
                _id: element._id,
                type,
                binding: {
                  get: (entity) => get(entity),
                  set: (entity, value) => {
                    console.log('set', entity, value);
                    set(entity, value);
                  }
                }
              };
            }),
            // type,
            // binding: {
            //   get: () => false,
            //   set: value => {
            //     console.log('set', value);
            //   }
            // }
          }
        })}
      />
    </>
  );
};
