export enum PaneType {
  pageEntities = '250d2880-357f-598d-ae7f-ca3675566e60',
  library = '3b8b552a-9fc7-5617-8432-750ec3b1addf',
  appLibrary = 'f7533e5b-5aab-5b1d-9c63-2e774a40c834',
  richTextEditor = '91c37f96-909e-5ca5-b33e-7b7cb341f3cb',
  chatGPT = '65501bfd-a13d-585d-be98-bb717a355aff',
  chatGPT2 = '82cdac9c-2b94-51a1-a1bd-73eeadcf1e0c',
  tableRow = 'b08366f5-7af3-5926-9de7-a38b59d4d32f',
  uiInspect = '1c026ea6-5be6-5ea0-aac8-64f1e6a3074a',

  canvas = 'fc1eab27-5f91-55be-b3b5-d346f3180c09',
}
