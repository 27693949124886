import 'react-universal-hooks';
import 'draft-js/dist/Draft.css';
import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { DndProvider as _DndProvider } from 'react-dnd';
import styled, { ThemeProvider } from 'styled-components';
import { BlockPageCont } from './components/BlockPage';
import { db, initDb, initLocalDb } from './db';
import { GlueDev, GlueStack, PaneContext, RootValue, indexValuePoints } from './glue/main';
import { OS } from './os';
import { _inspectState, openWindow, registerInspectObjectHandler, setOsKey } from './osHelpers';
import { component, css } from './component2';
import { X, x, XInit, XObject } from './XObject';
import { WindowType } from './etc/WindowType';
import { themeState } from './etc/themeState';
import { appState, memoryAppState } from './etc/appState';
import { promptData, promptState } from './etc/showPrompt';
import { SystemContext } from './etc/SystemContext';
import { indexEdges } from './etc/getEdgesForEntity';
import { DebugWrapper } from './components/DebugWrapper';
import { UIWrapper } from './components/UIWrapper';
import { chatGptManager } from './etc/chatGptManager';
import { topBarHeight, defaultSidebarWidth, AppRoot, inspectFromNav, initPaneObject, pushToPane, isEmptyPane, MobileRoot } from './components/AppRoot';
import { windowTypes } from './windowTypes';
import { resumeMode } from './resumeMode';
import { initTextMate } from './tm/main';
import { PropertyField } from './components/PropertyField';
import { getCodeComponent, pushCode } from './pushCode';
import { componentSystem } from './componentSystem';
import { ValuePoint } from './glue/ValuePoint';
import { NotionDocumentWindow } from './windows/NotionDocumentWindow';
import { QueryEditorWindow } from './windows/QueryEditorWindow';
import { ObjectDisplay } from './components/TypeEditor';
import { ObjectType } from './types/QueryParentType';
import { EditDocumentWindow } from './windows/EditDocumentWindow';
import { AttributeWindow } from './windows/AttributeWindow';
import { ObjectPicker } from './components/ObjectPicker';
import { EditModeWindow } from './windows/EditModeWindow';
import { createNewObject, objectName, setObjectName, setObjectParent } from './components/objectFuncs';
import { allObjects, childObjects, makeKey, objectsHierachy } from './components/allObjects';
import { Sidebar } from './components/Sidebar';
import { ObjectSidebarItem } from './rightSidebar';
import { CanvasEditor } from './CanvasEditor';
import { viewObject } from './viewObject';
import { SidebarAddButton } from './components/SidebarAddButton';
import { Svg } from './components/Svg';
import { showContextMenu } from './helpers';
import { PaneType } from './types/PaneType';
import { resolvePath } from './components/resolvePath';
import { CodeComponenType, CodeElementType, ComponentIDEEditor, ComponentSystem, EditorState, initEditor, registerComponentRenderer, registerPaneType } from './ide';
import { isMobile } from './isMobile';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";


registerInspectObjectHandler('95d1b469-6eff-5bcc-88f9-a53e4377f1bf', {
  render: (args) => {
    let view;
    let columns = [];
    if (args.doc) {
      const page = db.notionDocuments.findById(args.doc);
      view = page.tableData.views.find(x => x._id == args.view);
      columns = page.tableData.columns;
    }
    else if (args.path) {
      const [doc, row] = resolvePath(args.path);
      view = row.tableData.views.find(x => x._id == args.view);
      columns = row.tableData.columns;
    }

    return (
      <div key={args.view}>
        <select
          value={view?.type || ''}
          onChange={e => view.type = e.target.value}
        >
          <option />
          <option value="e81bab60-bdcf-5755-9e85-a1863fbf3a2a">Table</option>
          <option value="c777c657-6257-5cfc-a0f2-7e735bb50d49">Gallery</option>
        </select>

        {view?.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49' && (
          <>
            <div>
              <select
                value={view.galleryProperty}
                onChange={e => view.galleryProperty = e.target.value}
              >
                <option />
                {columns.map(column => (
                  <option
                    key={column._id}
                    value={column._id}
                  >{column.title}</option>
                ))}
              </select>
            </div>
          </>
        )}


      </div>
    )
  }
})

registerInspectObjectHandler(PaneType.tableRow, {
  render: (object, args) => {
    const [page, row] = resolvePath(object.path);
    console.log('rendering table row', x(row));
    return (
      <>
        {!row.tableData && <button
          onClick={() => {
            row.tableData = X({});
          }}
        >Make table</button>}
        

        {row.tableData && (() => {
          const columns = row.tableData.columns;

          return (
            <>
            <button
              onClick={() => {
                delete row.tableData;
              }}
            >Make page</button>
            
            <select
              value={row.tableData.galleryProperty}
              onChange={e => row.tableData.galleryProperty = e.target.value}
            >
              <option />
              {columns?.map?.(column => (
                <option key={column._id} value={column._id}>{column.title}</option>
              ))}
            </select>
    
            </>
          )

        })()}


      </>
    )
  }
})

@component
class ObjectInspect extends Component<{ object }> {
  state = XInit(class {

    sidebarState = {};
    movingPicked
  })

  static contextType = SystemContext;
  context: any;

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${Sidebar} {
      position: absolute;
      top: 0;
      bottom: 47px;
      left: 0;
      right: 0;
      overflow: auto;
    }
  `;
  
  render() {
    const { object } = this.props;
    const hierachy = objectsHierachy([ObjectType.entity]);
    return (
      <>
        <Sidebar
          rootItem={new ObjectSidebarItem(object, hierachy, obj => {
            XObject.push(_inspectState().focused.args, 'stack', obj);
          }, this.context)}
          state={this.state.sidebarState}
        />
        <SidebarAddButton
          onClick={e => {
            showContextMenu(e, [
              {
                text: 'Type',
                onClick: () => {
                  createNewObject(ObjectType.type, object);
                }
              },
              {
                text: 'Attribute',
                onClick: () => {
                  createNewObject(ObjectType.attribute, object);
                },
              }
            ], 'bottom')
          }}
        />
      </>
    )
  }
}

@component
class ObjectRenamer extends Component<{ obj, onRenamed }> {
  name
  constructor(props) {
    super(props);
    this.name = objectName(props.obj);
  }

  render() {
    const ref = React.createRef<any>();
    return (
      <>
        <input type="text" defaultValue={this.name} ref={ref}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              setObjectName(this.props.obj, ref.current.value);
              this.props.onRenamed();
            }
          }}
         />
        <button
          onClick={() => {
            setObjectName(this.props.obj, ref.current.value);
            this.props.onRenamed();
          }}
        >Done</button>
      </>
    )
  }
}

@component
class ObjectEditor extends Component<{ props }> {
  state = XInit(class {
    mode
  });

  static contextType = SystemContext;
  context: any;

  static styles = styled.div`
  color: #464646;
  font-family: ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
    display: flex;
    flex-direction: column;

    .top {
      /* border-bottom: 1px solid #e9e9e7; */
      padding: 4px;
      > .title {
        flex: 0 0 auto;
        font-weight: 500;
        display: flex;
        align-items: center;
        overflow: hidden;
        .back {
          transform: rotate(90deg);
          flex: 0 0 auto;
          width: 12px;
          height: 12px;
          margin-right: 6px;
          cursor: pointer;
          fill: #7d7d7d;

        }
        > ${ObjectDisplay} {
          display: flex;
          white-space: nowrap;
          color: #7d7d7d;
          svg {
            fill: #7d7d7d;
          }
        }
      }

    }

    .section {
      /* background-color: white; */
      display: flex;
      flex-direction: column;
      .sectionTitle {
        text-transform: uppercase;
        font-size: 10px;
        flex: 0 0 auto;
        padding: 4px;
        font-weight: 500;
        color: #c1c1c1;
      }
      flex: 1 1 0;

      .sectionContent {
        position: relative;
        flex: 1 1 0;
        overflow: auto;
        padding: 4px 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e7;
      }
    }
  `;

  render() {
    const { props } = this.props;
    const render = obj => {
      return (
        <>
          <SystemContext.Provider value={{
            next: () => {},
            navigate: config => {
              console.log('navigate');
              pushToPane(appState.panes[0], config, false);
            },
          }}>
            <div className="top">
              <div className="title">
                {props.stack?.length > 0 && <Svg className="back" name="chevron"
                  onClick={() => {
                    props.stack.pop();
                  }}
                />}
                <ObjectDisplay
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Rename',
                        onClick: () => {
                          this.state.mode = 'renaming';
                        },
                      },
                      {
                        text: 'Move',
                        onClick: () => {
                          this.state.mode = 'moving';
                        },
                      },
                      {
                        text: 'View',
                        onClick: () => {
                          viewObject(obj, this.context);
                        },
                      }
                    ]);
                  }}
                  obj={obj}
                  showPath
                />
              </div>
              {this.state.mode == 'moving' && (
                <>
                  <ObjectPicker
                    _onSelect={(ref, clear) => {
                      if (ref.type == ObjectType.global) {
                        setObjectParent(obj, null);
                      }
                      else {
                        setObjectParent(obj, ref);
                      }
                      delete this.state.mode;
                    }}
                  />
                </>
              )}
              {this.state.mode == 'renaming' && (
                <>
                  <ObjectRenamer obj={obj} onRenamed={() => {
                    delete this.state.mode;
                  }} />
                </>
              )}
            </div>  
            <div className="section">
              <div className="sectionContent">
                {obj.type == ObjectType.query && (
                  <QueryEditorWindow window={{
                    query: obj.id,
                  }} />
                )}
                {obj.type == ObjectType.page && (
                  <EditDocumentWindow
                    window={{
                      document: obj.id,
                    }}
                  />
                )}
                {obj.type == ObjectType.attribute && (
                  <AttributeWindow window={{ id: obj.id }} />
                )}
                {obj.type == ObjectType.mode && (
                  <>
                    <EditModeWindow
                      window={{
                        id: obj.id,
                      }}
                    />
                  </>
                )}
                {obj.type == ObjectType.canvas && (
                  <>
                    <CanvasEditor id={obj.id} />
                  </>
                )}
                {obj.type == ObjectType.codeComponent && (
                  <>
                    {(() => {
                      const comp = db.codeComponents.findById(obj.id);
                      if (comp) {
                        return (
                          <>
                            <ul>
                              {comp?.scope?.map?.((obj, i) => (
                                <li key={obj._id}>
                  
                                  <ObjectDisplay obj={obj} showPath /> <button
                                    onClick={() => {
                                      comp.scope.splice(i, 1);
                                    }}
                                  >X</button>
                                </li>
                              ))}
                            </ul>
                  
                            <ObjectPicker
                              _onSelect={(obj, clear) => {
                                XObject.push(comp, 'scope', XObject.obj(obj));
                                clear();
                              }}
                            />
                          </>
                        )
                      }
                    })()}
                  </>
                )}
              </div>
            </div>
            <div className="section">
              <span className="sectionTitle">Objects</span>
              <div className="sectionContent">
                <ObjectInspect object={obj} />
              </div>
            </div>
          </SystemContext.Provider>
        </>
      );
    }

    if (props.stack?.length) {
      return render(props.stack[props.stack.length - 1]);
    }
    else {
      return render(props.obj);
    }
  }
}

registerInspectObjectHandler('e57eb0f2-4a72-5002-b64e-11a7ba64970a', {
  render: (props) => {
    return <ObjectEditor props={props} />;
  }
});

@component
class ValuePointPane extends Component<{ valuePoint: string }> {
  state = X({
    stack: []
  });
  constructor(props) {
    super(props);
    this.state.stack = [
      XObject.obj({
        id: this.props.valuePoint
      })
    ]
  }
  static styles = styled.div`
    /* background-color: white; */
    ${GlueDev} {
      position: static;

      ${GlueDev.t.panes} {
        position: static;
      }
    }
    display: flex;
    height: 100%;
  `;
  render() {
    return <GlueStack state={this.state} />;
  }
}

registerPaneType('valuePoint', {
  width: 'auto',
  render: props => {
    console.log(props);
    return <ValuePointPane key={props.id} valuePoint={props.id} />
  }
})



registerPaneType('196e909e-5441-5945-adce-8d8658092c82', {
  render: (props) => {

    return (
      <div style={{ background: 'white' }}>
      <SystemContext.Provider value={{
        next: () => {

        },

      }}>
      <NotionDocumentWindow
        
      window={{
        notionDocument: props.id,
      }}

      />
      </SystemContext.Provider>
      </div>
    )
  }
})

@component
class ValuePointEditor extends Component<{ valuePoint: string, pushStack }> {
  static styles = styled.div`
    /* background-color: white; */
    padding-left: 19px;
  `;
  state = XInit(class {
    active
  })
  render() {
    return (
      <PaneContext.Provider value={{
        active: this.state.active,
        selectValuePoint: id => {
          this.state.active = id;
          this.props.pushStack({
            type: 'valuePoint',
            id,
          })
        },
      }}>
        <RootValue id={this.props.valuePoint} />
      </PaneContext.Provider>
    );
  }
}

registerComponentRenderer(CodeElementType.valuePoint, props => {
  return (
    <>
      <ValuePointEditor valuePoint={props.component._id} pushStack={props.pushStack} />
    </>
  )
});


window['MonacoEnvironment'] = {
  getWorkerUrl: function (moduleId, label) {
    console.log(moduleId, label);
    if (label === 'json') {
      return '/static/js/json.worker.bundle.js';
    }
    if (label === 'css' || label == 'scss') {
      return '/static/js/css.worker.bundle.js';
    }
    if (label === 'html') {
      return '/static/js/html.worker.bundle.js';
    }
    if (label === 'typescript' || label === 'javascript' || label == 'typescriptreact') {
      return '/static/js/ts.worker.bundle.js';
    }
    return '/static/js/editor.worker.bundle.js';
  }
}

@component
export class CodeComponentWindow extends Component<{ window }> {
  editorState: EditorState
  constructor(props) {
    super(props);
    this.editorState = new EditorState(componentSystem);
  }
  static styles = styled.div`
    ${ComponentIDEEditor} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      /* bottom: 200px; */
      bottom: 0;
    }

    .output {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
    }

    .run {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
    }
  `;
  render() {
    return (
      <>
        {/* <button
          className="run"
          onClick={async () => {
            console.log(this.props.window.component);
            await pushCode();
            console.log(getCodeComponent(this.props.window.component)());
          }}
        >Run</button> */}

        <ComponentIDEEditor
          componentSystem={componentSystem}
          editorState={this.editorState}
          id={this.props.window.component}
          onClickComponent={(id) => {}}
        />
      </>
    )
  }
}

@component
export class CodeComponentsWindow extends Component<{ window }> {
  render() {
    return (
      <>
        <button
          onClick={() => {
            pushCode();
          }}
        >Deploy</button>
        <ul>
          {db.codeComponents.map(component => {
            return (
              <li
                key={component._id}
              >
                <PropertyField object={component} property="name" /> ({_.invert(CodeComponenType)[component.type]})

                <button
                onClick={() => {
                  openWindow({
                    type: WindowType.CodeComponent,
                    component: component._id,
                  })
                }}
                >View</button>
              </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            db.codeComponents.push(XObject.obj({
              name: 'New Function',
              type: CodeComponenType.function,
              code:
`function __component__() {

}`
            }));
          }}
        >Function</button>
        <button
          onClick={() => {
            db.codeComponents.push(XObject.obj({
              name: 'New Component',
              type: CodeComponenType.component,
              code:
`class __component__ extends React.Component {
  render() {
    return null;
  } 
}`
            }));
          }}
        >Component</button>
      </>
    )
  }
}
let RootComp;


@component
export class CodeTestWindow extends Component<{ window }> {
  componentSystem: ComponentSystem;
  editoState: EditorState
  constructor(props) {
    super(props);
    this.editoState = new EditorState(this.componentSystem);
  }
  static styles = styled.div`
    ${ComponentIDEEditor} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 200px;
    }

    .output {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
    }

    .run {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
    }
  `;
  render() {
    return null;
  }
}

const nav = [
  {title: "ChatGPT Test", nav: {type: WindowType.ChatGPTTest}},
  {title: "Graphs", nav: {type: 'graphs'}},
  {title: "Root", nav: {type: 'root'}},
  {title: "All Graph", nav: {type: WindowType.AllGraph}},
  {title: "Meta States", nav: {type: WindowType.MetaStates}},
  {title: "Linear", nav: {type: WindowType.Linear}},
  {title: "Queues", nav: {type: WindowType.Queues}},
  {title: "Types", nav: {type: WindowType.Types}},
  {title: "Notion", nav: {type: WindowType.Notion}},
  {title: "Org Entities", nav: {type: WindowType.OrgEntities}},
  {title: "Databases", nav: {type: WindowType.Databases}},
  {title: "Documents", nav: {type: WindowType.NotionDocuments}},
  {title: "Glue Dev", nav: {type: WindowType.GlueDev}},
  {title: "Menu Items", nav: {type: WindowType.MenuItems}},
  {title: "System Config", nav: {type: WindowType.SystemConfig}},
  {title: "Users", nav: {type: WindowType.Users}},
  {title: "Inbox", nav: {type: WindowType.Inbox}},
  {title: "Spaces", nav: {type: WindowType.SpacesWindow}},
  {title: "Query Expression Editor", nav: {type: WindowType.QueryExpressionEditor}},

  { title: 'Notion Document Test', nav: { type: WindowType.NotionDocumentTest } },
  { title: 'Code Tetst', nav: { type: WindowType.CodeTest } },
  { title: 'Code Components', nav: { type: WindowType.CodeComponents } },

  { title: 'Data Editor Test Window', nav: { type: WindowType.DataEditorTestWindow } },

  { title: 'Tldraw', nav: { type: WindowType.Whiteboard } },
  { title: 'MindMap', nav: { type: WindowType.MindMap } },
]


if (!appState.panes.length) {
  appState.panes.push(XObject.obj());
}

@component
class Prompt extends Component {
  static styles = styled.div`

    .cont {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.5);

      z-index: 99999999;

      .prompt {
        background: white;
      }

    }

    
  `;
  render() {
    return (
      <>
        {promptState.current && <div className="cont">
          <div className="prompt">
            {promptState.current.message}
            <input type="text" autoFocus onKeyDown={e => {
              if (e.key === 'Enter') {
                // promptState.current.resolve(e.currentTarget.value);
                promptState.current = null;
                // this.forceUpdate();

                promptData.cb(e.currentTarget.value);
                promptData.cb = null;

              }
            }} />
          </div>
        </div>}
      </>
    )
  }
}

@component
class FullscreenImage extends Component {
  static styles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    z-index: 99999999;

    .img {
      position: absolute;
      top: 16px;
      left: 16px;
      right: 16px;
      bottom: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

    }
  `;
  render(Container?) {
    if (!memoryAppState.image) return null;
    return (
      <Container
        onClick={() => {
          memoryAppState.image = null;
        }}
      >
        {memoryAppState.image && <div className="cont">
          <span className="img" style={{
            backgroundImage: `url(${memoryAppState.image})`,
          }} />
        </div>}
      </Container>
    )
  }
}

let styles;
function updateInspectHighlight() {
  if (styles) {
    styles.parentNode?.removeChild?.(styles);
  }
  if (appState.inspecting?.id) {
    styles = document.createElement('style');
    styles.innerHTML = css`
      /* [data-inspect-id="${appState.inspecting?.id}"] {
        outline: 2px solid #12802d !important;
      } */
    `;
    document.head.appendChild(styles);
  }
}

XObject.observe(appState, 'inspecting', () => {
  updateInspectHighlight();
})

@component
class App extends Component {
  static styles = styled.div`
    font-family: "Inter UI", "SF Pro Display", -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* font-family: ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"; */
    -webkit-font-smoothing: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #4a4a4a;
    font-size: 12px;

    .inspecting {
      outline: 2px solid #12802d;
    }
    ${OS} {
      .mainContent {
      }
    }
    .editable-value {
      position: relative;

      &.empty {
        cursor: text;

        .editable-value__display {
          width: 4px;
          display: inline-block;
        }
      }
      &:hover {
        .editable-value__edit {
          pointer-events: all;
          opacity: 1;
          transform: scale(1);
        }
      }

      &.pre {
        .editable-value__edit  {
          right: 100%;
        }
      }

      .editable-value__edit {
        transform: scale(.9);
        transition: all 100ms linear;
        position: absolute;
        // left: calc(100%);
        opacity: 0;
        pointer-events: none;
        // top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        margin-left: 0 !important;
        height: 16px;
      }
    }

    > .left {
      width: 500px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-right: 1px solid #2c2d3c;
      box-sizing: border-box;

      > .select {
        position: absolute;
        z-index: 99999;
      }

    }

    > .right {
      position: absolute;
      top: 0;
      left: 500px;
      right: 0;
      bottom: 0;
    }

    ${BlockPageCont} {
      margin-bottom: 8px;
    }





    .notionDoc {
      position: absolute;
      top: ${topBarHeight}px;
      left: ${defaultSidebarWidth}px;
      right: 0;
      bottom: 0;
    }

    ${AppRoot} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .glueSideBar {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      border-left: 1px solid #2c2d3c;

      .closeGlueSideBar {
        z-index: 999999;
        position: absolute;
        top: 0;
        right: 0;
        --webkit-app-region: no-drag;
      }
    }

    &.showGlue {
      ${AppRoot} {
        right: 50%;
      }
    }


  `;

  constructor(props) {
    super(props);
    setOsKey('os');

    if (resumeMode.enabled) {
      if (!appState.panes?.length || !appState.panes[0].stack?.length || appState.panes[0].stack?.length == 1 && isEmptyPane(appState.panes[0].stack[0])) {
        appState.panes = X([{"viewType":"stack","pointer":0,"stack":[{"type":"page","id":"646cf264694d7e70c6d262cc","_id":"6484fae87424f6ab581a7636","state":{"notionDocument":"646cf264694d7e70c6d262cc"}}],"_id":"6484fae8ab07d744adccb934"}]);
      }

    }

    // appState.leftSidebar = false;
  }

  state = XInit(class {
    loaded = false;
    state: any = {}
    docState = {}
    drillDownState: any = {}
  });


  async componentDidMount() {
    // console.log(JSON.stringify(x(appState.panes)));
    if (resumeMode.enabled) {
      document.title = 'Jonathan Cook';
      const data = (await axios.get('https://jonathan-cook-portfolio.s3.amazonaws.com/data.json')).data;
      initLocalDb(data);
      appState.currentMode = resumeMode.mode;

    }
    else {
      await initDb([
        'users',
        'tasks',
        'appComponents',
        'state',
        'documents',
        'entities',
        'edges',
        'entityTypes',
        'stateTypes',
        'attributeTypes',
        'aspectTypes',
        'workspaces',
        'dataObjects',
        'orgEntities',
        'databases',
        'notionDocuments',
        'statementTypes',
        'parameters',
        'statements',
  
        'spaces',
  
        'sidebar',
        'queries',
        'libraries',
        'elements',
  
        'blocks',
        'pages',
        'modes',

        'codeComponents',

        'canvases',
      ]);  
      await chatGptManager.init();

      await initEditor(componentSystem);
      await initTextMate();
  
      await pushCode();
    }

    indexValuePoints();

    XObject.observe(db.edges, () => {
      indexEdges();
    });
    indexEdges();

    if (!db.appComponents.length) {
      db.appComponents.push(XObject.obj({
        name: 'Root',
      }));
    }

    this.state.loaded = true;

    setTimeout(() => {
      updateInspectHighlight();

    }, 1000);

    for (const doc of db.notionDocuments) {

      if (doc.createsEdges) {
        console.log(x(doc));

        const update = () => {
          const collectEntitiesGood = blocks => {
            const entities: {
              entity
              block
              parent
            }[] = [];
            const _collectEntities = (blocks, parent) => {
              for (const block of blocks) {
                if (block.id) {
                  entities.push({ block: block._id, entity: block.id, parent });
                }
                /*if (block.data?.filter) {
                  const r = block.data.filter(e => e?.[1] == 'entity');
                  for (const e of r) {
                    entities.push({ block: block._id, entity: e[0], parent });
                  }
                  // if (r) {
                  //   entities.push({ block: block._id, entity: r[0] });
                  // }
                }*/
                if (block.children) {
                  _collectEntities(block.children, block.id || parent);
                }
              }
            };
            _collectEntities(blocks, null);
          
            return entities;
          };
          
          const entities = collectEntitiesGood(doc.blocks);
  
          const edges = [];
          for (const entity of entities) {
            const key = JSON.stringify(['fc4a8f4f-aa90-50e2-bcee-e48debc77ab8', doc._id, entity.block ]);
            edges.push({
              entities: [ entity.parent || doc.parent?.id, entity.entity ],
              directed: true,
              sync: ['fc4a8f4f-aa90-50e2-bcee-e48debc77ab8', doc._id, entity.block ],
              key
            });
          }
  
          for (const edgeDef of edges) {
            const existingEdge = db.edges.find(e => e.key == edgeDef.key);
            if (!existingEdge) {
              db.edges.push(XObject.obj(edgeDef));
            }
            else {
              if (!_.isEqual(x(existingEdge.entities), x(edgeDef.entities))) {
                existingEdge.entities = edgeDef.entities;
              }
            }
          }
  
          const allExistingEdges = db.edges.filter(e => e.sync?.[0] == 'fc4a8f4f-aa90-50e2-bcee-e48debc77ab8' && e.sync?.[1] == doc._id);
          for (const existingEdge of allExistingEdges) {
            if (!edges.find(e => e.key == existingEdge.key)) {
              db.edges.splice(db.edges.indexOf(existingEdge), 1);
            }
          }

        }


        update();

        let timerId;
        XObject.observe(doc, 'blocks', () => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            update();
          }, 1000);
        });
      }
    }
  }

  render(Container?) {
    if (!this.state.loaded) return '';
    return (
      <Container
        className={classNames({
          showInspectBar: appState.rightSidebar,
          showGlue: appState.glue,
        })}
      >
        <BrowserRouter>
        <ThemeProvider theme={{
          mode: themeState.darkMode ? 'dark' : 'light',
        }}>
          <SystemContext.Provider value={{
            openWindow: () => {

            }
          }}>
            <UIWrapper>
              <DebugWrapper>
                {isMobile() && (
                  <>
                  <Routes>
                    <Route path="*"
                      Component={() => {
                        const args = useParams();
                        console.log(args);
                        return <MobileRoot pathArg={args['*']} />
                        // console.log(args);
                        // return <></>;
                      }}
                    />
                  </Routes>
                  </>
                )}
                {!isMobile() && <OS
                  windowTypes={windowTypes(this)}
                  menu={nav.map((n, i) => {
                    return <li key={i} onClick={() => {
                      openWindow(n.nav)
                    }}>{n.title}</li>
                  })}
                >
                  <AppRoot />
                  {appState.glue && (
                    <div className="glueSideBar">
                      <button
                        className="closeGlueSideBar"
                        onClick={() => {
                          delete appState.glue;
                        }}
                      >X</button>
                      <SystemContext.Provider value={{
                        navigate: config => {
                          inspectFromNav(config, appState.panes.length);
                          appState.panes.push(initPaneObject(config));
                        }
                      }}>
                        <GlueDev state={appState.glue} />
                      </SystemContext.Provider>
                    </div>
                  )}
                </OS>}
              </DebugWrapper>
            </UIWrapper>
          </SystemContext.Provider>
          <Prompt />
        </ThemeProvider>
        </BrowserRouter>
        <FullscreenImage />
      </Container>
    );
  }
}

export default App;
