import _ from 'lodash';
import { db } from '../db';
import { ObjectRef, ObjectType } from '../types/QueryParentType';
import { entityDisplayName, entityDisplayView } from './entityDisplayName';
import { ESLint } from 'eslint';
import { getStructureById, getValuePoint } from '../glue/main';
import { XObject } from '../XObject';


export function getObject(ref: ObjectRef) {
  if (ref.type == ObjectType.type) {
    return db.entityTypes.findById(ref.id);
  }
  else if (ref.type == ObjectType.attribute) {
    return db.attributeTypes.findById(ref.id);
  }
  else if (ref.type == ObjectType.query) {
    return db.queries.findById(ref.id);
  }
  else if (ref.type == ObjectType.entity) {
    return db.entities.findById(ref.id);
  }
  else if (ref.type == ObjectType.page) {
    // console.log(ref)
    return db.notionDocuments.findById(ref.id);
    throw new Error('Not implemented');
  }
  else if (ref.type == ObjectType.space) {
    return db.spaces.findById(ref.id);
  }
  else if (ref.type == ObjectType.library) {
    return db.libraries.findById(ref.id);
  }
  else if (ref.type == ObjectType.global) {
    return null;
  }
  else if (ref.type == ObjectType.element) {
    return db.elements.findById(ref.id);
  }
  else if (ref.type == ObjectType.mode) {
    return db.modes.findById(ref.id);
  }
  else if (ref.type == ObjectType.codeComponent) {
    return db.codeComponents.findById(ref.id);
  }
  else if (ref.type == ObjectType.valuePoint) {
    return getValuePoint(ref.id);
  }
  else if (ref.type == ObjectType.canvas) {
    return db.canvases.findById(ref.id);
  }
  else {
    console.log(ref);
    throw new Error('Invalid type');
  }
}

function _setObjectParent(type, obj, parent) {
  if (type == ObjectType.entity) {
    obj.space = parent;
  }
  else if (type == ObjectType.type) {
    obj.scope = parent;
  }
  else if (type == ObjectType.attribute) {
    obj.scope = parent;
  }
  else if (type == ObjectType.space) {
    obj.scope = parent;
  }
  else if (type == ObjectType.page) {
    obj.parent = parent;
  }
  else if (type == ObjectType.codeComponent) {
    obj.parent = parent;
  }
  else {
    throw new Error('Not implemented');
  }
}
export function setObjectParent(ref: ObjectRef, parent?: ObjectRef) {
  const obj = getObject(ref);
  _setObjectParent(ref.type, obj, parent);
}

export function getObjectParent(ref: ObjectRef) {
  const obj = getObject(ref);
  if (!obj) {
    return null;
  }
  if (ref.type == ObjectType.type) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.attribute) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.element) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.query) {
    if (obj.parentType) {
      return {
        id: obj.parent,
        type: obj.parentType,
      };
    }
    else if (_.isString(obj.parent)) {
      if (db.entities.findById(obj.parent)) {
        return {
          id: obj.parent,
          type: ObjectType.entity,
        };
      }
      else {
        return {
          id: obj.parent,
          type: ObjectType.query,
        };

      }
    }
    else if (obj.parent) {
      return obj.parent;
    }
    else if (obj.relative) {
      const entity = db.entities.find(e => e.queries?.find?.(q => q.query == obj._id));
      if (!entity)
        return null;
      return {
        id: entity._id,
        type: ObjectType.entity,
      };
    }
  }
  else if (ref.type == ObjectType.entity) {
    if (_.isString(obj.space)) {
      return {
        id: obj.space,
        type: ObjectType.space,
      };
    }
    else {
      return obj.space;
    }
  }
  else if (ref.type == ObjectType.page) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.space) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.codeComponent) {
    return obj.parent;
  }
}

export function objectName(ref: ObjectRef, plainText=true) {
  if (ref.type == ObjectType.global) {
    return 'Global';
  }

  const obj = getObject(ref);
  if (!obj) return '(deleted)';

  if (ref.type == ObjectType.type) {
    return obj.name;
  }
  else if (ref.type == ObjectType.attribute) {
    return obj.name;
  }
  else if (ref.type == ObjectType.element) {
    return obj.name;
  }
  else if (ref.type == ObjectType.query) {
    return obj.name;
  }
  else if (ref.type == ObjectType.entity) {
    if (plainText) {
      return entityDisplayName(obj._id);
    }
    else {
      return entityDisplayView(obj._id);
    }
  }
  else if (ref.type == ObjectType.page) {
    return obj.name;
  }
  else if (ref.type == ObjectType.space) {
    return obj.name;
  }
  else if (ref.type == ObjectType.library) {
    return obj.name;
  }
  else if (ref.type == ObjectType.mode) {
    return obj.name;
  }
  else if (ref.type == ObjectType.codeComponent) {
    return obj.name;
  }
  else if (ref.type == ObjectType.valuePoint) {
    if (obj.name) return obj.name;
    const structure = obj.type?.[1] && getStructureById(obj.type?.[1]);
    return structure?.name || 'value point ' + obj._id;

    // return 'value point ' + obj._id;
  }
  else if (ref.type == ObjectType.canvas) {
    return obj.name;
  }
}

export function setObjectName(ref: ObjectRef, name) {
  const obj = getObject(ref);
  if (ref.type == ObjectType.type ||
      ref.type == ObjectType.canvas ||
      ref.type == ObjectType.attribute ||
      ref.type == ObjectType.page || 
      ref.type == ObjectType.query ||
      ref.type == ObjectType.mode
    ) {
    obj.name = name;
  }
  else {
    throw new Error('Not implemented');
  }
}

export function getScopeTree(scope: ObjectRef) {
  const tree = [scope];
  while (true) {
    const parent = getObjectParent(scope);
    if (!parent) {
      break;
    }
    tree.push(parent);
    scope = parent;
  }

  tree.push({
    id: null,
    type: ObjectType.global,
  });

  return tree;
}
function getFromLibrary(id, type) {
  // const library = db.libraries.findById(id);
  if (type == ObjectType.type) {
    return db.entityTypes.filter(t => t.scope?.id == id).map(t => t._id);
  }
  else if (type == ObjectType.attribute) {
    return db.attributeTypes.filter(t => t.scope?.id == id).map(t => t._id);
  }
}

export function typesInScope(scope: ObjectRef) {
  if (!scope)
    return db.entityTypes.filter(t => !t.scope).map(t => t._id);
  let types = [];
  const tree = getScopeTree(scope);

  for (const ref of tree) {
    if (ref.type == ObjectType.global) {
      types = types.concat(db.entityTypes.filter(t => !t.scope).map(t => t._id));
    }
    else if (ref.type == ObjectType.space) {
      const space = db.spaces.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.type) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.type));
          }
        }
      }
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
    else if (ref.type == ObjectType.page) {
      const space = db.notionDocuments.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.type) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.type));
          }
        }
      }
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }

    else {
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
  }


  return _.uniq(types);
}

export function attributesInScope(scope: ObjectRef) {
  if (!scope)
    return db.attributeTypes.filter(t => !t.scope).map(t => t._id);
  let types = [];
  const tree = getScopeTree(scope);
  for (const ref of tree) {
    if (ref.type == ObjectType.global) {
      types = types.concat(db.attributeTypes.filter(t => !t.scope).map(t => t._id));
    }
    else if (ref.type == ObjectType.space) {
      const space = db.spaces.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.attribute) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.attribute));
          }
        }
      }
      types = types.concat(db.attributeTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
    else {
      types = types.concat(db.attributeTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
  }
  return types;
}

export function typesInScopes(scopes: ObjectRef[]) {
  let types = [];
  for (const scope of scopes) {
    types = types.concat(typesInScope(scope));
  }
  return _.uniq(types);
}

export function attributesInScopes(scopes: ObjectRef[]) {
  let types = [];
  for (const scope of scopes) {
    types = types.concat(attributesInScope(scope));
  }
  return _.uniq(types);
}

export function objectIcon(obj) {
  return typeIcon(obj.type);
}

export function typeIcon(type) {
  if (type == ObjectType.space) {
    return 'box';
  }
  else if (type == ObjectType.entity) {
    return 'tesseract';
  }
  else if (type == ObjectType.type) {
    return 'icons8-type';
  }
  else if (type == ObjectType.attribute) {
    return 'icons8-detail (1)';
  }
  else if (type == ObjectType.library) {
    return 'bundle';
  }
  else if (type == ObjectType.query) {
    return 'database';
  }
  else if (type == ObjectType.page) {
    return 'icons8-page';
  }
  else if (type == ObjectType.mode) {
    return 'icons8-mode';
  }
  else if (type == ObjectType.canvas) {
    return 'icons8-canvas';
  }
  else if (type == ObjectType.codeComponent) {
    return 'icons8-code';
  }
}


export function createNewObject(type, parent?) {
  let id;
  let obj;
  if (type == ObjectType.type) {
    obj = XObject.obj();
    id = obj._id;
  }
  else if (type == ObjectType.attribute) {
    obj = XObject.obj();
    id = obj._id;
  }

  if (parent) {
    _setObjectParent(type, obj, parent);
  }

  if (type == ObjectType.type) {
    db.entityTypes.push(obj);
  }
  else if (type == ObjectType.attribute) {
    db.attributeTypes.push(obj);
  }
}