export enum ObjectType {
  space = 'b136901e-de50-560c-967c-6ba1b2ae1b71',
  page = '3233c65b-5419-5435-af64-41c8f4ff0e52',
  entity = 'b3b94670-26f1-5fec-96e5-9f6137e97eed',
  query = 'faecfe12-ca1a-5bc8-9118-40cc9527d4ee',
  type = '721907d2-7cf8-50ea-a851-ed8bf8381ea0',
  attribute = '6a5561f3-f8eb-5f21-85dd-5f60ff5888d7',
  global = '12ad8c65-5aa2-5e2b-9a8b-4435a1f98b82',
  library = '29e57a7d-b613-5119-a147-91f4f3883fbc',
  element = '5bb91d65-c64f-5842-b8a8-ce961aa38d4e',
  mode = 'fc55965c-e0ab-5778-bfa7-e81046667aa5',

  codeComponent = 'bc1d82d2-15af-5c08-9bc5-1481a9303681',
  valuePoint = 'ccf27752-c086-53d1-8dc9-6339bc50a57e',

  canvas = 'dca1af8d-3352-5072-9d13-58ec245d5086',
}

export interface ObjectRef {
  id: string;
  type: ObjectType;
}