import React, { Component } from 'react';
import _ from 'lodash';
import { db } from '../db';
import { component, styled } from '../component2';
import { X, XInit, XObject, x } from '../XObject';
import { EntityRow } from './EntityRow';
import { QUERY, createEntityFromQuery, createQuery, describeQuery, executeQuery, queryChain, query_entityType } from '../etc/queryFuncs';
import { entityMetaStates } from '../etc/entityMatch';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { isId, showContextMenu } from '../helpers';
import { groupById } from '../glue/structs/groupById';
import { attributesForType, entitySchema } from './EntityView';
import pluralize from 'pluralize';
import { renderAttributeValue } from '../glue/structs/renderAttributeValue';
import { createEntity } from '../etc/createEntity';
import { SystemContext } from '../etc/SystemContext';
import { NotionButton } from './AddButton';
import classNames from 'classnames';
import { openWindow, registerInspectObjectHandler, triggerInspectObject } from '../osHelpers';
import { ViewType, supportsGrouping } from '../types/ViewType';
import { EntityDatabase } from '../glue/structs/$EntityDatabase';
import { ObjectPageFrame } from './ObjectPageFrame';
import { GraphView } from './Graph';
import { entityDisplayName } from './entityDisplayName';
import { getGraphParent, queryGraphBasic } from '../etc/queryGraph';
import { ObjectType } from '../types/QueryParentType';
import { showPrompt } from '../etc/showPrompt';
import { Svg } from './Svg';
import { QueryViewEditor } from './QueryViewEditor';
import { deleteEdge } from '../etc/getEdgesForEntity';
import { InsertionCont } from './InsertionCont';
import { ViewsList } from './ViewsList';

export function executeQueryObj(id, baseId?) {
  let result = [];
  const query = db.queries.findById(id);

  const view = query.views?.find(v => v._id == query?.state?.activeView);

  result = executeQuery(queryChain(query), undefined, baseId);

  if (view?.viewQuery) {
    result = executeQuery(view.viewQuery, result);
  }


  if (view?.filterQuery) {
    result = executeQuery(view.filterQuery, result);
  }

  if (view?.filter?.search) {
    result = result.filter(id => {
      
      if (entityDisplayName(id)?.toLowerCase().includes(view.filter?.search?.toLowerCase())) {
        return true;
      }
    });
  }

  return result;
}

@component
class Search extends Component<{
  value
  setValue
}> {
  static styles = styled.div`
    display: flex;
  `;

  state = XInit(class {
    show
    // value
  })

  render() {
    return (
      <>
        <NotionButton 
          img="icons8-search"
          onClick={() => {
            this.state.show = !this.state.show;
            if (!this.state.show) {
              this.props.setValue('');
            }
          }}
        />
        {(this.state.show || this.props.value) && <input
        autoFocus
        onBlur={() => {
          if (!this.props.value) {
            this.state.show = false;
          }
        }}
        type="text" defaultValue={this.props.value || ''}
          onChange={e => {
            this.props.setValue(e.target.value);
          }}
        />}
      </>
    )
  }
}

function viewIcon(v) {
  if (v.type == ViewType.table) return 'icons8-table';
  if (v.type == ViewType.list) return 'icons8-list';
  if (v.type == ViewType.graph) return 'icons8-tree-structure';
  if (v.type == ViewType.split) return 'icons8-columns';
}

function viewName(view) {
  if (view.name) {
    return view.name
  }
  else if (view.type == ViewType.list) {
    return 'List';
  }
  else if (view.type == ViewType.table) {
    return 'Table';
  }
  else if (view.type == ViewType.graph) {
    return 'Graph';
  }
  else if (view.type == ViewType.split) {
    return 'Split';
  }
  else {
    return 'View';
  }
}


function renderView(view, state, { entities, grouped }, add, onClick?, baseId?, touched?, uiContext?, getCount?, selected?, localBaseId?) {
  if (view.type == ViewType.list) {
    if (grouped) {
      return grouped.map(({ key, group, entities }) => {
        return (
          <div key={group} className="group">
            <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(view.groupBy, key, group)}</div>
            {entities.map(entity => {
              touched?.(entity);
              return (
                <EntityRow
                  key={entity}
                  id={entity}
                  path={baseId}
                  _onClick={onClick && (() => {
                    onClick?.(entity)
                  })}
                  uiContext={uiContext}
                />
              )
            })}
          </div>
        );
      })
    }
    else {
      return entities.map(entity => {
        touched?.(entity);
        return (
          <EntityRow key={entity} id={entity} path={baseId} _onClick={onClick && (() => {
            onClick?.(entity)
          })}
          
          uiContext={uiContext}
          />
        )
      })
    }
  }
  else if (view.type == ViewType.table) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
    return (
      <EntityDatabase
        baseId={baseId}
        active={selected}
        attributes={attributes}
        colStates={XObject.get(view, 'columns', {})}
        states={[]}
        entities={entities.map(x => x)}
        onClickAdd={add && (() => {
          add(false)
        })}
      />
    );
  }
  else if (view.type == ViewType.graph) {
    return (
      <GraphView
        _state={state}
        onSelect={onClick}
        selected={selected}
        _onDropExternal={(target, dropped) => {
          console.log('drop', db.entities.findById(target).name, db.entities.findById(dropped).name);

          const currentEdge = db.edges.find(e => e.entities[1] == dropped && e.directed);
          if (currentEdge) {
            deleteEdge(currentEdge._id);
          }

          db.edges.push(XObject.obj({
            entities: [target, dropped],
            directed: true,
          }))
        }}
        _onMove={(id, from, to) => {
          const currentEdge = db.edges.find(x => x.entities.includes(from) && x.entities.includes(id));
          if (currentEdge) {
            deleteEdge(currentEdge._id);
          }

          const newEdge = XObject.obj({
            entities: [to, id],
            directed: true,
          });
          db.edges.push(newEdge);
        }}
        label={id => {
          if (getCount) {
            return `${entityDisplayName(id)} (${getCount(id)})`;
          }
          else {
            return entityDisplayName(id);
          }
        }}
        data={entities.map(id => {
          return {
            id,
            meta: {
              parent: getGraphParent(null, id),
            }
          }
        })}
        root={null}
      />
    );
  }
}

interface ViewManager {
  get(): {
    _id
    type
    groupBy
    name
    viewQuery
    filterQuery
  }[]
  addView()
  init()
  editView(id)
  deleteView(id)
}

registerInspectObjectHandler('33118f77-16d5-5d79-b69b-04c172f186c0', {
  render: args => {
    return <QueryViewEditor
      window={{
        query: args.query,
        view: args.view,
      }}
    />
  }
})

@component
export class ViewQueryish extends Component<{
  viewManager: ViewManager,
  entities, state; entity?, showToolbar?, touched?, add? }> {
  static styles = styled.div`
    .toolBar {
      ${NotionButton} {
        height: 28px;
      }
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        select {
          margin-right: 6px;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .views {
        display: flex;
        .view {
          cursor: pointer;
          padding: 0 4px;
          &.active {
            /* border-bottom: 2px solid black; */
          }
        }
      }
      /* border-bottom: 1px solid #ccc; */
      margin-bottom: 8px;
    }
    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .sectionTitle {
      display: flex;
      align-items: center;
      ${NotionButton} {
        margin-left: auto;
      }
    }

  `
  state = XInit(class {
  })
  static contextType = SystemContext;
  context: any;
  render() {
    const showToolbar = this.props.showToolbar ?? true;
    let result = this.props.entities;

    let viewManager = this.props.viewManager;
    const canAddEntity = this.props.add;

    viewManager.init();
    const views = viewManager.get();

    const renderQuery = (mmmstate, onClick, baseId) => {
      if (!mmmstate.activeView && views[0]?._id) {
        mmmstate.activeView = views[0]?._id;
      }
  
      let view = views.find(v => v._id == mmmstate.activeView);
      if (!view && mmmstate.activeView) {
        mmmstate.activeView = views[0]?._id;
        view = views[0];
      }



      if (view?.viewQuery) {
        result = executeQuery(view.viewQuery, result);
      }


      if (view?.filterQuery) {
        result = executeQuery(view.filterQuery, result);
      }

      // if (view.filter)

      // console.log(x(view));
  
      const renderEntitiesSection = ({
        state = null as {
          onlyShowBackground?: boolean,
          includeBackgroundEntities?: boolean,
          // groupBy?: string,
          contentsType
          search
        },
        // title,
        contents,
        // customCheckboxes = undefined,
        // after = undefined,
        // underEntity = undefined,
        map = undefined,
        baseQuery = undefined,
      }) => {
        let orgContents = contents;
  
        if (map) {
          contents = contents.map(map).filter(Boolean);
        }
        const filterBackgroundEntities = c => {
          const metaStates = entityMetaStates(c);
          const background = metaStates.find(id => {
            const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
            if (metaState.backgroundState) return true;
          });
    
          if (state.onlyShowBackground) {
            if (!background) return false;
          }
          else if (background && !state.includeBackgroundEntities) return false;
    
    
          return true;
        }
        contents = contents.filter(filterBackgroundEntities);
  
        const allCount = contents.length;
  
        const types = {};
        for (const id of contents) {
          const entity = db.entities.findById(id);
          if (!entity) continue;
          const type = entity.type && db.entityTypes.findById(entity.type);
          if (type) {
            if (!types[type._id]) types[type._id] = 0;
            types[type._id]++;
          }
          else {
            if (!types['untyped']) types['untyped'] = 0;
            types['untyped']++;
          }
        }
    
        const orgSameType = (() => {
          // return true if all entities are of the same type
          let type;
          for (const id of contents) {
            const entity = db.entities.findById(id);
            if (!entity) continue;
            if (!type) {
              type = entity.type;
            }
            else if (type != entity.type) {
              return false;
            }
          }
          return type;
        })();
  
        if (state.contentsType) {
          contents = contents.filter(e => {
            if (state.contentsType == 'untyped') return !db.entities.findById(e).type;
    
            const entity = db.entities.findById(e);
            if (entity) {
              return entity.type === state.contentsType;
            }
            return false;
          });
        }
  
        const singleType = isId(state.contentsType) || orgSameType;
  
        const grouped = view && supportsGrouping(view.type) && view.groupBy && groupById(orgContents, view.groupBy);

  
        const filter = list => {
          return list?.map?.(cc => {
            let c;
            if (map) {
              c = map(cc);
            }
            else {
              c = cc;
            }
  
            if (!contents.includes(c)) return null;
  
            return c;

          }).filter(Boolean);
        }
  
        const add: any = canAddEntity && ((nav=true) => {
          this.props.add(view);
        });
  
        return (
          <>
            {showToolbar && (
              <div className="toolBar">
                <ViewsList
                  views={views?.map?.(v => ({
                    _id: v._id,
                    name: viewName(v),
                    icon: viewIcon(v),
                  }))}
                  active={mmmstate.activeView}
                  onClickView={id => {
                    mmmstate.activeView = id;
                  }}
                  contextMenu={id => {
                    return [
                      {
                        text: 'Edit!',
                        onClick: () => {
                          this.props.viewManager.editView(id);
                        }
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          this.props.viewManager.deleteView(id);
                        }
                      },
                    ]
                  }}
                  onAdd={(i, e) => {
                    viewManager.addView();
                  }}
                  onDelete={() => {}}
                />
                <div className="right">  
                  {!orgSameType && (
                    <select value={state.contentsType || ''}
                      onChange={e => {
                        state.contentsType = e.target.value;
                        delete view.groupBy;
                      }}
                    >
                      <option value="">All ({allCount})</option>
                      {Object.keys(types).filter(t => t != 'untyped').map(t => (
                        <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                      ))}
                      {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
                    </select>
                  )}

                  {/* <NotionButton
                    img={false}
                    text="Filter"
                    onClick={() => {
                      this.props.viewManager.editView(view._id);
                    }}
                  /> */}

                  <Search
                    value={state.search}
                    setValue={v => {
                      state.search = v;
                    }}
                  />

                  {add && <NotionButton
                    img="icons8-create (2)"
                    onClick={() => {
                      add();

                    }}
                  />}
                </div>
              </div>
            )}
            {view && orgContents && renderView(
              view,
              state, 
              {
                entities: filter(orgContents),
                grouped: grouped && grouped.map(({ key, group, entities }) => {
                  const r = filter(entities);
                  if (!r?.length) return;
                  return {
                    key,
                    group,
                    entities: r,
                  }
                }).filter(Boolean),
              },
              add,
              onClick,
              this.props.entity,
              this.props.touched,
              null,
              null,
              this.context?.next?.()?.id,
            )}
          </>
        );
      }
  
      return renderEntitiesSection({
        contents: result,
        state: view ? XObject.get(view, 'filter', {}) : {},
      });
    }

    return renderQuery(this.props.state, null, this.props.entity);
  }
}

@component
export class ViewQuery extends Component<{ id; state; entity?, showToolbar?, touched? }> {
  static styles = styled.div`
    .toolBar {
      /* font-size: 10px; */
      flex: 0 0 auto;
      ${NotionButton} {
        height: 28px;
      }
      overflow: auto;

      display: flex;
      align-items: center;
      /* .views {
        height: 34px;
        display: flex;
        align-items: center;
        ${NotionButton} {
          margin-left: 4px;
        }
        .view {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 34px;
          padding: 0 8px;
          color: #bcbcbc;
          font-weight: 600;
          box-sizing: border-box;
          border-bottom: 2px solid transparent;
          white-space: nowrap;
          svg {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: #bcbcbc;
          }
          &.noText {
            svg {
              margin-right: 0;
            }
          }
          &.active {
            color: inherit;
            svg {
              fill: #4a4a4a;
            }

          }
        }
      } */

      /* &:not(:hover) {
        .views {
          ${NotionButton} {
            display: none;
          }
        }

        .right {
          display: none;
        }
      } */

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }

        .create {
          svg {
            fill: #88a4ff;
          }
        }

        .add {
          user-select: none;
          transition: background 20ms ease-in 0s;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-radius: 3px;
          line-height: 1.1;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 500;
          background: rgb(35, 131, 226);
          color: white;
          display: block;

          position: relative;
          display: inline-flex;
          flex-shrink: 0;
          border-radius: 3px;
          overflow: hidden;
          height: 28px;
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }

    .queryView {
      position: relative;
      flex: 1 1 auto;
      min-height: 50px;
    }

    .header {
      display: flex;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .views {
        display: flex;
        .view {
          cursor: pointer;
          padding: 0 4px;
          &.active {
            /* border-bottom: 2px solid black; */
          }
        }
      }
      /* border-bottom: 1px solid #ccc; */
      margin-bottom: 8px;
    }
    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .sectionTitle {
      display: flex;
      align-items: center;
      ${NotionButton} {
        margin-left: auto;
      }
    }

    .layers {
      display: flex;
      /* position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; */
      .layer {
        position: relative;
        flex: 1 1 0;
        border-right: 2px solid #ccc;
        overflow: auto;
        display: flex;
        flex-direction: column;
        /* padding: 8px; */

        .queryView {
          padding: 8px;
        }
      }
    }

    &.layered {
      display: flex;
      flex-direction: column;
      .layers {
        flex: 1 1 auto;
        overflow: auto;
      }
    }

    .hasFilter {
      color: rgb(35, 131, 226);
    }

    .aiSearch {
      svg {
        width: 15px;
        height: 15px;
        fill: rgb(167, 130, 195);
      }
    }
  `
  state = XInit(class {
  })
  static contextType = SystemContext;
  context: any;
  render(Container?) {
    const showToolbar = this.props.showToolbar ?? true;
    const renderQuery = (query2: {
      layered
      views
      _id
      layers
      parent
      parentType
    }, mmmstate, onClick, baseId, getCount?, selected?, localBaseId?) => {
      let result;

      if (query2?.layered) {
        result = [];

        const q = {
          query: query2.layers[0].query,
          parentType: query2.parentType,
          parent: query2.parent,
        }

        const firstLayer = executeQuery(queryChain(q), undefined, baseId);
        for (const id of firstLayer) {
          const r = executeQuery(query2.layers[1].query, undefined, id);
          if (r.length) {
            result.push(...r);
          }
        }

        result = _.uniq(result);

      }
      else {
        if (query2) {
          const baseRels = {};
          if (_.isArray(baseId)) {
            result = [];
            for (const id of baseId) {
              result.push(...executeQuery(queryChain(query2), undefined, id));
            }
          }
          else {
            result = executeQuery(queryChain(query2), undefined, baseId, baseRels);
          }

  
        }
      }

      if (!query2.views) {
        XObject.push(query2, 'views', XObject.obj({
          name: 'Default',
          type: ViewType.list,
        }));
      }
  
  
      if (!mmmstate.activeView && query2.views[0]?._id) {
        mmmstate.activeView = query2.views[0]?._id;
      }
  
      let view = query2.views.find(v => v._id == mmmstate.activeView);
      if (!view && mmmstate.activeView) {
        mmmstate.activeView = query2.views[0]?._id;
        view = query2.views[0];
      }

      if (view?.viewQuery) {
        result = executeQuery(view.viewQuery, result);
      }


      if (view?.filterQuery) {
        result = executeQuery(view.filterQuery, result);
      }

      if (view?.filter?.search) {
        result = result.filter(id => {
          
          if (entityDisplayName(id)?.toLowerCase().includes(view.filter?.search?.toLowerCase())) {
            return true;
          }
        });
      }
  
      const renderEntitiesSection = ({
        state = null as {
          onlyShowBackground?: boolean,
          includeBackgroundEntities?: boolean,
          // groupBy?: string,
          contentsType
          search
        },
        // title,
        contents = undefined as string[],
        // customCheckboxes = undefined,
        // after = undefined,
        // underEntity = undefined,
        map = undefined,
        baseQuery = undefined,
      }) => {
        contents = _.uniq(contents);
        let orgContents = contents;
  
        if (map) {
          contents = contents.map(map).filter(Boolean) as any;
        }
        const filterBackgroundEntities = c => {
          const metaStates = entityMetaStates(c);
          const background = metaStates.find(id => {
            const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
            if (metaState.backgroundState) return true;
          });
    
          if (state.onlyShowBackground) {
            if (!background) return false;
          }
          else if (background && !state.includeBackgroundEntities) return false;
    
    
          return true;
        }
        contents = contents.filter(filterBackgroundEntities);
  
        const allCount = contents.length;
  
        const types = {};
        for (const id of contents) {
          const entity = db.entities.findById(id);
          if (!entity) continue;
          const type = entity.type && db.entityTypes.findById(entity.type);
          if (type) {
            if (!types[type._id]) types[type._id] = 0;
            types[type._id]++;
          }
          else {
            if (!types['untyped']) types['untyped'] = 0;
            types['untyped']++;
          }
        }
    
        const orgSameType = (() => {
          // return true if all entities are of the same type
          let type;
          for (const id of contents) {
            const entity = db.entities.findById(id);
            if (!entity) continue;
            if (!type) {
              type = entity.type;
            }
            else if (type != entity.type) {
              return false;
            }
          }
          return type;
        })();
  
        if (state.contentsType) {
          contents = contents.filter(e => {
            if (state.contentsType == 'untyped') return !db.entities.findById(e).type;
    
            const entity = db.entities.findById(e);
            if (entity) {
              return entity.type === state.contentsType;
            }
            return false;
          });
        }
  
        const singleType = isId(state.contentsType) || orgSameType;
  
        const grouped = view && supportsGrouping(view.type) && view.groupBy && groupById(orgContents, view.groupBy);

  
        const filter = list => {
          return list?.map?.(cc => {
            let c;
            if (map) {
              c = map(cc);
            }
            else {
              c = cc;
            }
  
            if (!contents.includes(c)) return null;
  
            return c;

          }).filter(Boolean);
        }
  
        const createBase = _.isArray(baseId) ? baseId[0] : baseId;
        const add = (createEntityFromQuery(queryChain(query2), {}, createBase) !== false) && (async (nav=true) => {
          let entity:any = {};
          createEntityFromQuery(queryChain(query2), entity, createBase);
          if (state.contentsType && !entity.type) {
            entity.type = state.contentsType;
          }

          if (view.viewQuery) {
            createEntityFromQuery(view.viewQuery, entity);
          }


          if (view.filterQuery) {
            createEntityFromQuery(view.filterQuery, entity);
          }
          

          if (view.type == ViewType.graph && (state as any).selected) {
            entity.$edges = [];
            entity.$edges.push({
              from: (state as any).selected || baseId,
              directed: true
            })
          }

          if (view.type == ViewType.graph) {
            entity.name = await showPrompt('Name');
          }
          console.log(_.cloneDeep(entity));


  
          entity = createEntity(entity, createBase);
  
          // if (nav && view.type != ViewType.graph) this.context?.navigate?.({
          //   type: 'entity',
          //   id: entity._id,
          // })
  
        });
  
        return (
          <>
            {/* <div className="header">
              <div className="views">
                {query2.views?.map?.(v => (
                  <span
                    className={classNames('view', { active: mmmstate.activeView == v._id})}
                    onClick={() => {
                      mmmstate.activeView = v._id;
                    }}
                    onContextMenu={e => {
                      e.preventDefault();
                      showContextMenu(e, [
                        {
                          text: 'Edit',
                          onClick: () => {
                            openWindow({
                              type: WindowType.QueryView,
                              query: query._id,
                              view: v._id,
                            })
                          }
                        },
                        {
                          text: 'Delete',
                          onClick: () => {
                            query2.views.splice(query2.views.indexOf(v), 1);
                          }
                        },
                      ]);
                    }}
                  >
                    {v.name || 'Untitled view'}
                  </span>
                ))}
  
                <button
                  onClick={() => {
                    XObject.push(query2, 'views', XObject.obj({
                      // name: 'New view',
                      type: ViewType.list,
                    }));
                  }}
                >+</button>
  
              </div>
  
              <div className="right">

              </div>
            </div> */}

            {showToolbar && (
              <div className="toolBar">
                <ViewsList
                  active={mmmstate.activeView}
                  contextMenu={id => {
                    return [
                      {
                        text: 'Edit',
                        onClick: () => {
                          triggerInspectObject({
                            type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                            args: {
                              query: query._id,
                              view: id,
                            }
                          }, true);
                        }
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          query2.views.splice(query2.views.findIndex(v => v._id == id), 1);
                        }
                      },
                    ]
                  }}
                  onAdd={(i, e) => {
                    if (!query2.views) {
                      XObject.push(query2, 'views', XObject.obj({
                        // name: 'New view',
                        type: ViewType.list,
                      }));

                    }
                    else {
                      query2.views.splice(i, 0, XObject.obj({
                        type: ViewType.list,
                      }));
                    }

                  }}
                  onClickView={id => {
                    mmmstate.activeView = id;
                    triggerInspectObject({
                      type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                      args: {
                        query: query._id,
                        view: id,
                      }
                    });

                  }}
                  onDelete={id => {


                  }}
                  views={query2.views?.map?.(v => ({
                    _id: v._id,
                    icon: viewIcon(v),
                    name: viewName(v),
                  }))}
                />
                {/* <InsertionCont
                  tag="div"
                  className="views"
                  onInsert={i => {
                    if (!query2.views) {
                      XObject.push(query2, 'views', XObject.obj({
                        // name: 'New view',
                        type: ViewType.list,
                      }));

                    }
                    else {
                      query2.views.splice(i, 0, XObject.obj({
                        type: ViewType.list,
                      }));
                    }
                  }}
                  orientation="horizontal"
                >
                  {query2.views?.map?.(v => {
                    const icon = viewIcon(v);
                    return (
                      <span
                        className={classNames('view', { active: mmmstate.activeView == v._id})}
                        onClick={() => {
                          mmmstate.activeView = v._id;
                          triggerInspectObject({
                            type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                            args: {
                              query: query._id,
                              view: v._id,
                            }
                          });
                        }}
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Edit',
                              onClick: () => {
                                triggerInspectObject({
                                  type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                                  args: {
                                    query: query._id,
                                    view: v._id,
                                  }
                                }, true);
                              }
                            },
                            {
                              text: 'Delete',
                              onClick: () => {
                                query2.views.splice(query2.views.indexOf(v), 1);
                              }
                            },
                          ]);
                        }}
                      >
                        {icon && <Svg name={icon} />} {viewName(v)}
                      </span>
                    )
                  })}
                </InsertionCont> */}
                <div className="right">
                  {/* {orgSameType && (
                    <select>
                      <option>{pluralize(db.entityTypes.findById(orgSameType).name)} ({allCount})</option>
                    </select>
                  )} */}
                  {!orgSameType && (
                    <select value={state.contentsType || ''}
                      onChange={e => {
                        state.contentsType = e.target.value;
                        delete view.groupBy;
                      }}
                    >
                      <option value="">All ({allCount})</option>
                      {Object.keys(types).filter(t => t != 'untyped').map(t => (
                        <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                      ))}
                      {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
                    </select>
                  )}
                  {/* {view && supportsGrouping(view.type) && singleType && (() => {
                    const schema = entitySchema(contents[0]);
                    if (!schema) return null;
                    const attributes = schema.attributes && evaluate(schema.attributes);
    
                    return (
                      <>
                        <select
                          value={view.groupBy || ''}
                          onChange={e => {
                            view.groupBy = e.target.value;
                          }}
                        >
                          <option value="">No Grouping</option>
                          {attributes?.map(a => {
                            const attr = db.attributeTypes.findById(a);
                            if (!attr) return null;
                            return (
                              <option key={a} value={a}>{attr.name}</option>
                            )
                          })}
                        </select>
                      </>
                    )
                  })()} */}
                  {/* <NotionButton
                    className={classNames({
                      hasFilter: view?.filterQuery?.[1]?.entries?.length
                    })}
                    img={false}
                    text="Filter"
                    onClick={() => {
                      openWindow({
                        type: WindowType.QueryView,
                        query: query._id,
                        view: view._id,
                      })
                    }}
                  /> */}
                  <Search
                    value={state.search}
                    setValue={v => {
                      state.search = v;
                    }}
                  />

                  {/* <NotionButton
                    className="aiSearch"
                    img="ai"
                    onClick={() => {
                      this.context?.navigate?.({
                        type: PaneType.chatGPT,
                        query: query._id,
                      })
                    }}
                  /> */}

                  
                  {add && <NotionButton
                    className="create"
                    img="icons8-create (2)"
                    onClick={() => {
                      add();
                    }}
                  />}
                </div>
              </div>
            )}
            <div className="queryView"
              style={view?.type == ViewType.graph ? {
                minHeight: '500px'
              } : {}}
            >
            {view && orgContents && renderView(
              view,
              state,
              {
                entities: filter(orgContents),
                grouped: grouped && grouped.map(({ key, group, entities }) => {
                  const r = filter(entities);
                  if (!r?.length) return;
                  return {
                    key,
                    group,
                    entities: r,
                  }
                }).filter(Boolean),
              },
              add,
              onClick,
              _.isArray(baseId) ? baseId[0] : baseId,
              this.props.touched,
              {
                type: ObjectType.query,
                id: this.props.id,
              },
              getCount,
              selected,
              localBaseId
            )}
            </div>
          </>
        );
      }
  
      return renderEntitiesSection({
        contents: result,
        state: view ? XObject.get(view, 'filter', {}) : {},
      });
    }

    const query = db.queries.findById(this.props.id);
    const st = XObject.get(query, 'state', {});
    
    if (query.layered && query.views?.find?.(v => v._id == st.activeView)?.type == ViewType.split) {
      const view = query.views?.find?.(v => v._id == st.activeView);
      const selected = XObject.get(view, 'layerSelection', {});
      return (
        <Container className={classNames({
          layered: query.layered,
        })}
          
        >
          <div className="toolBar">
            <ViewsList
              active={st.activeView}
              views={query.views.map(v => ({
                _id: v._id,
                name: viewName(v),
                icon: viewIcon(v),
              }))}
              contextMenu={id => {
                return [
                  {
                    text: 'Edit',
                    onClick: () => {
                      triggerInspectObject({
                        type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                        args: {
                          query: query._id,
                          view: id,  
                        }
                      }, true);
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      query.views.splice(query.views.findIndex(v => v._id == id), 1);
                    }
                  },
                ]
              }}
              
              onAdd={() => {
                XObject.push(query, 'views', XObject.obj({
                  // name: 'New view',
                  type: ViewType.list,
                }));

              }}

              onClickView={id => {
                st.activeView = id;

              }}
              onDelete={() => {

              }}
            />

            {/* <InsertionCont 
              tag="div"
              className="views"
              onInsert={() => {}}
              orientation="horizontal"
            >
              {query.views?.map?.(v => (
                  <span
                    className={classNames('view', { active: st.activeView == v._id})}
                    onClick={() => {
                      
                    }}
                    onContextMenu={e => {
                      e.preventDefault();
                      showContextMenu(e, [
                        {
                          text: 'Edit',
                          onClick: () => {
                            triggerInspectObject({
                              type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                              args: {
                                query: query._id,
                                view: v._id,  
                              }
                            }, true);
                          }
                        },
                        {
                          text: 'Delete',
                          onClick: () => {
                            query.views.splice(query.views.indexOf(v), 1);
                          }
                        },
                      ]);
                    }}
                  >
                    <Svg name={viewIcon(v)} /> {viewName(v)}
                  </span>
                ))}

            </InsertionCont> */}
            </div>
            <div className="layers">
              {query.layers.map((layer, i) => {
                const prevSelected = selected[i - 1];
                const prevView = query.layers[i - 1]?.views?.find?.(v => v._id == query.layers[i - 1].state.activeView);
                let graphSelect;

                if (prevView?.type == ViewType.graph) {
                  if (i > 0) {
                    const descendants = queryGraphBasic(prevSelected, true, false, false).map(e => e.entity);
                    graphSelect = descendants;
                  }
                }

                return (
                  <div key={layer._id} className="layer">
                    {renderQuery(
                      layer,
                      XObject.get(layer, 'state', {}),
                      i < query.layers.length - 1 && (id => {
                        selected[i] = id;
                      }),
                      i == 0 ? this.props.entity : (graphSelect ? graphSelect : prevSelected),
                      i == 0 && query.layers.length > 1 && (id1 => {
                        const q = {
                          query: query.layers[0].query,
                          parentType: query.parentType,
                          parent: query.parent,
                        }
                        const firstLayer = executeQuery(queryChain(q), undefined, id1);
                        let result = [];
                        for (const id of firstLayer) {
                          const r = executeQuery(query.layers[1].query, undefined, id);
                          if (r.length) {
                            result.push(...r);
                          }
                        }
                
                        result = _.uniq(result);

                        const activeViewId = query.layers[1]?.state?.activeView;
                        const activeView = query.layers[1]?.views?.find?.(v => v._id == activeViewId);

                        if (activeView?.viewQuery) {
                          result = executeQuery(activeView.viewQuery, result);
                        }


                        if (activeView?.filterQuery) {
                          result = executeQuery(activeView.filterQuery, result);
                        }
                
                        return result.length;
                      }),
                      selected[i],

                    )}
                  </div>
                )
              })}
          </div>
        </Container>
      );
    }
    else {
      return <Container>{renderQuery(
        query,
        st,
        null,
        this.props.entity,
        null,
        this.context?.next?.()?.id,
      )}</Container>;
    }
  }
}

@component
export class ViewQueryPage extends Component<{ id, state, entity? }> {
  static styles() {
    return styled.div`
      .queryName {
        font-size: 26px;
        font-weight: 600;
        display: block;
        line-height: 1.2;
      }
      ${ObjectPageFrame.t.content} {

        /* padding: 16px calc(44px + 16px); */
        padding: 16px calc(0 + 16px);
      }
      &.layered {
        ${ObjectPageFrame.t.content} {
          display: flex;
          flex-direction: column;
        
          ${ViewQuery} {
            flex: 1 1 auto;
            position: relative;
          }
        }
      }
    `;
  }

  render(Container?) {
    const query = db.queries.findById(this.props.id);
    return (
      <Container
        className={classNames({
          layered: query.layered,
        })}
      >
        <ObjectPageFrame
          icon="database"
          path={query.name || describeQuery(query.query)}
          right={null}
        >
          <span className="queryName">{query.name || describeQuery(query.query)}</span>
          <ViewQuery id={this.props.id} state={this.props.state} entity={this.props.entity} />
        </ObjectPageFrame>
      </Container>
    )
  }
}
