import _ from 'lodash';
import styled from 'styled-components';
import { Component } from 'react';
import { AllGraph } from './AllGraph';
import { db } from '../db';
import { DraftSelect } from '../etc/draftHelpers';
import { DrilldownView } from './Drilldown';
import { PropertyField } from './PropertyField';
import { showContextMenu } from '../etc/showContextMenu';
import { component } from '../component2';
import { XInit, XObject } from '../XObject';
import { queryGraph, resolvePath } from '../etc/queryGraph';
import { entityMetaStates } from '../etc/entityMatch';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { renderInspect } from './EntityInspect';
import { renderDocuments } from '../etc/renderDocuments';
import { addToQueue, getQueuePosition, inQueue, queueMenuItems, removeFromQueue, renderQueuePositions } from '../glue/getQueue';
import { entityContextMenuItems, EntityDisplay } from './EntityDisplay';
import { TypeEditor } from './TypeEditor';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { Comp } from '../glue/Comp';
import { SystemContext } from '../etc/SystemContext';
import { appState } from '../etc/appState';
import { deleteEdge, getEdgesForEntity } from '../etc/getEdgesForEntity';
import { createEntity } from '../etc/createEntity';

function getSubEntities(id) {
  return [];
  return db.entities.filter(x => x.parent == id);
}

{/* <BlockPageCont
noHeader
id={doc._id + args.elId}
inWindow={false}
blockPage={x}
state={XObject.get(this.state.state, x._id, {})}
_onFocused={id => {
  args.onSelectChild(id);
}}
/> */}


const ModuleWrapper = styled.div`
  > .header {
    margin-bottom: 6px;
    position: relative;
    > .type {
      font-size: 0.8em;
      color: #666;
    }

    > .path {
      font-size: 0.8em;
      color: #666;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: normal;
    }

    .metaStates {
      position: absolute;
      top: 0;
      right: 0;
    }



  }

  .rels {
    .type {
      font-size: 0.8em;
      color: #666;
    }

    .edgeType {
      font-weight: bold;
      font-size: 15px;
      margin-right: 4px;
    }
  }


`;

function getEdges(id) {
  return getEdgesForEntity(id);

}

const Wrapper = styled.div`
  > {
    ul {
      margin: 0;
    }
    .new + ul {
      /* margin-top: -1em */
    }
  }
`;

export function renderEntityRels({ root, id, prev, args, state, graph }) {
  const entity = db.entities.findById(id);
  const edges = db.edges.filter(x => x.entities.includes(entity._id));

  // group edges by direction
  const edgesByDirection = _.groupBy(edges, x => {
    if (x.entities[0] == entity._id) return 'out';
    if (x.entities[1] == entity._id) return 'in';
    return 'both';
  });

  function render(rels) {
    return     rels?.length > 0 && <ul className="rels">
    {rels?.map?.(x => {
      const otherEntityId = x.entities[0] == entity._id ? x.entities[1] : x.entities[0];
      const s = XObject.get(state, otherEntityId, {});
      const otherEntity = db.entities.findById(otherEntityId);
      if (!otherEntity) return;
      if (otherEntityId == prev)
        return;
      const direction = x.entities[0] == entity._id ? '>' : '<';
      return (
        <li data-value={otherEntityId}
        data-inspect-id={otherEntityId}
        key={x._id}
          onContextMenu={e => {
            e.preventDefault();

            showContextMenu(e, [
              {
                text: 'Delete',
                onClick: () => {
                  deleteEdge(x._id);
                }
              }
            ]);

          }}

          onClick={e => {
            e.stopPropagation();
            // args.onInspect(otherEntityId);
            appState.inspecting = {
              type: 'entity',
              id: otherEntityId,
            }
          }}
        >
          <span className="edgeType">{x.directed ? direction : '*'}</span>
            {/* <PropertyField  object={otherEntity} property="name" /> {getQueuePosition(root, otherEntityId)} {otherEntity.type && <><span className="type">{db.entityTypes.findById(otherEntity.type)?.name || '(deleted)'}</span></>} */}

            <EntityDisplay id={otherEntityId} root={root} graph={graph} />

          <button
            onClick={e => {
              if (e.target == e.currentTarget) {
                args.onSelectChild(otherEntity._id);

              }
            }}
          >&gt;</button>

          <button
            style={{ fontWeight: s.expanded ? 'bold' : 'normal' }}
            onClick={() => {
              s.expanded = !s.expanded;
            }}
          >{getEdges(otherEntityId).length}</button>


          {/* <span className="">
            {entityMetaStates(otherEntityId).map(id => defaultWorkspace().metaStates.find(x => x._id == id).name).join(', ')}
          </span> */}

          {s.expanded && renderEntityRels({
            root,
            id: otherEntityId,
            args,
            prev: id,
            state: XObject.get(s, otherEntityId, {}),
            graph,
          })}

        </li>
      );

    })}

    </ul>
  }

  return (
    <Wrapper>
      {render(edgesByDirection.out)}
      {render(edgesByDirection.in)}
      <ul className="new">
        <li><span>
          <select onChange={e => {
            e.target.value = '';
          }}>
            <option value="">Existing</option>
            <option>*</option>
            <option>&gt;</option>
            <option>&lt;</option>
          </select>
          <select onChange={e => {
            const type = e.target.value;
            e.target.value = '';

            const newEntity = XObject.obj({});
            createEntity(newEntity, root);

            appState.inspecting = {
              type: 'entity',
              id: newEntity._id,
            }

            args.onInspect?.(newEntity._id);

            let entities;
            if (type == '*' || type == '>') {
              entities = [entity._id, newEntity._id];
            }
            else {
              entities = [newEntity._id, entity._id];
            }

            const edge = XObject.obj({
              directed: type == '>' || type == '<',
              entities,
            });
            db.edges.push(edge);
          }}>
            <option value="">New</option>
            <option value="*">*</option>
            <option value=">">&gt;</option>
            <option value="<">&lt;</option>
          </select>

        </span></li>
      </ul>
    </Wrapper>
  )
}

@component
export class Root extends Comp<{
  drillDownState?
  root: {
    dontShowLine?
    width?
    children?
    render?
  }

  graph?: { graph?: string, root?: string }

  hideInspectPane?

}> {
  state = XInit(class {
    state: any = {};
    drillDownState: any = {};
    inspectState = {}
  });

  static contextType = SystemContext;
  context: any;

  render() {


    let doc;

    if (!db.documents.length) {
      doc = XObject.obj({
        title: 'Untitled',
        content: [],
      });
      db.documents.push(doc);
    }
    else {
      doc = db.documents[0];
    }

    const c = (
      <>
        <DrilldownView
          hideInspectPane={this.props.hideInspectPane}

          iface={{
            paneWidth: i => {
              if (i == 0) {
                return this.props.root.width;
              }
            },
            paneLine: i => {
              if (i == 0) {
                return false;
              }
              return true;
            },
            children: (id, prevId) => {
              let entities = [];
              const collectEntities = (blocks, level = 0) => {
                for (const block of blocks) {
                  let nextLevel = level;
                  if (block.type == 'entity') {
                    ++nextLevel;
                    entities.push(block.content);
                  }

                  if (block.children) {
                    collectEntities(block.children, nextLevel);
                  }
                }
              };
              if (!id) {
                return this.props.root.children();
              }
              else {
                const entity = db.entities.findById(id);
                if (entity) {
                  const subEntities = getSubEntities(id);//db.entities.filter(x => x.parent == id);
                  const edges = getEdgesForEntity(id);
  
                  const edgesByDirection = _.groupBy(edges, x => {
                    if (x.entities[0] == entity._id) return 'out';
                    if (x.entities[1] == entity._id) return 'in';
                    return 'both';
                  });
  
                  if (edgesByDirection.out) {
                    entities.push(...edgesByDirection.out.map(x => x.entities[1]));
                  }
                  if (edgesByDirection.in) {
                    entities.push(...edgesByDirection.in.map(x => x.entities[0]));
                  }
  
                  entities.push(...subEntities.map(x => x._id));
                }

              }
              return _.uniq(entities).filter(x => x != prevId);


              return [];
            },
            renderInspect: ({ n, onInspect }) => {
              return renderInspect(n, this.state.inspectState, id => {
                console.log('select', id);
                onInspect(id);
              })
            },
            renderModule: (args) => {
              if (!args.n) {
                return this.props.root.render(args);
              }
              else {
                const entity = db.entities.findById(args.n);
                if (!entity) return null;
                // const edges = db.edges.filter(x => x.entities.includes(entity._id));
                const subEntities = getSubEntities(entity._id);

                const path = (() => {
                  if (this.props.graph) {
                    const g = resolvePath(this.props.graph.graph, this.props.graph.root, args.n);
                    return g;

                  }
                })();

                return (
                  <ModuleWrapper>
                    <div className="header">
                    <span className="type"><TypeEditor
                      value={entity.type}
                      onChange={value => {
                        entity.type = value;
                      }}
                    /></span>
                    <h3 data-value={args.n}
                      style={{
                        opacity: entity.stateful ? 1 : .5,
                      }}
                      data-inspect-id={args.n}
                      onClick={() => {
                        console.log('on inspect');
                        // args.onSelectSelf();
                        // args.onInspect(args.n);
                        appState.inspecting = {
                          type: 'entity',
                          id: args.n,
                        }
                      }}
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [

                          ...entityContextMenuItems({
                            id: entity._id,
                            rootId: this.props.graph.root,
                            graphId: this.props.graph.graph,
                          })
                          // {
                          //   text: entity.stateful ? 'Make stateless' : 'Make stateful',
                          //   onClick: () => {
                          //     entity.stateful = !entity.stateful;
                          //   }
                          // },
                          // {
                          //   text: 'View document',
                          //   onClick: () => {
                          //     openWindow({
                          //       type: WindowType.Notion,
                          //       entity: entity._id,
                          //     })
                          //   },
                          // },

                          // ...queueMenuItems(this.props.graph.root, args.n),
                        ]);
                      }}
                    ><PropertyField object={entity} property="name" /> {renderQueuePositions(this.props.graph.root, entity._id)}</h3>
                    <div className="path">{path && path.map(id => db.entities.findById(id).name).join(' / ')}</div>

                    <div className="metaStates">
                      {entityMetaStates(entity._id).map(id => defaultWorkspace().metaStates.find(x => x._id == id).name).join(', ')}
                    </div>
                    </div>


                    {renderDocuments(entity._id, doc._id + args.elId, this.state.state, args.onSelectChild, config => {
                      this.context.navigate(config);
                    })}

                    {renderEntityRels({
                      root: this.props.graph.root,
                      args,
                      id: entity._id,
                      prev: args.prevN,
                      state: XObject.get(this.props.drillDownState, args.elId, {}),
                      graph: this.props.graph.graph,
                    })}


                    

                    <ul>
                      {subEntities.map(x => {
                        return (
                          <li
                            key={x._id}
                            onClick={() => {
                              args.onSelectChild(x._id);
                            }}
                            data-value={x._id}
                          >
                            <PropertyField object={x} property="name" />
                          </li>
                        );
                      })}
                      <li><button
                      onClick={() => {
                        const newEntity = XObject.obj({
                          parent: entity._id,
                        });
                        createEntity(newEntity, entity._id);
                      }}
                    >+</button></li>
                    </ul>

                  </ModuleWrapper>
                );
              }
            },
          }}
          root={null}
          state={this.props.drillDownState || this.state.drillDownState} />

      </>
    );

    return c;

  }
}
