import { Component } from "react";
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component, styled } from "../component2";
import { NotionDocument } from "../components/notionDocument/NotionDocument";
import { concatData, dataLength, sliceData } from "../components/richTextHelpers";
import { X, XClone, XObject, x } from "../XObject";
import { MyBlock, MyBlockManager } from "./MyBlockManager";
import { db } from "../db";
import { createEntity } from "../etc/createEntity";
import { ObjectType } from "../types/QueryParentType";
import { attributesInScope, typesInScope } from "../components/objectFuncs";
import { renderBlock } from "./renderBlock";
import { SystemContext } from "../etc/SystemContext";
import { Block } from "../components/notionDocument/BlockManager";
import { componentSystem } from "../componentSystem";
import { appState } from "../etc/appState";
import { InspectState } from "../components/InspectState";
import { types } from "./types";
import { showPrompt } from "../etc/showPrompt";
import { resumeMode } from "../resumeMode";

@component
export class MyNotionDocument extends Component<{
  title;
  setTitle;
  blocks;
  setBlocks;
  baseEntity;
  extendEntity;
  docId;
  insidePositionContext?
  tick?
}> {
  static styles = styled.div`
    .--entity-- {
      color: #0275ff;
      cursor: pointer;
    }

    .--code-- {
      /* color: #71c483; */
      cursor: pointer;
      border-bottom: 1px dashed #d0d0d0;
    }
    .--capture-- {
      border-bottom: 1px dashed #d0d0d0;
    }

    [data-type="blockCont"] > .block.activeBlock {
      /* background: #f3f3f3; */
      .entityDot, .dataBindingDot {
        opacity: 1;
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-left: -2px;
      }
    }

  `;

  blocks;
  constructor(props) {
    super(props);

    const blocks = this.props.blocks;
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    const observeExternalChanges = () => {
      XObject.observe(this.props.blocks, (change) => {
        this.blocks = XClone(this.props.blocks);
        this.forceUpdate();
      });
    }

    observeExternalChanges();

    XObject.observe(this.blocks, (change) => {
      this.props.setBlocks(XClone(this.blocks));
      observeExternalChanges();
    });
  }

  static contextType = SystemContext;
  context: any;

  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this);
        /*if (this.props.database) {
      const db = new DB(this.props.database);
      const titleCol = db.titleCol();
      XObject.observe(this.props.database.rows, mutation => {
        console.log(x(mutation));
        // return;
        // if (!mutation.pass?.internal) {
          if (mutation.type == 'set' && mutation.path[1] == 'data' && mutation.path[2] == titleCol._id) {
            const recordId = mutation.path[0].slice(1);
            const record = db.getRecord(recordId);
            const blocks = findBlocksMatching(this.blockManager.getRootBlocks(), b => b.record == recordId);
            // console.log(x(block), mutation.el._id);
            for (const block of blocks) {
              if (mutation.pass?.internal == block._id) continue;
              const changedName =  true;//mutation.path[2] == titleCol._id;

              // console.log(changedName);
              if (changedName) {
                if (mutation.el.name != dataToString(block.data || [])) {
                  console.log('===UPDATE')
                  const name = record.data[titleCol._id];
                  
                  block.data = X([name]);
                  // this.tick++;
                  const selection = getBlockSelection();
                  // console.log(selection);
                  this.forceUpdate();
                  setTimeout(() => {
                    setCaretToBlockSelection(selection);
                  }, 10);  
                }
    
              }
              // else if (mutation.path[1] == 'checked') {
              //   block.checked = mutation.el.checked;
              // }
            }
          }
        // }
      });
    }
    XObject.observe(db.entities, mutation => {
      // console.log(mutation);
      // if (!mutation.pass?.internal) {
        if (mutation.type == 'set') {
          const blocks = findBlocksMatching(this.blockManager.getRootBlocks(), b => b.id == mutation.el._id);
          // console.log(x(block), mutation.el._id);
          for (const block of blocks) {
            if (mutation.pass?.internal == block._id) continue;
            if (mutation.path[1] == 'name') {
              const str = dataToString(block.data || []);
              if (mutation.el.name != str) {
                console.log('===UPDATE', x(mutation.el.name), str)
                block.data = X([mutation.el.name]);
                // this.tick++;
                const selection = getBlockSelection();
                // console.log(selection);
                this.forceUpdate();
                setTimeout(() => {
                  setCaretToBlockSelection(selection);
                }, 10);  
              }
  
            }
            // else if (mutation.path[1] == 'checked') {
            //   block.checked = mutation.el.checked;
            // }
          }
        }
      // }
    });*/
    jQuery(el).on('click', '[data-type="entity"]', (e) => {
      const id = JSON.parse(atob(e.target.getAttribute('data-entity-data')));
      this.context?.navigate?.({
        type: 'entity',
        id
      })
    });

    jQuery(el).on('click', '[data-type="code"]', (e) => {
      const aaa = jQuery(e.target).closest('[data-code-data]');
      const id = JSON.parse(atob(aaa.attr('data-code-data')));
      appState.appInspect = {
        mode: InspectState.code,
        component: id.component,
      }
    });


  }

  options() {
    const types = this.props.docId ? typesInScope({
      type: ObjectType.page,
      id: this.props.docId,
    }) : [];

    const attributes = this.props.docId ? attributesInScope({
      type: ObjectType.page,
      id: this.props.docId,
    }) : [];

    const entityOptions = [

      {
        label: 'Insert entity',
        action: (block, menuPos) => {
          const position = menuPos;
          const length = dataLength(this.ctx, block.data);
          const firstPart = sliceData(this.ctx, block.data, 0, position);
          const secondPart = sliceData(this.ctx, block.data, position, length);
          const e = [['6435a0493239f5c3f494c1d6', 'entity']];
          block.data = concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart);
        }
      },

      {
        label: 'Entity',
        action: block => {
          block.type = 'entity';
        }
      },


      {
        label: 'Attach new entity',
        action: (block: MyBlock) => {
          const entity = XObject.obj({
            name: XClone(block.getContent()),
          });
          this.props.extendEntity?.(entity);
          createEntity(entity, this.props.baseEntity);
          block.block.id = entity._id;
        },
      },
      {
        label: 'Attach existing entity',
        action: async (block: MyBlock) => {
          const id = await showPrompt('Enter entity id');
          if (id) {
            block.block.id = id;
            const entity = db.entities.findById(id);
            block.setContent(XClone(entity.name));
          }
        }
      },
    ]    .concat(types.map(id => ({
      label: `Create ${db.entityTypes.findById(id).name}`,
      action: (block: MyBlock) => {
        const entity = XObject.obj({
          type: id,
        });
        createEntity(entity, this.props.baseEntity); // createEntityNull
        block.block.id = entity._id;
      }
    }))).concat(attributes.map(id => ({
      label: 'Set attribute ' + db.attributeTypes.findById(id).name,
      action: async (block: MyBlock) => {
        const value = await showPrompt('Enter value');

        if (block.block.attributes) {
          block.block.attributes[id] = value;
        }
        else {
          block.block.attributes = X({
            [id]: value,
          });
        }
      },
    })));

    let options = [
      {
        label: 'Insert code',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);
          const component = componentSystem.createComponent();
          const e = [[{
            id: XObject.id(),
            component: component._id,
          }, 'code']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart));
          appState.appInspect = {
            mode: InspectState.code,
            component: component._id,
          }
        },
      },
      {
        label: 'To-do list',
        action: (block: MyBlock) => {
          block.block.type = 'checkItem';
        }
      },
      {
        label: 'Plain block',
        action: block => {
          delete block.type;
        }
      },
      {
        label: 'Heading 1',
        action: (block: MyBlock) => {
          block.block.type = 'heading_1';
        }
      },
      {
        label: 'Code',
        action: (block: MyBlock) => {
          block.block.type = 'code';
        }
      },
      {
        label: 'Media',
        action: block => {
          block.block.type = 'media';
        }
      },



    ];

    if (!resumeMode.enabled) {
      options = options.concat(entityOptions);
    }

    return options.filter(Boolean);
  }
  tick = 0;

  ctx = {
    types,
  };


  entitySelectOptions(filter) {
    const r =  db.entities.filter(e => {
      if (!_.isString(e.name)) return false;
      return e.name.toLowerCase().includes(filter.toLowerCase());
    }).slice(0, 10).map(e => ({
      key: e._id,
      label: e.name,
      action: (block: MyBlock, menuPos) => {
        const ctx = {
          types
        }
        const data = block.getContent();
        const position = menuPos;
        const length = dataLength(ctx, data);
        const firstPart = sliceData(ctx, data, 0, position);
        const secondPart = sliceData(ctx, data, position, length);
        block.setContent(concatData(ctx, concatData(ctx, firstPart, [[e._id, 'entity']]), secondPart));
      }
    }))

    return r.concat({
      label: `Create entity "${filter}"`,
      action: (block, menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        const ctx = {
          types
        }
        const data = block.getContent();
        const position = menuPos;
        const length = dataLength(ctx, data);
        const firstPart = sliceData(ctx, data, 0, position);
        const secondPart = sliceData(ctx, data, position, length);
        block.setContent(concatData(ctx, concatData(ctx, firstPart, [[e._id, 'entity']]), secondPart));

      }
    })
  }

  memory = {};

  render() {
    return (
      <NotionDocument
        key={this.props.tick?.()}
        insidePositionContext={this.props.insidePositionContext}
        title={this.props.title}
        setTitle={this.props.setTitle}
        renderBlock={renderBlock}
        onBlockSelected={(block: MyBlock) => {
          if (block?.getId()) {
            appState.inspecting = {
              type: 'entity',
              id: block.getId(),
            }
          }  
        }}
        renderBlockArgs={{
          onClickEntityDot: id => {
            console.log(id);
            this.context.navigate({
              type: 'entity',
              id,
            })
          },
          docId: this.props.docId,
        }}
        types={types}
        blockManager={new MyBlockManager(
          () => this.blocks,
          blocks => {
            this.props.setBlocks(blocks);
            this.blocks = XClone(blocks);
            XObject.observe(this.blocks, (change) => {
              this.props.setBlocks(XClone(this.blocks));
            });
            this.tick++;
            this.forceUpdate();
            // ref.current.forceUpdate();
          },
          {
            baseEntity: this.props.baseEntity,
            ctx: this.ctx,
            extendEntity: this.props.extendEntity,
            memory: this.memory,
          })
        }
        menuIniters={{
          '/': this.options(),
          ...(!resumeMode.enabled ? {
            '@': filter => this.entitySelectOptions(filter),
          } : {})
        }}
      />
    );

  }

}
