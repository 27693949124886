import React, { Component } from 'react';
import Sugar from 'sugar';
import classNames from 'classnames';
import { component } from '../component2';
import _ from 'lodash';
import juration from '../juration';
import { Selector } from './Selector';
import { x } from '../XObject';
import { withRef } from '../etc/withRef';

@component
export class EditableValue extends Component<{
  set?
  input?
  type?
  convert?
  get?
  options?
  entries?
  onFilterChange?
  onNew?
  onDrop?
  create?
  displayGet?
  placeholder?
  display?
  debug?
  className?
  readOnly?
  dblClickEdit?
  editButton?
  onBeforeChange?
}> {
  refs: any;


  state: any;
  
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }
  action_save(value?) {
    value = value || this.extractValue();
    this.props.onBeforeChange?.();
    this.props.set(value || this.extractValue());
    this.setState({ editing: false });
  }
  action_cancel() {
    this.setState({ editing: false });
  }
  action_edit() {
    this.setState({ editing: true });
  }

  extractValue() {

    const value = () => {
      if (this.props.input) {
        return this.refs.input.selectedValue();
      }
      else {
        switch (this.props.type || 'text') {
          default:
            return this.refs.input.value;
          case 'date':
            try {
              return Sugar.Date.create(this.refs.input.value);
            }
            catch (e) {
              return null;
            }
          case 'time':
          case 'datetime':
            try {
              return Sugar.Date.create(this.refs.input.value);
            }
            catch (e) {
              return null;
            }
          case 'bool':
            return this.refs.input.checked;

          case 'duration':
            return juration.parse(this.refs.input.value);

          case 'dropdown':
            return this.refs.input.value;

          case 'entity':
            return this.refs.input.selectedValue();

          case 'selector':
            return this.refs.input.selectedValue();

        }
      }
    };

    let v = value();

    if (this.props.convert == 'int') {
      v = parseInt(v);
    }

    return v;

  }

  input():any {
    if (this.props.input) {
      return withRef('input', this.props.input(this.props.get(), value => {
        this.props.set(value);
        this.setState({ editing: false });
      }));
    }
    else {
      switch (this.props.type || 'text') {
        default:
        case 'text':
          return <input type="text" ref="input" onKeyPress={(e) => e.key === 'Enter' && this.action_save()} defaultValue={this.props.get?.()} />;

        case 'duration':
          return <input type="text" ref="input" onKeyPress={(e) => e.key === 'Enter' && this.action_save()} defaultValue={this.props.get?.() ? juration.stringify(this.props.get?.()) : ''} />;

        case 'date':
          return <input type="date" ref="input" onKeyPress={(e) => e.key === 'Enter' && this.action_save()} defaultValue={this.props.get?.() && this.props.get?.().format('{yyyy}-{MM}-{dd}')} />;

        case 'time':
        case 'datetime':
          return <input type="datetime" ref="input" onKeyPress={(e) => e.key === 'Enter' && this.action_save()} defaultValue={this.props.get?.() && this.props.get?.().format('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}')} />;

        case 'bool':
          return <input ref="input" defaultChecked={this.props.get?.()} type="checkbox" />;

        case 'dropdown':
          return (
            <select ref="input" defaultValue={this.props.get?.()}>
              <option></option>
              {this.props.options.map((option) => {
                if (_.isPlainObject(option)) {
                  return (
                    <option key={option} value={option.value}>{option.label}</option>
                  );
                }
                else {
                  return (
                    <option key={option}>{option}</option>
                  );
                }
              })}
            </select>
          );


        case 'selector':
          return (
            <Selector
              ref="input"
              onSelected={(value) => {
                this.action_save();
              }}
              entries={this.props.entries}
              onFilterChange={this.props.onFilterChange}
              onNew={this.props.onNew}
              onDrop={this.props.onDrop}
              create={this.props.create} />
          );

      }
    }
  }

  displayGet() {
    if (this.props.displayGet)
      return this.props.displayGet();
    else
      return this.props.get?.();
  }

  isEmpty() {
    switch (this.props.type || 'text') {
      default:
        return !!this.displayGet();
      case 'text':
        return !!(this.displayGet() && this.displayGet().toString());

    }
  }

  display() {
    if (this.props.placeholder && _.isNil(this.props.get())) {
      return this.props.placeholder;

    }
    if (this.props.display) {
      return this.props.display(this.props.get?.());
    }
    else {
      switch (this.props.type || 'text') {
        default:
          return this.displayGet();
        case 'text':
          return this.displayGet() && this.displayGet().toString();
        case 'date':
          return this.displayGet() && this.displayGet().format('{yyyy}-{MM}-{dd}');
        case 'time':
        case 'datetime':
          return this.displayGet() && this.displayGet().format('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
        case 'bool':
          return this.displayGet() ? 'Yes' : 'No';
        case 'duration':
          return this.displayGet() && juration.stringify(this.displayGet());
        case 'dropdown':
          if (_.isPlainObject(this.props.options[0])) {
            const v = this.props.get();
            if (_.isNil(v))
              return '';
            if (!this.props.options.find(option => option.value == v)) {
              console.log(x(v));
            }
            return this.props.options.find(option => option.value == v)?.label || '(uh oh)';
          }
          else {
            return this.displayGet();
          }
        case 'selector':
          const value = this.displayGet();
          if (value !== undefined && value !== null) {
            const entry = this.props.entries.find((entry) => entry.key == value);
            if (entry) {
              return entry.display;
            }
            else {
              return value;
            }
          }
          else {
            return '(none)';
          }

        case 'url':
          return this.props.get() && <a href={this.props.get()} target="_blank">URL</a>;
      }
    }
  }
  render() {
    let display = this.display();
    // console.log(dsp)
    if (this.props.debug) {
      console.log(display);
    }
    return (
      this.state.editing ?
        <span className={classNames('editable-value', this.props.className)}>
          {this.input()}
          <button onClick={() => this.action_save()}>Save</button>
          <button onClick={this.action_cancel.bind(this)}>Cancel</button>
        </span> :
        <span onDoubleClick={() => {
          if (!this.props.readOnly && this.props.dblClickEdit) {
            this.action_edit();
          }
        }} className={classNames('editable-value', this.props.className, { empty: display === '' || display === null || display === undefined }, this.props.editButton)}>
          <span className={classNames('editable-value__display', { empty: this.isEmpty() })}>{this.display()}</span>
          {!this.props.readOnly && !this.props.dblClickEdit && <button className="editable-value__edit" onClick={this.action_edit.bind(this)}>Edit</button>}
        </span>
    );
  }
}
