import { db } from '../db';
import { XInit, XObject } from '../XObject';
import ContentEditable from 'react-contenteditable';
import React, { Component } from 'react';
import { component } from '../component';
import { RichTextEditor } from './richTextEditor/RichTextEditor';
import { styled } from '../component2';


@component
export class EntityNameEditor extends Component<{ id; _onFocus?; _onBlur?; _onMouseOver?; _onMouseOut? }> {
  nameChangeTimer;
  state = XInit(class {
    name;
  });
  constructor(props) {
    super(props);
    const entity = db.entities.findById(this.props.id);
    this.state.name = entity.name;
    XObject.observe(entity, 'name', () => {
      this.state.name = entity.name;
      if (!this.focused) {
        ++this.tick;
        this.forceUpdate();
      }
    })
  }
  static styles = styled.div``;
  tick = 0;
  focused = false;
  render() {
    const entity = db.entities.findById(this.props.id);

    return (
      <RichTextEditor
        key={this.tick}
        _onBlur={() => {
          this.props._onBlur?.();
          this.focused = false;
        }}
        _onFocus={() => {
          this.props._onFocus?.();
          this.focused = true;
        }}
        _onMouseOver={this.props._onMouseOver}
        _onMouseOut={this.props._onMouseOut}
        value={() => this.state.name}
        setValue={e => {
          this.state.name = e;
          clearTimeout(this.nameChangeTimer);
          this.nameChangeTimer = setTimeout(() => {
            entity.name = e;
          }
            , 1000);
        }}
      />
    )
  }
}
