import React, { Component } from 'react';
import { execute, mapStructure } from '../glue/main';
import { component, styled } from '../component2';
import { X, x, XClone, XObject } from '../XObject';
import { NotionDocumentBad, renderBlock } from './NotionDocumentBad';
import { Runtime } from '../glue/Runtime';
import { Comp } from '../glue/Comp';
import { resolveBlockTypes } from '../glue/structs/$Document';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { MyNotionDocument } from '../MyNotionDocument/MyNotionDocument';
import classNames from 'classnames';
import { NotionDocument } from './notionDocument/NotionDocument';

@component
export class NotionBlockWrapper extends Component<{
  configId?
  configMap?
  block?
  blockTypes?
  noChildren?: boolean
}> {
  render() {
    const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
    const executed = execute(templateSupplierId, new Runtime({}));
    const m = mapStructure(executed);
    const value = m.blockTypes;

    // const value = this.props.configId ? execute(this.props.configId, new Runtime(this.context, { stateStore: this.state })) : this.props.config;

    // const value = this.props.configId && execute(this.props.configId, new Runtime(this.context, { stateStore: this.state }));
    const blockTypes = value ? resolveBlockTypes(value, this.props.configMap) : [];

    return (
      <>
        {renderBlock(this.props.block, {
          blockTypes,
          activeBlock: null,
          beforeChange: () => {},
          changed: () => {},
          db: null,
          draggingId: null,
          noChildren: this.props.noChildren,
          onClickEntityDoc: () => {

          },
          onMouseDownGrip: () => {

          },
          docId: null,
          onClickAddBlock: null,
          onContextMenu: null,
        })}
      </>
    )
  }
}

@component
export class NotionDocumentWrapper extends Comp<{ extState?, entity, inline?, configId?; config?; configMap?; blocks; setBlocks; name; setName?; docId }> {
  blocks;
  state = X({});
  title;

  tick = 0;
  docRef = React.createRef<NotionDocumentBad>();

  static styles = styled.div`
    &.inline {
      ${NotionDocument} {
        padding-bottom: 0;
      }
    }
  `;

  constructor(props) {
    super(props);

    if (this.props.blocks) {
      const blocks = this.props.blocks;
      if (!blocks.length) {
        blocks.push(XObject.obj({
          data: [],
          children: [],
        }));
      }
  
      this.blocks = XClone(blocks);
  
      XObject.observe(this.blocks, (change) => {
        console.log('change', change);
        this.props.setBlocks(XClone(this.blocks));
      });      
    }
  }


  render(Container?) {
    return (
      <Container className={classNames({ inline: this.props.inline })}>
        <MyNotionDocument
          baseEntity={this.props.entity}
          blocks={this.blocks}
          setBlocks={blocks => this.setBlocks(blocks)}
          docId={this.props.docId}
          extendEntity={() => {}}
          title={this.props.name}
          setTitle={this.props.setName && ((title) => {
            this.props.setName(title);
          })}
        />
      </Container>
    );
  }

  timerId

  setBlocks = (blocks) => {
    this.blocks = XClone(blocks);
    XObject.observe(this.blocks, (change) => {
      this.props.setBlocks(XClone(this.blocks));
      this.tick++;
      this.forceUpdate();  
    });

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.props.setBlocks(XClone(blocks));
    }, 500);
  }

  render2() {
    if (!this.props.blocks) return 'poop';
    const ref = this.docRef;
    const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
    const executed = execute(templateSupplierId, new Runtime({}));
    const m = mapStructure(executed);
    const value = m.blockTypes;


    return (
      <>
        <NotionDocumentBad
          extState={this.props.extState}
          docId={this.props.docId}
          entity={this.props.entity}
          valuePoints={this.props.configId}
          ref={ref}
          inline={this.props.inline}
          tick={this.tick}
          blockTypes={value ? resolveBlockTypes(value, this.props.configMap) : []}
          blocks={this.blocks}
          setBlocks={(blocks) => {
            // console.log(x(blocks));
            this.props.setBlocks(XClone(blocks));
            this.blocks = XClone(blocks);
            XObject.observe(this.blocks, (change) => {
              this.props.setBlocks(XClone(this.blocks));
            });
            this.tick++;
            this.forceUpdate();
            ref.current.forceUpdate();
          }}
          database={null}
          title={this.props.name}
          setTitle={this.props.setName && ((title) => {
            this.props.setName(title);
          })}
        />
      </>
    );
  }


}
