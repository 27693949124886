import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';


@component
export class UsersWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        {db.users.map((x, i) => (
          <div key={x._id}>
            {x._id}
            <div>
              Name: <PropertyField object={x} property="name" />
            </div>
            <div>
              Initials: <PropertyField object={x} property="initials" />
            </div>
            <div>
              Entity: <PropertyField object={x} property="entity" />
            </div>
            <button
              onClick={() => {
                db.users.splice(i, 1);
              }}
            >-</button>
          </div>
        ))}

        <button
          onClick={() => {
            db.users.push(XObject.obj({
              name: '',
            }));
          }}
        >+</button>

      </>
    );
  }
}
