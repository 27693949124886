import { DraftSelect } from '../etc/draftHelpers';
import React, { Component } from 'react';
import { component } from '../component';
import { AttributeType } from './AttributeType';

export const types = [
  {
    display: 'Duration',
    value: 'duration',
  },
  {
    display: 'URL',
    value: 'url',
  },
  {
    display: 'Text',
    value: AttributeType.text,
  },
  {
    display: 'Entity',
    value: AttributeType.entity,
  },
  {
    display: 'Entities',
    value: AttributeType.entities,
  },
  {
    display: 'Datetime',
    value: AttributeType.datetime,
  },
  {
    display: 'Due Date',
    value: AttributeType.dueDate,
  },
  {
    display: 'Value Point',
    value: AttributeType.valuePoint,
  },
  {
    display: 'Priority',
    value: AttributeType.priority,
  },
  {
    display: 'Switch',
    value: AttributeType.switch,
  },
  {
    display: 'Select',
    value: AttributeType.select,
  },
  {
    display: 'Multi Select',
    value: AttributeType.multiSelect,
  },
  {
    display: 'Rich Text',
    value: AttributeType.richText,
  }
];

@component
export class AttribtuteTypeSelector extends Component<{ t; }> {
  render() {
    const { t } = this.props;
    return (
      <>
        <DraftSelect
          inline
          id='asdfasdfsdf'
          options={types}
          onSelect={value => {
            t.type = value;
          }} />

      </>

    );
  }
}
