import { db } from '../db';
import { XObject } from '../XObject';
import { TWorkspace } from "../types/TWorkspace";

export function defaultWorkspace(): TWorkspace {
  let workspace;
  if (db.workspaces.length) {
    workspace = db.workspaces[0];
  }
  else {
    workspace = XObject.obj({});
    db.workspaces.push(workspace);
  }
  return workspace;
}
