import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { XObject } from '../XObject';
import { objectName } from "../components/objectFuncs";
import { ObjectType } from '../types/QueryParentType';
import { componentSystem } from '../componentSystem';
import { appState } from '../etc/appState';
import { InspectState } from '../components/InspectState';


@component
export class EditDocumentWindow extends Component<{ window; }> {
  render() {
    const page = db.notionDocuments.findById(this.props.window.document);
    return (
      <>
        <div>
          <input type="checkbox"
            checked={page.createsEdges}
            onChange={e => {
              page.createsEdges = e.target.checked;
            }}
            /> Graph
        </div>
        <div>
          <input type="checkbox"
            checked={page.scopedEntities}
            onChange={e => {
              page.scopedEntities = e.target.checked;
            }}
            /> Scoped Entities
        </div> 
        <ul>
          {page?.blockCodeComponents?.map?.((x, i) => {
            return (
              <li key={x}
                onClick={() => {
                  appState.appInspect = {
                    mode: InspectState.code,
                    component: x
                  }
                }}
              >
                {db.codeComponents.findById(x)?.name}
              </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            const codeComponent = componentSystem.createComponent();
            XObject.push(page, 'blockCodeComponents', codeComponent._id);
          }}
        >+</button>
        <ul>
          {page.imports?.map?.((x, i) => {
            return (
              <li key={x._id}>
                {objectName(x)} <button
                  onClick={() => {
                    page.imports.splice(i, 1);
                  }}
                >-</button>
              </li>
            );
          })}
        </ul>
        <select
          onChange={e => {
            XObject.push(page, 'imports', XObject.obj({
              type: ObjectType.library,
              id: e.target.value,
            }));
            e.target.value = '';
          }}
        >
          <option />
          {db.libraries.map(library => {
            return (
              <option key={library._id} value={library._id}>{library.name}</option>
            );
          }
          )}
        </select>

        {page.tableData && (() => {
          const columns = page.tableData.columns;

          return (
            <>
            <button
              onClick={() => {
                delete page.tableData;
              }}
            >Make page</button>
            
            <select
              value={page.tableData.galleryProperty}
              onChange={e => page.tableData.galleryProperty = e.target.value}
            >
              <option />
              {columns?.map?.(column => (
                <option key={column._id} value={column._id}>{column.title}</option>
              ))}
            </select>
    
            </>
          )

        })()}

      </>
    );
  }
}
