import React, { Component } from "react";
import _ from "lodash";
import ReactDOM from "react-dom";
import ReactDOMClient from "react-dom/client";
import jQuery from "jquery";
import { component, css, styled } from "../component";
import { MutationType, x, X, XClone, XInit, XObject } from "../XObject";
import classNames from "classnames";
import { PropertyField } from "./PropertyField";
import { db } from "../db";
import { Svg } from "./Svg";
import { renderInspect } from "./EntityInspect";
import { collectEntitiesGood } from './collectEntitiesGood';
import { AttributeType } from "./AttributeType";
import { darkModeBg } from "../etc/themeState";
import { appState } from "../etc/appState";
import { showPrompt } from "../etc/showPrompt";
import { EntityPath } from "./EntityPath";
import { ThemeProvider } from 'styled-components';
import { SystemContext } from "../etc/SystemContext";
import { createEntity } from "../etc/createEntity";
import { EntityViewType } from "./EntityRow";
import { ObjectType } from "../types/QueryParentType";
import { getScopeTree, typesInScope } from "./objectFuncs";
import { entityDisplayName } from "./entityDisplayName";
import { execute, mapStructure } from "../glue/main";
import { $DocumentCheckbox } from "../glue/structs/$Document";
import { resolveEntityBinding } from "../glue/structs/resolveBinding";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { showContextMenu } from "../etc/showContextMenu";
import { isMobile } from "../isMobile";

function color(el) {
  return props => {
    if (props.theme.mode == 'dark') {
      if (el == 'bg') {
        return darkModeBg;
      }
      else if (el == 'text') {
        return '#d4d4d4';
      }
      else if (el == 'text.checked') {
        return '#7f7f7f';
      }
      else if (el == 'text.checked.line') {
        return '#7f7f7f';
      }
      else if (el == 'block.actionIcon') {
        return '#5a5a5a';
      }
      else if (el == 'block.entityDot') {
        return '#41a663';
      }
      else if (el == 'block.dataBindingDot') {
        return '#4163a6';
      }

      else if (el == 'block.badge.bg') {
        return '#5c5c5c';

      }
      else if (el == 'block.badge.text') {
        return '#b6b6b6';
      }
      else if (el == 'block.metaLine.text') {
        return '#636363';
      }
      else if (el == 'block.actionIcon.bg') {
        return '#262626';
      }
      else if (el == 'checkbox.hover.bg') {
        return '#262626';
      }


    }
    else if (props.theme.mode == 'light') {
      if (el == 'text.checked') {
        return '#969696';
      }
      else if (el == 'text.checked.line') {
        return 'rgba(55, 53, 47, 0.25)';
      }
      else if (el == 'block.actionIcon') {
        return '#bababa;'
      }
      else if (el == 'block.entityDot') {
        return '#a5d3b5';
      }
      else if (el == 'block.dataBindingDot') {
        return '#4163a6';
      }
      else if (el == 'block.badge.bg') {
        return 'lightblue';
      }
      else if (el == 'block.badge.text') {
        return 'black';
      }
      else if (el == 'block.metaLine.text') {
        return '#c9c9c9';
      }
      else if (el == 'block.actionIcon.bg') {
        return '#f3f3f3';
      }
      else if (el == 'checkbox.hover.bg') {
        return 'rgba(55,53,47,0.08)';
      }



    }
    return 'inherit';
  }
}

@component
class Badge extends Component<{ text, _onClick?, dataValuePoint? }> {
  static styles = styled.div`
    user-select: none;
    font-size: 10px;
    color: ${color('block.badge.text')};
    background-color: ${color('block.badge.bg')};
    border-radius: 5px;
    padding: 0 4px;
    transition: opacity 0.2s;
    cursor: pointer;
  `;
  render(Container?) {
    return (
      <Container
        data-value-point={this.props.dataValuePoint}
        onClick={this.props._onClick}
      >
        {this.props.text}
      </Container>
    )
  }
}

@component
class RecordBadges extends Component<{ recordId, db }> {
  static styles = styled.div`
    display: flex;
    ${Badge} {
      margin-right: 4px;
    }
  `;
  render () {
    const { recordId, db } = this.props;
    const record = db.getRecord(recordId);

    return (
      <>
        {this.props.db.database.columns.filter(c => c.showInDoc).map(c => {
          const value = record.data[c._id];
          let d;

          if (c.type == 'select') {
            d = c.options.find(o => o._id == value)?.title;
          }
          else if (c.type == 'text') {
            d = value;
          }
          if (!d) return;
          return <Badge key={c._id} text={d} _onClick={() => {
            if (c.type == 'text') {
              const value = prompt("Enter new value");
              if (value) {
                const record = db.getRecord(recordId);
                record.data[c._id] = value;
              }
            }
          }} />
        })}
      </>
    )

    // return <Badge  text={"asdf"} />
  }
}


@component
class CheckBox extends Component<{ checkbox: {
  binding
}, block, _onChange?, _onBeforeChange, dataValuePoint? }> {
  static styles = styled.span`
    user-select: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    /* background: ${css.img('unchecked')} no-repeat center; */
    cursor: pointer;
    svg {
      display: block;
    }

    transition: background 200ms ease-out 0s;

    &:hover {
      background: ${color('checkbox.hover.bg')};
    }

    &[data-checked="true"] {
      width: 16px;
      height: 16px;
      background: rgb(35, 131, 226);
      display: flex;;
      justify-content: center;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        polygon {
          fill: white;
        }
        background: rgb(35, 131, 226);
        transition: background 200ms ease-out 0s;

      }
    }

    &:not([data-checked="true"]) {
      svg path {
        fill: ${color('text')};
      }
    }

  `;
  render(Container?) {
    const { checkbox, block } = this.props;
    const checked = checkbox ? checkbox.binding.get(block.id) : block.checked;
    return             <Container
    data-value-point={this.props.dataValuePoint}
    data-checked={checked}
    onClick={e => {
      if (checkbox) {
        checkbox.binding.set(block.id, !checkbox.binding.get(block.id));
        this.props._onChange?.();
      }
      else {
        this.props._onBeforeChange?.();
        block.checked = !block.checked;
        this.props._onChange?.();
      }
    }}
    contentEditable={false}
  >
    {checked ? <Svg name="checked" /> : <Svg name="unchecked" />}
  </Container>
  }
}

@component
class EntityBadges extends Component<{ block, entity, blockType }> {
  static styles = styled.div`
    display: flex;
    > * {
      margin-right: 4px;
    }

    align-items: center;

    .type {
      font-size: 10px;
      color: ${color('block.metaLine.text')};
      margin-left: 2px;
    }
  `;
  render() {
    if (!this.props.entity) return null;
    const type = this.props.blockType?.name || db.entityTypes.findById(this.props.entity.type)?.name;
    return (
      <>
        {this.props.blockType?.elements?.map?.((el, i) => {
          if (el.type == 'attribute') {
            const d = el.binding.get(this.props.entity._id);
            let display;

            if (el.binding.type == AttributeType.entity) {
              display = d && db.entities.findById(d).name;
            }
            else {
              display = d;
            }
            
            return d && (
              <Badge
                key={i}
                text={display}
                dataValuePoint={el._id}
                _onClick={async () => {
                  const value = await showPrompt("Enter new value");
                  if (value) {
                    el.binding.set(this.props.entity._id, value);
                  }
                }}
              />
            );
          }
          else if (el.type == 'badge') {
            const d = el.binding.get(this.props.block);
            return d && <Badge key={i} text={d} dataValuePoint={el._id} />;
          }
        })}

        {type && <span className="type">{type}</span>}
      </>
    );
  }
}

const Wrapper = styled.div`
  .entityDot, .dataBindingDot {
    width: 5px;
    height: 5px;
    position: absolute;
    left: -12px;
    /* left: -30px; */
    top: 13px;
    background: ${color('block.entityDot')};
    border-radius: 50%;
    transition: left 0.2s, opacity 0.2s, width 0.2s, height 0.2s, margin 0.2s;
    user-select: none;
    /* user-drag:  */
    opacity: .3;
    cursor: pointer;
  }

  .dataBindingDot {
    background: ${color('block.dataBindingDot')};
  }

  svg {
    user-select: none;
  }
  .meta {
    position: absolute;
    user-select: none;
  }

  .metaLine {
    font-size: 9px;
    color: ${color('block.metaLine.text')};
    user-select: none;
  }
  &.dragging {
    opacity: 0.2;
  }
  &.checkItem, &.entity, &.hasCheckbox {
    &.checked > .block {
      /* color: ; */
      text-decoration: line-through ${color('text.checked.line')};

      color: ${color('text.checked')};
    }
    .metaLine {
      /* margin-left: -25px; */
    }
    > .block {
      > input[type="checkbox"], > ${CheckBox} {
        position: absolute;
        left: 0;
        top: 7px;
        padding: 0;
        margin: 0;
      }
      padding-left: 24px;
    }
  }

  &.heading_1 {
    > .block {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.25;
    }
  }

  &.code {
    > .block {
      /* border-radius: 3px;
      background: rgb(247, 246, 243);
      padding: 8px; */
    }
  }

  .block {
    position: relative;
    font-size: 16px;
    line-height: 1.5;
    min-height: 24px;


    &:not(:hover) {
      /* .meta {
        opacity: .5;
      } */

      .entityDot {
        /* opacity: .5; */
      }
    }

    :hover {
      .entityDot {
        left: -30px;
      }
    }
    
    .editor {
      min-height: 21px;
      &:empty::before {
          /* content: 'Task'; */
          color: #acacac; 
      }

      &:empty:focus::before {
          content: "";
      }

    }
    .grip {
      position: absolute;
      top: 3px;
      left: -21px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: grab;
      path {
        fill: ${color('block.actionIcon')};
      }
      height: 24px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }
    .addBlock {
      position: absolute;
      top: 3px;
      left: -41px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: pointer;
      path, & {
        fill: ${color('block.actionIcon')};
      }
      height: 24px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }


    padding: 3px 0;

    &:hover {
      .grip, .addBlock {
        opacity: 1;
      }
    }

    .editor:focus {
      outline: none;
    }
  }

  .children {
    /* padding-left: 18px; */
    padding-left: 24px;
  }
`;

function findBlockParent(blocks, id, parent=null) {
  for (const block of blocks) {
    if (block._id === id) {
      return parent;
    }
    if (block.children) {
      const p = findBlockParent(block.children, id, block);
      if (p) {
        return p;
      }
    }
  }
  return null;
}

function unindentBlock(blocks, id) {
  const parent = findBlockParent(blocks, id);
  const block = findBlock(blocks, id);
  if (parent) {
    const grandparent = findBlockParent(blocks, parent._id);
    if (grandparent) {
      const index = grandparent.children.indexOf(parent);
      grandparent.children.splice(index + 1, 0, block);
      parent.children.splice(parent.children.indexOf(block), 1);
      clearSelection()
      setTimeout(() => {
        const el = jQuery(`[data-block-id="${block._id}"]`);
        const editorEl = el.find('.editor');
        setCaret(editorEl[0])
      }, 0);

    }
    else {
      const index = blocks.indexOf(parent);
      blocks.splice(index + 1, 0, block);
      parent.children.splice(parent.children.indexOf(block), 1);
      clearSelection()

      setTimeout(() => {
        const el = jQuery(`[data-block-id="${block._id}"]`);
        const editorEl = el.find('.editor');
        setCaret(editorEl[0])
      }, 0);

    }
  }

}

function indentBlock(blocks, id) {
  const parent = findBlockParent(blocks, id);
  const block = findBlock(blocks, id);
  let containingList;
  if (parent) {
    containingList = parent.children;
  }
  else {
    containingList = blocks;
  }

  const index = containingList.indexOf(block);
  const prevBlock = containingList[index - 1];
  if (prevBlock) {
    if (!prevBlock.children) {
      prevBlock.children = X([]);
    }
    prevBlock.children.push(block);
    containingList.splice(index, 1);
    clearSelection();

    setTimeout(() => {
      const el = jQuery(`[data-block-id="${block._id}"]`);
      const editorEl = el.find('.editor');
      setCaret(editorEl[0]);
    }, 0);
  }

}


export function findBlock(blocks, id, errorOnNotFound=false) {
  for (const block of blocks) {
    if (block._id === id) {
      return block;
    }
    if (block.children) {
      const p = findBlock(block.children, id);
      if (p) {
        return p;
      }
    }
  }
  if (errorOnNotFound) {
    throw new Error(`Block with id ${id} not found`);
  }
  return null;
}


function findBlockMatching(blocks, predicate) {
  for (const block of blocks) {
    if (predicate(block)) {
      return block;
    }
    if (block.children) {
      const p = findBlockMatching(block.children, predicate);
      if (p) {
        return p;
      }
    }
  }
  return null;
}


function findBlocksMatching(blocks, predicate) {
  const results = [];
  for (const block of blocks) {
    if (predicate(block)) {
      results.push(block);
    }
    if (block.children) {
      results.push(...findBlocksMatching(block.children, predicate));
    }
  }
  return results;
}
function removeBlock(blocks, id) {
  const parent = findBlockParent(blocks, id);
  const theseBlocks = parent ? parent.children : blocks;
  const index = theseBlocks.findIndex(b => b._id === id);
  if (index != -1) {
    theseBlocks.splice(index, 1);
    return true;
  }
  else {
    return false;
  }

}

function getBlockIndentation(blocks, id) {
  const parent = findBlockParent(blocks, id);
  if (parent) {
    return getBlockIndentation(blocks, parent._id) + 1;
  }
  else {
    return 0;
  }
}

function canIndentBlock(blocks, id) {
  const parent = findBlockParent(blocks, id);
  let containingList;
  if (parent) {
    containingList = parent.children;
  }
  else {
    containingList = blocks;
  }
  const index = containingList.findIndex(b => b._id === id);
  return index > 0;
}

function canUnindentBlock(blocks, id) {
  return getBlockIndentation(blocks, id) > 0;
}


function contractFromHtml(html) {
  return [];
}

function extractFromEl(el: HTMLElement) {
  const parts = [];
  const processeEl = (el: HTMLElement, styles=[]) => {
    if (el) {
      if (el.nodeType === Node.TEXT_NODE) {
        parts.push([el.textContent, ...(styles.length ? [styles] : [])]);
      }

      if (el.nodeType === Node.ELEMENT_NODE) {
        if (el.getAttribute('data-type') == 'entity') {
          const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
          parts.push([data, 'entity']);
        }
        else if (el.getAttribute('data-type') == 'capture') {
          parts.push([el.textContent, 'capture']);
        }
        else if (el.tagName == 'B') {
          processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'b']));
        }
        else if (el.tagName == 'I') {
          processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'i']));
        }
        else if (el.tagName == 'U') {
          processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'u']));
        }
      }

      processeEl(el.nextSibling as HTMLElement, styles);
    }
  }
  processeEl(el.firstChild as HTMLElement);
  return parts;
}

function expandToHtml(data) {
  let html = '';

  const currentStyles = [];
  try {
    for (const part of data) {
      if (!part) continue;
      if (_.isString(part)) {
        html += part;
      }
      else {
        if (part[1] == 'entity') {
          for (const style of currentStyles) {
            html += `</${style}>`;
          }
          currentStyles.splice(0, currentStyles.length);
          const entity = db.entities.findById(part[0]);
          html += `<span class="--entity--" contenteditable="false" data-type="entity" data-entity-data="${btoa(JSON.stringify(part[0]))}">${entity.name}</span>`;
        }
        else if (part[1] == 'capture') {
          for (const style of currentStyles) {
            html += `</${style}>`;
          }
          currentStyles.splice(0, currentStyles.length);
          html += `<span class="--capture--" contenteditable="true" data-type="capture">${part[0]}</span>`;
        }
        else {
          const [text, styles = []] = part;
          const newStyles = _.difference(styles, currentStyles);
          const removedStyles = _.difference(currentStyles, styles);
          for (const style of newStyles) {
            html += `<${style}>`;
          }
          for (const style of removedStyles) {
            html += `</${style}>`;
          }
          html += text;
          currentStyles.splice(0, currentStyles.length, ...styles);        
        }
      }
  
    }
  }
  catch (e) {
    console.log(data);
    console.log(e);
    throw e;
  }


  if (html == '<br />') return '';

  return html;
}


function createChunked(data=[]) {
  const chunked = [];
  let i = 0;
  for (const part of data) {
    if (_.isString(part)) {
      for (let j = 0; j < part.length; j++) {
        chunked[i + j] = [part[j], []];
      }
      i += part.length;
    }
    else {
      if (part[1] == 'entity') {
        chunked[i] = part;
        i++;
      }
      else if (part[1] == 'capture') {
        const [str, c] = part;
        for (let j = 0; j < str.length; j++) {
  
          chunked[i + j] = [str[j], c];
        }
        i += str.length;
  

      }
      else {
        const [str, styles=[]] = part;
        const styleMap = {};
        for (const style of styles) {
          styleMap[style] = true;
        }
        for (let j = 0; j < str.length; j++) {
  
          chunked[i + j] = [str[j], styleMap];
        }
        i += str.length;
  
      }
    }
  }

  return chunked;
}

function mergeChunked(chunked, start, end) {
  const slicedData = [];
  let prevStyles = {};
  let curStr = '';

  function fin() {
    if (prevStyles == 'capture') {
      slicedData.push([curStr, 'capture']);
    }
    else {
      const styles = [];
      for (const style in prevStyles) {
        styles.push(style);
      }
      slicedData.push([curStr, styles]);  
    }
  }

  for (let i = start; i < end; i++) {
    const [str, styles] = chunked[i] || ['', {}];
    if (styles == 'entity') {
      if (curStr) {
        fin();
        curStr = '';
        prevStyles = {};
      }

      slicedData.push(chunked[i]);
    }
    else if (styles == 'capture') {
      if (curStr && prevStyles != 'capture') {
        fin();
        curStr = '';
      }

      curStr += str;
      prevStyles = 'capture';
    }
    else if (str) {
      if (_.isEqual(prevStyles, styles)) {
        curStr += str;
      }
      else {
        fin();
        curStr = str;
        prevStyles = styles;
      }
    }
  }
  if (curStr) {
    fin();
  }

  return slicedData;
}

function sliceData(data, start, end) {
  const chunked = createChunked(data);
  return mergeChunked(chunked, start, end);
}

function dataLength(data) {
  const chunked = createChunked(data);
  return chunked.length;
}

function concatData(data, newData) {
  const chunked = createChunked(data).concat(createChunked(newData));
  return mergeChunked(chunked, 0, chunked.length);
}

function dataToString(data) {
  const chunked = createChunked(data);
  let str = '';
  for (const [char] of chunked) {
    str += char;
  }
  return str;
}

@component
class Code extends Component<{ block }> {
  static styles = styled.div`
    pre {
      margin: 0;
    }
    textarea {
      width: 100%;
      height: 500px;
    }
  `;
  render() {
    const { block } = this.props;
    return (
      <>
                    {block['editing'] && (
                <>
                  <textarea defaultValue={block['code']}
                  
                  onChange={(e) => {
                    block['code'] = e.target.value;
                  }}
                  ></textarea>

<button
                onClick={() => {
                  block['editing'] = !block['editing'];
                }}
              >Done</button>
                </>
              )}
              {!block['editing'] && (
                <>

                  <SyntaxHighlighter
                    onDoubleClick={() => {
                      block['editing'] = true;
                    }}
                  language="javascript" style={docco}>
                  {block['code']}
                  </SyntaxHighlighter>
                </>
              )}



      </>
    )
  }
}

export function renderBlock(block: { _id, data, children, type, checked, id, record, dataBinding }, {
  onMouseDownGrip,
  draggingId,
  beforeChange,
  changed,
  // getEntity,
  blockTypes,
  activeBlock,
  db: _db,
  onClickEntityDoc,
  noChildren = false,
  docId,
  onClickAddBlock,
  onContextMenu,
}) {
  const blockType = blockTypes.find(b => b._id == block.type);
  let checkbox = blockType?.elements?.find?.(e => e.type == 'checkbox');
  if (block.id) {
    const entity = db.entities.findById(block.id);
    if (entity.type) {
      const type = db.entityTypes.findById(entity.type);
      const blockView = type.views?.find?.(v => v.viewType == EntityViewType.block);
      if (blockView) {
        if (blockView.valuePoint) {
          const mapped = mapStructure(execute(blockView.valuePoint));
          const a = mapped.elements?.content?.find?.(e => e.type?.[1] == $DocumentCheckbox.$);

          if (a) {
            const mappedA = mapStructure(a);
            
            checkbox = {
              binding: {
                get: (entityId) => {
                  return resolveEntityBinding(entityId, mappedA.binding, {}).get();
                },
    
                set:  (entityId, value) => {
                  return resolveEntityBinding(entityId, mappedA.binding, {}).set(value);
                }
              }
            }
          }
        }
      }
    }
  }
  const checked = checkbox ? checkbox.binding.get(block.id) : block.type == 'checkItem' && block.checked;
  return (
    <Wrapper
      key={block._id}
      data-value-point={blockType?._id}
      data-block-type={blockType?._id}
      data-entity={block.id}
      data-record={block.record}
      data-type="blockCont" className={classNames(block.type, {
        dragging: block._id == draggingId,
        hasCheckbox: checkbox || block.type == 'checkItem',
        checked: checked,
      })}
    >
      <div 
        data-ui-binding={block.id && JSON.stringify({
          viewType: EntityViewType.block,
          entity: block.id,
          context: {
            type: ObjectType.page,
          }
        })}
        data-block-id={block._id}
        data-inspect-id={block.id}
        className={classNames('block', { activeBlock: block._id == activeBlock})}
      >

        <Svg name="plus" className="addBlock"
          onClick={e => {
            onClickAddBlock(e, block);
          }}
        />
        <Svg name="grip" className="grip"
          onMouseDown={e => {
            onMouseDownGrip(e, block);
          }}
          onContextMenu={e => {
            e.preventDefault();
            onContextMenu(e, block);
          }}
        />

        {block.type == 'code' && (
          <>
            <div contentEditable={false} className="codeBlock">
              <Code block={block} />
            </div>
          </>
        )}

        {block.type != 'code' && (
          <>
            {block.id && (
              <span 
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData('text/plain', block.id);
                }}
                className="entityDot"  contentEditable={false}
              
                onClick={() => {
                  onClickEntityDoc(block.id);
                }}
              />
            )}
            {block.dataBinding && (
              <span 
                className="entityDot dataBindingDot" draggable contentEditable={false}
              
                onClick={() => {
                  onClickEntityDoc(block.id);
                }}
              />
            )}
            {(checkbox || block.type == 'checkItem') && <CheckBox dataValuePoint={checkbox?._id} checkbox={checkbox} block={block} _onBeforeChange={beforeChange} _onChange={changed} />}
            <div className="editor" dangerouslySetInnerHTML={{ __html: expandToHtml(x(block.data) || []) }} data-type="blockData" />
            {block.record && (
              <>
                <div className="meta" style={{display:'none'}} contentEditable={false}>
                  <RecordBadges
                    db={_db}
                    recordId={block.record}
                  />
                </div>
              </>
            )}
            {block.id && (
              <div className="meta" style={{display:'none'}} contentEditable={false}>
                <EntityBadges block={block} entity={db.entities.findById(block.id)} blockType={blockType} />
              </div>
            )}
            {block.id && (
              <div className="metaLine" contentEditable={false}>
                <EntityPath entity={block.id} />
              </div>
            )}
          </>
        )}


      </div>
      {!noChildren && <div className="children" data-type="blockChildren">
        {block.children && block.children.map(b => renderBlock(b, {
          onMouseDownGrip,
          draggingId,
          changed,
          blockTypes,
          db: _db,
          activeBlock,
          beforeChange,
          onClickEntityDoc,
          docId,
          onClickAddBlock,
          onContextMenu,
        }))}
      </div>}
    </Wrapper>
  );
}

function FindReact(dom, traverseUp = 0) {
  const key = Object.keys(dom).find(key=>{
      return key.startsWith("__reactFiber$") // react 17+
          || key.startsWith("__reactInternalInstance$"); // react <17
  });
  const domFiber = dom[key];
  if (domFiber == null) return null;

  // react <16
  if (domFiber._currentElement) {
      let compFiber = domFiber._currentElement._owner;
      for (let i = 0; i < traverseUp; i++) {
          compFiber = compFiber._currentElement._owner;
      }
      return compFiber._instance;
  }

  // react 16+
  const GetCompFiber = fiber=>{
      //return fiber._debugOwner; // this also works, but is __DEV__ only
      let parentFiber = fiber.return;
      while (typeof parentFiber.type == "string") {
          parentFiber = parentFiber.return;
      }
      return parentFiber;
  };
  let compFiber = GetCompFiber(domFiber);
  for (let i = 0; i < traverseUp; i++) {
      compFiber = GetCompFiber(compFiber);
  }
  return compFiber.stateNode;
}

function getSelectionStart() {
  var node = document.getSelection().anchorNode;
  return (node.nodeType == 3 ? node.parentNode : node);
}

function setCaret(el) {
  const range = document.createRange()
  const sel = window.getSelection()
  
  const node = el.childNodes[el.childNodes.length - 1];
  if (node) {
    range.setStart(node, node?.length || 0)
  }
  else {
    range.setStart(el, 0)
  }
  range.collapse(true)
  
  sel.removeAllRanges()
  sel.addRange(range)
}

function clearSelection() {
  if (window.getSelection) {window.getSelection().removeAllRanges();}
  else if ((document as any).selection) {(document as any).selection.empty();}
}

function getSelectionElements() {
  var selection = window.getSelection();
  var range = selection.getRangeAt(0);
  var allWithinRangeParent = (range.commonAncestorContainer as any).getElementsByTagName("*");
  
  var allSelected = [];
  for (var i=0, el; el = allWithinRangeParent[i]; i++) {
    // The second parameter says to include the element 
    // even if it's not fully selected
    if (selection.containsNode(el, true) ) {
      allSelected.push(el);
    }
  }
  
  
  console.log('All selected =', allSelected);
  
}

// TODO: update to support entities
// iterator through a tree of nodes
function resolveOffset(el, position): [Node, number] {
  let offset = 0;
  let node = el.firstChild;
  while (node) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (offset + node.length >= position) {
        return [node, position - offset];
      }
      offset += node.length;
    }
    else if (node.getAttribute('data-type') === 'entity') {
      offset += 1;
    }
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const [foundNode, foundOffset] = resolveOffset(node, position - offset);
      if (foundNode) {
        return [foundNode, foundOffset];
      }
      offset += node.textContent.length;
    }
    node = node.nextSibling;
  }
  return [el, position];
}

function createRangeArrayFromBlockSelection(blockSelection): [Node, number, Node, number] {
  const [start, end] = blockSelection;
  const startBlock = document.querySelector(`[data-block-id="${start.blockId}"] .editor`);
  const endBlock = document.querySelector(`[data-block-id="${end.blockId}"] .editor`);

  return [
    ...resolveOffset(startBlock, start.position),
    ...resolveOffset(endBlock, end.position)
  ]

}

function createRangeFromBockSelection(blockSelection) {
  const [start, end] = blockSelection;
  const startBlock = document.querySelector(`[data-block-id="${start.blockId}"] .editor`);
  const endBlock = document.querySelector(`[data-block-id="${end.blockId}"] .editor`);

  const range = document.createRange();

  range.setStart(...resolveOffset(startBlock, start.position));
  range.setEnd(...resolveOffset(endBlock, end.position));
  return range;
}

function setCaretToBlockSelection(blockSelection) {
  if (blockSelection.length > 0) {
    const range = createRangeArrayFromBlockSelection(blockSelection);
    window.getSelection().setBaseAndExtent(...range);

  }
}

function getBlockSelection(sorted=false): [{blockId, position}, {blockId, position}] | [] {
  if (sorted) {
    const selection = getBlockSelection();
    const blockIds = getSelectedBlockIds();
    const firstId = blockIds[0];
    const lastId = blockIds[blockIds.length - 1];
    const first = selection.find(s => s.blockId === firstId);
    const last = selection.find(s => s.blockId === lastId);
    return [first, last];
  }
  else {
    const selection = window.getSelection();
    if (!selection.anchorNode) return [];
    const anchor = _getPositionInBlock('anchor');
    const focus = _getPositionInBlock('focus');
    const anchorNode = selection.anchorNode;
    const focusNode = selection.focusNode;
    const anchorBlock = jQuery(anchorNode).closest('[data-block-id]');
    const focusBlock = jQuery(focusNode).closest('[data-block-id]');
    const anchorBlockId = anchorBlock.attr('data-block-id');
    const focusBlockId = focusBlock.attr('data-block-id');
  
    if (!anchorBlockId || !focusBlockId) return [];
  
  
    return [{
      blockId: anchorBlockId,
      position: anchor
    }, {
      blockId: focusBlockId,
      position: focus
    }]
  }

}

function getSelectionHtml () {
  var html = "";
  if (typeof window.getSelection != "undefined") {
    var sel = window.getSelection ();
    if (sel.rangeCount) {
      var container = document.createElement ("div");
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild (sel.getRangeAt (i).cloneContents ());
      }
      html = container.innerHTML;
    }
  } else if (typeof (document as any).selection != "undefined") {
    if ((document as any).selection.type == "Text") {
      html = (document as any).selection.createRange ().htmlText;
    }
  }
  return html;
}


function _getPositionInBlock(which) {
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return -1;
  let position = selection[which + 'Offset'];

  const node = selection[which + 'Node'];
  if (node.getAttribute?.('data-type') === 'blockData') {
    return position;
  }

  if (!node) return 0;


  let rootNode = node;
  while (rootNode && rootNode.getAttribute?.('data-type') !== 'blockData') {
    rootNode = rootNode.parentNode;
  }
  if (!rootNode) return 0;

  let pos = 0;

  const findNode = n => {
    if (n == node) {
      pos += position;
      return true;
    }
    else if (n.getAttribute?.('data-type') === 'entity') {
      pos += 1;
    }
    else if (n.nodeType == Node.TEXT_NODE) {
      pos += n.textContent?.length || 0;
    }
    else if (n.nodeType == Node.ELEMENT_NODE) {
      if (n.childNodes) {
        for (let i = 0; i < n.childNodes.length; i++) {
          if (findNode(n.childNodes[i]) === true) {
            return true;
          }
        }
      }
    }
  }

  findNode(rootNode);

  return pos;


}

/* function _getPositionInBlock2(which) {
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return -1;
  let position = selection[which + 'Offset'];

  const rootNode = selection[which + 'Node'];
  if (rootNode.getAttribute?.('data-type') === 'blockData') {
    return position;
  }

  const next = (node:HTMLElement) => {
    if (!node) return;
    if (node.getAttribute?.('data-type') === 'blockData') {
      return;
    }
    if (node.nodeType == Node.TEXT_NODE) {
      position += node.textContent?.length || 0;
    }
    next((node.previousSibling || node.parentNode) as HTMLElement);
  }

  next((rootNode.previousSibling || rootNode.parentNode) as any);

  return position;
} */

function getPositionInBlock() {
  return _getPositionInBlock('anchor');
}

function moveCaret(charCount) {
  var sel, range;

    sel = window.getSelection();
    if (sel.rangeCount > 0) {
        var textNode = sel.focusNode;
        var newOffset = sel.focusOffset + charCount;
        sel.collapse(textNode, Math.min(textNode.length, newOffset));
    }
}

function getSelectedBlockIds() {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]');
  return blockEls.toArray().map((el) => el.getAttribute('data-block-id'));
}

function getFirstPos() {
  if (window.getSelection().isCollapsed) {
    return getPositionInBlock();
  }
  else {
    const firstBlock = getSelectedBlockIds()[0];
    return getBlockSelection().find((s) => s.blockId === firstBlock).position;  
  }
}


function copy(blocks, e) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]');
  if (blockEls.length) {
    const copiedBlocks = [];
    let i = 0;
    for (const blockEl of blockEls) {
      const id = blockEl.getAttribute('data-block-id');
      const data = extractFromEl(jQuery(blockEl).find('[data-type="blockData"]')[0]);
      const indentation = getBlockIndentation(blocks, id);
      const block = findBlock(blocks, id, true);
      let position;
      if (i == 0) {
        position = getFirstPos();
      }
      else {
        position = 0;
      }
      copiedBlocks.push({
        _id: id,
        data,
        position,
        indentation,
        type: block.type,
        id: block.id,
        record: block.record,
        checked: block.checked,
      });
      ++ i;
    }
    e.originalEvent.clipboardData.setData('text/_blocks', JSON.stringify(copiedBlocks));
    console.log(copiedBlocks)
  }
  else {
    // e.originalEvent.clipboardData.setData('text/test', 'test');
    const data = extractFromEl(el);
    console.log(el, data);
    e.originalEvent.clipboardData.setData('text/_blockSegment', JSON.stringify(data));
    e.originalEvent.clipboardData.setData('text/plain', dataToString(data));
  }
}

function isFullSelection(blocks) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]').toArray();

  if (blockEls.length) {
    const sortedSel = getBlockSelection(true);
    if (sortedSel.length != 2) return false;
    // console.log(sortedSel[0].position, dataLength(findBlock(blocks, sortedSel[1].blockId).data), sortedSel[1].position);
    if (sortedSel[0].position == 0 && dataLength(findBlock(blocks, sortedSel[1].blockId)?.data || []) == sortedSel[1].position) {
      console.log('full delete');
      return true;
    }
  }
}

function deleteSelection({ blocks, setBlocks }, replacementChar?) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]').toArray();

  if (blockEls.length) {
    const blockIds = blockEls.map(el => el.getAttribute('data-block-id'));

    if (isFullSelection(blocks)) {
      const fl = flatList(blocks).filter(({ _id }) => !blockIds.includes(_id));
      setBlocks(constructTree(fl));
      return;
    }

    console.log('multiple blocks selected')
    const anchor = _getPositionInBlock('anchor');
    const focus = _getPositionInBlock('focus');

    const selection = getBlockSelection();


    console.log(anchor, focus, selection);

    // const copiedBlocks = [];

    const firstBlockId = blockIds[0];
    const firstBlock = findBlock(blocks, firstBlockId);
    const firstBlockPos = selection.find(({ blockId }) => blockId == firstBlockId).position;
    // const firstBlockLength = dataLength(firstBlock.data);
    const firstSlice = sliceData(firstBlock.data, 0, firstBlockPos);

    const lastBlockId = blockIds[blockIds.length - 1];

    const lastBlockPos = selection.find(({ blockId }) => blockId == lastBlockId).position;


    const lastBlock = findBlock(blocks, lastBlockId);
    const lastBlockLength = dataLength(lastBlock.data);
    
    // console.log(lastBlockPos, lastBlockLength - lastBlockPos + 1, lastBlockLength, lastBlockId, dataToString();

    const lastSlice = sliceData(lastBlock.data, lastBlockPos, lastBlockLength);

    const newData = replacementChar !== undefined ? concatData(concatData(firstSlice, [replacementChar]), lastSlice) : concatData(firstSlice, lastSlice);


    if (lastBlock.id) {
      const lastBlockEl = jQuery(el).find(`[data-block-id="${lastBlock._id}"]`)[0];
      const data = extractFromEl(jQuery(lastBlockEl).find('[data-type="blockData"]')[0]);
      
      updateBlockData(lastBlock, X(data));
    }

    for (let i = blockIds.length - 1; i >= 1; i--) {
      removeBlock(blocks, blockIds[i]);
    }
    firstBlock.data = XClone(newData);
    firstBlock.children = X(x(lastBlock.children || []).concat(x(firstBlock.children) || []));
    
    clearSelection();
  }
  else {
    document.execCommand('delete');
    return true;
  }
}

function test() {
  const pos = window.getSelection().getRangeAt(0).getBoundingClientRect();
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.top = pos.top + 'px';
  el.style.left = pos.left + 'px';
  el.style.width = Math.max(2, pos.width) + 'px';
  el.style.height = pos.height + 'px';
  
  el.style.background = 'red';
  document.body.appendChild(el);

}


const MenuWrapper = styled.div`
  width: 324px;
  border-radius: 4px;
  background: white;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  overflow: hidden;
  padding: 6px 0;

  transition: opacity .1s linear, transform .1s linear;

  transform-origin: top left;
  z-index: 999999999;

  &.hidden {
    opacity: 0;
    transform: scale(.9);
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
    animation-name: bounce;
    animation-duration: 0.3s;
  }

  &.closing {
    opacity: 0;
    transform: scale(.9);
  }


  @keyframes bounce {
    20% {
      transform: scale(1.01);
    }
    
    50% {
      transform: scale(0.99);
    }
    
    100% {
      transform: scale(1);
    }
  }




  .item {
    margin: 0 4px;
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    border-radius: 3px;
    &.active {
      background: #f3f3f3;
    }
    min-height: 28px;
    display: flex;
    align-items: center;
    padding: 0 14px;
  }
`;

class DB {
  constructor(public database) {

  }
  getRecord(id) {
    return this.database.rows.find(r => r._id === id)
  }

  titleCol() {
    return this.database.columns.find(c => c.type === 'title');
  }

  getRecordTitle(record) {
    const titleCol = this.titleCol();
    return record.data[titleCol._id];
  }
}

@component
class Menu extends Component<{ block, blockTypes, db: DB, type, baseEntity, entityTypes, extendEntity }> {
  state = XInit(class {
    index = 0;
    filter = '';
    hoverIndex
    state = 'hidden'
  })
  down() {
    this.state.index = (this.state.index + 1) % this.filtererOptions().length;
  }
  up() {
    this.state.index = (this.state.index - 1 + this.filtererOptions().length) % this.filtererOptions().length;
  }
  filtererOptions() {
    const baseEntity = db.entities.findById(this.props.baseEntity);
    if (this.props.type == 'mention') {
      const r =  db.entities.filter(e => {
        // if (baseEntity.space && e.space !== baseEntity.space) return false;
        if (!_.isString(e.name)) return false;
        return e.name.toLowerCase().includes(this.state.filter.toLowerCase());
      }).slice(0, 10).map(e => ({
        label: e.name,
        action: (block, menuPos) => {
          const position = menuPos;
          const length = dataLength(block.data);
          const firstPart = sliceData(block.data, 0, position);
          const secondPart = sliceData(block.data, position, length);
          const en = [[e._id, 'entity']];
          block.data = concatData(concatData(firstPart, en), secondPart)

        }
      }))

      return r.concat({
        label: `Create entity "${this.state.filter}"`,
        action: (block, menuPos) => {
          const e = XObject.obj({
            name: this.state.filter,
          })
          createEntity(e, this.props.baseEntity); // createEntityNull
          const position = menuPos;
          const length = dataLength(block.data);
          const firstPart = sliceData(block.data, 0, position);
          const secondPart = sliceData(block.data, position, length);
          const en = [[e._id, 'entity']];
          block.data = concatData(concatData(firstPart, en), secondPart)

        }
      })
    }
    else {
      return this.options().filter(o => o.label.toLowerCase().includes(this.state.filter.toLowerCase()));
    }
  }
  setFilter(filter) {
    this.state.filter = filter;
  }
  enter() {
    const option = this.filtererOptions()[this.state.index];
    console.log(option);
    return option;
  }
  close(cb) {
    this.state.state = 'closing';
    setTimeout(() => {
      cb();
    }, 100);
  }

  options() {
    const blockType = this.props.blockTypes.find(b => b._id === this.props.block?.type);
    const options = [
      {
        label: 'Insert entity',
        action: (block, menuPos) => {
          const position = menuPos;
          const length = dataLength(block.data);
          const firstPart = sliceData(block.data, 0, position);
          const secondPart = sliceData(block.data, position, length);
          const e = [['6435a0493239f5c3f494c1d6', 'entity']];
          block.data = concatData(concatData(firstPart, e), secondPart)
        }
      },
      {
        label: 'To-do list',
        action: block => {
          block.type = 'checkItem';
        }
      },
      {
        label: 'Plain block',
        action: block => {
          delete block.type;
        }
      },
      {
        label: 'Heading 1',
        action: block => {
          block.type = 'heading_1';
        }
      },
      {
        label: 'Code',
        action: block => {
          block.type = 'code';
        }
      },
      {
        label: 'Entity',
        action: block => {
          block.type = 'entity';
        }
      },
      {
        label: 'Attach new entity',
        action: block => {
          const entity = XObject.obj({});
          this.props.extendEntity?.(entity);
          createEntity(entity, this.props.baseEntity);
          block.id = entity._id;
        },
      },
      {
        label: 'Attach existing entity',
        action: block => {
          const id = prompt('Enter entity id');
          if (id) {
            block.id = id;
            const entity = db.entities.findById(id);
            block.data = X([entity.name])
          }
        }
      },
      this.props.db && {
        label: 'Attach existing record',
        action: block => {
          const id = prompt('Enter record id');
          if (id) {
            block.record = id;
            const record = this.props.db.getRecord(id);
            block.data = X([this.props.db.getRecordTitle(record)])
          }
        }
      },
      this.props.db && {
        label: 'Attach new record',
        action: block => {
          const record = XObject.obj({
            data: {}
          });
          this.props.db.database.rows.push(record);
          block.record = record._id;
        }
      }
    ].concat(this.props.blockTypes.map(t => {
      return {
        label: `Set block type to ${t.name}`,
        action: block => {
          block.type = t._id;
        }
      }
    })).concat(this.props.entityTypes.map(id => ({
      label: `Create ${db.entityTypes.findById(id).name}`,
      action: block => {
        const entity = XObject.obj({
          type: id,
        });
        createEntity(entity, this.props.baseEntity); // createEntityNull
        block.id = entity._id;

      }
    })))

    for (const blockType of this.props.blockTypes) {
      options.push({
        label: `Turn into ${blockType.name}`,
        action: block => {
          console.log(blockType);
          block.type = blockType._id;
          const entity = XObject.obj({
            name: dataToString(block.data || []),
          });


          if (blockType.entityType) {
            entity.type = blockType.entityType;
          }

          if (blockType.stateful) {
            entity.stateful = true;
          }

          if (blockType.parent) {
            db.edges.push(XObject.obj({
              entities: [blockType.parent, entity._id],
              directed: true,
            }))
          }

          createEntity(entity, this.props.baseEntity); // createEntityNull
          block.id = entity._id;
        }
      })
    }

    if (this.props.db) for (const col of this.props.db.database.columns) {
      if (col.showInDoc) {
        if (col.type == 'select') {
          for (const value of col.options) {
            options.push({
              label: `Set ${col.title} to ${value.title}`,
              action: block => {
                const record = this.props.db.getRecord(block.record);
                record.data[col._id] = value._id;
              }
            })
          }
        }
        else if (col.type == 'text') {
          options.push({
            label: `Set ${col.title} to ...`,
            action: block => {
              const record = this.props.db.getRecord(block.record);
              record.data[col._id] = prompt(`Set ${col.title} to ...`);
            }
          })          
        }
      }
    }

    if (blockType) {

      if (blockType.elements) for (const el of blockType.elements) {
        if (el.type == 'attribute') {
          if (el.attributeType == 'select') {
            for (const value of el.options) {
              options.push({
                label: `Set ${el.title} to ${value.title}`,
                action: block => {
                  // block.data[el._id] = value._id;
                }
              })
            }
          }
          else if (el.type == 'attribute') {
            options.push({
              label: `Set ${el.title} to ...`,
              action: async block => {
                const r = await showPrompt(`Set ${el.title} to ...`);
                el.binding.set(block.id, r);
                // block.data[el._id] = prompt(`Set ${el.title} to ...`);
              }
            })          
          }
        }
      }
    }

    return options.filter(Boolean);
  }

  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.state.state = 'visible';
    }, 50);
  }

  render() {
    const filteredOptions = this.filtererOptions();
    const index = this.state.index;//this.state.hoverIndex ?? this.state.index;
    return (
      <MenuWrapper className={this.state.state}>
        {filteredOptions.map((o, i) => (
          <div
            key={o.label}
            className={classNames("item", { active: i == index })}
            onMouseEnter={() => this.state.index = i}
            onMouseLeave={() => this.state.index = null}
          >
            {o.label}
          </div>
        ))}
      </MenuWrapper>
    );
  }
}

function getFirstBlockInSelection() {
  if (isMultiBlockSelection()) {
    const firstBlock = getSelectedBlockIds()[0];
    return getBlockSelection().find(b => b.blockId == firstBlock);  
  }
  else {
    return getBlockSelection()[0];
  }
}

@component
class DisplayEntity extends Component<{ entity }> {
  render() {
    return (
      <div>
        <input type="checkbox" checked={this.props.entity.checked}
        
          onChange={e => {
            this.props.entity.checked = e.target.checked;
          }}
        /> {this.props.entity.name.join('')}

        <PropertyField
          object={this.props.entity}
          property="badge"
          
        />
      </div>
    )
  }
}

@component
class EntityBadge extends Component<{ entity }> {
  render() {
    return (
      <span>
        {this.props.entity.badge}
      </span>
    )
  }
}

@component
class Inspector extends Component<{ block }> {
  state = X({});
  render() {
    const block = this.props.block();
    return (
      <>
        {/* {block?._id} */}

        {block?.id && renderInspect(block?.id, this.state)}
      </>
    )
  }
}

function isMultiBlockSelection() {
  if (!window.getSelection().isCollapsed) {
    const el = document.createElement('div');
    el.innerHTML = getSelectionHtml();
    const blockEls = jQuery(el).find('[data-block-id]').toArray();
    return blockEls.length > 1;
  }

  return false;
}

@component
class PositionInspector extends Component<{ blocks }> {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate();
    }, 50);
  }
  render() {
    return getBlockSelection()?.[0]?.position ?? '---'

    return (
      <>
        {isFullSelection(this.props.blocks) ? 'Yes' : 'No'}
      </>
    )
  }
}

function executeEnter(_blocks, blockTypes, blockId, pos, e, baseEntity, extendEntity) {
  const block = findBlock(_blocks, blockId);
  let newBlock;

  if (pos == 0 && dataLength(block.data) > 0) {
    newBlock = XObject.obj({
      data: []
    });

    const parent = findBlockParent(_blocks, blockId);

    let blocks;
    if (parent) {
      blocks = parent.children;
    }
    else {
      blocks = _blocks;

    }

    blocks.splice(blocks.indexOf(block), 0, newBlock);
    
    return block;
  }
  else {
    const position = pos;
    const length = dataLength(block.data);
    const firstPart = sliceData(block.data, 0, position);
    const secondPart = sliceData(block.data, position, length);

    updateBlockData(block, firstPart);
    
    newBlock = XObject.obj({
      // data: secondPart
    });

    if (!e.altKey && (block.type || block.id)) {
      if (block.type) {
        if (!block.type.startsWith('heading')) {
          newBlock.type = block.type;
        }
        const blockType = blockTypes.find(b => b._id == block.type);
        if (block.id) {
          const entity = XObject.obj({
            type: blockType.entityType,
          });
          if (blockType.parent) {
            db.edges.push(XObject.obj({
              entities: [blockType.parent, entity._id],
              directed: true,
            }))
          }
          if (blockType.stateful) {
            entity.stateful = true;
          }
          newBlock.id = entity._id;
          extendEntity?.(entity);
          createEntity(entity, baseEntity); // createEntityNull
        }
      }
      else {
        const currentEntity = db.entities.findById(block.id);
        const entity = XObject.obj({
          type: currentEntity.type,
        });
        newBlock.id = entity._id;
        extendEntity?.(entity);
        createEntity(entity, baseEntity);
      }
    }

    updateBlockData(newBlock, secondPart);
    

    if (block.children?.length) {
      block.children.splice(0, 0, newBlock);  
    }
    else {
      const parent = findBlockParent(_blocks, blockId);

      let blocks;
      if (parent) {
        blocks = parent.children;
      }
      else {
        blocks = _blocks;

      }

      blocks.splice(blocks.indexOf(block) + 1, 0, newBlock);
    }
  }

  return newBlock;
}

function flatList(blocks):{
  _id: string,
  data
  indentationLevel: number,
}[] {
  const list = [];
  const add = (blocks, parent=null, indentationLevel = 0) => {
    for (const block of blocks) {
      list.push({
        _id: block._id,
        data: block.data,
        indentationLevel,
        record: block.record,
        id: block.id,
        checked: block.checked,
      });
      if (block.children) {
        add(block.children, block._id, indentationLevel + 1);
      }
    }
  }

  add(blocks);

  return list;
}

function constructTree(flatList) {
  const findParent = (fromI, currentIndentation) => {
    for (let i = fromI - 1; i >= 0; -- i) {
      const block = flatList[i];
      if (block.indentationLevel < currentIndentation) {
        return block;
      }
    }
    return null;
  }

  const rootBlocks = [];
  const blocksMap = {};
  for (let i = 0; i < flatList.length; ++ i) {
    const block = flatList[i];
    const newBlock = {
      _id: block._id,
      data: block.data,
      children: [],
      type: block.type,
      record: block.record,
      id: block.id,
      checked: block.checked,
    }
    blocksMap[block._id] = newBlock;
    const parent = findParent(i, block.indentationLevel);
    if (parent) {
      const parentBlock = blocksMap[parent._id];
      parentBlock.children.push(newBlock);
    } else {
      rootBlocks.push(newBlock);
    }
  }

  return rootBlocks;

}


function updateBlockSyncedData(block, data) {
  if (block.id) {
    const entity = db.entities.findById(block.id);
    if (entity) {
      XObject.withPass({ internal: block._id }, () => {
        entity.name = dataToString(block.data || []);
      });
    }
  }
  // else if (block.record) {
  //   // const db = new DB(this.props.database);
  //   const titleCol = db.titleCol();
  //   const record = db.getRecord(block.record);
  //   if (record) {
  //     XObject.withPass({ internal: block._id }, () => {
  //       record.data[titleCol._id] = X(x(block.data))[0] || '';
  //     });
  //   }
  // }

}
function updateBlockData(block, data) {
  block.data = XClone(data);

  updateBlockSyncedData(block, data);
}

function isOnTitle() {
  return jQuery(window.getSelection().focusNode).parents('[data-type="title"]').length || jQuery(window.getSelection().focusNode).is('[data-type="title"]');
}

@component
export class NotionDocumentBad extends Component<{
  onlyShowTitle?: boolean
  extState?,
  valuePoints?,
  inline?,
  blockTypes,
  blocks?: any[],
  setBlocks?,
  database?,
  tick?,
  title?,
  setTitle?,
  entity,
  docId,
}> {
  static reactive = false;
  title
  static styles = styled.div`
    .--entity-- {
      color: #0275ff;
      cursor: pointer;
    }
    .--capture-- {
      /* text-decoration: underline; */
      border-bottom: 1px dashed #d0d0d0;
    }
    background-color: ${color('bg')};
    color: ${color('text')};

    &:not(.inline) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

    }

    &.inline {
      position: relative;
    }

    .title {
      margin-bottom: 10px;
      min-height: 31px;
      font-weight: 700;
      line-height: 1.2;
      /* font-size: 40px; */

      font-weight: 600;
      font-size: 26px;
      
      &:empty::before {
        content: 'Untitled';
        color: #373737; 
      }
      &:empty:focus::before {
          content: "";
      }
    }

    .wrapper {
      &.hideOverlays .grip {
        opacity: 0;
      }
      font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
      -webkit-font-smoothing: auto;
      cursor: text;
      
    }
    /* [data-type="blockCont"][data-entity] > .block:hover {
      background: #f9f9f9;
    }*/

    [data-type="blockCont"] > .block.activeBlock {
      /* background: #f3f3f3; */
      .entityDot, .dataBindingDot {
        opacity: 1;
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-left: -2px;
      }
    }




    .menu {
    }

    .dragArea {
      &:hover {
        /* background: #eee; */
      }

      z-index: 5;

      &.hovering {
        /* opacity: .3; */
        /* background: rgba(0, 0, 255, .3); */

        &.above {
          border-top: 3px solid #9fd4ee;
        }

        &.below {
          border-bottom: 3px solid #9fd4ee;
        }

      }

    }

    > .wrapper {
      padding: 0 30px;

      &:not(.inline) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
      }

      &.inline {
        /* padding-top: 8px; */
        /* padding-bottom: 8px; */
      }
      &:focus {
        outline: none;
      }
    }

    > .inspector {
      display: none;
      overflow: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 400px;
      border-left: 1px solid #cacaca;
    }

    &.mobile {
      > .wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `;

  history = [];
  historyIndex = 0;

  menuRef = React.createRef<Menu>();

  getBlocks() {
    return this.props.blocks;
  }

  state = XInit(class {
    data = ''
    activeBlock
    /*blocks = X([
      // XObject.obj({
      //   data: ['^hello', [' world', ['b']]],
      //   type: 'checkItem',
      //   checked: true,
      // }),
      XObject.obj({
        data: ['Tasks'],
        type: 'heading_1',
        // children: X([
        //   XObject.obj({
        //     data: ['asdf'],
        //     children: X([
        //       XObject.obj({
        //         data: ['^hello', [' world', ['b']]],
        //         type: 'entity',
        //       }),
        //     ])
        //   })
        // ])
      }),
      XObject.obj({
        data: ['Task 1'],
        // type: 'entity',
        // id: 'e9646153-c2d0-5530-b37e-6761b535c338',
      }),
      XObject.obj({
        data: ['Task 2'],
        type: 'checkItem',
      }),
      XObject.obj({
        data: ['Task 3'],
        type: 'entity',
      }),
      XObject.obj({
        data: [''],
        id: '6423b69372c90789b336443b',
        type: '6423a4e0ec638e14e4f1a963',
        // type: 'entity',
      }),

      // XObject.obj({
      //   data: ['^world2'],
      //   children: [
      //     XObject.obj({
      //       data: ['world3'],
    
      //     }),
      //   ]

      // }),     

    ])*/

    // focus 
  });

  setBlocks(blocks) {
    if (this.props.setBlocks) {
      this.props.setBlocks(blocks);
    } else {
      // this.getBlocks() = blocks;
    }
  }

  constructor(props) {
    super(props);
    // if (this.props.blocks) {
    //   this.getBlocks() = this.props.blocks;
    // }

    this.title = this.props.title;

    for (const block of this.getBlocks()) {
      if (block.id) {
        const entity = db.entities.findById(block.id);
        if (entity) {
          block.data = X([entity.name]);
        }
      }
    }
  }

  transaction(block) {
    block();
  }

  deleteSelection(replaceChar?) {
    return deleteSelection({
      blocks: this.getBlocks(),
      setBlocks: this.props.setBlocks,
    },
    replaceChar);
  }

  funcs = [];

  collectFunc(func) {
    this.funcs.push(func);
    return func;
  }

  static contextType = SystemContext;
  context: any;
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    if (this.props.database) {
      const db = new DB(this.props.database);
      const titleCol = db.titleCol();
      XObject.observe(this.props.database.rows, mutation => {
        console.log(x(mutation));
        // return;
        // if (!mutation.pass?.internal) {
          if (mutation.type == 'set' && mutation.path[1] == 'data' && mutation.path[2] == titleCol._id) {
            const recordId = mutation.path[0].slice(1);
            const record = db.getRecord(recordId);
            const blocks = findBlocksMatching(this.getBlocks(), b => b.record == recordId);
            // console.log(x(block), mutation.el._id);
            for (const block of blocks) {
              if (mutation.pass?.internal == block._id) continue;
              const changedName =  true;//mutation.path[2] == titleCol._id;

              // console.log(changedName);
              if (changedName) {
                if (mutation.el.name != dataToString(block.data || [])) {
                  console.log('===UPDATE')
                  const name = record.data[titleCol._id];
                  
                  block.data = X([name]);
                  // this.tick++;
                  const selection = getBlockSelection();
                  // console.log(selection);
                  this.forceUpdate();
                  setTimeout(() => {
                    setCaretToBlockSelection(selection);
                  }, 10);  
                }
    
              }
              // else if (mutation.path[1] == 'checked') {
              //   block.checked = mutation.el.checked;
              // }
            }
          }
        // }
      });
    }
    XObject.observe(db.entities, mutation => {
      // console.log(mutation);
      // if (!mutation.pass?.internal) {
        if (mutation.type == 'set') {
          const blocks = findBlocksMatching(this.getBlocks(), b => b.id == mutation.el._id);
          // console.log(x(block), mutation.el._id);
          for (const block of blocks) {
            if (mutation.pass?.internal == block._id) continue;
            if (mutation.path[1] == 'name') {
              const str = dataToString(block.data || []);
              if (mutation.el.name != str) {
                console.log('===UPDATE', x(mutation.el.name), str)
                block.data = X([mutation.el.name]);
                // this.tick++;
                const selection = getBlockSelection();
                // console.log(selection);
                this.forceUpdate();
                setTimeout(() => {
                  setCaretToBlockSelection(selection);
                }, 10);  
              }
  
            }
            // else if (mutation.path[1] == 'checked') {
            //   block.checked = mutation.el.checked;
            // }
          }
        }
      // }
    });

    jQuery(el).on('click', '[data-type="entity"]', this.collectFunc((e) => {
      const id = JSON.parse(atob(e.target.getAttribute('data-entity-data')));
      this.context?.navigate?.({
        type: 'entity',
        id
      })
    }));

    jQuery(el).on('cut', '[contenteditable]', this.collectFunc((e) => {
      this.saveState('cut');
      e.preventDefault();
      copy(this.getBlocks(), e);
      this.deleteSelection();
      this.forceUpdate();  
    }));

    jQuery(el).on('copy', '[contenteditable]',  this.collectFunc((e) => {
      copy(this.getBlocks(), e);
      e.preventDefault();
    }));
    
    jQuery(el).on('paste', '[contenteditable]',  this.collectFunc((e) => {
      return;
      const d = e.originalEvent.clipboardData.getData('text/_blocks')
      e.preventDefault();
      if (d) {
        this.saveState('paste');
        const pastedData = JSON.parse(d);
        const a = getFirstBlockInSelection();
        const currentBlockId = a.blockId;
        const position = a.position;

        if (!window.getSelection().isCollapsed) {
          if (this.deleteSelection()) {
            this.save('paste');
          }
        }


        // console.log(pastedData, currentBlockId);
        const fullCopy = pastedData[0].position == 0;

        const flatList = this.flatList();
        const currentBlockIndex = flatList.findIndex((block) => block._id === currentBlockId);
        const currentBlockIndentation = flatList[currentBlockIndex].indentationLevel;

        const newBlocks = [];
        let firstIndentation = null;
        let lastSlice;

        let i = 0;
        for (const pastedBlock of pastedData) {
          if (firstIndentation === null) {
            firstIndentation = pastedBlock.indentation;

            if (!fullCopy) {
              const c = flatList.find((block) => block._id === currentBlockId);


              const length = createChunked(c.data).length;

              const firstSlice = sliceData(c.data, 0, position);
              lastSlice = sliceData(c.data, position, length);

              c.data = concatData(firstSlice, pastedBlock.data);
            }
            else {
              newBlocks.push({
                _id: XObject.id(),
                data: pastedBlock.data,
                indentationLevel: pastedBlock.indentation - firstIndentation + currentBlockIndentation,
                type: pastedBlock.type,
                record: pastedBlock.record,
                checked: pastedBlock.checked,
                id: pastedBlock.id,
              })
  
            }

          }
          else {

            let data = pastedBlock.data;
            if (!fullCopy && i === pastedData.length - 1) {
              data = concatData(data, lastSlice);
            }
            newBlocks.push({
              _id: XObject.id(),
              data,
              indentationLevel: pastedBlock.indentation - firstIndentation + currentBlockIndentation,
              type: pastedBlock.type,
              record: pastedBlock.record,
              checked: pastedBlock.checked,
              id: pastedBlock.id,
            })
          }

          ++i;

        }

        if (!fullCopy) {
          const last = newBlocks[newBlocks.length - 1];
          if (last.id) {
            updateBlockSyncedData(last, last.data);
          }  
        }

        if (fullCopy && dataLength(flatList[currentBlockIndex].data) == 0) {
          flatList.splice(currentBlockIndex, 1, ...newBlocks);
        }
        else {
          flatList.splice(currentBlockIndex + 1, 0, ...newBlocks);
        }

        const tree = this.constructTree(flatList);



        this.setBlocks(X(tree));

        this.doTick();

      }
      else {
        let d = e.originalEvent.clipboardData.getData('text/_blockSegment')
        let pasted;
        if (d) {
          d = JSON.parse(d);
          pasted = d;
          // console.log(d);
        }
        else {
          d = e.originalEvent.clipboardData.getData('text/plain');
          if (d) {
            // console.log(d);
            pasted = [d];
          }
        }

        if (pasted) {
          this.saveState('paste');
          const block = findBlock(this.getBlocks(), getBlockSelection()[0].blockId);
          const position = getBlockSelection()[0].position;
          const length = createChunked(block.data).length;
          const firstSlice = sliceData(block.data, 0, position);
          const lastSlice = sliceData(block.data, position, length);

          // console.log(
          //   position,
          //   length,
          //   dataToString(firstSlice), ' --- ',
          //   dataToString(pasted), ' --- ',
          //   dataToString(lastSlice), ' --- ',
          //   dataToString(concatData(concatData(firstSlice, pasted), lastSlice))
          // );

          block.data = X(concatData(concatData(firstSlice, pasted), lastSlice));

          this.doTick();
        }

      }
    }));

    // jQuery(document).on('keydown', '[contenteditable]', (e) => {
    //   console.log(e);
    // });

    jQuery(el).on('beforeinput', '[contenteditable]', (e) => {
      if (this.showMenu) return;
      if (isOnTitle()) {
        if (e.originalEvent.inputType == 'insertParagraph') {
          e.preventDefault();
          const newBlock = XObject.obj()
          this.getBlocks().splice(0, 0, newBlock);
          this.doTick();

          setTimeout(() => {
            setCaretToBlockSelection([
              {
                blockId: newBlock._id,
                position: 0,
              },
              {
                blockId: newBlock._id,
                position: 0,
              }
            ])
  
          }, 0)

          return;
        }
      }
      const type = e.originalEvent.inputType;
      if (type.startsWith('format') || type.startsWith('insert') || type.startsWith('delete')) {
        this.saveState(type);
      }
    });

    jQuery(el).on('input', '[contenteditable]', e => {
      if (this.showMenu) return;
      if (isOnTitle()) {
        const titleEl = jQuery(window.getSelection().focusNode).closest('[data-type="title"]')
        console.log(titleEl.text(), titleEl.html(), titleEl[0]);
        if (!titleEl.text()) titleEl.text('');
        this.props.setTitle(titleEl.text());
        this.title = titleEl.text();
      }
      else {
        const type = e.originalEvent.inputType;
        if (type.startsWith('format') || (type.startsWith('insert') || type.startsWith('delete')) && !this.showMenu) {
          this.save('input');
        }
      }
    });

    document.addEventListener('selectionchange', e => {
      // console.log(e);
      const b = getBlockSelection();
      const blockId = b?.[0]?.blockId;
      if (!blockId) return;
      if (blockId != this.state.activeBlock) {
        this.state.activeBlock = blockId;
        if (this.props.extState) {
          this.props.extState.activeBlock = blockId;
        }
        const block = findBlock(this.getBlocks(), blockId);
        if (block?.id) {
          appState.inspecting = {
            type: 'entity',
            id: block.id,
          }
        }

        jQuery(this.wrapperEl.current).find('.activeBlock').removeClass('activeBlock');
        jQuery(this.wrapperEl.current).find(`[data-block-id="${this.state.activeBlock}"]`).addClass('activeBlock');  
      }
    });

    jQuery(window).mousemove(e => {
      if (this.dragStart) {
        this.dragging = this.dragStart;
        delete this.dragStart;
        this.updateAreas();
        this.forceUpdate();
        jQuery('html').addClass('dragging');
      }
      if (this.dragging) {
        
        // find area under cursor
        const area = jQuery(document.elementFromPoint(e.clientX, e.clientY)).closest('.dragArea');

        jQuery('.dragArea.hovering').removeClass('hovering');
        area.addClass('hovering');

        // console.log(area);
      }
    })

    jQuery(window).mouseup(e => {
      delete this.dragStart;
      if (this.dragging) {
        const areaEl = jQuery(document.elementFromPoint(e.clientX, e.clientY)).closest('.dragArea');
        
        const area = this.areas[areaEl.attr('data-index')];
        // console.log(area);
        this.saveState('drop');
        area.action(this.dragging);
        this.dragging = false;
        jQuery('.dragArea.hovering').removeClass('hovering');
        jQuery('html').removeClass('dragging');


      }
    });

    const updateMetaPos = () => {
      this.updateMetaPos();

    }

    setInterval(() => {
      updateMetaPos();
    }, 10);
    updateMetaPos();
  }

  flatList() {
    return flatList(this.getBlocks());
  }

  lastPoses = {};
  updateMetaPos() {
    const lastPoses = this.lastPoses;
    for (const el of jQuery(this.wrapperEl.current).find('.meta')) {
      let top, left;
      const blockEl = jQuery(el).parent();
      const blockId = blockEl.attr('data-block-id');

      const editorEl = jQuery(blockEl).find('.editor');
      // // console.log(blockEl, editorEl);
      if (editorEl[0].lastChild) {
        const range = document.createRange();
        range.setStartBefore(editorEl[0]);
        range.setEndAfter(editorEl[0].lastChild || editorEl[0]);
        const rect = range.getBoundingClientRect();
        left = rect.left - editorEl.offset().left + rect.width + 8;
        top = rect.top - editorEl.offset().top;
      }
      else {
        left = 8;
        top = 0;
      }

      if (lastPoses[blockId] && (lastPoses[blockId].left != left || lastPoses[blockId].top != top)) {
        // console.log(left, top);
      }
      lastPoses[blockId] = {left, top};

      jQuery(el).css({
        position: 'absolute',
        left: left + editorEl.position().left,
        top: top + 30/2 - jQuery(el).outerHeight()/2 - 1,
        display: 'block',
      })

    }
  }

  constructTree(flatList) {
    return constructTree(flatList);
    const findParent = (fromI, currentIndentation) => {
      for (let i = fromI - 1; i >= 0; -- i) {
        const block = flatList[i];
        if (block.indentationLevel < currentIndentation) {
          return block;
        }
      }
      return null;
    }

    const rootBlocks = [];
    const blocksMap = {};
    for (let i = 0; i < flatList.length; ++ i) {
      const block = flatList[i];
      const newBlock = {
        _id: block._id,
        data: block.data,
        children: [],
        type: block.type,
        record: block.record,
        id: block.id,
        checked: block.checked,
      }
      blocksMap[block._id] = newBlock;
      const parent = findParent(i, block.indentationLevel);
      if (parent) {
        const parentBlock = blocksMap[parent._id];
        parentBlock.children.push(newBlock);
      } else {
        rootBlocks.push(newBlock);
      }
    }

    return rootBlocks;

  }


  areas = []

  updateAreas() {
    this.areas = [];
    const iterate = (list, parent) => {
      for (let i = 0; i < list.length; ++ i) {
        const block = list[i];
        // const comp = this.blockManager.components[block.id];
        const el = jQuery(`[data-block-id="${block._id}"]`)[0];

        // above
        {
          const offset = jQuery(el).offset();
          this.areas.push({
            block: parent,
            side: 'above',
            action: dropped => {
              removeBlock(this.getBlocks(), dropped._id);
              const parent = findBlockParent(this.getBlocks(), block._id);
              const blocks = parent ? parent.children : this.getBlocks();
              const index = blocks.indexOf(block);
              blocks.splice(index, 0, dropped);
              this.dragging = null;

              this.forceUpdate();
            },
            top: offset.top + 1,
            left: offset.left + 1,
            width: jQuery(el).width() - 2,
            height: jQuery(el).height()/2 - 2,
          })
        }

        // below
        {
          const offset = jQuery(el).offset();
          this.areas.push({
            block: parent,
            side: 'below',
            action: b => {
              removeBlock(this.getBlocks(), b._id);
              const parent = findBlockParent(this.getBlocks(), block._id);
              const blocks = parent ? parent.children : this.getBlocks();
              const index = blocks.indexOf(block);
              blocks.splice(index + 1, 0, b);
              this.dragging = null;
              this.forceUpdate();
            },

            top: offset.top + jQuery(el).height()/2 + 1,
            left: offset.left + 1,
            width: jQuery(el).width() - 2,
            height: jQuery(el).height()/2 - 2,
          });
        }

        // child
        if (!block.collapsed) {
          const offset = jQuery(el).offset();
          this.areas.push({
            block,
            side: 'below',
            action: b => {
              removeBlock(this.getBlocks(), b._id);
              const blocks = block.children || (block.children = X([]));
              blocks.splice(0, 0, b);
              this.dragging = null;
              this.forceUpdate();
            },

            top: offset.top + 30/2 + 1,
            left: offset.left + 1 + 24,
            width: jQuery(el).width() - 2 - 24,
            height: 30/2 - 2,
          });
          block.children && iterate(block.children, block);
        }

      }

    }
    iterate(this.getBlocks(), null);
  }

  dragging
  dragStart

  // rootEl = React.createRef<HTMLDivElement>();
  wrapperEl = {current: null}
  _tick = 0

  doTick() {
    this._tick ++;
    this.hideOverays = true;
    this.forceUpdate();
  }

  showMenu


  saveState(action, e?) {
    
    this.history.push({
      _id: XObject.id(),
      blocks: _.cloneDeep(x(this.getBlocks())),
      selection: getBlockSelection(),
      action,
      e,
    })
    
    console.log('saveState', this.historyIndex = this.history.length - 1, e?.key);
  }

  timerId
  undo() {
    if (!this.history[this.historyIndex]) {
      console.log('no undo history');
      return;
    }


    const entry = this.history[this.historyIndex];
    console.log('undo', entry.action);

    this.setBlocks(X(entry.blocks));
    const selection = entry.selection;
    this.doTick();
    this.historyIndex --;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      try {
        setCaretToBlockSelection(selection);
      }
      catch (e) {
        console.log(entry);
        console.log(selection);
        console.log(e);
      }
    }, 1);
  }

  menuState = X({
    index: 0,
  });

  currentBlock() {
    const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')
    const blockId = el.data('block-id');
    return findBlock(this.getBlocks(), blockId);;
  }


  extendEntity(entity) {
    const doc = db.notionDocuments.findById(this.props.docId);
    if (doc.scopedEntities) {
      entity.space = {
        type: ObjectType.page,
        id: this.props.docId,
      }
    }

  }
  presentMenu({ block, left, top, endLength, position, type }) {
    const cont = jQuery('<div />').css({
      position: 'absolute',
      left,
      top,
      zIndex: 9999999,
    });
    jQuery('body').append(cont);
    const root = ReactDOMClient.createRoot(cont[0]);

    const types = this.props.docId ? typesInScope({
      type: ObjectType.page,
      id: this.props.docId,
    }) : [];
    // console.log('types', types, getScopeTree({
      // type: ObjectType.page,
      
    // })

    root.render(
      <Menu
        entityTypes={types}
        baseEntity={this.props.entity}
        ref={this.menuRef}
        blockTypes={this.props.blockTypes} 
        db={this.props.database && new DB(this.props.database)}
        block={block}
        type={type}
        extendEntity={entity => this.extendEntity(entity)}
      />
    );
    this.showMenu = {
      root,
      cont,
      selection: getBlockSelection(),
      position,
      endLength,
    }
  }

  hideOverays = false;

  ignoreEnter

  letNextInsert

  position

  hideOverlay() {
    this.hideOverays = true;
    jQuery(this.wrapperEl.current).addClass('hideOverlays');;
  }

  restoreSelection
  componentDidUpdate(): void {
    if (this.selection) {
      setCaretToBlockSelection(this.selection);
      this.selection = null;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.blocks != this.props.blocks;
  }

  save = (from) => {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');
    const block = findBlock(this.getBlocks(), blockId);
    if (block.type == 'code') return;

    if (!block) {
      console.log('no block found', blockId, el, window.getSelection().focusNode, from);
      return;
    }

    const editorEl = el.find('.editor');

    if (editorEl.html() == '<br>') {
      editorEl.html('');
    }

    block.data = extractFromEl(editorEl[0]);

    if (block.id) {
      const entity = db.entities.findById(block.id);
      if (entity) {
        XObject.withPass({ internal: block._id }, () => {
          entity.name = dataToString(block.data || []);
        });
      }
    }
    else if (block.record) {
      const db = new DB(this.props.database);
      const titleCol = db.titleCol();
      const record = db.getRecord(block.record);
      if (record) {
        XObject.withPass({ internal: block._id }, () => {
          record.data[titleCol._id] = X(x(block.data))[0] || '';
        });
      }
    }
    else if (block.dataBinding && block.attrBinding) {
      const e = db.entities.findById(this.props.entity);
      if (e) {
        let value;

        if (block.pullEntities) {
          value = collectEntitiesGood([block]).map(e => e.entity);
        }
        else {
          value = x(block.data).find(d => d[1] == 'capture')?.[0];
        }

        if (e.attributes) {
          e.attributes[block.attrBinding] = value;
        }
        else {
          e.attributes = X({
            [block.attrBinding]: value
          });
        }
      }
    }

    let parent = findBlockParent(this.getBlocks(), blockId);
    while (parent) {
      if (parent.pullEntities) {
        const e = db.entities.findById(this.props.entity);

        const value = collectEntitiesGood([parent]).map(e => e.entity);
        if (e.attributes) {
          e.attributes[parent.attrBinding] = value;
        }
        else {
          e.attributes = X({
            [parent.attrBinding]: value
          });
        }


      }

      parent = findBlockParent(this.getBlocks(), parent._id);
    }
  }

  comp
  currentScroll
  selection

  menuPos

  getBlock(id) {
    return findBlock(this.getBlocks(), id);
  }

  closeMenu() {
    this.menuRef.current.close(() => {
      this.showMenu.root.unmount();
      this.showMenu.cont.remove();
      delete this.showMenu;
    })
  }

  initial
  render(Container?) {
    return (
      <ThemeProvider theme={{ mode: 'light' }}>
        <Container
          data-value-points={this.props.valuePoints}
          className={classNames({
            inline: this.props.inline,
            mobile: isMobile(),
          })}
        >
          <>
            {/* <PositionInspector blocks={this.getBlocks()} /> */}
            <div
              key={this._tick}
              className={classNames('wrapper', {
                hideOverlays: this.hideOverays,
                inline: this.props.inline,
              })}
              suppressContentEditableWarning
              contentEditable
              spellCheck={false}
              ref={e => {
                if (e) {
                  this.wrapperEl.current = e;
                  e.scrollTop = this.currentScroll;
                }
              }}
              onMouseMove={e => {
                this.hideOverays = false;
                jQuery(e.currentTarget).removeClass('hideOverlays');
              }}
              onKeyDown={async e => {
                if (isOnTitle()) return;
                const hasSelection = !window.getSelection().isCollapsed;
                const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')
                const blockId = el.data('block-id');

                this.position = getPositionInBlock();

                const block = findBlock(this.getBlocks(), blockId);
                if (block.type == 'code') return;
                if (!block) {
                  console.log('no block found', blockId, el, window.getSelection().focusNode);
                  return;
                }

                if (e.key == 'z' && e.metaKey) {
                  e.preventDefault();
                  this.undo();
                }
                else if (e.key == '/' || e.key == '@') {
                  let left, top, height;
                  const position = getPositionInBlock();
                  this.menuPos = position;
                  if (position > 0) {
                    ({ left, top, height } = window.getSelection().getRangeAt(0).getBoundingClientRect());

                  }
                  else {
                    left = el.offset().left;
                    top = el.offset().top;
                    height = el.height();
                  }

                  this.presentMenu({
                    left,
                    top: top + height,
                    position,
                    endLength: el.children('[data-type="blockData"]').text().length - position,
                    block,
                    type: e.key == '/' ? 'command' : 'mention',
                  });
                  this.letNextInsert = true;
                }
                else if (e.key === 'Enter') {
                  e.preventDefault();
                  if (this.showMenu) {
                    const option = this.menuRef.current.enter();
                    if (option) {
                      this.saveState('action');
                      await option.action(block, this.menuPos);
                      delete this.menuPos;
                    }

                    const selection = this.showMenu.selection;
                    this.ignoreEnter = true;
                    
                    this.doTick();
                    setTimeout(() => {
                      setCaretToBlockSelection(selection);
                    }, 1)


                    this.closeMenu();

                  }
                  else if (isOnTitle()) {

                  }
                  else {
                    let currentBlock;
                    this.saveState('enter');
                    if (hasSelection && isMultiBlockSelection()) {
                      const firstBlock = getSelectedBlockIds()[0];
                      const pos = getBlockSelection().find(b => b.blockId == firstBlock).position;
                      this.deleteSelection();
                      currentBlock = executeEnter(this.getBlocks(), this.props.blockTypes, firstBlock, pos, e, this.props.entity, entity => this.extendEntity(entity));
                    }
                    else {
                      currentBlock = executeEnter(this.getBlocks(), this.props.blockTypes, blockId, getPositionInBlock(), e, this.props.entity, entity => this.extendEntity(entity));
                    }

                    /*if (pos == 0) {
                      newBlock = XObject.obj({
                        data: []
                      });

                      const parent = findBlockParent(this.getBlocks(), blockId);
      
                      let blocks;
                      if (parent) {
                        blocks = parent.children;
                      }
                      else {
                        blocks = this.getBlocks();

                      }

                      blocks.splice(blocks.indexOf(block), 0, newBlock);
                      this.forceUpdate();
                    }
                    else {
                      const position = pos;
                      const length = dataLength(block.data);
                      const firstPart = sliceData(block.data, 0, position);
                      const secondPart = sliceData(block.data, position, length);
                      block.data = X(firstPart);
                      
                      newBlock = XObject.obj({
                        data: secondPart
                      });
      
                      if (!e.altKey && block.type) {
                        if (!block.type.startsWith('heading')) {
                          newBlock.type = block.type;
                        }
                        const blockType = this.props.blockTypes.find(b => b._id == block.type);
                        if (block.id) {
                          const entity = XObject.obj({
                            type: blockType.entityType,
                          });
                          newBlock.id = entity._id;
                          createEntity(entity);
                        }
                      }
      
                      if (block.children?.length) {
                        block.children.unshift(newBlock);  
                      }
                      else {
                        const parent = findBlockParent(this.getBlocks(), blockId);
          
                        let blocks;
                        if (parent) {
                          blocks = parent.children;
                        }
                        else {
                          blocks = this.getBlocks();
      
                        }
            
                        blocks.splice(blocks.indexOf(block) + 1, 0, newBlock);
                      }
                    }*/

                    this.forceUpdate();
                    console.log('poop');
                    setTimeout(() => {
                      setCaretToBlockSelection([
                        { blockId: currentBlock._id, position: 0 },
                        { blockId: currentBlock._id, position: 0}
                      ]);

                      jQuery(`[data-block-id="${currentBlock._id}"]`)[0].scrollIntoViewIfNeeded(false);
                    }, 0);
                  }

                }
                else if (e.key == 'Tab') {
                  this.saveState('indentation');
                  const blockSelection = getBlockSelection();
                  this.hideOverlay();

                  e.preventDefault();
                  if (hasSelection) {
                    const el = document.createElement('div');
                    el.innerHTML = getSelectionHtml();
                    const blockEls = jQuery(el).find('[data-block-id]');
                    const blockIds = blockEls.toArray().map(el => jQuery(el).data('block-id')).filter(id => {
                      return e.shiftKey ? canUnindentBlock(this.getBlocks(), id) : canIndentBlock(this.getBlocks(), id);
                    });

                    let lowest;
                    const indentLevels = {};
                    for (const blockId of blockIds) {
                      indentLevels[blockId] = getBlockIndentation(this.getBlocks(), blockId);
                      if (lowest === undefined || indentLevels[blockId] < lowest) {
                        lowest = indentLevels[blockId];
                      }
                    }

                    if (e.shiftKey) {
                      blockIds.reverse();
                      for (const blockId of blockIds) {
                        if (indentLevels[blockId] === lowest) {
                          unindentBlock(this.getBlocks(), blockId);
                        }
                      }
                    }
                    else {
                      for (const blockId of blockIds) {
                        if (indentLevels[blockId] === lowest) {
                          indentBlock(this.getBlocks(), blockId);
                        }
                      }
                    }
                  }
                  else {
                    if (e.shiftKey) {
                      unindentBlock(this.getBlocks(), blockId);
                      this.forceUpdate();
                    }
                    else {
                      indentBlock(this.getBlocks(), blockId);
                    }
                  }
                  this.forceUpdate();

                  setTimeout(() => {
                    setCaretToBlockSelection(blockSelection);

                  }, 1);
                  

                }
                else if (e.key === 'ArrowDown') {
                  if (this.showMenu) {
                    this.menuRef.current.down();
                    e.preventDefault();
                  }
                }
                else if (e.key === 'ArrowUp') {
                  if (this.showMenu) {
                    this.menuRef.current.up();
                    e.preventDefault();

                  }
                }
                else if (e.key == 'ArrowLeft') {
                  if (getPositionInBlock() == 0) {
                    e.preventDefault();
                    const flatList = this.flatList();
                    const index = flatList.findIndex(b => b._id == blockId);

                    const prevBlockId = flatList[index - 1]?._id;

                    const prevBlock = findBlock(this.getBlocks(), prevBlockId);
                    const length = dataLength(prevBlock.data);

                    const pos = {
                      blockId: prevBlock._id,
                      position: length,
                    }
                    setCaretToBlockSelection([pos, pos]);

                  }
                }
                else if (e.key == 'ArrowRight') {
                  if (getPositionInBlock() == dataLength(block.data)) {
                    e.preventDefault();
                    const flatList = this.flatList();
                    const index = flatList.findIndex(b => b._id == blockId);
                    
                    const nextBlockId = flatList[index + 1]?._id;

                    const pos = {
                      blockId: nextBlockId,
                      position: 0,
                    }
                    setCaretToBlockSelection([pos, pos]);
                  }
                }
                else if (e.key == 'Backspace') {
                  if (hasSelection) {
                    console.log('delete selection');
                    e.preventDefault();
                    this.saveState('deleteSelection');
                    if (this.deleteSelection()) {
                      this.save('delete');
                    }
                    this.doTick();
                  }
                  else if (this.position == 0) {
                    if (block.type) {
                      console.log('remove type', el, blockId, block);
                      const blockSelection = getBlockSelection();

                      block.type = null;
                      this.doTick();
                      e.preventDefault();
                      setTimeout(() => {
                        setCaretToBlockSelection(blockSelection);
                      }, 5);

                    }
                    else {
                      this.saveState('backspace');

                      let action: 'unindent' | 'merge' = null;
                      if (getBlockIndentation(this.getBlocks(), blockId) == 0) {
                        action = 'merge';
                      }
                      else {
                        const parent = findBlockParent(this.getBlocks(), blockId);
                        const positionInParent = parent?.children?.indexOf(block);
                        if (positionInParent == 0) {
                          if (parent.children.length > 1) {
                            action = 'merge';
                          }
                          else if (parent.children.length == 1) {
                            action = 'unindent';
                          }
                        }
                        else {
                          const flatList = this.flatList();

                          const index = flatList.findIndex(b => b._id == blockId);
                          const flatBlock = flatList[index];
                          const prevFlatBlock = flatList[index - 1];
                          if (prevFlatBlock && prevFlatBlock.indentationLevel == flatBlock.indentationLevel) {
                            action = 'merge';
                          }
                          else {
                            action = 'unindent';
                          }
                        }
                      }

                      if (action == 'unindent') {
                        console.log('unindent')
                        const blockSelection = getBlockSelection();
                        e.preventDefault();
                        unindentBlock(this.getBlocks(), blockId);
                        this.doTick();
                        setTimeout(() => {
                          setCaretToBlockSelection(blockSelection);
                        }, 5);
                      }
                      else if (action == 'merge') {
                        console.log('remove block')
                        e.preventDefault();

                        const flatList = this.flatList();
                        const index = flatList.findIndex(b => b._id == blockId);
                        const prevBlockId = flatList[index - 1]?._id;
                        if (prevBlockId) {
                          const prevBlock = findBlock(this.getBlocks(), prevBlockId);
                          const length = dataLength(prevBlock.data);
                          prevBlock.data = X(concatData(prevBlock.data, block.data));

                          if (findBlockParent(this.getBlocks(), blockId) != prevBlock) {
                            prevBlock.children = block.children;
                          }
                          else if (block.children) {
                            prevBlock.children = X(x(block.children).concat(x(prevBlock.children)));
                          }
                          const pos = {
                            blockId: prevBlock._id,
                            position: length,
                          }
                          removeBlock(this.getBlocks(), blockId);
                          this.doTick();
                          setTimeout(() => {
                            console.log([pos, pos]);
                            setCaretToBlockSelection([pos, pos]);
                          }, 1)

                        }

                      }

                    }
                  }
                  else {
                    if (!_.isNil(this.menuPos) && _.isEqual(getPositionInBlock() - 1, this.menuPos)) {
                      this.closeMenu();
                    }
                    this.saveState('delete');
                  }
                }
                else {
                  if (e.key.length == 1 && !e.metaKey) {
                    if (hasSelection && isMultiBlockSelection()) {
                      e.preventDefault();
                      this.saveState('deleteSelection');
                      this.deleteSelection(e.key);
                      this.doTick();
                    }
                    else {
                      // this.saveState('insert', e);
                    }
                  }
                  // console.log(block._id, editorEl[0])
                }

                // if (!e.metaKey) {
                //   if (!window.getSelection().isCollapsed) {
                //     setTimeout(() => {
                //       this.resyncDataWithDom();
                //     }, 0);
                    
                //   }
        
                // }
              }}
              onKeyUp={e => {
                const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')
                const blockId = el.data('block-id');
                const block = findBlock(this.getBlocks(), blockId);
                // console.log('keyup', e.key, blockId, block, window.getSelection().focusNode);
                // e.preventDefault();
                // return;

                /*const save = () => {
                  if (!block) {
                    console.log('no block found', blockId, el, window.getSelection().focusNode);
                    return;
                  }
                  // console.log('save', blockId, block)
                  const editorEl = el.find('.editor');
                  // console.log(editorEl[0]);

                  if (editorEl.html() == '<br>') {
                    editorEl.html('');
                  }

                  block.data = extractFromEl(editorEl[0]);
                  if (block.id) {
                    const entity = db.entities.findById(block.id);
                    if (entity) {
                      XObject.withPass({ internal: block._id }, () => {
                        entity.name = dataToString(block.data || []);
                        // console.log('set name', entity.name)
                      });
                    }
                  }
                  else if (block.record) {
                    const db = new DB(this.props.database);
                    const titleCol = db.titleCol();
                    const record = db.getRecord(block.record);
                    if (record) {
                      XObject.withPass({ internal: block._id }, () => {
                        record.data[titleCol._id] = X(x(block.data))[0] || '';
                      });
                    }
                  }
                }*/

                if (e.key == 'Escape') {
                  if (this.showMenu) {
                    this.save('input');
                    this.menuRef.current.close(() => {
                      this.showMenu.root.unmount();
                      this.showMenu.cont.remove();
                      delete this.showMenu;  
                    });
                  }
                }

                else if (!e.metaKey) {
                  if (!this.showMenu) {
                    if (e.key == 'Enter' && this.ignoreEnter) {
                      delete this.ignoreEnter;
                      e.preventDefault();
                    }
                    // else {
                    //   this.save();
                    // }
                  }
                  else if (this.letNextInsert) {
                    delete this.letNextInsert;
                  }
                  else {
                    const text = el.children('[data-type="blockData"]').text();
                    console.log(text, this.showMenu.position, this.showMenu.endLength, text.slice(this.showMenu.position + 1, text.length - this.showMenu.endLength));
                    this.menuRef.current.setFilter(text.slice(this.showMenu.position + 1, text.length - this.showMenu.endLength));
                  }
                }
              }}
              onScroll={e => {
                this.currentScroll = (e.target as any).scrollTop;
              }}
            >
              {this.props.setTitle && <div data-type="title" className="title">{this.title}</div>}
              {!this.props.onlyShowTitle && (
                <>
                  <div
                    ref={e => {
                      if (e){ 
                        if (!this.initial) {
                          this.updateMetaPos();          
                          this.initial = true;
                        }
                      }
                    }}
                    key={this._tick}
                  >
                    {/* {this.tick + this.props.tick} */}
                    {this.getBlocks().map((b, i) => renderBlock(b, {
                      onMouseDownGrip: (e, b) => {
                        this.dragStart = b;
                        e.preventDefault();
                      },
                      draggingId: this.dragging?._id,

                      beforeChange: () => {
                        this.saveState('beforeChange');
                      },
                      changed: () => {
                        console.log('changed', this._tick)
                        // this.tick++;
                        this.forceUpdate()
                      },
                      // getEntity: (id) => {
                      //   return this.state.entities.find(e => e._id == id);
                      // },
                      docId: this.props.docId,
                      blockTypes: this.props.blockTypes,
                      db: new DB(this.props.database),
                      activeBlock: this.state.activeBlock,
                      onClickEntityDoc: id => {
                        this.context.navigate?.({
                          type: 'entity',
                          id,
                        })
                      },
                      onClickAddBlock: (e, block) => {
                        const parent = findBlockParent(this.getBlocks(), block._id);
                        let blocks;
                        if (parent) {
                          blocks = parent.children;
                        }
                        else {
                          blocks = this.getBlocks();

                        }

                        const newBlock = XObject.obj();
                        const index = blocks.indexOf(block);
                        blocks.splice(e.altKey ? index : index + 1, 0, newBlock);
                        this.forceUpdate();

                      },
                      onContextMenu: (e, block) => {
                        e.preventDefault();
                        showContextMenu(e, [
                          {
                            text: 'Delete',
                            onClick: () => {
                              removeBlock(this.getBlocks(), block._id);
                              this.forceUpdate();
                            },
                          }
                        ])
                      }
                    }))}
                  </div>
                  {this.showMenu && (
                    <>
                      <div
                        className="menu"
                        style={{
                          position: 'absolute',
                          top: this.showMenu.top,
                          left: this.showMenu.left,
                        }}
                      >

                      </div>
                    </>
                  )}
                  {this.dragging && this.areas.map((area, i) => {
                    return (
                      <div
                        contentEditable={false}
                        key={i}
                        data-index={i}
                        className={classNames('dragArea', area.side)}
                        style={{
                          position: 'fixed',
                          top: area.top,
                          left: area.left,
                          width: area.width,
                          height: area.height,
                          zIndex: 9999999999,
                        }}
                        // onMouseDown={e => {
                        //   e.preventDefault();
                        //   e.stopPropagation();
                        //   console.log('area', area);
                        //   area.action(this.dragging);
                        // }}
                      ></div>
                    )
                  })}
                </>
              )}
            </div>
            <div className="inspector">
              <button
                onClick={e => {
                  this.doTick();
                }}
              >Rerender</button>
              {/* <PositionInspector blocks={this.getBlocks()} /> */}
              

              {/* <EntityBlockTable
                activeBlock={() => {
                  return this.state.activeBlock && findBlock(this.getBlocks(), this.state.activeBlock);
                }}
                blockType={() => {
                  const block = this.state.activeBlock && findBlock(this.getBlocks(), this.state.activeBlock);
                  if (block?.type) {
                    return this.props.blockTypes.find(t => t._id == block.type);
                  }
                }}
                blocks={this.getBlocks()}
              /> */}
              {/* <Inspector block={() => {
                return this.state.activeBlock && findBlock(this.getBlocks(), this.state.activeBlock);
              }}/> */}
            </div>
          </>
        </Container>
      </ThemeProvider>
    );
  }
}
