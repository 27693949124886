import { Type, expandToText } from "../components/richTextHelpers";
import { db } from "../db";
import { ComponentValueWrapper } from "./ComponentValueWrapper";

export const types = {
  entity: {
    type: Type.atomic,
    html: (id) => {
      const entity = db.entities.findById(id);
      return `<span class="--entity--" contenteditable="false" data-type="entity" data-entity-data="${btoa(JSON.stringify(id))}">${entity.name}</span>`;
    },
    text: (id) => {
      const entity = db.entities.findById(id);
      return expandToText({ types }, entity.name);
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
      return [data, 'entity'];
    }
  },
  code: {
    type: Type.atomic,
    html: (id, comps, args) => {
      const n = Math.random();
      comps[n] = {
        mount: <ComponentValueWrapper component={id.component} args={args} />,
      };
      return `<span class="--code--" contenteditable="false" data-type="code" data-code-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}" />${!id.component ? `<span style="color: red;">Component not found</span>` : ''}</span>`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-code-data')));
      return [data, 'code'];
    }
  },
  capture: {
    type: Type.text,
    html: text => {
      return `<span class="--capture--" contenteditable="true" data-type="capture">${text}</span>`;;
    },
    part: el => {
      return [el.textContent, 'capture'];
    }
  },
};
