import React, { Component } from 'react';
import { component } from '../component2';
import { ObjectDisplay } from './TypeEditor';
import { objectName } from "./objectFuncs";
import { XInit } from '../XObject';
import classNames from 'classnames';
import { styled } from '../component';
import { allObjects } from './allObjects';

@component
export class ObjectPicker extends Component<{
  showAllEmpty?;
  _onSelect?;
  type?;
}> {
  static styles = styled.div`
    .selected {
      font-weight: bold;
    }
  `;
  state = XInit(class {
    filter = '';
    selected = 0;
  });
  render() {
    const objects = allObjects({ includeGlobal: true });
    const filtered = (!this.props.showAllEmpty && !this.state.filter) ? [] : objects.filter(obj => {
      const name = objectName(obj);
      if (!name) return false;
      if (this.props.type && obj.type !== this.props.type) {
        return false;
      }
      return name.toLowerCase().includes(this.state.filter.toLowerCase());
    });

    return (
      <>
        <input type="text" defaultValue={this.state.filter} onChange={e => {
          this.state.filter = e.target.value;
          this.state.selected = 0;
        }}
          onKeyDown={e => {
            // up and down with wrapping
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              this.state.selected = (this.state.selected - 1 + filtered.length) % filtered.length;
            }
            else if (e.key === 'ArrowDown') {
              e.preventDefault();
              this.state.selected = (this.state.selected + 1) % filtered.length;
            }
            else if (e.key === 'Enter') {
              e.preventDefault();
              this.props._onSelect(filtered[this.state.selected], () => {
                this.state.filter = '';
                this.state.selected = 0;
                e.target['value'] = '';
              });
            }
          }} />

        <ul>
          {filtered.map((obj, i) => {
            return (
              <li key={obj.id}
                className={classNames({
                  selected: i === this.state.selected,
                })}
              >
                <ObjectDisplay obj={obj} showPath />
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}
