import { currentSpace, triggerInspectObject } from './osHelpers';


export function inspectObj(obj, openSidebar=false) {
  triggerInspectObject({
    type: 'e57eb0f2-4a72-5002-b64e-11a7ba64970a',
    args: {
      obj,
    }
  });

  if (openSidebar) {
    currentSpace().sideBar = true;
  }
}
