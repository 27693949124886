import React from 'react';
import { Component } from 'react';
import { component, styled } from '../component2';
import { db } from '../db';
import { ObjectPageFrame } from './ObjectPageFrame';
import {Rnd} from 'react-rnd';
import { X, XInit, XObject, x } from '../XObject';
import { ObjectPicker } from './ObjectPicker';
import { ObjectDisplay } from './TypeEditor';
import { NotionDocumentWindow } from '../windows/NotionDocumentWindow';
import { ObjectType } from '../types/QueryParentType';
import { createQuery } from '../etc/queryFuncs';
import { ViewQueryPage } from './ViewQuery';
import { inspectObj } from '../inspectObj';
import { Svg } from './Svg';
import { MindMap, findNode } from '../MindMap';
import { registerInspectObjectHandler, triggerInspectObject } from '../osHelpers';

registerInspectObjectHandler('c2773882-7e8e-5081-903e-78913fa658a6', {
  render: (args) => {
    const canvas = db.canvases.findById(args.canvas);
    const node = findNode(args.node, canvas.state2.nodes);
    return (
      <>
        <button
          onClick={() => {
            console.log(x(args), x(node));
          }}
        >Debug</button>


        <button
          onClick={() => {
            const doc = XObject.obj({
              name: 'new page',
              blocks: [],
              showInitView: true,
            });
            db.notionDocuments.push(doc);

            node.ref = {
              type: ObjectType.page,
              id: doc._id,
            }


            node.type = 'object';

          }}
        >
          Make page
        </button>
      </>
    )
  }
});


@component
export class CanvasObject extends Component<{ object: { ref } }> {
  state = X({});
  render() {
    const obj = this.props.object;
    let d;
    if (obj.ref.type == ObjectType.page) {
      d = (
        <NotionDocumentWindow
          window={{
            notionDocument: obj.ref.id
          }}
          insidePositionContext
        />
      );
    }
    else if (obj.ref.type == ObjectType.query) {
      d = <ViewQueryPage id={obj.ref.id} state={this.state} entity={null} />;
    }
    else {
      console.log(x(obj));
      d = 'unknown type';
    }
    return d;
  }
}

@component
export class CanvasView extends Component<{ pane }> {
  state = XInit(class {
    x = 0
    y = 0
  })
  static styles = styled.div`
    > ${ObjectPageFrame} > ${ObjectPageFrame.t.content} {
      padding: 0;
      background-color: #f7f7f7;

      > .object {
        &:not(:hover) .dragHandle {
          opacity: 0;
        }

        > .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: auto;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          box-shadow: 0 0 1px rgba(0,0,0,0.1);
          background: white;

        }
      }

      > .tools {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid black;
        z-index: 999999999;
        background-color: white;;
      }
    }

    .dragHandle {
      opacity: 1;
      transition: opacity 0.2s;
      position: absolute;
      top: 6px;
      left: -18px;
      cursor: grab;
      svg {
        fill: #c1c1c1;
      }
      /* width: 20px; */
      /* height: 20px; */
      /* border-bottom-right-radius: 8px; */
      /* background: black; */
    }
  `;
  render() {

    const ref = React.createRef<any>();
    const canvas = db.canvases.findById(this.props.pane.id);
    const scale = 1;

    let c;

    /*if (canvas.version != 'ab599af3-6f75-54c2-bbe9-aa25055959e1') {
      c = 
        <>
          {canvas.objects?.map?.(obj => {
            return (
              <Rnd
                className="object"
                key={obj._id}
                style={{
                  // transform: `scale(${1/scale})`,
                }}
                dragHandleClassName="dragHandle"
                      
                bounds={ref.current}
                // size={{ width: 500,  height: 500 }}
                size={{
                  width: obj.frame.width,
                  height: obj.frame.height,
                }}
                scale={scale}
                position={{
                  x: obj.frame.x,
                  y: obj.frame.y,
                }}
                onDragStop={(e, d) => {
                  obj.frame.x = d.x;
                  obj.frame.y = d.y;
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  obj.frame.width = ref.style.width;
                  obj.frame.height = ref.style.height;
                }}
              >
                <div className="content">
                  <CanvasObject object={obj} />
                </div>
                
                <div className="dragHandle" onMouseDown={() => {
                  inspectObj(obj.ref)
                }}>
                  <Svg name="grip" />
                </div>
              </Rnd>
            );
          })}
        </>
      
    }
    else {*/
      c = (
        <>
          <MindMap
            window={canvas}
            onSelect={node => {
              console.log(x(node));
              triggerInspectObject({
                type: 'c2773882-7e8e-5081-903e-78913fa658a6',
                args: {
                  canvas: canvas._id,
                  node: node._id,
                }
              })
            }}
          />
        </>
      )
    // }
    return (
      <>
        <ObjectPageFrame
          icon={'icons8-canvas'}
          path={canvas.name}
        >
          {/* <div className="tools">
            {canvas.objects?.map?.(obj => {
              return (
                <div key={obj._id}>
                  <ObjectDisplay obj={obj.ref} showPath />
                </div>
              )
            })}

            <select
              onChange={e => {
                let ref;
                if (e.target.value == 'page') {
                  const obj = XObject.obj();
                  db.notionDocuments.push(obj);
                  ref = {
                    type: ObjectType.page,
                    id: obj._id,
                  }
                }
                else if (e.target.value == 'query') {
                  const q = createQuery({});
                  ref = {
                    type: ObjectType.query,
                    id: q._id,
                  }
                }

                XObject.push(canvas, 'objects', XObject.obj({
                  ref,
                  frame: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                  }
                }));  


                e.target.value = '';
              }}
            >
              <option />
              <option value="page">Page</option>
              <option value="query">Query</option>
            </select>

            <ObjectPicker 
              _onSelect={obj => {
                XObject.push(canvas, 'objects', XObject.obj({
                  ref: obj,
                  frame: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                  }
                }));
              }}
            />
          </div> */}


          {c}


        </ObjectPageFrame>
      </>
    )
  }
}
