import { db } from './db';
// import { openWindow } from './osHelpers';
import { x, XObject } from './XObject';
import { WindowType } from './etc/WindowType';
import { appState } from './etc/appState';
import { entityQueries } from './components/EntityInspect';
import { entityDisplayName } from './components/entityDisplayName';
import { createQuery, describeQuery } from './etc/queryFuncs';
import { cssImg } from './img';
import { getSidebar } from './etc/getSidebar';
import { SidebarItemType } from './types/SidebarItemType';
import { ObjectType } from './types/QueryParentType';
import { collectEntities } from "./etc/collectEntities";
import { PaneType } from './types/PaneType';
import { iconColor } from "./color";
import { resumeMode } from './resumeMode';
import { inspectObj } from './inspectObj';
import { SidebarItem } from './components/Sidebar';

class SpaceSidebarItem extends SidebarItem {
  space;
  constructor(data) {
    super(data);
    this.space = db.spaces.findById(data.space);
  }
  getId() {
    return this.data._id;
  }
  getTitle() {
    return this.space?.name;
  }

  getNav() {
    return {
      type: 'spaceEntities',
      space: this.space?._id,
    };
  }

  getIcon() {
    return cssImg('box', {
      '#000000': iconColor,
    });
  }

  getChildren() {
    return this.space?.queries?.map?.(q => {
      const query = db.queries.findById(q);
      if (!query)
        return null;
      return new QuerySidebarItem({
        id: query._id,
      });
    })?.filter?.(Boolean) || [];
  }

  contextMenu() {
    return [
      {
        text: 'Add query',
        onClick: () => {
          const query = XObject.obj({
            name: 'New query',
            parentType: ObjectType.space,
            parent: this.space._id,
            views: [],
          });
          db.queries.push(query);
          XObject.push(this.space, 'queries', query._id);
        },
      },
      // {
      //   text: 'Edit',
      //   onClick: () => {
      //     openWindow({
      //       type: WindowType.SpaceWindow,
      //       space: this.space._id,
      //     });
      //   }
      // }
    ];
  }
}
class QuerySidebarItem extends SidebarItem {
  query;
  constructor(data) {
    super(data);
    this.query = db.queries.findById(data.id);
  }
  getChildren(): SidebarItem[] {
    const children = db.queries.filter(q => q.parent == this.data.id);
    return children.map(x => new QuerySidebarItem({
      id: x._id,
    }));
  }

  getIcon() {
    return cssImg('database', {
      '#000000': iconColor,
    });
  }

  getId() {
    return this.data.id;
  }

  getTitle(): string {
    if (!this.query)
      return '---';
    return this.query.name || describeQuery(this.query.query);
  }

  getNav() {
    return {
      type: 'query',
      id: this.data.id,
      entity: this.data.entity,
    };
  }

  contextMenu() {
    const query = this.query;
    return [
      {
        text: 'Edit',
        onClick: () => {
          inspectObj({
            type: ObjectType.query,
            id: query._id,
          }, true);
        }
      },
      {
        text: 'Delete',
        onClick: () => {
          db.queries.splice(db.queries.indexOf(query), 1);
          if (this.data.entity) {
            const entity = db.entities.findById(this.data.entity);
            entity.queries.splice(entity.queries.findIndex(x => x.query == query._id), 1);
          }
        }
      },
      {
        text: 'Add subquery',
        onClick: () => {
          db.queries.push(XObject.obj({
            name: 'New query',
            views: [],
            parent: query._id,
          }));
        }
      },
      // {
      //   text: 'Add to sidebar',
      //   onClick: () => {
      //     getSidebar().children.push(XObject.obj({
      //       type: SidebarItemType.query,
      //       id: query._id,
      //     }));
      //   }
      // }
    ];
  }
}
class PageSidebarItem extends SidebarItem {
  page;
  constructor(data) {
    super(data);
    if (this.data.entity) {
      const entity = db.entities.findById(data.entity);
      this.page = entity.documents.find(doc => doc._id == data.id);

    }
    else {
      this.page = db.notionDocuments.findById(data.id);
    }
  }
  getId(): string {
    return this.data.id;
  }
  getTitle(): string {
    return this.page?.name || 'Untitled';
  }

  getIcon() {
    return cssImg('icons8-page', {
      '#000000': iconColor,
    });
  }

  getChildren() {
    if (!this.page)
      return [];
    const entities = collectEntities(this.page.content || this.page.blocks || []);

    if (entities.length) {
      return [
        new PageEntitiesSidebarItem({
          ...this.data,
        }),
      ];
    }
    else {
      return [];
    }
  }

  contextMenu() {
    return [
      // {
      //   text: 'Edit',
      //   onClick: () => {
      //     openWindow({
      //       type: WindowType.EditDocument,
      //       document: this.page._id,
      //     });
      //   }
      // }
    ];
  }

  getNav() {
    if (!this.page)
      return {};
    if (this.data.entity) {
      return {
        type: '146a4264-a507-5fb9-b7d3-e59ee00fb050',
        entity: this.data.entity,
        document: this.data.id,
      };
    }
    else {
      return {
        type: 'page',
        id: this.page._id,
      };
    }
  }
}
class PageEntitiesSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
  }
  getIcon() {
    return cssImg('tesseract', {
      '#000000': iconColor,
    });
  }

  getTitle() {
    return 'Entities';
  }

  getNav() {
    return {
      type: PaneType.pageEntities,
      ...this.data,
    };
  }
  getChildren() {
    if (this.data.entity) {
      const entity = db.entities.findById(this.data.entity);
      const doc = entity.documents.find(doc => doc._id == this.data.id);
      return (doc.queries?.map?.(q => {
        const query = db.queries.findById(q);
        if (!query)
          return null;
        return new QuerySidebarItem({
          id: query._id,
        });
      }) || []).filter(Boolean);
    }
    else {
      const doc = db.notionDocuments.findById(this.data.id);
      return (doc.queries?.map?.(q => {
        const query = db.queries.findById(q);
        if (!query)
          return null;
        return new QuerySidebarItem({
          id: query._id,
        });
      }) || []).filter(Boolean);
    }
  }
  contextMenu() {
    const entity = db.entities.findById(this.data.entity);
    const doc = entity.documents.find(doc => doc._id == this.data.id);
    return [
      {
        text: 'Add query',
        onClick: () => {
          const query = XObject.obj({
            name: 'New query',
            parentType: ObjectType.page,
            parent: [this.data.entity, this.data.id],
            views: [],
          });
          db.queries.push(query);
          XObject.push(doc, 'queries', query._id);
        }
      }
    ];
  }
}
class EntitySidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.entity = db.entities.findById(data.id);
  }
  entity;
  getChildren(): SidebarItem[] {
    if (!this.entity)
      return [];
    const children = [];
    if (this.entity.documents) {
      for (const doc of this.entity.documents) {
        children.push(new PageSidebarItem({
          entity: this.entity._id,
          id: doc._id,
        }));
      }
    }

    const pages = db.notionDocuments.filter(doc => doc.parent?.id == this.entity._id);
    for (const doc of pages) {
      children.push(new PageSidebarItem({
        id: doc._id,
      }));
    }

    const queries = entityQueries(this.entity._id);

    for (const query of queries) {
      children.push(new QuerySidebarItem({
        id: query,
        entity: this.entity._id,
      }));
    }

    return children;
  }

  getIcon() {
    return cssImg('tesseract', {
      '#000000': iconColor,
    });
  }

  getId() {
    return this.entity?._id;
  }

  getTitle() {
    return this.entity && entityDisplayName(this.entity._id);
  }

  contextMenu() {
    return [
      {
        text: 'Add page',
        onClick: () => {
          db.notionDocuments.push(XObject.obj({
            parent: {
              type: ObjectType.entity,
              id: this.entity._id,
            },
            name: 'new doc',
            blocks: [],
          }));

        }
      },
      {
        text: 'Add query',
        onClick: () => {
          const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', this.entity._id], null, true);
          XObject.push(this.entity, 'queries', XObject.obj({
            query: query._id,
          }));

        }
      }
    ];
  }

  onClick() {
    appState.inspecting = {
      type: 'entity',
      id: this.entity._id,
    };
  }

  getNav() {

    return {
      type: 'entity',
      id: this.entity?._id,
    };

  }
}
class ErrorSidebarItem extends SidebarItem {
  constructor(data, i) {
    super(data);
  }
  getIcon() {
    // return cssImg('error', {
    //   '#000000': iconColor,
    // })
  }

  getTitle() {
    return 'Error';
  }

  getNav() {
    return {
      type: 'error',
    };
  }

  getChildren() {
    return [];
  }
}

class LibrarySidebarItem extends SidebarItem {
  library;
  constructor(data) {
    super(data);
    this.library = db.libraries.findById(data.library);
  }
  getId() {
    return this.library._id;
  }
  getIcon() {
    return cssImg('bundle', {
      '#000000': iconColor,
    });
  }
  getTitle() {
    return this.library.name;
  }

  getNav() {
    return {
      type: PaneType.library,
      id: this.library._id,
    };
  }
}
class ChatGPTSidebarItem extends SidebarItem {
  getId() {
    return 'chatGPT';
  }

  getIcon() {
    return cssImg('ai', {
      '#000000': 'rgb(167, 130, 195)',
    });
  }
  getTitle() {
    return 'Chat';
  }

  getNav() {
    return {
      type: PaneType.chatGPT
    };

  }
}
class AppLibrarySidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
  }
  getId() {
    return 'appLibrary';
  }
  getIcon() {
    return cssImg('bundle', {
      '#000000': iconColor,
    });
  }
  getTitle() {
    return 'App Library';
  }

  getNav() {
    return {
      type: PaneType.appLibrary
    };
  }
}

class CanvasSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.canvas = db.canvases.findById(data.id);
  }
  canvas;

  getIcon() {
    return cssImg('icons8-canvas', {
      '#000000': iconColor,
    });

  }
  getTitle() {
    return this.canvas.name;
  }
  onClick() {
    // getSidebar().children.push(XObject.obj({
    //   type: SidebarItemType.canvas,
    // }));
  }

  getNav() {
    return {
      type: PaneType.canvas,
      id: this.canvas._id,
    }
  }
}

export class RootSidebarItem extends SidebarItem {
  constructor() {
    super(null);
    this.sidebar = getSidebar();
  }
  sidebar;

  fixedItems() {
    if (resumeMode.enabled) {
      return [];
    }
    else {
      return [new ChatGPTSidebarItem(null)];
    }
  }

  moveChild(oldIndex: any, newIndex: any): void {
    oldIndex -= this.fixedItems().length;
    newIndex -= this.fixedItems().length;
    const children = x(this.sidebar.children);
    const child = children[oldIndex];
    children.splice(oldIndex, 1);
    children.splice(newIndex, 0, child);
    this.sidebar.children = children;
  }
  getChildren(): SidebarItem[] {
    return this.fixedItems().concat(this.sidebar.children.map((entry, i) => {
      if (!entry) {
        return new ErrorSidebarItem(entry, i);
      }
      if (entry.type == SidebarItemType.query) {
        return new QuerySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.entity) {
        return new EntitySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.space) {
        return new SpaceSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.page) {
        return new PageSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.pageEntities) {
        return new PageEntitiesSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.library) {
        return new LibrarySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.canvas) {
        return new CanvasSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.error) {
      }

    }));
  }

  deleteItem(i: any): void {
    this.sidebar.children.splice(i - this.fixedItems().length, 1);
  }
}