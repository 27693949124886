import { RenderData } from '../MyNotionDocument/Editor';
import { types } from "../MyNotionDocument/types";
import { db } from '../db';
import { entitySchema } from './EntityView';
import { expandToText } from './richTextHelpers';

export function entityDisplayName(id):string {
  const entity = db.entities.findById(id);
  if (!entity) return '(deleted)';
  const schema = entitySchema(id);

  let nameValue;
  if (schema?.titleAttribute) {
    nameValue = entity.attributes?.[schema.titleAttribute.content]?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
  } else {
    nameValue = expandToText({ types }, entity.name);
  }

  return nameValue
}


export function entityDisplayView(id) {
  const entity = db.entities.findById(id);
  if (!entity) return '(deleted)';
  const schema = entitySchema(id);

  let nameValue;
  if (schema?.titleAttribute) {
    nameValue = entity.attributes?.[schema.titleAttribute.content]?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
  } else {
    nameValue = <RenderData ctx={{
      types: types,
    }}
    data={entity.name}
    />
  }

  return nameValue;

}