import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { createRootValuePoint } from '../glue/main';
import { ObjectType } from '../types/QueryParentType';
import { ElementConfigType } from "../types/ElementConfigType";



@component
export class TypeWindow extends Component<{ window; }> {
  render() {
    const type = db.entityTypes.findById(this.props.window.id);
    const elements = db.elements.filter(x => x.parent?.id == this.props.window.id);
    return (
      <>
        {type._id}
        <div>
          Name: <PropertyField object={type} property="name" />
        </div>
        <ul>
          {elements.map(element => {
            return (
              <li key={element._id}>
                <span data-value-point={element.valuePoint}>{element.valuePoint}</span>
                <div>
                  Name: <PropertyField object={element} property="name" />
                </div>
              </li>
            );
          })}
        </ul>

        <button
          onClick={() => {
            const valuePoint = createRootValuePoint();
            const newType = XObject.obj({
              parent: {
                type: ObjectType.type,
                id: this.props.window.id,
              },
              configType: ElementConfigType.glue,
              valuePoint: valuePoint._id,
            });
            db.elements.push(newType);
          }}
        >
          + Glue
        </button>
      </>
    );
  }
}
