import _ from 'lodash';
import jQuery from 'jquery';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { db } from '../db';
import { GlueDev, GlueView } from '../glue/main';
import { currentSpace, openWindow, triggerInspectObject } from '../osHelpers';
import { component } from '../component2';
import { X, x, XInit, XObject } from '../XObject';
import { LinearWindow } from './LinearWindow';
import { showContextMenu } from '../helpers';
import { ObjectDisplay } from './TypeEditor';
import { WindowType } from '../etc/WindowType';
import { Svg } from './Svg';
import classNames from 'classnames';
import { appState, memoryAppState } from '../etc/appState';
import { SystemContext } from '../etc/SystemContext';
import { isElectron } from '../etc/isElectron';
import { EntityView } from './EntityView';
import { createEntity } from '../etc/createEntity';
import { NotionButton } from './AddButton';
import { createQuery } from '../etc/queryFuncs';
import { ViewQueryPage, ViewQueryish } from './ViewQuery';
import { cssImg } from '../img';
import { getSidebar } from '../etc/getSidebar';
import { SidebarItemType } from '../types/SidebarItemType';
import { ViewType } from '../types/ViewType';
import { ObjectType } from '../types/QueryParentType';
import { ObjectPageFrame } from './ObjectPageFrame';
import { collectEntities } from "../etc/collectEntities";
import { AppLibrary } from './AppLibrary';
import { PaneType } from '../types/PaneType';
import { RichTextEditor } from './RichTextEditor';
import { GraphView__ } from './GraphView__';
import { ChagGPTPane, ChagGPTPane2 } from './ChatGPTPane';
import { QueuesWindow } from '../windows/QueuesWindow';
import { EntityQueuesWindow } from '../windows/EntityQueuesWindow';
import { Inbox } from './Inbox';
import { NotionDocumentWindow } from '../windows/NotionDocumentWindow';
import { UIInspectPane } from './UIInspectPane';
import { SpaceEntities } from './SpaceEntities';
import { TableRowView } from './TableRowView';
import { RootSidebarItem } from '../leftSidebar';
import { Sidebar } from './Sidebar';
import { color, iconColor } from '../color';
import { ImageEnum } from '../ImageEnum';
import { notionMode, resumeMode } from '../resumeMode';
import { CanvasView } from './CanvasView';
import { inspectObj } from '../inspectObj';
import { entityDisplayView } from './entityDisplayName';
import { resolvePath } from './resolvePath';
import { Type } from './Type';

import { useNavigate } from  'react-router-dom';
const rightWidth = '500px';

const sidebarStyles = css`
  background-color: #ffffff;
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  /* bottom: 0; */
  box-sizing: border-box;
  /* border-left: 1px solid ${color('separator')}; */

  > .top {
    background-color: #ffffff;
    -webkit-app-region: drag;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    .title {
      margin-left: 10px;
      color: ${color('bgTextColor')};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 12px;
      font-weight: 500;
    }
    .toggleSidebar {
      flex: 0 0 auto;
      margin-left: auto;
      /* margin-right:   8px; */
    }

    .toggleAppInspect {
      margin-right: 11px;
    }
  }

  > .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

  }
`;


export function pushToPane(pane, config, inspect=true) {
  if (inspect) inspectFromNav(config, appState.panes.indexOf(pane));
  pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
  pane.pointer = pane.stack.length - 1;
}

@component
class RightTopPanel extends Component {
  render() {
    return (
      <>
        <span
          className={classNames('toggleGraphView svg-button', { active: appState.rightSidebar })}
          onClick={() => {
            appState.rightSidebar = !appState.rightSidebar;
          }}
        >
          <Svg name="icons8-mind-map" />
        </span>
        <span className={classNames('toggleSidebar svg-button', { visible: currentSpace().sideBar })} onClick={() => {
          currentSpace().sideBar = !currentSpace().sideBar;
        }}>
          <Svg name={currentSpace().sideBar ? ImageEnum.sidebarRightFilled : ImageEnum.sidebarRight} />
        </span>
      </>
    );
  }
}

@component
class UserSelect extends Component {
  static styles = styled.div`
    height: 39px;
    position: relative;
    padding: 2px 14px;
    box-sizing: border-box;
    margin: 0 17px;
    border-radius: 18px;

    &:not(:hover) {
      > svg {
        display: none;
      }
    }

    > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
      width: 12px;
      height: 12px;
      fill: rgba(55, 53, 47, 0.45);
    }

    display: flex;
    align-items: center;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    cursor: pointer;
    .icon {
      border-radius: 0.25em;
      width: 20px;
      height: 20px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      svg, svg path {
        fill: #7081b9;
      }
    }

    .userName {
      color: #7081b9;
      font-weight: 500;
    }

    margin-bottom: 8px;
  `;

  render(Container?) {
    return <Container
      onContextMenu={e => {
        e.preventDefault();
        showContextMenu(e, [
          {
            text: 'Edit',
            onClick: () => {
              inspectObj({
                type: ObjectType.mode,
                id: appState.currentMode,
              }, true);
  
            },
          }
        ]);
      }}
      onClick={e => {
        if (resumeMode.enabled) return;
        showContextMenu(e, [{
          text: 'No mode',
          onClick: () => {
            appState.currentMode = null;
          }
        }].concat(db.modes.map(mode => ({
          text: mode.name,
          onClick: () => {
            appState.currentMode = mode._id;
            inspectObj({
              type: ObjectType.mode,
              id: mode._id,
            })
          },
        }))).concat([
          {
            text: '(New mode)',
            onClick: () => {
              const newMode = XObject.obj({
                name: 'Untitled',
              });
              db.modes.push(newMode);
              appState.currentMode = newMode._id;
              inspectObj({
                type: ObjectType.mode,
                id: newMode._id,
              })
  
            }
          },
          {
            text: '(Open library)',
            onClick: () => {
              openWindow({
                type: WindowType.AppLibrary,
              });
  
            }
          }
        ]))
      }}
    >
      <span className="icon">
          <Svg name="icons8-mode" />
      {/* {db.modes.findById(appState.currentMode)?.name?.[0]?.toUpperCase?.()} */}
      </span>
      <span className="userName">{db.modes.findById(appState.currentMode)?.name}</span>
      <Svg name="expand" />
    </Container>;
  }
}

export function inspectFromNav(config, pane) {
  console.log('inspectFromNav', config, pane);
  appState.lastActivePane = pane;
  if (config?.type == 'entity') {
    appState.inspecting = {
      type: 'entity',
      id: config.id,
    };
  }

  if (config.type == 'query') {
    inspectObj({
      type: ObjectType.query,
      id: config.id,
    })
  }
  else if (config.type == 'page') {
    inspectObj({
      type: ObjectType.page,
      id: config.id,
    })
  }
  else if (config.type == PaneType.canvas) {
    inspectObj({
      type: ObjectType.canvas,
      id: config.id,
    })
  }
  else if (config.type == 'entity') {
    inspectObj({
      type: ObjectType.entity,
      id: config.id,
    })
  }
  else if (config.type == PaneType.tableRow) {
    appState.inspecting = config;

    triggerInspectObject({
      type: PaneType.tableRow,
      args: config,
    })
  }

}

function viewWidth(pane) {
  if (pane.type == PaneType.uiInspect) {
    return 'auto';
  }
}

export function isEmptyPane(pane) {
  return !pane || Object.keys(x(pane)).length <= 2;
}
function renderView(pane, state, navigate) {
  if (isEmptyPane(pane)) {
    return '';
  }
  const id = pane.id;
  if (pane.type == PaneType.tableRow) {
    return <TableRowView window={pane} />;
  }
  if (pane.type == PaneType.chatGPT) {
    return <ChagGPTPane window={pane} />;
  }
  if (pane.type == PaneType.chatGPT2) {
    return <ChagGPTPane2 window={pane} />;
  }
  if (pane.type == PaneType.richTextEditor) {
    if (pane.entity) {
      const entity = db.entities.findById(pane.entity);
      return (
        <RichTextEditor
          value={entity?.attributes?.[pane.attribute]}
          setValue={value => {
            if (!entity.attributes) {
              entity.attributes = {
                [pane.attribute]: value,
              };
            }
            else {
              entity.attributes[pane.attribute] = value;
            }
          }} />
      );
    }
  }
  if (pane.type == PaneType.uiInspect) {
    return <UIInspectPane args={pane.args} />
  }
  if (pane.type == 'entity') {
    return <EntityView id={pane.id} state={XObject.get(state, 'viewState', {})} />;
  }
  if (pane.type == 'query') {
    return <ViewQueryPage id={pane.id} state={state} entity={pane.entity} />;
  }
  if (pane.type == 'spaceEntities') {
    return <SpaceEntities space={pane.space} state={state} />;
  }
  if (pane.type == WindowType.EntityQueues) {
    return <EntityQueuesWindow window={pane} hideInspectPane />;
  }
  if (pane.type == PaneType.appLibrary) {
    return <AppLibrary window={pane} />;
  }
  if (pane.type == 'glue') {
    return (
      <GlueView
        key={pane._id}
        args={x(pane.map)}
        id={pane.id}
        state={state} />
    );

  }
  else if (pane.type == PaneType.canvas) {
    return <CanvasView pane={pane} />
  }
  else if (pane.type == 'inbox') {
    return <Inbox />;
  }
  else if (id == '6effb8ff-9c63-5bca-b51a-a629d1e5b2b6') {
    return <QueuesWindow window={{}} />;
  }
  else if (id == '83078246-7ae7-593a-8fb1-75b411b8507e') {
    return <GlueDev state={state} />;
  }
  if (id == 'linear') {
    return <LinearWindow window={state} />;
  }
  else if (pane.type == PaneType.pageEntities) {
    let doc;
    if (pane.entity) {
      const entity = db.entities.findById(pane.entity);
      doc = entity.documents.find(doc => doc._id == pane.id);

    }
    else {
      doc = db.notionDocuments.findById(pane.document || pane.id);
    }


    const entities = collectEntities(doc.content || doc.blocks || []);

    const entityQuery = XObject.get(doc, 'entitySpace', {
      views: [],
    });


    return <ObjectPageFrame
      icon="tesseract"
      path={<>
        <span>{'Entities'}</span>
      </>}
      right={null}
    >
      <ViewQueryish
        entities={entities}
        state={state}
        showToolbar
        viewManager={{
          get() {
            return entityQuery.views || [];
          },
          addView() {
            XObject.push(entityQuery, 'views', XObject.obj({
              type: ViewType.list,
            }));
          },
          init() {
          },
          editView(id) {
            openWindow({
              type: WindowType.QueryView,
              doc: doc._id,
              view: id,
            });

          },
          deleteView() {
          }
        }} />
    </ObjectPageFrame>;

  }

  const notionDoc = db.notionDocuments.findById(id);
  if (notionDoc) {
    state.notionDocument = id;
    return (
      <ObjectPageFrame
        icon="icons8-page"
        path={notionDoc.name}
        // right={<>
        //   <NotionButton img={false} text="Entities"
        //     onClick={() => {
        //       navigate({
        //         type: PaneType.pageEntities,
        //         id: notionDoc._id,
        //       });
        //     }} />
        // </>}
      >
        <NotionDocumentWindow
          key={id}
          window={state}
        />
      </ObjectPageFrame>
    );
  }

  return (
    <>
      <button onClick={() => {
        console.log(x(pane));
      }}>Debug</button>
    </>
  );
}
function viewTitle(pane) {
  if (isEmptyPane(pane))
    return '---';
  if (pane.type == 'inbox') {
    return 'Inbox';
  }
  if (pane.type == '146a4264-a507-5fb9-b7d3-e59ee00fb050') {
    return <Svg name="icons8-page" />;
  }
  if (pane.type == 'entity') {
    return <ObjectDisplay obj={{
      id: pane.id,
      type: ObjectType.entity,
    }} />;
  }
  else if (pane.type == PaneType.canvas) {
    return (
      <ObjectDisplay
        obj={{
          id: pane.id,
          type: ObjectType.canvas,
        }} />
    );
  }
  else if (pane.type == PaneType.tableRow) {
    console.log(x(pane));
    const [doc, row] = resolvePath(pane.path);
    
    const cols = doc.tableData.columns;

    const titleCol = cols.find(col => col.type == Type.name);

    return row.values?.[titleCol._id] || '';


    // return <ObjectDisplay obj={{
    //   id: pane.id,
    //   type: ObjectType.page,
    // }} />;
  }
  else if (pane.type == 'query') {
    return <ObjectDisplay obj={{
      id: pane.id,
      type: ObjectType.query,
    }} />;
  }
  const id = pane.id;
  if (pane.type == WindowType.EntityQueues) {
    const entity = db.entities.findById(pane.entity);
    return entity.name + ' queues';
  }
  if (id == 'linear') {
    return 'Linear';
  }
  if (pane.type == 'spaceEntities') {
    return <ObjectDisplay
      obj={{
        id: pane.space,
        type: ObjectType.space,
      }} />;
  }

  const notionDoc = db.notionDocuments.findById(pane.id);
  if (notionDoc) {
    return <ObjectDisplay
      obj={{
        type: ObjectType.page,
        id: notionDoc._id,
      }} />;
  }



  return '---';

}

@component
class Title extends Component<{ pane; }> {
  static styles = styled.span`
  display: inline-flex;
  align-items: center;
    svg {
      fill: #3131319c;
    }
  `;
  render() {
    if (!this.props.pane)
      return '---';
    return viewTitle(this.props.pane);
  }
}

@component
class LeftSidebar extends Component {
  state = X({});
  context: any;
  static contextType = SystemContext;
  static styles = styled.div`
    background-color: #ffffff;
    ${sidebarStyles}
    .inbox {
      margin: 0 8px 8px;
      padding: 2px 10px 2px 5px;
      color: rgb(155, 155, 155);
      font-weight: 600;

      &:hover {
        background: rgba(255, 255, 255, 0.055);
      }
      border-radius: 3px;
    }
    .section {
      > .title {
        display: block;
        text-transform: initial;
        font-size: 12px;
        line-height: 1;
        /* color: rgba(255, 255, 255, 0.282); */
        color: rgba(55, 53, 47, 0.5);
        font-weight: 600;

        padding: 0 8px;
        margin-bottom: 3px;
      }

      margin-bottom: 16px;
    }

    .item.entity {
      position: relative;
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background: ${cssImg('tesseract', {
          '#000000': iconColor,
        })} no-repeat center;
        background-size: contain;
        position: absolute;
        left: 3px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &.active {
        background: rgba(255, 255, 255, 0.055);
      }
      margin: 0 8px;
      cursor: pointer;
      padding: 2px 10px 2px 5px;
      padding-left: 24px;
      &:hover {
        background: rgba(255, 255, 255, 0.055);

      }

      border-radius: 3px;
      display: flex;
      align-items: center;

      color: ${color('bgTextColor')};
      font-weight: 600;
    }

    .children {
      margin-left: 16px;
      margin-top: 2px;
    }

    .item.query {
      position: relative;
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background: ${cssImg('database', {
          '#000000': iconColor,
        })} no-repeat center;
        background-size: contain;
        position: absolute;
        left: 3px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.active {
        background: rgba(255, 255, 255, 0.055);
      }
      margin: 0 8px;
      cursor: pointer;
      padding: 2px 10px 2px 5px;
      padding-left: 24px;

      &:hover {
        background: rgba(255, 255, 255, 0.055);

      }
      border-radius: 3px;
      /* display: flex; */
      /* align-items: center; */

      color: ${color('bgTextColor')};
      font-weight: 600;
    }

    > .add {
      height: 47px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      /* border-top: 1px solid #e9e9e7; */
      color: ${color('bgTextColor')};
      font-weight: 600;
      svg {
        margin-right: 12px;
        fill: ${color('bgTextColor')};
      }
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.055);
      }
    }

    > .top {
      padding-left: ${isElectron() ? '68px' : '8px'};
      
      border-bottom: none;
    }
    > .content {
      background-color: #ffffff;
      position: absolute;
      top: ${isElectron() ? '37px' : '0'};
      left: 0;
      right: 0;
      bottom: 48px;
      overflow: auto;

    }


    z-index: 9999;
    .toggleLeftSidebar.svg-button {
      transform: rotate(0);
      margin-left: auto;

      svg path {
        fill: rgba(55,53,47,0.45);
      }

    }
  `;
  render(Container?) {
    return (
      <>
        {appState.leftSidebar && (
          <Container>
            {isElectron() && (
              <>
                <div className="top">
                  <span
                    className={classNames('toggleLeftSidebar svg-button', { visible: appState.leftSidebar })}
                    onClick={() => {
                      appState.leftSidebar = !appState.leftSidebar;
                    }}
                  >
                    <Svg name="doubleChevronLeft" />
                  </span>
                  <span className="title">
                  </span>
                </div>
              </>
            )}
            <div className="content">
              <div className="section">
                <UserSelect />
                {getSidebar() && <Sidebar rootItem={new RootSidebarItem()} state={XObject.get(getSidebar(), 'state', {})} />}
              </div>
            </div>
            <div className="add"
              onClick={e => {
                if (notionMode.enabled) {
                  const doc = XObject.obj({
                    name: 'new page',
                    blocks: [],
                    showInitView: true,
                  });
                  db.notionDocuments.push(doc);

                  getSidebar().children.push(XObject.obj({
                    type: SidebarItemType.page,
                    id: doc._id,
                  }));
                }
                else {
                showContextMenu(e, [
                  {
                    text: 'Add page',
                    onClick: () => {
                      const doc = XObject.obj({
                        name: 'new page',
                        blocks: [],
                        showInitView: true,
                      });
                      db.notionDocuments.push(doc);

                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.page,
                        id: doc._id,
                      }));

                    }
                  },
                  !notionMode.enabled && {
                    text: 'Add space',
                    onClick: () => {
                      const space = XObject.obj({
                        name: 'new space',
                      });
                      db.spaces.push(space);
                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.space,
                        space: space._id,
                      }));
                    }
                  },
                  !notionMode.enabled && {
                    text: 'Add library',
                    onClick: () => {
                      const library = XObject.obj({
                        name: 'new library',
                      });
                      db.libraries.push(library);
                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.library,
                        library: library._id,
                      }));
                    }
                  },
                  !notionMode.enabled && {
                    text: 'Add entity',
                    onClick: () => {
                      const entity = createEntity({
                        name: 'New entity',
                      }, null);
                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.entity,
                        id: entity._id,
                      }));

                    }
                  },

                  !notionMode.enabled && {
                    text: 'Add query',
                    onClick: () => {
                      const q = createQuery({}, null, appState.currentMode && {
                        type: ObjectType.mode,
                        id: appState.currentMode
                      });
                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.query,
                        id: q._id,
                      }));
                    }
                  },

                  {
                    text: 'Add canvas',
                    onClick: () => {
                      const canv = XObject.obj({
                        name: 'New canvas',
                        version: 'ab599af3-6f75-54c2-bbe9-aa25055959e1',
                      });
                      db.canvases.push(canv);
                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.canvas,
                        id: canv._id,
                      }));
                    }
                  },


                ], 'bottom');
                }
              }}
            >
              <Svg name="plus" /> New
            </div>
          </Container>
        )}
      </>
    );
  }
}


@component
class RightSidebar extends Component {
  state = X({});
  static styles = styled.div`
    ${sidebarStyles}

    background-color: white;
      width: ${rightWidth};
      > .top {
        background-color: #fbfbfb;
        z-index: 1;
      }

      > .content {
        top: 37px;
      }
  `;


  render(Container?) {
    return (
      <>
        {appState.rightSidebar && <Container className="rightSidebar">
          <div className="top">
            <span className="title">
              {(() => {

                if (appState.inspecting?.type == 'entity') {
                  const entity = db.entities.findById(appState.inspecting.id);
                  return entityDisplayView(entity._id);
                }
              })()}

              
            </span>

            {/* <RightTopPanel /> */}



          </div>
          <div className="content">

            <GraphView__ />

            {(() => {

              if (appState.inspecting?.type == 'entity') {
                // return renderInspect(appState.inspecting.id, this.inspectState);
                return (
                  <>

                    {/* <EntityInspect id={appState.inspecting.id} /> */}

                    {/* <EntityView id={appState.inspecting.id} state={this.state} /> */}

                  </>
                );
              }
              else {
                return <div>Nothing to inspect</div>;
              }
            })()}
          </div>
        </Container>}</>
    );
  }
}
const topHeight = 37 * 2;
export function initPaneObject(config) {
  return XObject.obj({
    viewType: appState.lastViewState || 'stack',
    pointer: 0,
    stack: [XObject.obj(config)],
  });
}

@component
class ClientView extends Component<{ pane, i }> {
  scrollToNew(id) {

    const timerId = setInterval(() => {
      const el = jQuery(`[data-stack-id="${id}"]`);
      if (el.length) {
        setTimeout(() => {
          el[0].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
  
        }, 200);
        clearInterval(timerId);
      }
    }, 100);
  }
  render() {
    const { pane, i } = this.props;
    
    return (
      <div className={classNames("client", pane.viewType)} key={pane._id}>
      {(() => {
        if (pane.viewType == 'browser') {
          const current = pane.stack?.[pane.pointer];
          if (!current)
            return null;
          return (
            <div className="clientComp" key={current._id}>
              <SystemContext.Provider
                value={{
                  navigate: (config) => {
                    inspectFromNav(config, i);
                    const newItem = XObject.obj(config);
                    pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), newItem]);
                    pane.pointer = pane.stack.length - 1;
                  },
                  next: () => {
                    return pane.stack[pane.pointer + 1];
                  },
                  viewType: pane.viewType,
                }}
              >

                {renderView(current, XObject.get(current, 'state', {}), (config) => {
                  inspectFromNav(config, i);
                  pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
                  pane.pointer = pane.stack.length - 1;
                })}
              </SystemContext.Provider>
            </div>
          );
        }
        else if (pane.viewType == 'stack') {
          return pane?.stack?.map?.((item, j) => {
            return (
              <div 
                className="clientComp"
                key={item._id}
                data-stack-id={item._id}
                style={{
                  width: viewWidth(item),
                }}
              >
                <SystemContext.Provider
                  value={{
                    navigate: (config) => {
                      inspectFromNav(config, i);
                      const newItem = XObject.obj(config);
                      pane.stack = X([...x(pane.stack.slice(0, j + 1)), newItem]);
                      pane.pointer = pane.stack.length - 1;
                      const el = ReactDOM.findDOMNode(this) as Element;

                      this.scrollToNew(newItem._id);

                    },
                    next: () => {
                      return pane.stack[j + 1];
                    },
                    viewType: pane.viewType,
                  }}
                >

                  {renderView(item, XObject.get(item, 'state', {}), (config) => {
                    inspectFromNav(config, i);
                    // use j instead of pane.pointer
                    pane.stack = X([...x(pane.stack.slice(0, j + 1)), XObject.obj(config)]);
                    pane.pointer = pane.stack.length - 1;
                  })}
                </SystemContext.Provider>
              </div>
            );
          });
        }
      })()}

    </div>
    )

  }
}


@component
export class MobileRoot extends Component<{ pathArg }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    ${LeftSidebar} > .content {
      padding-top: 8px;
    }

    > .view {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: white;
      border-left: 1px solid #ddd;
      box-shadow: 0 0px 5px rgb(220 220 220);
      transition: left 0.1s ease-in-out;
    }

    &.showSidebar {
      > .view {
        left: calc(100% - 80px);
      }
    }

  `;

  state = XInit(class {
    view

    token
  });

  render(Container?) {
    const navigate = useNavigate();
    let config;

    const fix = (str) => {
      if (_.isString(str)) {
        // trim trailing /
        if (str.endsWith('/')) {
          str = str.slice(0, -1);
        }
      }

      return str;

    }

    const pathArg = fix(this.props.pathArg) || 'root';

    if (pathArg) {
      if (this.state.token != pathArg) {
        this.state.token = pathArg;
        if (pathArg == 'root') {
          config = {"type":"page","id":"646cf264694d7e70c6d262cc"}
        }
        else {
          try {
            config = JSON.parse(atob(pathArg));
          }
          catch (e) {
            // console.error(e);
          }
        }
        
        this.state.view = XObject.obj(config);
      }
    }
    else {
      
    }

    return (
      <Container className={classNames({
        showSidebar: memoryAppState.showSidebar,
      })}>
      <SystemContext.Provider
        value={{
          next: () => {},
          navigate: (config) => {
            this.state.view = XObject.obj(config);
            memoryAppState.showSidebar = false;
            navigate('/' + btoa(JSON.stringify(x(config))));
          }
        }}
       >
        <LeftSidebar />
        <div className="view" key={pathArg}>
          {this.state.view && renderView(this.state.view, this.state, () => {

          })}
        </div>
        </SystemContext.Provider>
      </Container>
    )
  }
}


@component
export class AppRoot extends Component {
  static styles = styled.div`
    &:not(.electron) {
      ${LeftSidebar} > .content {
        padding-top: 8px;
      }
    }

    ${LeftSidebar}, ${RightSidebar} {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    ${LeftSidebar} {
      border-right: 1px solid ${color('separator')};
      box-shadow: 0 3px 2px rgb(0 0 0 / 10%);
    }
  
    .toggleSidebar {
      &.visible {
        svg path {
          fill: #797979;
        }
      }

      &:not(.visible) {
        rect, line {
          stroke: #797979;
        }
      }
    }

    .toggleLeftSidebar.svg-button {
      /* border-left: 2px solid ${color('separator')}; */
      transform: rotate(180deg);
      cursor: pointer;
      svg path {
          fill: #797979;;
        }


      /* margin-left: auto; */

      &.visible {
      }

      &:not(.visible) {
        rect, line {
          stroke: #797979;
        }
      }
    }

    .svg-button {
      -webkit-app-region: no-drag;

      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: rgba(255, 255, 255, 0.055);
        border-radius: 3px;
      }
      svg {
        width: 16px;
        height: 16px;
        & {
          fill: #797979;
        }
      }
    }

    .toggleGraphView {
      &:not(.active) {
        svg {
          fill: #c1c1c1;
        }
      }
    }

    > .panes {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 1;
      > div {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;

        &:not(:last-child) {
          border-right: 1px solid ${color('separator')};
        }

        > .top {
          box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
          position: relative;
          background-color: #fbfbfb;
          height: ${topHeight}px;
          color: ${color('bgTextColor')};
          border-bottom: 1px solid ${color('separator')};
          box-sizing: border-box;


          > .topTop {
            height: 37px;
            -webkit-app-region: drag;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            /* border-bottom: 2px solid red; */
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-size: 12px;
            font-weight: 500;
            
            /* font-weight: bold; */
            > span {
              -webkit-app-region: no-drag;
            }

            .title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              align-items: center;
              svg {
                fill: #3131319c;
                width: 12px;
                height: 12px;
              }
            }

            .add, .toggleSidebar {

            }


            .right {
              position: absolute;
              right: 8px;

              top: 0;
              bottom: 0;
              margin: auto;

              display: flex;
              align-items: center;

            }

            .add {
              flex: 0 0 auto;
              width: 24px;  
              display: flex;
              align-items: center;
              justify-content: center;

              &.last {
                /* border-left: 1px solid ${color('separator')}; */

              }

              &.first {

                /* border-right: 1px solid ${color('separator')}; */
                margin-right: 8px;

              }



              -webkit-app-region: no-drag;
              /* margin-right: 16px; */
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background: rgba(255, 255, 255, 0.055);
                border-radius: 3px;
              }
              svg {
                width: 16px;
                height: 16px;
                fill: #797979;
              }
            }
          }

          > .topBottom {
            position: absolute;
            top: 37px;
            height: 37px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            right: 0;
            box-sizing: border-box;
            overflow: hidden;

            .nav {
              flex: 0 0 auto;
              white-space: nowrap;
              ${NotionButton} {
                &.disabled {
                  opacity: 0.5;
                  /* cursor: default; */
                  pointer-events: none;
                }
                height: 24px;
              }
              svg {
                /* fill: rgba(255,255,255,0.81); */
                fill: #797979;
              }
              .prev {
                svg {
                  transform: rotate(90deg);
                }
              }

              .next {
                svg {
                  transform: rotate(-90deg);
                }
              }

              margin-right: 8px;
            }



            .stack {
              display: flex;
              flex: 0 1 auto;
              overflow: hidden;
              margin-right: 8px;
              .comp {
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 0 1 auto;
                max-width: 260px;
                .title {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .active {
                  font-weight: 700;
                }

                &:not(:first-child) {
                  &:before {
                    content: ' › ';
                    margin: 0 4px;
                  }
                }
              }
            }

            .viewType {
              margin-left: auto;
              flex: 0 0 auto;
              width: 24px;
              height: 24px;

              svg {
                width: 18px;
                height: 18px;
              }

              &.stack-view {
                svg {
                  fill: #242424;
                }
              }
              /* width: 100%; */
            }


          }
        }

        &:first-child {
          > .top {
            padding-left: ${isElectron() ? '60px' : '8px'};
            > .topTop/* , > .topBottom */ {
              left: ${isElectron() ? '60px' : '0'};
            }

            > .topBottom {
              left: 0;
            }
          }
        }

        &:not(:first-child) {
          > .top {
            > .topBottom {
              left: 0;
            }
          }
        }

        > .client {
          overflow: auto;
          position: absolute;
          top: ${topHeight + 2}px;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;

          &.stack {
            > .clientComp {
              position: relative;
              flex: 0 0 auto;
              width: 600px;
              overflow: auto;
              &:not(:last-child) {
                border-right: 1px solid ${color('separator')};
              }
            }
          }

          &.browser {
            width: 100%;
            .clientComp {
              width: 100%;
            }
          }

        }

        &:last-child {
          > .client {
            &.stack {
              > .clientComp {
                &:last-child {
                  border-right: 1px solid ${color('separator')};
                }
              }
            }
          }
        }
      }
    }

    &.rightSidebar {
      &:last-child {
          > .panes  > div > .client {
            &.stack {
              > .clientComp {
                &:last-child {
                  /* border-right: none; */
                }
              }
            }
          }
        }

    }

    &.leftSidebar {
      > .panes {
        > div {
          &:first-child {
            > .top {
              padding-left: 0;
              > .topTop, > .topBottom {
                left: 0;
              }
            }
          }
        }
      }
    }

    ${LeftSidebar} {
      left: 0;
      right: auto;
      width: 265px;
    }

    &.rightSidebar {
      > .panes {
        right: ${rightWidth};
      }
    }

    &.leftSidebar {
      > .panes {
        left: 265px;
      }
    }

    .toggleAppInspect {
      -webkit-app-region: no-drag;
    }

    &.bottomPane {
      > .panes {
        bottom: 30%;
      }
      ${RightSidebar}, ${LeftSidebar} {
        bottom: 30%;
      }
      
      .bottomPane {
        border-top: 2px solid ${color('separator')};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30%;
        overflow: auto;
      }
    }
  `;

  inspectState = X({});

  viewState = X({});

  render(Container?) {
    if (!appState.panes.length) {
      appState.panes.push(initPaneObject({}));
    }
    return (
      <Container
        className={classNames({
          rightSidebar: appState.rightSidebar,
          leftSidebar: appState.leftSidebar,
          bottomPane: appState.appInspect,
          electron: isElectron(),
        })}
      >
        <SystemContext.Provider value={{
          navigate: config => {
            inspectFromNav(config, 0);
            appState.panes[0] = initPaneObject(config);
          },
          next: () => {
            const pane = appState.panes?.[0];
            return pane.stack?.[0];
          },
        }}>
          <LeftSidebar />
        </SystemContext.Provider>
        <div className="panes">
          {appState.panes.map((pane, i) => {
            const current = pane.stack?.[pane.pointer];
            return (
              <div key={pane._id} data-pane-id={pane._id}>
                <div className="top">
                  <div className="topTop">
                    {i == 0 && (
                      <>
                        {!appState.leftSidebar && <>
                          {/* {!resumeMode.enabled && <span
                            style={{
                              '-webkit-app-region': 'no-drag',
                            } as any}
                          ><ModeSelect /></span>} */}
                          <span
                            className={classNames('toggleLeftSidebar svg-button', { visible: appState.leftSidebar })}
                            onClick={() => {
                              appState.leftSidebar = !appState.leftSidebar;
                            }}>
                            {/* <Svg name={appState.leftSidebar ? ImageEnum.sidebarRightFilled : ImageEnum.sidebarRight} /> */}
                            <Svg name="doubleChevronLeft" />
                          </span></>}
                        <span className="add first svg-button"
                          onClick={e => {
                            appState.panes = X([initPaneObject({}), ...x(appState.panes)]);
                            // showContextMenu(e, showViewMenu(id => {
                            //   appState.panes = X([initPaneObject({ id }), ...x(appState.panes)]);
                            // }));
                          }}
                        ><Svg name="plus" /></span>
                      </>
                    )}
                    <span
                      className="title"
                      onClick={e => {
                        // showContextMenu(e, [
                        //   ...showViewMenu(id => {
                        //     delete pane.id;
                        //     setTimeout(() => {
                        //       delete pane.state;
                        //       pane.id = id;
                        //     }, 500);
                        //   }),
                        //   {
                        //     text: 'Close',
                        //     onClick: () => {
                        //       appState.panes.splice(i, 1);
                        //       if (appState.panes.length == 0) {
                        //         appState.panes.push(XObject.obj());
                        //       }
                        //     }
                        //   }
                        // ]);
                      }}
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [
                          {
                            text: 'Close',
                            onClick: () => {
                              appState.panes.splice(i, 1);
                              if (appState.panes.length == 0) {
                                appState.panes.push(XObject.obj());
                              }
                            }
                          }
                        ]);
                      }}
                    >
                      <Title pane={current} />
                    </span>
                    {!notionMode.enabled && appState.panes.length - 1 == i && (
                      <div className="right">
                        <span
                          className="add last svg-button"
                          onClick={e => {
                            appState.panes.push(initPaneObject({}));
                          }}
                        >
                          <Svg name="plus" />
                        </span>
                        <RightTopPanel />
                      </div>
                    )}
                  </div>
                  <div className="topBottom">
                    <span className="nav">
                      <NotionButton
                        dark
                        className={classNames("prev", {
                          disabled: pane.pointer == 0
                        })}
                        img="chevron"
                        onClick={() => {
                          pane.pointer = Math.max(0, pane.pointer - 1);

                          inspectFromNav(pane.stack[pane.pointer], i);
                        }} />




                      <NotionButton
                        dark
                        className={classNames("next", {
                          disabled: pane.pointer == pane.stack?.length - 1
                        })}
                        img="chevron"
                        onClick={() => {
                          pane.pointer = Math.min(pane.stack.length - 1, pane.pointer + 1);
                        }} />

                    </span>
                    <span className="stack">
                      {pane?.stack?.map?.((item, j) => {
                        return (
                          <span
                            key={j}
                            className={classNames('comp')}
                            onClick={() => {
                              pane.pointer = j;
                              inspectFromNav(item, i);
                            }}
                          >
                            <span className={classNames('title', { active: j == pane.pointer })}><Title pane={item} /></span>
                          </span>
                        );
                      })}
                    </span>

                    <NotionButton 
                      className={classNames("viewType", pane.viewType + '-view')}
                      img="icons8-view-column"
                      onClick={() => {
                        if (pane.viewType == 'stack') {
                          pane.viewType = 'browser';
                        }
                        else {
                          pane.viewType = 'stack';
                        }
                        appState.lastViewState = pane.viewType;
                      }}
                    />
                    {/* <select
                      className="viewType"
                      value={pane.viewType || 'stack'}
                      onChange={e => {
                        pane.viewType = e.target.value;
                        appState.lastViewState = e.target.value;
                      }}
                    >
                      <option value="stack">Stack</option>
                      <option value="browser">Browser</option>
                    </select> */}
                  </div>
                </div>
                <ClientView i={i} pane={pane} />
{/*                 <div className={classNames("client", pane.viewType)} key={pane._id}>
                  {(() => {
                    if (pane.viewType == 'browser') {
                      const current = pane.stack?.[pane.pointer];
                      if (!current)
                        return null;
                      return (
                        <div className="clientComp" key={current._id}>
                          <SystemContext.Provider
                            value={{
                              navigate: (config) => {
                                inspectFromNav(config, i);
                                pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
                                pane.pointer = pane.stack.length - 1;
                              },
                              next: () => {
                                return pane.stack[pane.pointer + 1];
                              },
                              viewType: pane.viewType,
                            }}
                          >

                            {renderView(current, XObject.get(current, 'state', {}), (config) => {
                              inspectFromNav(config, i);
                              pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
                              pane.pointer = pane.stack.length - 1;
                            })}
                          </SystemContext.Provider>
                        </div>
                      );
                    }
                    else if (pane.viewType == 'stack') {
                      return pane?.stack?.map?.((item, j) => {
                        return (
                          <div 
                            className="clientComp"
                            key={item._id}
                            data-stack-id={item._id}
                            style={{
                              width: viewWidth(item),
                            }}
                          >
                            <SystemContext.Provider
                              value={{
                                navigate: (config) => {
                                  inspectFromNav(config, i);
                                  pane.stack = X([...x(pane.stack.slice(0, j + 1)), XObject.obj(config)]);
                                  pane.pointer = pane.stack.length - 1;
                                },
                                next: () => {
                                  return pane.stack[j + 1];
                                },
                                viewType: pane.viewType,
                              }}
                            >

                              {renderView(item, XObject.get(item, 'state', {}), (config) => {
                                inspectFromNav(config, i);
                                // use j instead of pane.pointer
                                pane.stack = X([...x(pane.stack.slice(0, j + 1)), XObject.obj(config)]);
                                pane.pointer = pane.stack.length - 1;
                              })}
                            </SystemContext.Provider>
                          </div>
                        );
                      });
                    }
                  })()}

                </div> */}
              </div>
            );
          })}
        </div>
        <SystemContext.Provider value={{
          navigate: (config, paneIndex, pointer) => {
            inspectFromNav(config, paneIndex || 0);
            if (!_.isNil(paneIndex) && !_.isNil(pointer)) {
              appState.panes[paneIndex].stack = X([...x(appState.panes[paneIndex].stack.slice(0, pointer + 1)), XObject.obj(config)]);
            }
            else {
              appState.panes[0] = initPaneObject(config);
            }
          }
        }}>
          <RightSidebar />

          {appState.appInspect && (
            <div className="bottomPane">
              <UIInspectPane
                args={appState.appInspect}
                onClickClose={() => {
                  appState.appInspect = null;
                }}
              />
            </div>
          )}
        </SystemContext.Provider>
      </Container>
    );
  }
}

export const topBarHeight = 35;
export const defaultSidebarWidth = 300;
