import styled from 'styled-components';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import _ from 'lodash';
import { db } from '../db';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { DraftSelect } from '../etc/draftHelpers';
import { SortHandle, SortableCont, SortableEl, isId, showContextMenu } from '../helpers';
import { PropertyField } from './PropertyField';
import { ObjectDisplay, TypeEditor } from './TypeEditor';
import { attributesInScope, attributesInScopes, getScopeTree, objectName } from "./objectFuncs";
import { X, XInit, XObject, x } from '../XObject';
import { Cell } from "./Cell";
import { Tag } from "./Tag";
import { SelectEditor } from "./SelectEditor";
import { $GroupedSelectAttribute_CellType, EntitiesCellType, EntityCellType, MultiSelectCellType, PriorityCellType, SelectCellType, TextCellType } from "./cells";
import { css } from '../component2';
import React, { Component, Context, ContextType } from 'react';
import { component } from '../component';
import { SystemContext } from '../etc/SystemContext';
import { EntityDisplay, EntityName, MetaStatesWrapper, metaStatesClasses } from './EntityDisplay';
import { findBlock } from './NotionDocumentBad';
import { NotionBlockWrapper, NotionDocumentWrapper } from './NotionDocumentWrapper';
import { getEdgesForEntity } from '../etc/getEdgesForEntity';
import { deleteEdge } from '../etc/getEdgesForEntity';
import { entitySchema } from './EntityView';
import { $AllEntitiesMatch, executeAllEntitiesMatch } from '../glue/structs/$AllEntitiesMatch';
import { $EntityTemplate, ADD_ATTRIBUTE_SIGNAL, ADD_MATCH_SIGNAL, REMOVE_ATTRIBUTE_SIGNAL } from '../glue/structs/$EntityTemplate';
import { CompiledValuePoint, RenderType, createRootValuePoint, evaluate, execute, getValuePoint, mapStructure, render } from '../glue/main';
import { sendSignal } from "../glue/signalsRegistry";
import { entityMetaStates } from '../etc/entityMatch';
import { NotionButton } from './AddButton';
import { EntityPath } from './EntityPath';
import { EntityRow } from './EntityRow';
import { queryGraphBasic } from '../etc/queryGraph';
import pluralize from 'pluralize';
import { createEntity } from '../etc/createEntity';
import { UserBadge } from './UserBadge';
import { memoryAppState } from '../etc/appState';
import { Runtime } from '../glue/Runtime';
import { $GroupedSelectAttribute } from '../glue/structs/$GroupedSelectAttribute';
import { AttributeType } from './AttributeType';
import { ValueType } from '../glue/ValueType';
import { getAttributeKey, renderAttributeValue } from '../glue/structs/renderAttributeValue';
import { executeSwitch } from './executeSwitch';
import { inArchivedSpace } from './inArchivedSpace';
import { groupById } from '../glue/structs/groupById';
import { EXPRESSION_QUERY, QUERY, createQuery, describeQuery, executeQuery, queryChain, query_entityChildren, query_entityDescendants, query_entityReferences, query_entityType } from "../etc/queryFuncs";
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { ViewQuery, ViewQueryish } from './ViewQuery';
import { Svg } from './Svg';
import classNames from 'classnames';
import { ObjectType } from '../types/QueryParentType';
import { $EntityElement } from '../glue/structs/$EntityElement';
import { $Document } from '../glue/structs/$Document';
import { showPrompt } from '../etc/showPrompt';
import { getReferences } from './getReferences';
import { PaneType } from '../types/PaneType';
import Truncate from 'react-truncate-html';
import { RichTextEditor } from './RichTextEditor';
import { entityDisplayName } from './entityDisplayName';
import { ViewType } from '../types/ViewType';
import { getCodeComponent } from '../pushCode';
import { inspectObj } from '../inspectObj';
import { EntityNameEditor } from './EntityNameEditor';
import { types } from './types';
import { collectEntitiesGood } from './collectEntitiesGood';
import { InsertionCont } from './InsertionCont';
import { CodeComponenType } from '../ide';

interface PassedData {
  references: {
    sourceEntity: string
    attribute: string;
  }[];
  backlinks: {

  }[];
}

enum BlockType2 {
  page = 'e65f19a5-5151-5da5-ad08-a9f50193230c',
  attributes = 'd364cbd9-7486-5889-8032-827bf66daca7',
  schemaAttributes = 'b754124a-0ec3-5898-b48d-093b5cd0f82e',
  query = '8eb3a96d-7625-568e-8974-6c7cdb732d0e',
  contentsQuery = '4826a002-8d81-56a0-b72c-de66294f369b',
  richTextAttribute = '3db1484a-cecf-5ab6-bd43-a25b8b278212',

  systemProperties = 'f1e6b625-2eae-57ac-a454-4d5bff393c2c',
  systemEntities = 'e21bd85f-ca4c-537c-9455-d0c943dec3be',
  systemBacklinks = 'f8771db1-2476-588a-a782-11ce103342fa',
  systemReferences = 'ac2991c2-b981-578a-97ba-67cfda487a58',

  attributeReferences = 'd255aad0-c8a7-59d9-af68-04702dc633f3',
}

function getReverseLineProps(passedData: PassedData) {
  const reverseLineProps = {};
  const refs = passedData.references
  for (const ref of refs) {
    const attr = db.attributeTypes.findById(ref.attribute);
    const rule = attr?.reversePropRules?.find?.(r => r.viewType == '28460994-2d99-5d47-9f30-c29c7fdfd37f');
    if (rule) {
      if (!reverseLineProps[ref.attribute]) reverseLineProps[ref.attribute] = {
        entities: [],
        rule,
      }
      reverseLineProps[ref.attribute].entities.push(ref.sourceEntity);
    }
  }

  return reverseLineProps;
}

const renderEntitiesSection = ({
  defaultHidden,
  id,
  state,
  title,
  contents,
  customCheckboxes = undefined,
  after = undefined,
  underEntity = undefined,
  map = undefined,
  baseQuery = undefined,
  n,
  el = undefined,
}) => {
  let orgContents = contents;
  const entity = db.entities.findById(n);
  if (map) {
    contents = contents.map(map).filter(Boolean);
  }
  const filterBackgroundEntities = c => {
    const metaStates = entityMetaStates(c);
    const background = metaStates.find(id => {
      const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
      if (metaState.backgroundState) return true;
    });

    if (state.onlyShowBackground) {
      if (!background) return false;
    }
    else if (background && !state.includeBackgroundEntities) return false;


    return true;
  }
  contents = contents.filter(filterBackgroundEntities);

  const allCount = contents.length;

  const types = {};
  for (const id of contents) {
    const entity = db.entities.findById(id);
    if (!entity) continue;
    const type = entity.type && db.entityTypes.findById(entity.type);
    if (type) {
      if (!types[type._id]) types[type._id] = 0;
      types[type._id]++;
    }
    else {
      if (!types['untyped']) types['untyped'] = 0;
      types['untyped']++;
    }
  }

  const orgSameType = (() => {
    // return true if all entities are of the same type
    let type;
    for (const id of contents) {
      const entity = db.entities.findById(id);
      if (!entity) continue;
      if (!type) {
        type = entity.type;
      }
      else if (type != entity.type) {
        return false;
      }
    }
    return type;
  })();

  if (state.contentsType) {
    contents = contents.filter(e => {
      if (state.contentsType == 'untyped') return !db.entities.findById(e).type;

      const entity = db.entities.findById(e);
      if (entity) {
        return entity.type === state.contentsType;
      }
      return false;
    });
  }

  const singleType = isId(state.contentsType) || orgSameType;

  const grouped = state.groupBy && groupById(orgContents, state.groupBy);


  const render = list => {
    return list?.map?.(cc => {
      let c;
      if (map) {
        c = map(cc);
      }
      else {
        c = cc;
      }

      if (!contents.includes(c)) return null;
      

      
      const entity = db.entities.findById(c);
      

      // const entity = db.entities.findById(c);
      // const schema = entitySchema(c, {});
      // let elements = renderEntityElements(c);
      if (!entity) return (
        <div className="entityRow" key={c}
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Delete edge',
              onClick: () => {
                const edge = db.edges.find(e => e.entities.includes(n) && e.entities.includes(c));
                if (edge) deleteEdge(edge._id);
              }
            }
          ]);
        }}

        >
          <span className="name">
            {c}
          </span>
        </div>
      )
      
      return (
        <div  key={c}>
        <EntityRow id={c} parent={n} path={n} el={el?.(cc)} />
        {underEntity?.(cc)}
        </div>
      );
    }).filter(Boolean);
  }

  const hidden = state.hidden ?? defaultHidden;

  return (
    <>
      <div className={classNames('section contents', {
        hidden: hidden,
      })}>
        <span
          className="sectionTitle"
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Create query',
                onClick: () => {
                  createQuery([
                    QUERY,
                    baseQuery.concat([
                      state.contentsType && query_entityType(state.contentsType),
                    ]),
                  ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n]);
                }
              },
              {
                text: 'Create relative query',
                onClick: () => {
                  const q = createQuery([
                    QUERY,
                    baseQuery.concat([
                      state.contentsType && query_entityType(state.contentsType),
                    ]),
                  ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], null, true);

                  XObject.push(entity, 'queries', XObject.obj({
                    query: q._id,
                  }));
                }
              },
            ]);
          }}
        >
          {hidden && <Svg name="chevron" className="hiddenIndicator" />}
          <span
            className="sectionTitleTitle"
            onClick={() => {
              console.log(id);
              state.hidden = !hidden;
            }}
          >{title}</span>
          
          {/* {orgSameType && (
            <>
              <select>
                <option>{pluralize(db.entityTypes.findById(orgSameType).name)} ({allCount})</option>
              </select>
            </>
          )} */}

          {/* {!orgSameType && (
            <>
                          <select value={state.contentsType || ''}
            onChange={e => {
              state.contentsType = e.target.value;
              delete state.groupBy;
            }}
          >
            <option value="">All ({allCount})</option>
            {Object.keys(types).filter(t => t != 'untyped').map(t => (
              <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
            ))}
            {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
          </select>
            </>
          )} */}

          {/* {singleType && (() => {
            const schema = entitySchema(contents[0]);
            if (!schema) return null;
            const attributes = schema.attributes && evaluate(schema.attributes);

            return (
              <>
                <select
                  value={state.groupBy || ''}
                  onChange={e => {
                    state.groupBy = e.target.value;
                  }}
                >
                  <option value="">No Grouping</option>
                  {attributes?.map?.(a => {
                    const attr = db.attributeTypes.findById(a);
                    if (!attr) return null;
                    return (
                      <option key={a} value={a}>{attr.name}</option>
                    )
                  })}
                </select>
              </>
            )
          })()} */}
          


          {/* <div className="checkboxes">
            {customCheckboxes?.()}
            <input title="Include Background Entities" type="checkbox" checked={state.includeBackgroundEntities} onChange={e => {
              state.includeBackgroundEntities = e.target.checked;
            }} />
            <input title="Only Show Background Entities" type="checkbox" checked={state.onlyShowBackground} onChange={e => {
              state.onlyShowBackground = e.target.checked;
            }} />

          </div> */}

        </span>

        
        {!hidden && (
          <>
                      {grouped && (
          <>
            {grouped.map(({ key, group, entities }) => {
              const r = render(entities);
              if (!r?.length) return;
              return (
                <div key={group} className="group">
                  <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(state.groupBy, key, group)}</div>
                  {r}
                </div>
              )
            })}
          </>
        )}
        {!grouped && render(orgContents)}
        {after?.()}
          </>
        )}


      </div>
    </>
  );
}

@component
class HTMLPreview extends Component<{ html: string }> {
  static styles = styled.div`
    h1 {
      display: inline;
      font-size: inherit;
      margin: 0;
      padding: 0;
      margin-right: 4px;
    }
    ul, li, p {
      margin: 0;
      padding: 0;
      display: inline;
      margin-right: 4px;
    }
  `;
  render() {
    return <Truncate
    lines={1}
    dangerouslySetInnerHTML={{
     __html: this.props.html
    }}
  />
  }
}

const hoverStyles = css`
  border-radius: 3px;
  /* padding: 0px 6px; */
  /* height: 34px; */
  /* display: flex; */
  /* align-items: center; */
  color: #969696;
  &:hover {
    background: rgba(57, 57, 57, 0.08);
  }
`

function getEntityBacklinks(entityId: string) {
  // return [];
  const sources = [];
  for (const entity of db.entities) {
    if (inArchivedSpace(entity, entityId)) continue;
    if (entity.documents) {
      for (const document of entity.documents) {
        const entities = collectEntitiesGood(document.content || []);
        const f = entities.find(e => e.entity === entityId);
        if (f) {
          sources.push({
            document: document._id,
            sourceEntity: entity._id,
            block: f.block,
          });
        }
      }
    }

    if (_.isArray(x(entity.name))) {
      const r = entity.name.filter(e => e?.[1] == 'entity' && e[0] == entityId);
      if (r.length) sources.push({ sourceEntity: entity._id });
    }
  }

  for (const doc of db.notionDocuments) {
    const entities = collectEntitiesGood(doc.blocks || []);
    if (doc.parent?.type == ObjectType.entity) {
      const f = entities.find(e => e.entity === entityId);
      if (f) {
        sources.push({
          document: doc._id,
          sourceEntity: doc.parent.id,
          block: f.block,
        });
      }
    }
  }
    

  return sources;
}

function _renderElement(element: CompiledValuePoint, map, id) {
  if (!element.type) return;
  const entity = db.entities.findById(id);
  let c;
  let styles;
  if (element.type[1] == $AllEntitiesMatch.$) {
    const pass = executeAllEntitiesMatch(element, {
      ...map,
    });
    c = pass ? '✓' : '✗';
  }
  else if (element.type[1] == $EntityElement.$) {
    const mapped = mapStructure(element);
    c = _renderElement(mapped.content, map, id);
  }
  else if (element.type[0] == ValueType.EntityElement) {
    c = 'poop2';
  }
  else if (element.type[0] == ValueType.EntityAttribute) {
    styles = {
      color: 'gray',
      fontSize: '.8em',
      fontWeight: 500,
    }
    c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
  }
  else if (element.type[0] == ValueType.CodeComponent) {
    c = 'test!';
  }

  return (
    <span data-value-point={element._id} key={element._id} style={styles}>{c}</span>
  )
}

export function _renderEntityElements(id, _elements) {
  const entity = db.entities.findById(id);

  let elements;
    const map = {
      [$EntityTemplate.Entity]: id,
    }
    elements = _elements.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      if (!element?.type) return;
      let c;
      let styles;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[1] == $EntityElement.$) {
        c = 'poop';
      }
      else if (element.type[0] == ValueType.EntityElement) {
        c = 'poop2';

        const el = db.elements.findById(element.content);
        // if (el.type == ElementConfigType.glue) {
          c = _renderElement(execute(el.valuePoint), map, id);
        // }

      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        styles = {
          color: 'gray',
          fontSize: '.8em',
          fontWeight: 500,
        }
        c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        if (element.content) {
          if (getCodeComponent(element.content)) {
            const comp = db.codeComponents.findById(element.content);
            if (comp.type == CodeComponenType.function) {
              c = getCodeComponent(element.content)(entity);
            }
            else if (comp.type == CodeComponenType.component) {
              const Comp = getCodeComponent(element.content);
              c = <Comp entity={entity} />;
            }
            else {
              c = 'test!';
            }  

          }
          else {
            c = 'no component';
          }
        }
        else {
          c = 'no value';
        }
      }
      else if (element.type[0] == ValueType.String) {
        c = element.content;
      }


      return (
        <span data-value-point={e._id} key={element._id} style={styles}>{c}</span>
      )
    });


  return elements;
}

export function renderEntityElements(id) {
  if (!id) return [];
  // return [];
  const entity = db.entities.findById(id);
  const schema = entitySchema(id, {});
  let elements;
  if (schema?.elements) {
    const map = {
      [$EntityTemplate.Entity]: id,
    }
    elements = schema.elements.content.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      let c;
      let styles;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        styles = {
          color: 'gray',
          fontSize: '.8em',
          fontWeight: 500,
        }
        c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        c = 'test';
      }

      return (
        <span data-value-point={e._id} key={element._id} style={styles}>{c}</span>
      )
    });
  }

  return elements;
}

export function renderEntityRowElememnts(c) {
  // return [];
  const entity = db.entities.findById(c);
  const schema = entitySchema(c, {});
  let rowElements;
  if (schema?.rowElements) {
    const map = {
      [$EntityTemplate.Entity]: c,
    }
    rowElements = schema.rowElements.content.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      let c;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        return renderAttributeValue(element.content, getAttributeKey(element.content, c), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        c = 'test';
      }


      return (
        <span data-value-point={e._id} key={element._id}>{c}</span>
      )
    });
  }

  return rowElements;
}

function capturedReferences(id: string, passedData: PassedData) {
  const captured: { sourceEntity, attribute }[] = [];
  for (const ref of passedData.references) {
    const attr = db.attributeTypes.findById(ref.attribute);
    if (attr?.reversePropRules?.length) {
      captured.push(ref);
    }
  }
  return captured; 
}

function excludeReferences(source: {sourceEntity, attribute}[], compare: {sourceEntity, attribute}[]) {
  return source.filter(s => !compare.find(c => c.sourceEntity == s.sourceEntity && c.attribute == s.attribute));
}

class BlockManager {
  constructor(private entity, private page, private passedData: PassedData) {

  }

  _entityPageBlocks() {
    return XObject.get(XObject.get(this.entity, 'pageBlocks', {
      [this.page._id]: [],
    }), this.page._id, []);;
  }

  addPersonalBlock(index?) {
    this.insertBlock({
      block: XObject.obj(),
      personal: true,
    }, index ?? this.blocks().length);
  }

  resetAfter() {
    const typeBlocks = this.matchedBlocks();
    const entityPageBlocks = this._entityPageBlocks();
    for (let i = 0; i < entityPageBlocks.length; i++) {
      const personalBlock = entityPageBlocks[i];
      if (i == 0) {
        personalBlock.after = typeBlocks[typeBlocks.length - 1]._id;
      }
      else {
        personalBlock.after = entityPageBlocks[i - 1]._id;
      }
    }
  }

  matchedBlocks(baseRels={}) {
    return this.page.blocks?.filter?.(b => {
      if (b.match) {
        return b.match[1] == this.entity.type;
      }
      else if (b.type == BlockType2.systemProperties) {
        return true;
      }
      else if (b.type == BlockType2.systemReferences) {
        const captured = capturedReferences(this.entity._id, this.passedData);
        let refs = excludeReferences(this.passedData.references, captured);
                refs = refs.filter(r => {
          if (baseRels[r.sourceEntity]?.[1] == r.attribute) {
            return false;
          }
          return true;
        });

        return refs.length > 0;
      }
      else if (b.type == BlockType2.systemBacklinks) {
        return this.passedData.backlinks.length > 0;
      }
      else if (b.type == BlockType2.systemEntities) {

        
        let r = getEdgesForEntity(this.entity._id).filter(e => {
          return e.directed && e.entities[0] == this.entity._id;
        }).map(e => e.entities[1]);

                      r = r.filter(id => {
                if (baseRels[id]?.[0] == 'children' || baseRels[id]?.[0] == 'descendants') {
                  return false;
                }
                return true;
              })

        

        return r.length > 0;
      }
      else if (b.type == BlockType2.attributeReferences) {
        const attr = db.attributeTypes.findById(b.attribute);
        const rule = attr.reversePropRules.find(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54');
        if (rule) {
          let refs = this.passedData.references.filter(r => r.attribute == b.attribute).map(r => r.sourceEntity)
          if (rule.filter) {
            refs = executeQuery(rule.filter, refs);
          }

          return refs.length > 0;
        }
      }
    }) || [];
  }

  blocks(baseRels={}): {
    personal
    block
  }[] {
    const entityPageBlocks = this._entityPageBlocks();

    const typeBlocks = this.matchedBlocks(baseRels);

    const blocks = [];

    for (const block of typeBlocks) {
      blocks.push(block);
    }

    const toAdd = [...entityPageBlocks]
    loop: while (toAdd.length > 0) {
      for (let i = 0; i < toAdd.length; i++) {
        const block = toAdd[i];
        if (block.after) {
          const after = blocks.find(b => b._id == block.after);
          if (after) {
            const index = blocks.indexOf(after);
            blocks.splice(index + 1, 0, block);
            toAdd.splice(i, 1);  
            continue loop;
          }

        }
        else {
          blocks.unshift(block);
          toAdd.splice(i, 1);
          continue loop;
        }
      }
    }


    const b = blocks.map(b => ({
      personal: !typeBlocks.find(tb => tb._id == b._id),
      block: b,
    }))

    return b;
  }

  insertBlock(block: {personal, block }, index) {

    const blocks = this.blocks();
    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);

    const entityPageBlocks = x(this._entityPageBlocks());


    if (block.personal) {
      entityPageBlocks.push(block.block);
    }
    else {
      // newPageBlocks.push(block.block);
    }


    if (block.personal) {
      afterChanges[block.block._id] = blocks[index - 1]?.block?._id;

    }
    else {
      let lastBlock;
      for (let i = index - 1; i >= 0; i--) {
        if (!blocks[i].personal) {
          lastBlock = blocks[i];
          break;
        }
      }

      if (lastBlock) {
        const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
        newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
      }
      else {
        newPageBlocks.unshift(block.block);
      }
    }

    const currentBlock = blocks[index];

    if (currentBlock) {
      if (currentBlock.personal) {
        afterChanges[currentBlock.block._id] = block.block._id;
      }
    }


    this.page.blocks = X(newPageBlocks);

    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);

  }

  delete(index) {
    const blocks = this.blocks();
    const afterChanges = {};
    const block = blocks[index];
    const newPageBlocks = x(this.page.blocks);

    const nextBlock = blocks[index + 1];
    if (nextBlock?.personal) {
      afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
    }

    if (block.personal) {
      this._entityPageBlocks().splice(this._entityPageBlocks().findIndex(b => b._id == block.block._id), 1);
    }
    else {
      newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
    }
    
    blocks.splice(index, 1);

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this._entityPageBlocks());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);


  }

  moveBlock(fromIndex, toIndex) {
    if (fromIndex == toIndex) return;
    const blocks = this.blocks();

    const blockIds = {};

    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);
    for (const block of newPageBlocks) {
      blockIds[block._id] = true;
    }

    const removeBlock = index => {
      const block = blocks[index];
      const nextBlock = blocks[index + 1];
      if (nextBlock?.personal) {
        afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
      }

      if (block.personal) {
        afterChanges[block.block._id] = false;
      }
      else {
        newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
      }
      
      blocks.splice(index, 1);
    }
    const insertBlock = (index, block: { personal, block }) => {
      if (block.personal) {
        console.log(afterChanges[block.block._id] = blocks[index - 1]?.block?._id);

      }
      else {
        let lastBlock;
        for (let i = index - 1; i >= 0; i--) {
          if (!blocks[i].personal) {
            lastBlock = blocks[i];
            break;
          }
        }

        if (lastBlock) {
          const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
          console.log(lastBlockIndex)
          newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
          console.log(1);
        }
        else {
          newPageBlocks.unshift(block.block);
          console.log(2);
        }
      }

      const currentBlock = blocks[index];

      if (currentBlock) {
        if (currentBlock.personal) {
          afterChanges[currentBlock.block._id] = block.block._id;
        }
      }

    }

    const block = blocks[fromIndex];
    removeBlock(fromIndex);

    insertBlock(toIndex, block);

    for (const id in blockIds) {
      if (!newPageBlocks.find(b => b._id == id)) {
        console.log('missing a block', id, x(block));
        return
      }
    }

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this._entityPageBlocks());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);
  }
}

@component
class EntityBlocks extends Component<{ page, entity, renderAttributes, passedData: PassedData }> {
  state = XInit(class {
    blockState = {}
    reordering = false
  })

  static styles = styled.div`
    .section {
      margin-bottom: 0 !important;
      padding-top: 0 !important;
    }
    .line {
      height: 4px;
      margin: 8px 0;
      cursor: pointer;
      position: relative;

      &:hover:before {
        background: #ccc;
      }


      &:before {
        content: '';
        position: absolute;
        background: #f7f7f7;
        top: 0;
        /* width: 70%; */
        right: 0;
        left: 0;
        /* left: 100px; */
        /* right: 100px; */
        height: 2px;
        /* width: 100%; */
        margin: auto;
        bottom: 0;
      }
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 28px;
      svg {
        width: 15px;
        height: 15px;
      }
      z-index: 999999;
    }

    .block__ {
      /* padding-top: 4px; */


      .actionBar {
        content: '';
        position: absolute;
        left: -11px;
        top: 0;
        bottom: 0;
        /* height: 30px; */
        margin: auto;
        width: 3px;
        background: #eee;

        cursor: pointer;

        &:hover {
          background: #ccc;
        }
      }

      position: relative;

      &.personal {
        .actions {
          svg {
            fill: #5684ee;;
          }
        }

        .actionBar {
          /* background: #5684ee4d; */
          background: #569eee4d;

          &:hover {
            background: #5684ee;
          }
        }
      }
    }

    ${ViewQuery} {
      flex: 1 1 auto;
    }
  `;

  static t = {
    init: styled.div`
      span {
        padding: 3px 2px;
        margin-top: 16px;
        margin-bottom: 4px;
        letter-spacing: 0px;
        font-size: 13px;
        color: rgba(55, 53, 47, 0.5);
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    
      button {
        display: block;
        width: 100%;

        appearance: none;
        border: none;
        background: transparent;

        height: 32px;

        &:hover {
          background: #f3f3f3;
          color: #4a4a4a;
        }

        border-radius: 3px;
        font: inherit;

        text-align: left;
        cursor: pointer;

        color: inherit;

        color: #969696;
      }
    `,
  }

  render() {
    const { t } = EntityBlocks;

    const page = this.props.page;
    const entity = this.props.entity;
    const renderAttributes = this.props.renderAttributes;
    const n = entity._id;

    const renderBlock: (block)=>[any, any] = (block => {
      if (!block.type) {
        return [(
          <t.init>
            <span>Block type:</span>
            <button
              onClick={() => {
                const doc = XObject.obj({
                  relative: true,
                  key: '0a2b8091-6355-5df8-9abb-ae59667d9206',
                  parent: {
                    type: ObjectType.entity,
                    id: n,
                  },
                  blocks: [],
                });
                db.notionDocuments.push(doc);

                block.type = BlockType2.page;
                block.page = doc._id;

              }}
            >Page</button>
            <button
            
              onClick={() => {
                block.attributes = X([]);
                block.type = BlockType2.attributes;
              }}
            >Attributes</button>

            <button
              onClick={() => {
                block.type = BlockType2.schemaAttributes;
              }}
            >Schema Attributes</button>

            <button
            
            onClick={() => {
              const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], {
                type: ObjectType.entity,
                id: n,
              }, true);
              block.query = query._id;


              block.type = BlockType2.query;
            }}
          >Query</button>

            <button
            
            onClick={() => {
              const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], {
                type: ObjectType.entity,
                id: n,
              }, true);
              block.query = query._id;


              block.type = BlockType2.contentsQuery;
            }}
          >Contents Query</button>



          <button
            onClick={() => {
              block.type = BlockType2.richTextAttribute;
            }}
          >Rich Text Property</button>
          </t.init>
        )];
      }

      const st = XObject.get(this.state.blockState, block._id, {});

      if (block.type == BlockType2.page) {
        const doc = db.notionDocuments.findById(block.page);
        if (!doc) return ['!!'];
        return [(
          <>
            <NotionDocumentWrapper
              docId={doc._id}
              entity={n}
              inline
              blocks={doc.blocks}
              setBlocks={blocks => {
                doc.blocks = blocks;
              }}
              configId={null}
              name={null}
              configMap={{
                [$Document.Entity]: n,
              }}
            />
          </>
        ), [{
          text: 'Edit',
          onClick: () => {
            openWindow({
              type: WindowType.NotionDocument,
              notionDocument: doc._id,
            })
          }
        }]]
      }
      else if (block.type == BlockType2.attributes) {
        const schema = entitySchema(n);
        const attributeTypes = db.attributeTypes.filter(a => {
          if (schema?.attributes) {
            const attributes = evaluate(schema.attributes);
            if (attributes?.includes?.(a._id)) {
              return true;
            }
            else {
              return false;
            }
    
          }
          return false;
    
        });

        return [(
          <>
            {renderAttributes(
              block.attributes?.map?.(id => db.attributeTypes.findById(id)) || [],
              attr => {
                XObject.push(block, 'attributes', attr);
              },
              attr => {
                block.attributes.splice(block.attributes.indexOf(attr._id), 1);
              },
              st,
              t => {
                return !attributeTypes.find(a => a._id == t._id);
              }
            )}
          </>
        )]
      }
      else if (block.type == BlockType2.schemaAttributes || block.type == BlockType2.systemProperties) {
        const schema = entitySchema(n);
        const attributeTypes = db.attributeTypes.filter(a => {
          if (schema?.attributes) {
            const attributes = evaluate(schema.attributes);
            if (attributes?.includes?.(a._id)) {
              return true;
            }
            else {
              return false;
            }
    
          }
          return false;
    
        });
        return [(
          <>
            {renderAttributes(
              attributeTypes,
              async value => {
                if (schema?.match) {
                  sendSignal(schema.match, ADD_ATTRIBUTE_SIGNAL, value);
                }
                else {
                  const m = await sendSignal(getValuePoint(defaultWorkspace().entityTemplateSupplier), ADD_MATCH_SIGNAL, entity.type);
                  sendSignal(m, ADD_ATTRIBUTE_SIGNAL, value);
                }  
              },
              attr => {
                sendSignal(schema.match, REMOVE_ATTRIBUTE_SIGNAL, attr._id);
              },
              st,
              null,
              'Add property to schema',
              true,
            )}
          </>
        )]
      }
      else if (block.type == BlockType2.query || block.type == BlockType2.contentsQuery) {
        return [(
          <div
          >
            <ViewQuery
              key={block._id}
              id={block.query}
              state={st}
              entity={n}
              showToolbar={true}
              touched={e => {
              }}
            />
          </div>
        ), [
          {
            text: 'Edit',
            onClick: () => {
              inspectObj({
                type: ObjectType.query,
                id: block.query,
              }, true)

            }
          }
        ]]
      }
      else if (block.type == BlockType2.richTextAttribute) {
        if (!block.attribute) {
          return (
            [<>
              <AttributeSelector
                scopes={[
                  {
                    type: ObjectType.entity,
                    id: n,
                  }
                ]}
                onSelect={attr => {
                  block.attribute = attr;
                }}

              />
            </>]
          )
        }
        else {
          const attr = db.attributeTypes.findById(block.attribute);
          return [
            <>
              <h2>{attr.name}</h2>
              <RichTextEditor
          value={entity?.attributes?.[block.attribute]}
          setValue={value => {
            if (!entity.attributes) {
              entity.attributes = {
                [block.attribute]: value,
              }
            }
            else {
              entity.attributes[block.attribute] = value;
            }
          }}

              />
            </>
          ]
        }
      }
      else if (block.type == BlockType2.systemReferences) {
        const captured = capturedReferences(n, this.props.passedData);

        let refs = excludeReferences(this.props.passedData.references, captured); 

        refs = refs.filter(r => {
          if (baseRels[r.sourceEntity]?.[1] == r.attribute) {
            return false;
          }
          return true;
        });

        return [
          renderEntitiesSection({
            defaultHidden: false,
            id: 'references',
            state: XObject.get(st, 'referencesFilters', {}),
            contents: refs,
            map: ref => ref.sourceEntity,
            title: <><Svg name="tesseract" /> Referenced by</>,
            baseQuery: [
              query_entityReferences(n),
            ],
            n: entity._id,
            el: c => db.attributeTypes.findById(c.attribute)?.name,
          })
        ]
      }
      else if (block.type == BlockType2.systemBacklinks) {
        return (
          [
            renderEntitiesSection({
              defaultHidden: false,
              id: 'backlinks',
              state: XObject.get(st, 'backlinksFilters', {}),
              contents: this.props.passedData.backlinks,
              title: <><Svg name="tesseract" /> Backlinks</>,
              map: b => b.sourceEntity,
              underEntity: c => {
                return;
                const entity = db.entities.findById(c.sourceEntity);
                const doc = entity.documents.find(d => d._id == c.document);
                const block = findBlock(doc.content, c.block);
                return (
                  <div className="blockRef">
                    <NotionBlockWrapper
                      block={block}
                      configId={doc.config}
                      noChildren
                    />
                  </div>
                );
              },
              n: entity._id,
            })
          ]
        )
      }
      else if (block.type == BlockType2.systemEntities) {
        return [
          renderEntitiesSection({
            defaultHidden: false,
            id: 'contents',
            state: XObject.get(st, 'contentsFilters', {
              includeDescendants: true,
            }),
            contents: (() => {
              let r;
              if (st.includeDescendants) {
                r = queryGraphBasic(entity._id, false, false, !st.contentsFilters?.includeBackgroundEntities).map(e => e.entity);
              }
              else {
                r = getEdgesForEntity(entity._id).filter(e => {
                  return e.directed && e.entities[0] == entity._id;
                }).map(e => {
                  return e.entities[1];
                });
              }

              r = r.filter(id => {
                if (baseRels[id]?.[0] == 'children' || baseRels[id]?.[0] == 'descendants') {
                  return false;
                }
                return true;
              })

              return r;

            })(),
            title: <><Svg name="tesseract" /> Entities</>,
            customCheckboxes: () => {
              return                 <input title="Include Descendants" type="checkbox" checked={st.includeDescendants} onChange={e => {
                st.includeDescendants = e.target.checked;
              }} />

            },
            after: () => {
              // return             <NotionButton text="Add entity" onClick={() => {
              //   add();
              // }} />
  
            },
            baseQuery: [
              st.includeDescendants ?
                query_entityDescendants(n) :
                query_entityChildren(n),
            ],
            n: entity._id,
          })
        ]
      }
      else if (block.type == BlockType2.attributeReferences) {
        
        const attr = db.attributeTypes.findById(block.attribute);
        const rule = attr.reversePropRules.find(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54');
        if (rule) {
          let refs = this.props.passedData.references.filter(r => r.attribute == block.attribute).map(r => r.sourceEntity);

          if (rule.filter) {
            refs = executeQuery(rule.filter, refs);
          }
          return [
            <>
              <b>{rule.title}</b>
              {/* <div>
                {refs.map(id => (
                  <EntityRow key={id} id={id} />
                ))}
              </div> */}

              <ViewQueryish
                entities={refs}
                state={st}
                add={() => {
                  const entity = {
                    type: rule.match?.[0],
                    attributes: {
                      [attr._id]: attr.type == AttributeType.entity ? n : [n]
                    }
                  }

                  createEntity(entity, n);
                }}
                viewManager={{
                  addView: () => {
                    XObject.push(rule, 'views', XObject.obj({
                      type: ViewType.list,
                    }))
      
                  },
                  deleteView: () => {
                  },
                  editView: () => {
                  },
                  get: () => {
                    return rule.views || [];
                  },
                  init: () => {

                  },
                }}
              />
            </>
          ]
        }
      }

      return [(
        <>
        
        </>
      )];
    }) as any;

    const blockManager = new BlockManager(entity, page, this.props.passedData);

    let blocks = blockManager.blocks();
    const baseRels = {};

    for (const block of blocks) {
      if (block.block.type == BlockType2.query || block.block.type == BlockType2.contentsQuery) {
        const query = db.queries.findById(block.block.query);
        executeQuery(queryChain(query), undefined, n, baseRels);
        console.log(baseRels);
      }
    }

    blocks = blockManager.blocks(baseRels);

    return (
      <>
        <SortableCont
          className="blocks"
          distance={this.state.reordering ? 10 : 10}
          style={this.state.reordering ? {
            'user-select': 'none',
          } : {}}
          onSortEnd={({ oldIndex, newIndex }) => {
            blockManager.moveBlock(oldIndex, newIndex);
          }}
          useDragHandle
        >
          {blocks.map?.(({ block, personal }, i) => {
            const id = block._id;
            if (!block) {
              return '';
            }
            const [c, contextMenu ] = renderBlock(block)
            return (
              <SortableEl index={i} key={block._id}>
                <div key={id} data-id={id}>
                  <div className="line" onClick={() => blockManager.addPersonalBlock(i)} />
                  <div className={classNames('block__', { personal })}>
                    <SortHandle>
                      <div
                        className="actionBar"
                        onClick={e => {
                          showContextMenu(e, [
                            {
                              text: 'Delete',
                              onClick: () => {
                                blockManager.delete(i);
                              }
                            },
                            page.match && !block.match && entity.type && {
                              text: 'Type match', 
                              onClick: () => {
                                const b = x(block);
                                blockManager.delete(i);

                                setTimeout(() => {
                                  b.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                                  blockManager.insertBlock({
                                    block: b,
                                    personal: false,
                                  }, i);

                                }, 1000);



                                // entityPageBlocks.splice(entityPageBlocks.indexOf(block), 1);
                                // XObject.push(page, 'blocks', block);
        
                              },
                            },

                            ...((contextMenu || []))
                          ].filter(Boolean), true);
                        }}
                      />
                    </SortHandle>
                    <div
                      style={this.state.reordering ? {
                        maxHeight: '100px',
                        overflow: 'hidden',
                      } :{}}
                    >
                      {c}
                    </div>
                  </div>
                </div>
              </SortableEl>
            );
          })}
        </SortableCont>
        <div className="line"
          onClick={() => {
            blockManager.addPersonalBlock();
          }}
        />
      </>
    );

  }
}

@component
class EntityActivity extends Component<{ id }> {
  static contextType = SystemContext;
  context!: ContextType<typeof SystemContext>;
  render() {
    const children = getEdgesForEntity(this.props.id).filter(e => {
      return e.directed && e.entities[0] == this.props.id;
    }).map(e => {
      return e.entities[1];
    }).filter(e => {
      const entity = db.entities.findById(e);
      if (!entity?.meta?.creation) {
        return false;
      }
      return true;
    });

    children.sort((a, b) => {
      const aEntity = db.entities.findById(a);
      const bEntity = db.entities.findById(b);
      return aEntity.meta.creation.timestamp.getTime() - bEntity.meta.creation.timestamp.getTime();
    });

    const entity = db.entities.findById(this.props.id);


    return (
      <>
        {entity.meta?.creation && <div>

          <b>{db.users.findById(entity.meta.creation.user).name}</b> created this entity at {entity.meta.creation.timestamp.toLocaleString()}
        </div>}
        {children.map(e => {
          const entity = db.entities.findById(e);
          const user = db.users.findById(entity.meta.creation.user);
          return (
            <div key={e}>
              <b>{user.name}</b> added <b
                style={{
                  background: this.context.next?.()?.id == e ? '#f3f3f3' : 'transparent',
                }}
                onClick={() => {
                  this.context.navigate({
                    type: 'entity',
                    id: e,
                  })
                }}
              >{entity.name}</b> at {entity.meta.creation.timestamp.toLocaleString()}
            </div>
          )
        })}
      </>
    );
  }
}

export function entityQueries(id) {
  const entity = db.entities.findById(id);
  const schema = entitySchema(id);
  const length = entity.queries?.length;
  return (entity.queries || []).concat(schema?.queries ? evaluate(schema.queries) : []).map(q => q.query).filter(q => db.queries.findById(q))
}

@component
export class AttributeSelector extends Component<{ scopes, onSelect }> {
  state = XInit(class {
    newPropertyScope
  })
  render() {
    const state = this.state;
    let attrs = [];

    const tree = getScopeTree(this.props.scopes[0]);

    attrs = attrs.concat(attributesInScopes(this.props.scopes));

    attrs = _.uniq(attrs);



    return (
      <>
        <SelectEditor
                close={() => {}}
                frame={null}
                createOption={name => {
                  let scope;
                  if (state.newPropertyScope) {
                    // if (state.newPropertyScope == entity.type) {
                    //   scope = {
                    //     type:ObjectType.type,
                    //     id:entity.type,
                    //   }
                    // }
                    // else {
                      scope = tree.find(t => t.id == state.newPropertyScope);
                    // }
                  }
                  console.log(scope);
                  const newAttr = XObject.obj({
                    name,
                    scope,
                  })
                  db.attributeTypes.push(newAttr);
                  return newAttr._id;
                }}
                options={attrs.map(id => db.attributeTypes.findById(id)).map(t => ({
                  _id: t._id,
                  title: t.scope ? `${t.name} (${objectName(t.scope)})` : t.name,
                }))}
                setValue={async value => {
                  // onAdd(value);
                  // delete state.addingProperty;
                  this.props.onSelect(value);
                }}
                value={null}

                renderCreate={filter => {
                  return <>Create&nbsp;<Tag text={filter} />
                  
                  <select
                    value={state.newPropertyScope || ''}
                    onChange={e => {
                      state.newPropertyScope = e.target.value || null;
                    }}
                  >
                    <option />
                    {/* {entity.type && <option
                      value={entity.type}
                    >{objectName({
                      id: entity.type,
                      type: ObjectType.type,
                    })} (type)</option>} */}
                    {tree.map(t => {
                      return <option key={t.id} value={t.id}>{objectName(t)}</option>
                    })}
                  </select>
                  </>
                }}
              />
      </>
    )
  }
}

function getSystemPage() {
  let systemPage = db.pages.find(p => p.key == 'system');
  if (!systemPage) {
    systemPage = XObject.obj({
      key: 'system',
      match: ['6a1705b5-b274-5578-800d-192f43d77fcb'],
      blocks: [],
    });
    db.pages.push(systemPage);
  }
  return systemPage;
}

@component
class Inspect extends Component<{
  id,
  state: {
    includeDescendants: boolean,
    contentsFilters
    view
    statesExpanded
  }
  onGraphClick
  config
}> {
  static styles = styled.div`
    .nameAndType {
      overflow: hidden;
      position: relative;
      margin-bottom: 8px;
      .timestamp {
        font-size: 10px;
        color: rgba(55, 53, 47, 0.5);
      }

      .entityName {
        flex: 1 1 auto;
      }
      ${TypeEditor} {
        flex: 0 0 auto;
        min-width: 20px;
      }
      ${Tag} {
        margin-right: 7px;
      }
      > .space {
        display: flex;
        align-items: center;
      }

      .right {
        box-shadow: 0 0 3px 0px rgba(0,0,0,0.16);
        transition: opacity 0.2s;
        background-color: white;
        border-bottom-left-radius: 4px;  
        padding-bottom: 2px;
        padding-left: 4px; 
        position: absolute;
        top: 0;
        right: 0;
        > * {
          &:not(:last-child) {
            margin-right: 4px;
          }
        }


        margin-left: auto;

        display: flex;
        align-items: center;
        > * {
          flex: 0 0 auto;
        }

        > .space {
          flex: 1 1 0;
          select {
            flex: 1 1 0;
            width: 100%;
          }

        }

      }

      ${ObjectDisplay} {
        white-space: nowrap;
      }
    }

    &.nameOver:not(.nameFocused) {
      .nameAndType .right {
        opacity: .5;
      }
    }

    &.nameFocused {

      .nameAndType .right {
        pointer-events: none;
      }

      &.nameOver {
        .nameAndType .right {
          opacity: 0;
        }
      }
      &:not(.nameOver) {
        .nameAndType .right {
          opacity: .5;
        }
      }

    }

    > .top {

      > .toolBar {
        ${NotionButton} {
          height: 28px;
        }

        display: flex;
        /* border-bottom: 1px solid rgba(55, 53, 47, 0.1); */
        /* padding-bottom: 4px; */
        margin-bottom: 8px;
        align-items: center;
        .views {
          display: flex;
          align-items: center;
          ${NotionButton} {
            margin-left: 4px;
          }
          .view {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 34px;
            padding: 0 8px;
            color: #bcbcbc;
            font-size: 14px;
            font-weight: 600;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;

            &.personal {
              svg {
                fill: #5684eea1;
              }
            }
            svg {
              width: 15px;
              height: 15px;
              margin-right: 3px;
              fill: #bcbcbc;
            }
            &.noText {
              svg {
                margin-right: 0;
              }
            }
            /* border-bottom: 2px solid #bcbcbc; */
            &.active {
              /* border-bottom: 2px solid #4a4a4a; */
              color: inherit;
              svg {
                fill: #4a4a4a;
              }

              &.personal {
                svg {
                  fill: #5684ee;
                }
                /* border-bottom: 2px solid #5684ee; */
              }

            }
          }
        }
        .right {
          margin-left: auto;
          display: flex;
          align-items: center;

          .create {
            svg {
              fill: #88a4ff;
            }
          }

          svg {
            width: 16px;
            height: 16px;
          }

          .actions {
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &.fullscreen {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        /* > * {
          flex: 1 1 auto;
        } */
      }

      > .view {
        &.fullscreen {
          flex: 1 1 auto;
          position: relative;
        }
      }


    /* } */


    .addButton {
      ${hoverStyles}
      padding-left: 6px;
      padding-right: 6px;
      height: 34px;
      display: inline-flex;
      align-items: center;
      svg {
        fill: #bababa;
        margin-right: 9px;
      }
    }

    .entityName {
      font-size: 26px;
      font-weight: 600;
      display: block;
      cursor: text;

      > * {
        &:empty::before {
          content: 'Untitled';
          color: #373737; 
        }
        &:empty:focus::before {
          content: "";
        }
        
      }

      > *:focus {
        outline: none;
      }

      [contenteditable] {
        outline: none;
      }
    }

    .sectionTitle {
      color: rgba(55, 53, 47, 0.5);
      font-size: 14px;
      display: block;
      font-weight: 600;
      display: flex;
      align-items: center;
      /* border-bottom: 1px solid #ededec; */
      margin-bottom: 8px;
      padding-bottom: 4px;

      .hiddenIndicator {
        transform: rotate(-90deg);
      }


      > .sectionTitleTitle {
        display: flex;
        align-items: center;
        cursor: pointer;
      }


      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;

        fill: #37352f57;
      }


      &.hasToolbar {
        padding-bottom: 0;
      }
    }
    
    .states {
      .title {
        font-size: 12px;
        color: #969696;
      }

      ${Cell} {
        flex: 1 1 auto;
        > * {
          /* width: 100%;s */
          margin-right: 4px;

        }

        border-radius: 3px;
        padding: 0px 6px;
        height: 34px;
        display: flex;
        align-items: center;
        color: #969696;
        &:hover {
          background: #f3f3f3;
        }
      }
    }

    .section {
      /* border-bottom: 1px solid #ededec; */
      margin-bottom: 16px;
      padding-bottom: 8px;

      &.hidden {
        .sectionTitle {
          border-bottom: none;

        }
      }
    }

    .attributes {
      .attribute {
        &.personal {
          .name {
            color: #5684ee;
          }
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        display: flex;
        height: 29px;

        .name {
          font-size: 12px;
          width: 160px;
          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          color: #969696;
          &:hover {
            background: #f3f3f3;
          }
        }

        .value {
          color: #4a4a4a;
          flex: 1 1 auto;
          > * {
            width: 100%;
          }

          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          &:hover {
            background: #f3f3f3;
          }
        }
      }
    }

    .entityRow {
      width: 100%;
      box-sizing: border-box;
      border-radius: 3px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0px 6px;
      &.active {
        background: #f3f3f3;
      }
      .name {
        margin-right: auto;
        display: flex;
        align-items: center;
        ${EntityPath} {
          margin-left: 4px;
          color: rgba(55, 53, 47, 0.5);
        }
      }

      ${EntityPath} {
        font-size: 8px;
      }
    }

    .section.contents {
      /* max-height: 380px; */
      overflow: auto;
      .sectionTitle {
        display: flex;
        align-items: center;
        select {
          margin-left: 4px;
        }
        .checkboxes {
          margin-left: auto;
        }
      }
    }
    .blockRef {
      margin-left: 10px;
      padding-left: 8px;
      border-left: 2px solid #dbdbdb;
    }

    .section.backlinks {
    }

    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .pageView {
      .top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 200px;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        border-top: 1px solid #ddd;
      }
    }
  }
  `;

  static contextType = SystemContext;

  context: any;

  state = XInit(class {
    addingProperty /* = {
      addToRule: true
    } */

    newPropertyScope

    docState:any = {}
    blockState ={}

    // nameFocused = false
  })

  constructor(props) {
    super(props);
    if (this.props.state.includeDescendants === undefined) {
      this.props.state.includeDescendants = true;
    }
  }

  render(Container?) {
    const { id: n, state, onGraphClick, config } = this.props;
    const navigate = this.context.navigate;
    const entity = db.entities.findById(n);
    if (!entity) return 'Entity not found'
    // const queues = defaultWorkspace().queues.filter(q => q.entity === n);
    const schema = entitySchema(n);

    // const fullscreenView = true;

    const systemBlocks: any[] = [
      {
        type: BlockType2.systemProperties,
        key: BlockType2.systemProperties,
      },
      {
        type: BlockType2.systemEntities,
        key: BlockType2.systemEntities,
      },
      {
        type: BlockType2.systemReferences,
        key: BlockType2.systemReferences,
      },
      {
        type: BlockType2.systemBacklinks,
        key: BlockType2.systemBacklinks,
      },
    ]

    for (const attr of db.attributeTypes) {
      if (attr.reversePropRules?.find?.(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54')) {
        systemBlocks.push({
          type: BlockType2.attributeReferences,
          key: BlockType2.attributeReferences + attr._id,
          attribute: attr._id,
        });
      }
    }
  
    const stateTypes = db.stateTypes.filter(s => {
      if (config?.states) {
        return config?.states?.includes?.(s._id);        
      }
      else if (schema?.states) {
        const states = evaluate(schema.states);
        if (states?.includes?.(s._id)) {
          return true;
        }
        else {
          return false;
        }
      }
    });

    const systemPage = getSystemPage();

    for (const sysBlock of systemBlocks) {
      if (!systemPage.blocks.find(b => b.key === sysBlock.key)) {
        systemPage.blocks.push(XObject.obj(sysBlock));
      }
    }

    const schemaAttributes = (() => {
      if (schema?.attributes) {
        return evaluate(schema.attributes);
      }
      else {
        return [];
      }
    })();

    let attributeTypes = db.attributeTypes.filter(a => {
      if (config?.attributes) {
        return config?.attributes?.includes?.(a._id);
      }
      else if (schemaAttributes.includes(a._id)) {
        return true;
      }
      return false;

    })

    if (entity.hasAttributes) {
      for (const id of entity.hasAttributes) {
        if (!attributeTypes.find(a => a._id === id)) {
          attributeTypes.push(db.attributeTypes.findById(id));
        }
      }
    }

    if (entity.attributes) {
      for (const id in entity.attributes) {
        if (!attributeTypes.find(a => a?._id === id)) {
          attributeTypes.push(db.attributeTypes.findById(id));
        }
      }
    }

    attributeTypes = _.uniqBy(attributeTypes, '_id');

    let queries = (entity.queries || []).concat(schema?.queries ? evaluate(schema.queries) : []);

    const defaultContentHidden = !!queries.find(q => q.inline);

    // unique queries by .query
    queries = queries.filter((q, i) => {
      const index = queries.findIndex(q2 => q2.query === q.query);
      return index === i;
    });

  
    const user = db.users.findById(memoryAppState.user);
    const subscriptions = XObject.get(user, 'subscriptions', {});

    let allCount;
    let contents = (() => {
      let c;
      if (state.includeDescendants) {
        c = queryGraphBasic(this.props.id, false, false, !state.contentsFilters?.includeBackgroundEntities).map(e => e.entity);
        // const descendants = db.entities.find(e => e.path?.includes?.(n));
        // return descendants;
      }
      else {
        c = getEdgesForEntity(this.props.id).filter(e => {
          return e.directed && e.entities[0] == this.props.id;
        }).map(e => {
          return e.entities[1];
        });
      }

      c = _.uniq(c);


      allCount = c.length;


      return c;
    })();

    const renderAttribute = attrId => {
      const t = db.attributeTypes.findById(attrId);
      if (!t) return null;

      let cellType;
      let defaultValue;
      let valuePoint;
      if (t.type === AttributeType.text) {
        cellType = new TextCellType({});
      }
      else if (t.type == AttributeType.entities) {
        cellType = new EntitiesCellType({
          baseEntity: n,
          query: t.query
        });
        defaultValue = [];
      }
      else if (t.type == AttributeType.entity) {
        cellType = new EntityCellType({
          baseEntity: n,
          query: t.query
        });
      }
      else if (t.type == AttributeType.valuePoint) {
        if (t.valuePoint) {
          const value = execute(t.valuePoint, new Runtime({}));
          if (value.type?.[1] == $GroupedSelectAttribute.$) {
            cellType = new $GroupedSelectAttribute_CellType({
              valuePoint: t.valuePoint
            });
          }
        }
        else {
          return (
            <>
              <PropertyField object={t} property="valuePoint" />
              <button
                onClick={() => {
                  const valuePoint = createRootValuePoint();
                  t.valuePoint = valuePoint._id;
                }}
              >New</button>
            </>
          );
        }
      }
      else if (t.type == AttributeType.switch) {
        if (t.valuePoint) {
          const value = executeSwitch(t.valuePoint, n);
          valuePoint = t.valuePoint;
          if (value?.type?.[1] == $GroupedSelectAttribute.$) {
            cellType = new $GroupedSelectAttribute_CellType({
              valuePoint: value._id,
            });
          }
          else {
            return <span data-value-point={t.valuePoint}>No entry</span>
          }
        }
        else {
          return (
            <span data-value-point={t.valuePoint}>
              <PropertyField object={t} property="valuePoint" />
              <button
                onClick={() => {
                  const valuePoint = createRootValuePoint();
                  t.valuePoint = valuePoint._id;
                }}
              >New</button>
            </span>
          );
        }
      }
      else if (t.type == AttributeType.priority) {
        cellType = new PriorityCellType({});
      }
      else if (t.type == AttributeType.select) {
        cellType = new SelectCellType({
          addOption: (name) => {
            const option = XObject.obj({
              title: name,
            })
            XObject.push(t, 'options', option);
            return option._id;
          },
          options: XObject.get(t, 'options', []),
        });
      }
      else if (t.type == AttributeType.multiSelect) {
        cellType = new MultiSelectCellType({
          addOption: (name) => {
            const option = XObject.obj({
              title: name,
            })
            XObject.push(t, 'options', option);
            return option._id;
          },
          options: XObject.get(t, 'options', []),
        });
        defaultValue = [];
      }
      else if (t.type == AttributeType.richText) {
        const active = this.context.next?.()?.attribute == t._id;

        return (
          <div
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',

              background: active ? 'rgba(0, 0, 0, 0.1)' : undefined,


            }}
            onClick={() => {
              this.context.navigate({
                type: PaneType.richTextEditor,
                entity: n,
                attribute: t._id,
              })

            }}
          >
           
            {entity?.attributes?.[t._id] && <HTMLPreview html={entity.attributes[t._id]} />}


          </div>
        )
      }

      if (cellType) {
        return (
          <span data-value-point={valuePoint}>
            <Cell
              cell={cellType}
              get={() => {
                // console.log('get', x(XObject.get(XObject.get(entity, 'attributes', {}), t._id, defaultValue)));
                return XObject.get(XObject.get(entity, 'attributes', {}), t._id, defaultValue);
              }}
              set={value => {
                XObject.get(entity, 'attributes', {})[t._id] = value;
              }}
            />
          </span>
        );
      }

      if (t.type == AttributeType.entity) {
        return (
          <PropertyField object={XObject.get(entity, 'attributes', {})} property={t._id} display={value => {
            return value && <EntityName id={value} />;
          }} />
        )
      }
      if (t.type == AttributeType.datetime || t.type == AttributeType.dueDate) {
        const styles = {};
        if (t.type == AttributeType.dueDate) {
          if (XObject.get(entity, 'attributes', {})[t._id]) {
            const dueDate = new Date(XObject.get(entity, 'attributes', {})[t._id]);
            if (dueDate < new Date()) {
              styles['color'] = 'red';
            }
          }
        }
          
        return (
          <span style={styles}>
          <PropertyField type="time" object={XObject.get(entity, 'attributes', {})} property={t._id} />
          </span>
        )
      }

      return (
        <>
          <PropertyField type={t.type} object={XObject.get(entity, 'attributes', {})} property={t._id} />
        </>
      );

    }

    const backlinks = getEntityBacklinks(n);
    const references = getReferences(n);  

    const passedData: PassedData = {
      backlinks,
      references,
    }

    const renderEntitiesSection = ({
      defaultHidden,
      id,
      state,
      title,
      contents,
      customCheckboxes = undefined,
      after = undefined,
      underEntity = undefined,
      map = undefined,
      baseQuery = undefined,
      el = undefined
    }) => {
      let orgContents = contents;

      if (map) {
        contents = contents.map(map).filter(Boolean);
      }
      const filterBackgroundEntities = c => {
        const metaStates = entityMetaStates(c);
        const background = metaStates.find(id => {
          const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
          if (metaState.backgroundState) return true;
        });
  
        if (state.onlyShowBackground) {
          if (!background) return false;
        }
        else if (background && !state.includeBackgroundEntities) return false;
  
  
        return true;
      }
      contents = contents.filter(filterBackgroundEntities);

      const allCount = contents.length;

      const types = {};
      for (const id of contents) {
        const entity = db.entities.findById(id);
        if (!entity) continue;
        const type = entity.type && db.entityTypes.findById(entity.type);
        if (type) {
          if (!types[type._id]) types[type._id] = 0;
          types[type._id]++;
        }
        else {
          if (!types['untyped']) types['untyped'] = 0;
          types['untyped']++;
        }
      }
  
      const orgSameType = (() => {
        // return true if all entities are of the same type
        let type;
        for (const id of contents) {
          const entity = db.entities.findById(id);
          if (!entity) continue;
          if (!type) {
            type = entity.type;
          }
          else if (type != entity.type) {
            return false;
          }
        }
        return type;
      })();

      if (state.contentsType) {
        contents = contents.filter(e => {
          if (state.contentsType == 'untyped') return !db.entities.findById(e).type;
  
          const entity = db.entities.findById(e);
          if (entity) {
            return entity.type === state.contentsType;
          }
          return false;
        });
      }

      const singleType = isId(state.contentsType) || orgSameType;

      const grouped = state.groupBy && groupById(orgContents, state.groupBy);


      const render = list => {
        return list?.map?.(cc => {
          let c;
          if (map) {
            c = map(cc);
          }
          else {
            c = cc;
          }

          if (!contents.includes(c)) return null;
          

          
          const entity = db.entities.findById(c);
          

          // const entity = db.entities.findById(c);
          // const schema = entitySchema(c, {});
          // let elements = renderEntityElements(c);
          if (!entity) return (
            <div className="entityRow" key={c}
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Delete edge',
                  onClick: () => {
                    const edge = db.edges.find(e => e.entities.includes(n) && e.entities.includes(c));
                    if (edge) deleteEdge(edge._id);
                  }
                }
              ]);
            }}

            >
              <span className="name">
                {c}
              </span>
            </div>
          )
          
          return (
            <div key={c}>
              <EntityRow id={c} parent={n} path={this.props.id} el={el?.(cc)} />
              {underEntity?.(cc)}
            </div>
          );
        }).filter(Boolean);
      }

      const hidden = state.hidden ?? defaultHidden;

      return (
        <>
          <div className={classNames('section contents', {
            hidden: hidden,
          })}>
            <span
              className="sectionTitle"
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Create query',
                    onClick: () => {
                      createQuery([
                        QUERY,
                        baseQuery.concat([
                          state.contentsType && query_entityType(state.contentsType),
                        ]),
                      ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n]);
                    }
                  },
                  {
                    text: 'Create relative query',
                    onClick: () => {
                      const q = createQuery([
                        QUERY,
                        baseQuery.concat([
                          state.contentsType && query_entityType(state.contentsType),
                        ]),
                      ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], null, true);

                      XObject.push(entity, 'queries', XObject.obj({
                        query: q._id,
                      }));
                    }
                  },
                ]);
              }}
            >
              {hidden && <Svg name="chevron" className="hiddenIndicator" />}
              <span
                className="sectionTitleTitle"
                onClick={() => {
                  console.log(id);
                  state.hidden = !hidden;
                }}
              >{title}</span>
              
              {orgSameType && (
                <>
                  <select>
                    <option>{pluralize(db.entityTypes.findById(orgSameType).name)} ({allCount})</option>
                  </select>
                </>
              )}

              {!orgSameType && (
                <>
                              <select value={state.contentsType || ''}
                onChange={e => {
                  state.contentsType = e.target.value;
                  delete state.groupBy;
                }}
              >
                <option value="">All ({allCount})</option>
                {Object.keys(types).filter(t => t != 'untyped').map(t => (
                  <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                ))}
                {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
              </select>
                </>
              )}

              {singleType && (() => {
                const schema = entitySchema(contents[0]);
                if (!schema) return null;
                const attributes = schema.attributes && evaluate(schema.attributes);

                return (
                  <>
                    <select
                      value={state.groupBy || ''}
                      onChange={e => {
                        state.groupBy = e.target.value;
                      }}
                    >
                      <option value="">No Grouping</option>
                      {attributes?.map?.(a => {
                        const attr = db.attributeTypes.findById(a);
                        if (!attr) return null;
                        return (
                          <option key={a} value={a}>{attr.name}</option>
                        )
                      })}
                    </select>
                  </>
                )
              })()}
              


              <div className="checkboxes">
                {customCheckboxes?.()}
                <input title="Include Background Entities" type="checkbox" checked={state.includeBackgroundEntities} onChange={e => {
                  state.includeBackgroundEntities = e.target.checked;
                }} />
                <input title="Only Show Background Entities" type="checkbox" checked={state.onlyShowBackground} onChange={e => {
                  state.onlyShowBackground = e.target.checked;
                }} />
              </div>
            </span>            
            {!hidden && (
              <>
                {grouped && (
                  <>
                    {grouped.map(({ key, group, entities }) => {
                      const r = render(entities);
                      if (!r?.length) return;
                      return (
                        <div key={group} className="group">
                          <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(state.groupBy, key, group)}</div>
                          {r}
                        </div>
                      )
                    })}
                  </>
                )}
                {!grouped && render(orgContents)}
                {after?.()}
              </>
            )}
          </div>
        </>
      );
    }

    const add = () => {
      const newEntity = XObject.obj({
        name: 'New Entity',
      });
      if (state.contentsFilters?.contentsType && state.contentsFilters?.contentsType != 'untyped') newEntity.type = state.contentsFilters?.contentsType;
      createEntity(newEntity, n);
      db.edges.push(XObject.obj({
        directed: true,
        entities: [n, newEntity._id],
      }));
      navigate?.({
        type: 'entity',
        id: newEntity._id,
      });

    }

    const tree = getScopeTree({
      type: ObjectType.entity,
      id: n,
    })

    let attrs = [];

    if (entity.type) {
      attrs = attributesInScope({
        type: ObjectType.type,
        id: entity.type,
      })
    }

    attrs = attrs.concat(attributesInScope({
      id: n,
      type: ObjectType.entity,
    }));

    attrs = _.uniq(attrs);

    

    const matchedPages = db.pages.filter(p => p.match[0] == '6a1705b5-b274-5578-800d-192f43d77fcb' || entity.type && p.match[1] == entity.type);

    if (!entity.pageIndex) {
      entity.pageIndex = X(matchedPages.map(p => p._id));
    }
    else {
      for (const page of matchedPages) {
        if (!entity.pageIndex.includes(page._id)) {
          entity.pageIndex.push(page._id);
        }
      }
    }


    if (!state.view) {
      state.view = systemPage._id;
    }


    const activeView = state.view && schema?.views?.content?.find?.(v => v._id == state.view);

    const activePage = state.view && entity.pages?.find?.(p => p._id == state.view) || db.pages.findById(state.view);

    const reverseLineProps = getReverseLineProps(passedData);

    const fullscreen = activeView && mapStructure(activeView)?.fullscreen?.content || state.view == 'c9c20aa3-b226-5d27-ad0d-d5f04535ca96';

    const renderAttributes = (attributeTypes, onAdd, remove, state: {
      addingProperty: boolean;
      newPropertyScope
    }, isPersonal?, addLabel?, reverse?) => {
      return (
        <InsertionCont
          className="attributes section"
          onInsert={i => {
            // onAdd(i);
            state.addingProperty = true;
          }}
          tag="div"
          orientation="vertical"
        >
          {attributeTypes.filter(Boolean).map(t => {
            if (t._id == schema?.titleAttribute?.content) return null;
            return (
              <div key={t._id} className={classNames("attribute", {
                personal: isPersonal?.(t),
              })}>
                <span
                  className="name"
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Clear type',
                        onClick: () => {
                          delete t.type;
                        }
                      },
                      {
                        text: 'Remove',
                        onClick: () => {
                          remove(t);
                        }
                      },
                      {
                        text: 'Delete value',
                        onClick: () => {
                          delete entity.attributes[t._id];
                        }
                      },
                      {
                        text: 'Edit',
                        onClick: () => {
                          inspectObj({
                            type: ObjectType.attribute,
                            id: t._id,
                          }, true);
                        }
                      },
                      {
                        text: 'Copy ID',
                        onClick: () => {
                          navigator.clipboard.writeText(t._id);
                        }
                      },
                      {
                        text: 'Debug',
                        onClick: () => {
                          console.log(x(entity.attributes[t._id]))
                        },
                      }
                    ]);
                  }}
                >
                  {t.name}
                </span>
                <div className="value">
                  {!t.type && (
                    <>
                      <DraftSelect
                        inline
                        id='asdfasdfsdf'
                        options={types}
                        onSelect={value => {
                          t.type = value;
                        }}
                      />
                    </>
                  )}
                  {t.type && (() => {
                    return renderAttribute(t._id);
                  })()}
                </div>
              </div>
            );
          })}

          {reverse && (() => {
            const reverseLineProps = getReverseLineProps(passedData);

            return (
              Object.keys(reverseLineProps).map(id => {
                const rule = reverseLineProps[id].rule;
                const entities = reverseLineProps[id].entities;

                return (
                  <div key={id} className="attribute"
                  >
                    <span className="name"
                      onContextMenu={e => {
                        showContextMenu(e, [
                          {
                            text: 'Edit',
                            onClick: () => {
                              inspectObj({
                                type: ObjectType.attribute,
                                id: id,
                              });    
                            }
                          }
                        ])
                      }}
                    >{rule.title}</span>
                    <div className="value"><div>{entities.map(id => <Tag
                      key={id}  
                      text={entityDisplayName(id)}
                      _onClick={() => {
                        this.context?.navigate?.({
                          type: 'entity',
                          id,
                        })
                      }}
                    />)}</div></div>
                  </div>
                )
              })
            )

          })()}

          {state.addingProperty && (
            <>
              <SelectEditor
                close={() => {}}
                frame={null}
                createOption={name => {
                  let scope;
                  if (state.newPropertyScope) {
                    if (state.newPropertyScope == entity.type) {
                      scope = {
                        type:ObjectType.type,
                        id:entity.type,
                      }
                    }
                    else {
                      scope = tree.find(t => t.id == state.newPropertyScope);
                    }
                  }
                  console.log(scope);
                  const newAttr = XObject.obj({
                    name,
                    scope,
                  })
                  db.attributeTypes.push(newAttr);
                  return newAttr._id;
                }}
                options={attrs.map(id => db.attributeTypes.findById(id)).map(t => ({
                  _id: t._id,
                  title: t.scope ? `${t.name} (${objectName(t.scope)})` : t.name,
                }))}
                setValue={async value => {
                  onAdd(value);
                  delete state.addingProperty;
                }}
                value={null}

                renderCreate={filter => {
                  return <>Create&nbsp;<Tag text={filter} />
                  
                  <select
                    value={state.newPropertyScope || ''}
                    onChange={e => {
                      state.newPropertyScope = e.target.value || null;
                    }}
                  >
                    <option />
                    {entity.type && <option
                      value={entity.type}
                    >{objectName({
                      id: entity.type,
                      type: ObjectType.type,
                    })} (type)</option>}
                    {tree.map(t => {
                      return <option key={t.id} value={t.id}>{objectName(t)}</option>
                    })}
                  </select>
                  </>
                }}
              />
            </>
          )}

          {/* <NotionButton text={addLabel || "Add a property"} onClick={e => {
            state.addingProperty = true;
          }} /> */}
        </InsertionCont>
      )
    }

    let nameChangeTimer;

    return (
      <Container data-value-point={config?.valuePoint}>
        <div className={classNames("top", {
          fullscreen,
        })}>
          <div className="nameAndType">
            <span className="entityName">
              {schema?.titleAttribute && renderAttribute(schema.titleAttribute.content)}
              {!schema?.titleAttribute && (
                <MetaStatesWrapper className={metaStatesClasses(n)}>
                  <EntityNameEditor id={entity._id} 
                    _onFocus={() => {
                      jQuery(ReactDOM.findDOMNode(this)).addClass('nameFocused');
                    }}
                    _onBlur={() => {
                      jQuery(ReactDOM.findDOMNode(this)).removeClass('nameFocused');
                    }}
                    _onMouseOver={() => {
                      jQuery(ReactDOM.findDOMNode(this)).addClass('nameOver');
                    }}
                    _onMouseOut={() => {
                      jQuery(ReactDOM.findDOMNode(this)).removeClass('nameOver');
                    }}
                  />
                </MetaStatesWrapper>
              )}
            </span>
            <span className="right">
              {schema?.showTimestamp && entity.meta?.creation?.timestamp && (
                <span className="timestamp">{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
              )}
              {entity.type ? (
                <Tag
                  text={
                    <TypeEditor
                      scopeObj={{
                        id: n,
                        type: ObjectType.entity,
                      }}
                      right
                      value={entity.type}
                      onChange={id => {
                      entity.type = id;
                      }}
                    />
                  }
                />
              ) : (
                <TypeEditor
                  right
                  value={entity.type}
                  onChange={id => {
                    entity.type = id;
                  }}
                  scopeObj={{
                    id: n,
                    type: ObjectType.entity,
                  }}
                />
              )}
              {schema?.showCreator && entity.meta?.creation?.user && <UserBadge user={entity.meta?.creation?.user} />}
              {(!entity.space || _.isString(entity.space)) ?
                <ObjectDisplay
                  obj={{
                    type: ObjectType.space,
                    id: entity.space,
                  }}
                  showPath
                /> : 
                <ObjectDisplay obj={entity.space} showPath />
              }
            </span>
          </div>
          <div className="toolBar">
            <InsertionCont
              className="views"
              onInsert={() => {
                const page = XObject.obj({});
                XObject.push(entity, 'pages', page);
                XObject.push(entity, 'pageIndex', page._id);
              }}
              orientation="horizontal"
              tag="div"
            >
              {entity?.pageIndex?.map?.(id => {
                const page = entity.pages?.find?.(p => p._id === id) || db.pages.findById(id);
                if (!page) return;
                const personal = entity.pages?.find?.(p => p._id === id);

                return (
                  <div key={id} className={classNames("view entity noText page", {
                    active: state.view == id,
                    personal,
                  })}

                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Rename',
                        onClick: async () => {
                          const name = await showPrompt('Rename page', page.name);
                          if (!_.isNil(name)) {
                            page.name = name;
                          }
                        }
                      },
                      !page.match && {
                        text: 'Global match',
                        onClick: () => {
                          page.match = ['6a1705b5-b274-5578-800d-192f43d77fcb'];
                          entity.pages.splice(entity.pages.indexOf(page._id), 1);
                          db.pages.push(page);
            
                        }
                      },
                      !page.match && {
                        text: 'Type match',
                        onClick: () => {
                          page.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                          entity.pages.splice(entity.pages.indexOf(page._id), 1);
                          db.pages.push(page);
            
                        }
                      },
                    ]);
                  }}
                  onClick={() => {
                    state.view = id;
                  }}
                  >{page.name ? page.name : <Svg name="icons8-page" />}</div>
    
                );
                
              })}
              <div className={classNames("view entity noText", {
                active: state.view == 'df0e3751-93ea-5acd-adef-afef57ef9b85'
              })}
              onClick={() => {
                state.view = 'df0e3751-93ea-5acd-adef-afef57ef9b85';
              }}
              ><Svg name="tesseract" /></div>
              {schema?.views?.content?.map?.(v => {
                const mapped = mapStructure(v);
                return (
                  <div key={v._id} className={classNames("view entity", {
                    active: state.view == v._id,
                  })} onClick={() => {
                    state.view = v._id;
                  }}>{mapped?.name?.content}</div>
                )
              })}
            </InsertionCont>
            <div className="right">
              {/* <NotionButton
                img="dots"
                className="actions"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Add query to entity',
                      onClick: () => {
                        const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], null, true);
                        XObject.push(entity, 'queries', XObject.obj({
                          query: query._id,
                        }));
                      }
                    },
                    {
                      text: 'Add query to rule',
                      onClick: async () => {
                        if (schema?.match) {
                          // sendSignal(schema.match, ADD_ATTRIBUTE_SIGNAL, value);
                        }
                        else {
                          const m = await sendSignal(getValuePoint(defaultWorkspace().entityTemplateSupplier), ADD_MATCH_SIGNAL, entity.type);
                          // sendSignal(m, ADD_ATTRIBUTE_SIGNAL, value);
                        }

                      }
                    }
                  ], true);
                }}
              /> */}
              <NotionButton
                className="create"
                img="icons8-create (2)"
                onClick={() => {
                  add();
                }}
              />
              {/* <span className="add" onClick={() => {
                add();
              }}>Add</span> */}
            </div>
          </div>

          {state.view == 'df0e3751-93ea-5acd-adef-afef57ef9b85' && (
            <>
              {/* Elements: {renderEntityElememnts(entity._id)} */}
              {/* <div>Type: <TypeEditor value={entity.type} onChange={id => {
                  entity.type = id;
              }} /></div> */}
      
              {/* <div>
                <input key={entity._id} type="checkbox" checked={entity.stateful} onChange={e => {
                  entity.stateful = !entity.stateful;
                }} /> Stateful
              </div> */}

              <InsertionCont
                className="attributes section"
                onInsert={(i, e) => {
                  showContextMenu(e, [
                    {
                      text: 'Add property to entity',
                      onClick: () => {
                        this.state.addingProperty = {
                          addToEntity:true
                        }

                      }
                    },
                    entity.type && {
                      text: `Add property to rule`,
                      onClick: () => {
                        // console.log(schema.match.type[1])
                        this.state.addingProperty = {
                          addToRule:true
                        }
                      }
                    },
                    // {
                    //   text: 'Add property to new rule',
                    //   onClick: () => {
                    //   }
                    // },
                  ])

                }}
                orientation="vertical"
                tag="div"
              >
                {attributeTypes.map(t => {
                  if (t?._id == schema?.titleAttribute?.content) return null;
                  return (
                    <div key={t._id}
                      className={classNames("attribute", {
                        personal: !schemaAttributes.includes(t._id),
                      })}
                    >
                      <span
                        className="name"
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Clear type',
                              onClick: () => {
                                delete t.type;
                              }
                            },
                            {
                              text: 'Remove',
                              onClick: () => {
                                if (schema?.match) sendSignal(schema.match, REMOVE_ATTRIBUTE_SIGNAL, t._id);
                                if (entity.hasAttributes) {
                                  entity.hasAttributes.splice(entity.hasAttributes.indexOf(t._id), 1);
                                }
                              },
                            },
                            {
                              text: 'Delete value',
                              onClick: () => {
                                delete entity.attributes[t._id];
                              }
                            },
                            {
                              text: 'Edit',
                              onClick: () => {
                                inspectObj({
                                  type: ObjectType.attribute,
                                  id: t._id,
                                });
                              }
                            }
                          ]);
                        }}
                      >
                        {t.name}
                      </span>
                      <div className="value">
                        {!t.type && (
                          <>
                            <DraftSelect
                              inline
                              id='asdfasdfsdf'
                              options={types}
                              onSelect={value => {
                                t.type = value;
                              }}
                            />
                          </>
                        )}
                        {t.type && (() => {
                          return renderAttribute(t._id);
                        })()}
                      </div>
                    </div>
                  );
                })}

                {Object.keys(reverseLineProps).map(id => {
                  const rule = reverseLineProps[id].rule;
                  const entities = reverseLineProps[id].entities;

                  return (
                    <div key={id} className="attribute">
                      <span className="name">{rule.title}</span>
                      <div className="value"><div>{entities.map(id => <Tag
                        key={id}  
                        text={entityDisplayName(id)}
                        _onClick={() => {
                          this.context?.navigate?.({
                            type: 'entity',
                            id,
                          })
                        }}
                      />)}</div></div>
                    </div>
                  )
                })}

                {this.state.addingProperty && (
                  <>
                    <SelectEditor
                      close={() => {}}
                      frame={null}
                      createOption={name => {
                        let scope;
                        if (this.state.newPropertyScope) {
                          if (this.state.newPropertyScope == entity.type) {
                            scope = {
                              type:ObjectType.type,
                              id:entity.type,
                            }
                          }
                          else {
                            scope = tree.find(t => t.id == this.state.newPropertyScope);
                          }
                        }
                        console.log(scope);
                        const newAttr = XObject.obj({
                          name,
                          scope,
                        })
                        db.attributeTypes.push(newAttr);
                        return newAttr._id;
                      }}
                      options={attrs.map(id => db.attributeTypes.findById(id)).map(t => ({
                        _id: t._id,
                        title: t.scope ? `${t.name} (${objectName(t.scope)})` : t.name,
                      }))}
                      setValue={async value => {
                        if (this.state.addingProperty.addToRule) {
                          if (schema?.match) {
                            sendSignal(schema.match, ADD_ATTRIBUTE_SIGNAL, value);
                          }
                          else {
                            const m = await sendSignal(getValuePoint(defaultWorkspace().entityTemplateSupplier), ADD_MATCH_SIGNAL, entity.type);
                            sendSignal(m, ADD_ATTRIBUTE_SIGNAL, value);
                          }  
                        }
                        else if (this.state.addingProperty.addToEntity) {
                          XObject.push(entity, 'hasAttributes', value);
                        }
                        delete this.state.addingProperty;
                      }}
                      value={null}

                      renderCreate={filter => {
                        return <>Create&nbsp;<Tag text={filter} />
                        
                        <select
                          value={this.state.newPropertyScope || ''}
                          onChange={e => {
                            this.state.newPropertyScope = e.target.value || null;
                          }}
                        >
                          <option />
                          {entity.type && <option
                            value={entity.type}
                          >{objectName({
                            id: entity.type,
                            type: ObjectType.type,
                          })} (type)</option>}
                          {tree.map(t => {
                            return <option key={t.id} value={t.id}>{objectName(t)}</option>
                          })}
                        </select>
                        </>
                      }}
                    />
                  </>
                )}

                {/* <NotionButton text="Add a property" onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Add property to entity',
                      onClick: () => {
                        this.state.addingProperty = {
                          addToEntity:true
                        }

                      }
                    },
                    entity.type && {
                      text: `Add property to rule`,
                      onClick: () => {
                        // console.log(schema.match.type[1])
                        this.state.addingProperty = {
                          addToRule:true
                        }
                      }
                    },
                    // {
                    //   text: 'Add property to new rule',
                    //   onClick: () => {
                    //   }
                    // },
                  ])
                }} /> */}
              </InsertionCont>
  
              {stateTypes.length > 0 && entity.stateful && (
                <>
                  <div className="states section">
                    <span className="sectionTitle"
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, config?.statesContextMenu)
                      }}
                      onClick={() => {
                        state.statesExpanded = !state.statesExpanded;
                      }}

                    >
                      States ({stateTypes.length}) <span className="toggle"
                      
                      >{state.statesExpanded ? '-' : '+'}</span>
                    </span>
                    {stateTypes.map(t => {
                      if (!state.statesExpanded && !entity.states?.[t._id]) {
                        return null;
                      }
                      return (
                        <div key={t._id}>
                          {/* <span className="title">{t.values.map(v => v.name).join(', ')}</span> */}
                          <Cell
                            cell={new SelectCellType({
                              showAll: true,
                              options: t.values.map(v => ({
                                _id: v._id,
                                title: v.name,
                              }))
                            })}
                            get={() => {
                              return entity?.states?.[t._id];
                            }}
      
                            set={value => {
                              if (!entity.states)
                              entity.states = X({
                                [t._id]: value,
                              });
                            else {
                              if (entity.states[t._id] == value) {
                                delete entity.states[t._id];
                              }
                              else {
                                entity.states[t._id] = value;
                              }
                            }
      
                            }}
      
                          />
                        </div>
                      );
                    })}
                    
                    {/* <button
                      onClick={() => {
                        db.stateTypes.push(XObject.obj({
                          values: [],
                        }));
                      }}>+</button> */}
                  </div>
                </>
              )}

              {/* {schema?.sets?.content?.map?.(set => {
                const mapped = mapStructure(set);
                const name = evaluate(mapped.name);
                const r = doEntityQuery(mapped.query, {
                  [$EntityTemplate.Entity]: this.props.id,
                }).filter(e => {
                  return !inArchivedSpace(db.entities.findById(e), n)
                })

                if (!mapped.creator && !r.length) return null;
                
                return (
                  <div className="section" key={set._id}>
                    <span className="sectionTitle"><Svg name="database" /> {name}</span>
                    {r.map(e => {
                      touched[e] = true;
                      return <EntityRow id={e} key={e} path={n} />
                    })}
                    {mapped.creator && (
                      <NotionButton text="Add" onClick={() => {
                        executeCreate(mapped.creator, {
                          [$EntityTemplate.Entity]: this.props.id,
                        })
                      }} />
                    )}
                  </div>
                );
              })}

              {mapped?.globalSets?.content?.map?.(set => {
                const mapped = mapStructure(set);
                const name = evaluate(mapped.name);
                const r = doEntityQuery(mapped.query, {
                  [$EntityTemplate.Entity]: this.props.id,
                });

                if (!r.length) return null;
                
                return (
                  <div className="section" key={set._id}>
                    <span className="sectionTitle"><Svg name="database" /> {name}</span>
                    {r.map(e => {
                      touched[e] = true;
                      return <EntityRow id={e} key={e} path={n} />
                    })}
                  </div>
                );
              })}

              {queries?.filter(q => q.inline)?.map?.((q, i) => {
                const query = db.queries.findById(q.query);
                return (
                  <div className="section">
                    <div className="sectionTitle hasToolbar">
                      <span
                        className="sectionTitleTitle"
                                            onContextMenu={e => {
                                              e.preventDefault();
                                              showContextMenu(e, [
                                                {
                                                  text: 'Edit',
                                                  onClick: () => {
                                                    openWindow({
                                                      type: WindowType.Query,
                                                      query: q.query,
                                                    });
                                                  }
                                                },
                                                {
                                                  text: 'Make entry',
                                                  onClick: () => {
                                                    q.inline = false;
                                                  }
                                                },
                                                {
                                                  text: 'Remove from entity',
                                                  onClick: () => {
                                                    entity.queries.splice(entity.queries.findIndex(e => e._id == q._id), 1);
                                                  }
                                                }
                                              ]);
                                            }}
                      
                      >
                        <Svg name="database" /> {query.name || describeQuery(query.query) || '---'}
                      </span>
                    
                      <QueryToolbar
                        query={db.queries.findById(q.query)}
                        state={XObject.get(state, q._id, {})}
                        entity={n}
                      />
                    </div>
                    <ViewQuery
                      key={q._id}
                      id={q.query}
                      state={XObject.get(state, q._id, {})}
                      entity={n}
                      showToolbar={false}
                      touched={e => {
                        touched[e] = true;
                      }}
                    />
                  </div>
                )
              })}

              {queries?.filter(q => !q.inline)?.length > 0 && (
                <div className="section">
                  <div className="sectionTitle"
                    onContextMenu={e => {
                      e.preventDefault();
                      showContextMenu(e, [
                        {
                          text: 'Add query',
                          onClick: () => {
                            const q = createQuery(null, null, null, true);
                            XObject.push(entity, 'queries', XObject.obj({
                              query: q._id,
                            }));
                          }
                        }
                      ])
                    }}
                  ><Svg name="database" /> Queries</div>

                  {queries?.filter(q => !q.inline)?.map?.((q, i) => {
                    const query = db.queries.findById(q.query);
                    if (!query) return (
                      <div key={i}>
                        <button
                          onClick={() => {
                            entity.queries.splice(i, 1);
                          }}
                        >Delete</button>
                      </div>
                    )
                    return (
                      <div key={q._id} className="query"
                        onClick={() => {
                          this.context?.navigate?.({
                            type: 'query',
                            id: query._id,
                            entity: n,
                          })

                        }}
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Edit',
                              onClick: () => {
                                openWindow({
                                  type: WindowType.Query,
                                  query: q.query,
                                })

                              }
                            },
                            {
                              text: 'Delete',
                              onClick: () => {
                                entity.queries.splice(i, 1);
                              }
                            },
                            {
                              text: 'Make inline',
                              onClick: () => {
                                q.inline = true;
                              }
                            }
                          ])
                        }}
                      >
                        {query.name || describeQuery(query.query) || '---'}
                      </div>
                    );
                  })}
                </div>
              )} */}

              {/* <div className="section"> 
                <span className="sectionTitle"><Svg name="document" /> Pages</span>
                {renderDocuments(n, n + 'inspect', state, null, navigate)}
              </div> */}

              {renderEntitiesSection({
                defaultHidden: false,//defaultContentHidden,
                id: 'contents',
                state: XObject.get(state, 'contentsFilters', {
                  includeDescendants: true,
                }),
                contents: (() => {

                  return contents//.filter(e => !touched[e]);
                })(),
                title: <><Svg name="tesseract" /> Entities</>,
                customCheckboxes: () => {
                  return                 <input title="Include Descendants" type="checkbox" checked={state.includeDescendants} onChange={e => {
                    state.includeDescendants = e.target.checked;
                  }} />

                },
                after: () => {
                  // return             <NotionButton text="Add entity" onClick={() => {
                  //   add();
                  // }} />
      
                },
                baseQuery: [
                  state.includeDescendants ?
                    query_entityDescendants(n) :
                    query_entityChildren(n),
                ]
              })}


              {/* <button onClick={() => {
                db.attributeTypes.push(XObject.obj({
                }));
              }}>+1</button> */}

                
              {/* <ul>
                {queues.map(q => {
                  return (
                    <li key={q._id}>
                      <PropertyField object={q} property="name" /><button onClick={() => {
                        openWindow({
                          type: WindowType.Queue,
                          queue: q._id,
      
                        })
                      }}>.</button>
                    </li>
                  );
                })}
              </ul> */}
      

              {/* 
                <h3>Aspects</h3>
                {db.aspectTypes.map(t => {
                  return (
                    <div key={t._id}>
                      <ul>
                        {t.values.map(v => {
                          return (
                            <li key={v._id}>
                              <input type="checkbox" checked={entity?.aspects?.[t._id] == v._id} onChange={() => {
                                if (!entity.aspects)
                                  entity.aspects = X({
                                    [t._id]: v._id,
                                  });
                                else {
                                  if (entity.aspects[t._id] == v._id) {
                                    delete entity.aspects[t._id];
                                  }
                                  else {
                                    entity.aspects[t._id] = v._id;
                                  }
                                }
                              }} /> <PropertyField object={v} property="name" /> <button onClick={() => {
                                t.values.splice(t.values.indexOf(v), 1);
                              }}>x</button>
                            </li>
                          );
                        })}
                        <li>
                          <button onClick={() => {
                            t.values.push(XObject.obj({}));
                          }}>+</button>
      
                        </li>
                      </ul>
                    </div>
                  );
                })}
      
                <button onClick={() => {
                  db.aspectTypes.push(XObject.obj({
                    values: [],
                  }));
                }}>+</button>
              */}

      
              {/* {config?.sections?.map?.(s => {
                return (
                  <div className="section" key={s._id} data-value-point={s._id}>
                    <span className="sectionTitle"
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, s.contextMenu);
                      }}
                    >{s.title}
                    
                    {s.adder && <button
                      onClick={() => {
                        s.adder();
                      }}
                    >+</button>}
                    </span>
                    {s.content}
                  </div>
                );
              })} */}          

              {references.length > 0 && renderEntitiesSection({
                defaultHidden: false,
                id: 'references',
                state: XObject.get(state, 'referencesFilters', {}),
                contents: references,
                map: r => r.sourceEntity,
                title: <><Svg name="tesseract" /> Referenced by</>,
                baseQuery: [
                  query_entityReferences(n),
                ],
                el: c => db.attributeTypes.findById(c.attribute).name,
              })}

              {backlinks.length > 0 && renderEntitiesSection({
                defaultHidden: false,
                id: 'backlinks',
                state: XObject.get(state, 'backlinksFilters', {}),
                contents: backlinks,
                title: <><Svg name="tesseract" /> Backlinks</>,
                map: b => b.sourceEntity,
                underEntity: c => {
                  return;
                  const entity = db.entities.findById(c.sourceEntity);
                  const doc = entity.documents.find(d => d._id == c.document);
                  const block = findBlock(doc.content, c.block);
                  return (
                    <div className="blockRef">
                      <NotionBlockWrapper
                        block={block}
                        configId={doc.config}
                        noChildren
                      />
                    </div>
                  );
                }
              })}

              <div className="section">
                <div className="sectionTitle">Activity <input type="checkbox" checked={!!subscriptions[n]} onChange={e => {
                  if (e.target.checked) {
                    subscriptions[n] = new Date();
                  }
                  else {
                    delete subscriptions[n];
                  }
                }} /></div>
                <EntityActivity id={n} />
              </div>
            </>
          )}

          {/* {state.view == 'df0e3751-93ea-5acd-adef-afef57ef9b85' && (
            <>
              <EntityBlocks page={systemPage} entity={entity} renderAttributes={renderAttributes} />
            </>
          )}
          */}
          {activePage && <EntityBlocks page={activePage} entity={entity} renderAttributes={renderAttributes} passedData={passedData} />}

          {/* {activePage && (() => {
            const page = activePage;
            const entityBlockIndex = XObject.get(XObject.get(entity, 'pageBlockIndex', {
              [page._id]: [],
            }), page._id, []);
            const entityPageBlocks = XObject.get(XObject.get(entity, 'pageBlocks', {
              [page._id]: [],
            }), page._id, []);

            const renderBlock = block => {
              enum BlockType {
                page = 'e65f19a5-5151-5da5-ad08-a9f50193230c',
                attributes = 'd364cbd9-7486-5889-8032-827bf66daca7',
                query = '8eb3a96d-7625-568e-8974-6c7cdb732d0e',
              }
              if (!block.type) {
                return (
                  <>
                    <button
                    
                      onClick={() => {
                        const doc = XObject.obj({
                          relative: true,
                          key: '0a2b8091-6355-5df8-9abb-ae59667d9206',
                          parent: {
                            type: ObjectType.entity,
                            id: n,
                          },
                          blocks: [],
                        });
                        db.notionDocuments.push(doc);

                        block.type = BlockType.page;
                        block.page = doc._id;

                      }}
                    >Page</button>
                    <button
                    
                      onClick={() => {
                        block.attributes = X([]);
                        block.type = BlockType.attributes;
                      }}
                    >Attributes</button>

                  <button
                    
                    onClick={() => {
                      const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], {
                        type: ObjectType.entity,
                        id: n,
                      }, true);
                      block.query = query._id;


                      block.type = BlockType.query;
                    }}
                  >Query</button>
                  </>
                )
              }

              const st = XObject.get(this.state.blockState, block._id, {});

              if (block.type == BlockType.page) {
                const doc = db.notionDocuments.findById(block.page);
                return (
                  <>
                  <NotionDocumentWrapper
                  // extState={this.state.docState}
                    // ref={ref}
                    docId={doc._id}
                    entity={n}
                    inline
                    blocks={doc.blocks}
                    setBlocks={blocks => {
                      doc.blocks = blocks;
                    }}
                    configId={null}
                    name={null}
                    configMap={{
                      [$Document.Entity]: n,
                    }}
                  />
                  </>
                )
              }
              else if (block.type == BlockType.attributes) {
                return (
                  <>
                    {renderAttributes(block.attributes?.map?.(id => db.attributeTypes.findById(id)) || [], attr => {
                      console.log(attr);
                      XObject.push(block, 'attributes', attr);
                    }, st)}
                  </>
                )
              }
              else if (block.type == BlockType.query) {
                return (
                  <>
                  <button
                    onClick={() => {
                      openWindow({
                        type: WindowType.Query,
                        query: block.query,
                      });

                    }}
                  >
                      Edit
                  </button>
                                        
                                        <QueryToolbar
                        query={db.queries.findById(block.query)}
                        state={st}
                        entity={n}
                      />
                    <ViewQuery
                      key={block._id}
                      id={block.query}
                      state={st}
                      entity={n}
                      showToolbar={false}
                      touched={e => {
                        touched[e] = true;
                      }}
                    />

                  </>
                )
              }

              return (
                <>
                
                </>
              );
            }

            const typeBlocks = page.blocks?.filter?.(b => b.match[1] == entity.type) || [];
            for (const b of typeBlocks) {
              if (!entityBlockIndex.includes(b._id)) {
                entityBlockIndex.push(b._id);
              }
            }

            return (
              <>

                {!page.match && (
                  <>
                  <button
                    onClick={() => {
                      page.match = ['6a1705b5-b274-5578-800d-192f43d77fcb'];
                      entity.pages.splice(entity.pages.indexOf(page._id), 1);
                      db.pages.push(page);
                    }}
                  >
                    Global matcb
                  </button>
                  <button
                    onClick={() => {
                      page.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                      entity.pages.splice(entity.pages.indexOf(page._id), 1);
                      db.pages.push(page);

                    }}
                  >
                    Type match
                  </button>
                </>
                )}
                <button
                  onClick={() => {
                    const newBlock = XObject.obj({});
                    entityPageBlocks.push(newBlock);
                    entityBlockIndex.unshift(newBlock._id);
                  }}
                >+</button>

                <div className="blocks">
                  {entityBlockIndex?.map?.((id, i) => {
                    const block = entityPageBlocks?.find?.(b => b._id == id) || page.blocks?.find?.(b => b._id == id);
                    if (!block) {
                      return '';
                    }
                    return (
                      <div key={id} className="block">
                        {renderBlock(block)}

                        {page.match && !block.match && entity.type && (
                          <>
                            <button
                              onClick={() => {
                                block.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                                entityPageBlocks.splice(entityPageBlocks.indexOf(block), 1);
                                XObject.push(page, 'blocks', block);
                              }}
                            >
                              Type Match
                            </button>
                          </>
                        )}

                        <button
                          onClick={() => {
                            entity.blockIndex.splice(i, 1);
                            if (block.match) {
                              // db.blocks.splice(db.blocks.indexOf(block), 1);
                            }
                          }}
                        >
                          -
                        </button>

                        <button
                          onClick={() => {
                            const newBlock = XObject.obj({});
                            entityPageBlocks.push(newBlock);
                            entityBlockIndex.splice(i + 1, 0, newBlock._id);
                          }}
                        >+</button>
                      </div>
                    );
                  })}
                </div>
              </>
            );

          })()} */}
          {activeView && (() => {
            const mapped = mapStructure(activeView);
            // console.log(mapped);

            return (
              <div className={classNames('view', {
                fullscreen: mapped.fullscreen?.content,
              })}>
                {render(mapped.content, {
                  [$EntityTemplate.Entity]: n
                }, RenderType.full, XObject.get(state, activeView._id, {}))}
              </div>
            );
            
          })()}
        

        </div>
      </Container>
    );
  }
}

export function renderInspect(n, state, onGraphClick?, navigate?, config?: {
  states?: string[]
  attributes?: string[]
  sections?: any[]
  statesContextMenu?: any[]
  menu?
  valuePoint?
}) {
  return <Inspect id={n} state={state} onGraphClick={onGraphClick} config={config} />;
}
