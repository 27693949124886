import React, { Component } from 'react';
import { db } from '../db';
import { openWindow } from '../osHelpers';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { WindowType } from '../etc/WindowType';
import { ObjectType } from '../types/QueryParentType';


@component
export class NotionDocumentsWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <ul>
          {db.notionDocuments.map((x, i) => (
            <li key={x._id}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('text/plain', JSON.stringify({
                  type: ObjectType.page,
                  id: x._id,
                }));
              }}


            >

              <PropertyField object={x} property="name" /> (<PropertyField object={x} property="config" />)

              <button
                onClick={() => {
                  openWindow({
                    type: WindowType.NotionDocument,
                    notionDocument: x._id,
                  });
                }}

              >Doc</button>

              <button
                onClick={() => {
                  db.notionDocuments.splice(i, 1);
                }}
              >-</button>
            </li>
          ))}
        </ul>
        <button
          onClick={() => {
            db.notionDocuments.push(XObject.obj({
              name: 'New Notion Document',
              blocks: [],
            }));
          }}
        >+</button>

      </>
    );
  }
}
