import React, { Component } from 'react';
import { db } from './db';
import { PropertyField } from './components/PropertyField';
import { component } from './component2';
import { X, XObject } from './XObject';
import { ObjectType } from './types/QueryParentType';


@component
export class TypesWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <h2>Types</h2>
        <ul>

          {db.entityTypes.map((x, i) => {
            let library;
            if (x.scope?.type == ObjectType.library) {
              library = db.libraries.findById(x.scope.id);
            }
            return (
              <li key={i}>
                <PropertyField object={x} property="name" /> {library && <> ({library.name})</>}


                <select
                  onChange={e => {
                    if (e.target.value) {
                      x.scope = X({
                        type: ObjectType.library,
                        id: e.target.value,
                      });
                    }
                    else {
                      x.scope = null;
                    }
                    e.target.value = '';

                  }}
                >
                  <option />
                  {db.libraries.map((lib, i) => (
                    <option key={lib._id} value={lib._id}>{lib.name}</option>
                  ))}

                </select>




                {/*  (<TypeEditor inline value={x.parent} onChange={value => {
                            x.parent = value;
                            }} />) */}

                <button
                  onClick={() => {
                    db.entityTypes.splice(i, 1);
                  }}
                >-</button>

              </li>
            );
          })}
        </ul>

        <button

          onClick={() => {
            db.entityTypes.push(XObject.obj({
              name: 'New Type',
            }));
          }}
        >+</button>

        <h2>Attributes</h2>
        <ul>
          {db.attributeTypes.map((x, i) => {
            let library;
            if (x.scope?.type == ObjectType.library) {
              library = db.libraries.findById(x.scope.id);
            }

            return (
              <li key={i}>
                <PropertyField object={x} property="name" /> {library && <> ({library.name})</>}


                <select
                  onChange={e => {
                    if (e.target.value) {
                      x.scope = X({
                        type: ObjectType.library,
                        id: e.target.value,
                      });
                    }
                    else {
                      x.scope = null;
                    }
                    e.target.value = '';

                  }}
                >
                  <option />
                  {db.libraries.map((lib, i) => (
                    <option key={lib._id} value={lib._id}>{lib.name}</option>
                  ))}

                </select> <button
                  onClick={() => {
                    db.attributeTypes.splice(i, 1);
                  }}
                >-</button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}
