import React, { Component } from "react";
import { component, styled } from "../component";
import { closeImage } from "./notionDatabase/icons";


@component
export class Tag extends Component<{ text; _onClick?; onClickX?; color?; }> {
  static styles = styled.div`
    height: 17px;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 0px;
    font-size: 11px;
    line-height: 120%;
    color: #565656;
    background: #cde2ff;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .close {
      margin-left: 2px;
      margin-right: 2px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &:not(.hasClose) {
      padding-right: 4px;
    }
  `;

  render(Container?) {
    return (
      <Container onClick={this.props._onClick} className={this.props.onClickX && 'hasClose'} style={{ background: this.props.color }}>
        {this.props.text}
        {this.props.onClickX && (
          <div className="close"
            onClick={this.props.onClickX}
          >
            {closeImage}
          </div>

        )}
      </Container>
    );
  }
}
