import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { x, XObject } from '../XObject';
import { SortableCont, SortableEl, showContextMenu } from '../helpers';
import { Svg } from './Svg';
import classNames from 'classnames';
import { SystemContext } from '../etc/SystemContext';
import { cssImg } from '../img';
import { getSidebar } from '../etc/getSidebar';
import { SidebarItemType } from '../types/SidebarItemType';
import { ObjectType } from '../types/QueryParentType';
import { color } from "../color";


@component
class SidebarItemComp extends Component<{ item: SidebarItem; indent; state; contextMenu?; }> {
  static styles = styled.div`
    &.expanded > .item > .wrapper > .toggle {
      transform: rotate(0deg);
    }
    > .item {
      font-size: 13px;
      &.active {
        background: #eceff9;
      }

      > .wrapper {
        position: relative;
        padding-left: 36px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .toggle {
          position: absolute;
          left: 0px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 12px;
          transform: rotate(-90deg);
          fill: #bababa;
          padding: 2px;
          border-radius: 2px;

          &:hover {
            background: #8b8b8b14;
          }
        }
        > .icon {
          width: 13px;
          height: 13px;
          background: no-repeat center;
          background-size: contain;
          position: absolute;
          left: 19px;
          top: 0;
          bottom: 0;
          margin: auto;

        }
      }


      margin: 0 8px;
      margin-bottom: 2px;

      cursor: pointer;
      padding: 2px 3px 2px 3px;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }

      border-radius: 2px;
      display: flex;
      align-items: center;

      color: #808080;
      font-weight: 500;



    }

    > .children {
      margin-left: 0 !important;
      margin-top: 0 !important;

    }
  `;
  static contextType = SystemContext;
  context: any;
  render(Container?) {
    try {
      const { item } = this.props;
      const children = item.getChildren();
      const state = XObject.get(this.props.state, item.getId(), {});
      return (
        <Container indent={this.props.indent} className={classNames({
          expanded: state.expanded,
        })}>
          <div
            className={classNames('item', {
              active: item.clickAction == 'navigate' && _.isMatch(x(this.context.next()), item.getNav()),
            })}
            onClick={() => {
              item.onClick();
              if (this.props.item.clickAction == 'navigate') {
                this.context?.navigate?.(item.getNav());
              }
            }}
            onContextMenu={e => {
              e.preventDefault();
              let items = item.contextMenu();
              if (this.props.contextMenu) {
                if (!items)
                  items = [];
                items = items.concat(this.props.contextMenu);
              }
              if (items.length) {
                showContextMenu(e, items);
              }
            }}
          >
            <span className="wrapper" style={{
              marginLeft: this.props.indent * 12,
            }}>
              {children.length > 0 && <Svg name="chevron" className="toggle"

                onClick={e => {
                  e.stopPropagation();
                  state.expanded = !state.expanded;
                }} />}
              <span className="icon" style={{
                backgroundImage: item.getIcon(),
              }} />{item.getTitle() || '---'}
            </span>
          </div>
          {state.expanded && children.length > 0 && (
            <div className="children">
              {children.map(child => (
                <SidebarItemComp key={child.getId()} item={child} indent={this.props.indent + 1} state={this.props.state} />
              ))}
            </div>
          )}
        </Container>
      );

    }

    catch (e) {
      console.log(e);

      return (
        <Container indent={this.props.indent} className={classNames({
          expanded: false,
        })}>
          <div
            onContextMenu={e => {
              e.preventDefault();
              let items = [];
              if (this.props.contextMenu) {
                if (!items)
                  items = [];
                items = items.concat(this.props.contextMenu);
              }
              if (items.length) {
                showContextMenu(e, items);
              }
            }}

            className={classNames('item', {
              // active: _.isMatch(x(this.context.next()), item.getNav()),
            })}>
            <span className="wrapper" style={{
              marginLeft: this.props.indent * 12,
            }}>
              Error
            </span>
          </div>

        </Container>


      );
    }

  }
}

@component
export class Sidebar extends Component<{ rootItem: SidebarItem; state; }> {
  static styles = styled.div``;
  render(Container?) {

    return (
      <>
        <Container
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            e.preventDefault();
            const droppedEntity = JSON.parse(e.dataTransfer.getData('text/plain'));

            if (droppedEntity.type == ObjectType.query) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.query,
                id: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.space) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.space,
                space: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.page) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.page,
                id: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.canvas) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.canvas,
                id: droppedEntity.id,
              }));
            }
          }}
        >
          <SortableCont
            tag="div"
            distance={10}
            onSortEnd={(sortEnd, sortEvent) => {
              console.log(sortEnd, sortEvent);
              this.props.rootItem.moveChild(sortEnd.oldIndex, sortEnd.newIndex);
            }}
          >
            {this.props.rootItem.getChildren().map((item, i) => (
              <SortableEl index={i} key={item.getId()}>
                <SidebarItemComp
                  key={item.getId()}
                  item={item}
                  indent={0}
                  state={this.props.state}
                  contextMenu={[
                    {
                      text: 'Remove from sidebar',
                      onClick: () => {
                        this.props.rootItem.deleteItem(i);
                      }
                    }
                  ]} />
              </SortableEl>
            ))}

          </SortableCont>
        </Container>
      </>
    );
  }
}

export class SidebarItem {
  clickAction: 'handleClick' | 'navigate' = 'navigate';

  constructor(public data) { }
  getChildren(): SidebarItem[] {
    return [];
  }

  moveChild(oldIndex, newIndex) {
  }

  onClick() { }

  getIcon() {
    return null;
  }

  getId(): string {
    return null;
  }

  getTitle(): string {
    return null;
  }

  getNav(): any {
  }

  deleteItem(i) {
  }

  contextMenu(): any {
    return [];
  }



}

