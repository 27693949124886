import { Component, useContext } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { db } from '../db';
import { PropertyField } from './PropertyField';
import { component } from '../component2';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { entityMetaStates } from '../etc/entityMatch';
import { renderAttributes } from "../etc/renderAttributes";
import { getQueuePosition, queueMenuItems, renderQueuePositions } from '../glue/getQueue';
import { showContextMenu } from '../helpers';
import { entityTypeName } from '../etc/entityTypeName';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { XObject } from '../XObject';
import { appState } from '../etc/appState';
import { showPrompt } from '../etc/showPrompt';
import { deleteEntity } from '../etc/deleteEntity';
import { SystemContext } from '../etc/SystemContext';
import { entitySchema } from './EntityView';
import { entityDisplayName } from './entityDisplayName';
import { RenderData } from '../MyNotionDocument/Editor';
import { types } from "../MyNotionDocument/types";

export function entityContextMenuItems({
  id,
  rootId = undefined,
  graphId = undefined,
  context = undefined,
}) {
  const entity = db.entities.findById(id);
  return [
    {
      text: 'Copy ID',
      onClick: () => {
        navigator.clipboard.writeText(id);
      }
    },
    entity && {
      text: entity.stateful ? 'Make stateless' : 'Make stateful',
      onClick: () => {
        entity.stateful = !entity.stateful;
      }
    },
    {
      text: 'View',
      onClick: () => {
        context?.navigate?.({
          type: 'entity',
          id: id,
        })
        // openWindow({
        //   type: WindowType.Notion,
        //   entity: entity._id,
        // })
      },
    },
    {
      text: 'Create new queue',
      onClick: async () => {
        const workspace = defaultWorkspace();
        const queue = XObject.obj({
          entity: id,
          list: [],
          name: await showPrompt('Queue name'),
        });
        XObject.get(workspace, 'queues', []).push(queue);

      }
    },
    ...(rootId ? queueMenuItems(rootId, id, graphId) : []),


    {
      text: 'Delete',

      onClick: () => {
        deleteEntity(id);
      }
    }

  ].filter(Boolean);
}

export const MetaStatesWrapper = styled.div`

&.closed {
    /* .name { */
      text-decoration: line-through;

    /* } */
    opacity: 0.5;
  }

  &.highlight {
    .name {
      /* font-weight: bold; */
      /* text-decoration: underline; */

    }
    background-clip: content-box;

    background-color: #f9ff86;

  }
    &.stateless {
      /* opacity: 0.5; */
    }
`;

export function metaStatesClasses(id) {
  const entity = db.entities.findById(id);
  const metaStates = entityMetaStates(id);
  const closed = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).closedState);
  const highlight = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).highlightState);

  return cx(entity.stateful ? 'stateful' : 'stateless', closed && 'closed', highlight && 'highlight');
}

@component
export class EntityName extends Component<{ id: string }> {
  static styles = styled.span`
    .metaStates {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 10px;
      color: #0c62c3;
    }

    &.noName {
      .name {
        color: #d7d7d7;
      }
    }

    &.closed {
      .name {
        text-decoration: line-through;

      }
      opacity: 0.5;
    }

    &.highlight {
      .name {
        /* font-weight: bold; */
        /* text-decoration: underline; */

      }
      background-color: #f9ff86;

    }
    &.stateless {
      /* opacity: 0.5; */
    }
  `;
  static t = {
    type: styled.span`
      font-size: 9px;
      color: gray;
      margin-right: 4px;
    `,

    queueNumber: styled.span`
      /* font-weight: bold; */
      font-size: 9px;
    `,
  };
  render(Container?) {
    const schema = entitySchema(this.props.id);
    const entity = db.entities.findById(this.props.id);
    const metaStates = entityMetaStates(this.props.id);
    const closed = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).closedState);
    const highlight = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).highlightState);

    const nameValue = entityDisplayName(this.props.id);


    return (
      <Container
        onClick={e => {
          appState.inspecting = {
            type: 'entity',
            id: this.props.id,
          }
        }}
        className={cx(entity.stateful ? 'stateful' : 'stateless', closed && 'closed', highlight && 'highlight', !nameValue && 'noName')}
      >
        <span className="name">
          {nameValue ? (
            <>
                      {schema?.titleAttribute && (() => {
            return entity.attributes?.[schema.titleAttribute.content]?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
          })()}
          {!schema?.titleAttribute && 
            <RenderData ctx={{
              types: types,
            }}
            data={entity.name}
            />

          
          }

            </>
          ) : (
            <>&mdash;</>
          )}
        </span>
      </Container>
    );
  }
}


@component
export class EntityDisplay extends Component<{ id: string, root?: string, graph?: string, menuItems? }> {
  static styles = styled.span`
    .metaStates {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 10px;
      color: #0c62c3;
    }

    &.closed {
      .name {
        text-decoration: line-through;

      }
      opacity: 0.5;
    }

    &.highlight {
    .name {
      /* font-weight: bold; */
      /* text-decoration: underline; */

    }
    background-color: #f9ff86;

    }
    &.stateless {
      /* opacity: 0.5; */
    }
  `;
  static t = {
    type: styled.span`
      font-size: 9px;
      color: gray;
      margin-right: 4px;
    `,

    queueNumber: styled.span`
      /* font-weight: bold; */
      font-size: 9px;
    `,
  };
  render(Container?) {
    // return null;
    const { t } = EntityDisplay;
    const entity = db.entities.findById(this.props.id);
    const context = useContext(SystemContext);
    if (!entity) {
      return (
        <Container
          onContextMenu={e => {
            e.stopPropagation();
            e.preventDefault();
            showContextMenu(e, entityContextMenuItems({
              id: this.props.id,
              rootId: this.props.root,
              graphId: this.props.graph,
              context,

            }).concat(this.props.menuItems || []));
          }}

        >
          Deleted
        </Container>
      )
    }
    const type = entity.type && db.entityTypes.findById(entity.type);
    const metaStates = entityMetaStates(this.props.id);
    const closed = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).closedState);
    const highlight = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).highlightState);
    return (
      <Container
        onClick={e => {
          context?.navigate?.({
            type: 'entity',
            id: this.props.id,
          })
          // appState.inspecting = {
          //   type: 'entity',
          //   id: this.props.id,
          // }
        }}

        className={cx(entity.stateful ? 'stateful' : 'stateless', closed && 'closed', highlight && 'highlight')}
        onContextMenu={e => {
          e.stopPropagation();
          e.preventDefault();
          showContextMenu(e, entityContextMenuItems({
            id: this.props.id,
            rootId: this.props.root,
            graphId: this.props.graph,

          }).concat(this.props.menuItems || []));
        }}
      >
        <span className="name"><PropertyField dblClickEdit object={entity} property="name" /></span> {type && <t.type>{entityTypeName(type._id)}</t.type>} {this.props.root && <t.queueNumber>{renderQueuePositions(this.props.root, entity._id)}</t.queueNumber>}  <span
          style={{
            fontSize: '9px',
            color: '#d58428',
          }}
        >
          {renderAttributes(entity)}</span>
        <span className="metaStates">{metaStates.map(id => defaultWorkspace().metaStates.find(i => i._id == id).name).join(', ')}</span>
      </Container>
    );
  }
}
