import { styled } from "../component";
import { color } from "../components/notionDocument/color";
import { CheckBox } from "./CheckBox";


export const Wrapper = styled.div`
  .entityDot, .dataBindingDot {
    width: 5px;
    height: 5px;
    position: absolute;
    left: -12px;
    /* left: -30px; */
    top: 11px;
    background: ${color('block.entityDot')};
    border-radius: 50%;
    transition: left 0.2s, opacity 0.2s, width 0.2s, height 0.2s, margin 0.2s;
    user-select: none;
    /* user-drag:  */
    opacity: .3;
    cursor: pointer;
  }

  .dataBindingDot {
    background: ${color('block.dataBindingDot')};
  }

  svg {
    user-select: none;
  }
  .meta {
    position: absolute;
    user-select: none;
    display: flex;
  }

  .metaLine {
    font-size: 9px;
    color: ${color('block.metaLine.text')};
    user-select: none;
    display: flex;
  }
  &.dragging {
    opacity: 0.2;
  }
  &.checkItem, &.entity, &.hasCheckbox {
    &.checked > .block {
      /* color: ; */
      text-decoration: line-through ${color('text.checked.line')};

      color: ${color('text.checked')};
    }
    .metaLine {
      /* margin-left: -25px; */
    }
    > .block {
      > input[type="checkbox"], > ${CheckBox} {
        position: absolute;
        left: 0;
        top: 6px;
        padding: 0;
        margin: 0;
      }
      padding-left: 19px;
    }
  }

  &.heading_1 {
    > .block {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.25;
      margin-top: 20px;
    }
  }


  .block {
    position: relative;
    font-size: 14px;
    line-height: 1.5;
    min-height: 22px;

    .editor {
      min-height: 21px;
      &:empty::before {
          /* content: 'Task'; */
          color: #acacac; 
      }

      &:empty:focus::before {
          content: "";
      }

    }
    .grip {
      position: absolute;
      top: 2px;
      left: -21px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: grab;
      path {
        fill: ${color('block.actionIcon')};
      }
      height: 21px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }
    .addBlock {
      position: absolute;
      top: 3px;
      left: -41px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: pointer;
      path, & {
        fill: ${color('block.actionIcon')};
      }
      height: 24px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }


    padding: 3px 0;

    &:not(.mobile) {
      &:hover {
        .grip, .addBlock {
          opacity: 1;
        }
      }

      &:not(:hover) {
        /* .meta {
          opacity: .5;
        } */

        .entityDot {
          /* opacity: .5; */
        }
      }

      :hover {
        .entityDot {
          left: -30px;
        }
      }
    }


    .editor:focus {
      outline: none;
    }
  }

  .children {
    /* padding-left: 18px; */
    padding-left: 24px;
  }
`;
