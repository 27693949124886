import React from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { PropertyField } from './PropertyField';
import { component } from '../component2';
import { Comp } from '../glue/Comp';
import { ViewType } from '../types/ViewType';
import { Conjuction, ExpressionEntryType, renderEntry } from '../etc/expressionQuery';
import { XObject, XTouch, x } from '../XObject';
import { EXPRESSION_QUERY, queryChain, queryScopeAssertions } from '../etc/queryFuncs';
import { ObjectType } from '../types/QueryParentType';
import _ from 'lodash';
import { SortableCont, SortableEl, showContextMenu } from '../helpers';
import { arrayMove } from 'react-sortable-hoc';
import { typesInScope } from "./objectFuncs";
import { Cell } from './Cell';
import { EntityTypesCellType } from './cells';
import { attributesForType } from './EntityView';


@component
export class QueryViewEditor extends Comp<{ window; }> {
  static styles = styled.div`

  `;

  render() {
    let view, scopes: any[], typesScopes;
    if (this.props.window.query) {
      const query = db.queries.findById(this.props.window.query);
      view = query.views?.find?.(v => v._id == this.props.window.view);
      if (!view) {
        if (query.layers) for (const layer of query.layers) {
          view = layer.views.find(v => v._id == this.props.window.view);
          if (view) break;
        }
      }

      scopes = [{type: ObjectType.query, id: query._id}];
      query.query && queryScopeAssertions(queryChain(query), scopes);
      scopes.splice(0, 0, ...scopes);
      scopes = _.uniqBy(scopes, x => x.id);

      XTouch(query.query);

      typesScopes = [ {type: ObjectType.query, id: query._id} ];

    }
    else if (this.props.window.space) {
      const space = db.spaces.findById(this.props.window.space);
      view = space.views?.find?.(v => v._id == this.props.window.view);

      scopes = [{type: ObjectType.space, id: space._id}];
      typesScopes = [ {type: ObjectType.space, id: space._id} ];
    }
    else if (this.props.window.doc) {
      const doc = db.notionDocuments.findById(this.props.window.doc);
      console.log(x(doc), this.props.window.view);
      view = doc.entitySpace?.views?.find?.(v => v._id == this.props.window.view);
      scopes = [{type: ObjectType.page, id: doc._id}];
      typesScopes = [ {type: ObjectType.page, id: doc._id} ];
    }

    return (
      <>
        <div>
          Name: <PropertyField object={view} property="name" />
        </div>
        <div>
          Type: <select value={view.type} onChange={e => view.type = e.target.value}>
            <option value={ViewType.list}>List</option>
            <option value={ViewType.table}>Table</option>
            <option value={ViewType.split}>Split</option>
            <option value={ViewType.graph}>Graph</option>
          </select>
        </div>

        <Cell
          cell={new EntityTypesCellType({
            scopes: typesScopes,
          })}
          get={() => {
            return XObject.get(view, 'entityTypes', []);
          }}
          set={value => {
            view.entityTypes = value;
          }}
        />



        {renderEntry(
          XObject.get(view, 'viewQuery', [EXPRESSION_QUERY, {
          _id: 'root',
          type: ExpressionEntryType.group,
          conjunction: Conjuction.and,
          entries: [],
        }])[1], scopes)}


        {renderEntry(
          XObject.get(view, 'filterQuery', [EXPRESSION_QUERY, {
          _id: 'root',
          type: ExpressionEntryType.group,
          conjunction: Conjuction.and,
          entries: [],
        }])[1], scopes)}

        {view.type == ViewType.table && (() => {
          const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

          const allColumns = ['title'].concat(x(attributes));
          const colTitle = id => {
            if (id == 'title') return 'Title';
            const attr = db.attributeTypes.findById(id);
            return attr?.name || id;
          }

          return (
            <>
              <b>Included</b>
              <SortableCont
                tag="ul"
                onSortEnd={({oldIndex, newIndex}) => {
                  view.columns['@index2'] = arrayMove(x(view.columns['@index2']), oldIndex, newIndex);
                }}
              >
                {view.columns?.['@index2']?.map?.((id,i) => {
                  return (
                    <SortableEl
                      key={id}
                      index={i}
                    >
                    <li key={id}>
                      <span
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Exclude',
                              onClick: () => {
                                view.columns['@index2'] = view.columns['@index2'].filter(i => i != id);
                              }
                            }
                          ]);
                        }}
                      >{colTitle(id)}</span>
                    </li>
                    </SortableEl>
                  );
                })}
              </SortableCont>
              <b>Excluded</b>
              <ul>
                {allColumns.map(id => {
                  if (view.columns?.['@index2']?.includes(id)) return null;
                  return (
                    <li key={id}>
                      <span
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Include',
                              onClick: () => {
                                view.columns['@index2'].push(id);
                              }
                            }
                          ]);
                        }}
                      >{colTitle(id)}</span>
                    </li>
                  )
                })}
              </ul>
            </>
          )
        })()}

        {view.type == ViewType.list && (() => {
          const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

          return (
            <>
                                  <select
                        value={view.groupBy || ''}
                        onChange={e => {
                          view.groupBy = e.target.value;
                        }}
                      >
                        <option value="">No Grouping</option>
                        {attributes.map(a => {
                          const attr = db.attributeTypes.findById(a);
                          if (!attr) return null;
                          return (
                            <option key={a} value={a}>{attr.name}</option>
                          )
                        })}
                      </select>

            </>
          )
        })()}

      </>
    );
  }
}
