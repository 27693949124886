import { XInit } from "./XObject";

const state = XInit(class {
  isMobile
})

function update() {
  state.isMobile = window.innerWidth < 600;

}

update();

setInterval(() => {
  update();
}, 100);

export function isMobile() {
  return state.isMobile;
}
